import React, { Component } from 'react'
import { connect } from 'react-redux';

import * as actions from "../../store/actions";
import { CommonUtils, Role, SELECTOR_ACCOUNT_SCREEN, PREFIX_CUSTODYCD } from "../../utils";
import { emitter } from "utils/EventEmitter";
import SelectorAccountCustomer from './../../components/SelectorAccount/SelectorAccountCustomer';
import SelectorAccountId from './../../components/SelectorAccount/SelectorAccountId';
import Assets from './Assets/Assets';
import OrderBookQuickOrder from './OrderBookQuickOrder/OrderBookQuickOrder';
import PlaceOrderQuickOrder from './PlaceOrderQuickOrder/PlaceOrderQuickOrder';
import PortfolioQuickOrder from './PortfolioQuickOrder/PortfolioQuickOrder';
// import DraggableModal from 'components/Modal/DraggableModal';
import DragableSkyLight from 'components/screeners/modal/dragableSkyLight';

import { Modal, ModalBody, } from 'reactstrap';

import './QuickOrder.scss'
import { FormattedMessage } from 'react-intl';
import TextToolTip from 'components/TextToolTip';

const dialogStyles = {
    width: '350px',
    height: 'calc(100vh - 60px)',
    maxHeight: '100vh',
    overflowX: 'hidden',
    padding: '0px 0px 0px 0px',
    left: '85px',
    top: '0px',
    opacity: '1',
    zIndex: 98,
    borderRadius: '10px',
    // border: '2px solid #888',
    backgroundColor: 'unset',
    // border: '1px solid rgba(45, 44, 53, 1)',
    background: 'transparent',
    boxShadow: '-1px 2px 10px #000000',
    transform: 'translate(-10%,-10%)',
    // transform: 'translate(500px,350px)',
    margin: 'unset',
    border: '1px solid #FFF'
}

const DF_LIST_ITEM_SELECT = [
    {
        id: "quick-order.tab.place-order",
        key: 1
    },
    {
        id: "quick-order.tab.order-book",
        key: 2
    },
    {
        id: "quick-order.tab.openPosition",
        key: 3
    },
    {
        id: "quick-order.tab.assets",
        key: 4
    }
]
class QuickOrder extends Component {


    initialState = {
        itemSelectKey: 1,
        setPlaceOrderHeigh: false,
        isDisableEscapeClose: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
        this.accountSelectRef = React.createRef();
        this.selectAccountCustomerRef = React.createRef();
        this.listenToTheEmitter();
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate = (prevProps) => {
        const { isOpen, editOnly } = this.props;
        const { isOpen: prevOpen } = prevProps;
        if (isOpen !== prevOpen && isOpen && !editOnly) {
            this.onFirstRefFocus();
        }
    }

    onFirstRefFocus = () => {
        if (this.props.focusOnRender) return; // focus ngoài mặc định khi mới mở
        if (this.isBroker()) {
            let inputRef = this.selectAccountCustomerRef && this.selectAccountCustomerRef.current && this.selectAccountCustomerRef.current.getInputRef();
            if (inputRef && inputRef.current) {
                inputRef.current.focus();
                // inputRef.current.setSelectionRange(0, 6);
                // let value = inputRef.current;
                let timer = setTimeout(() => {
                    let value = inputRef.current ? inputRef.current.value : '';
                    if (value) {
                        inputRef.current.setSelectionRange(PREFIX_CUSTODYCD.length, value.length);
                    }
                    clearTimeout(timer)
                }, 100);
            }
        } else {
            emitter.emit('FOCUS_SYMBOL');
        }
    }

    setTradeOrderHeigh = () => {
        this._setState({ setPlaceOrderHeigh: !this.state.setPlaceOrderHeigh });
    }

    callback = (data, callBack) => {
        if (data) {
            this.onChangeItemSelect(data)
        }
        if (callBack) callBack();

    }

    listenToTheEmitter() {
        emitter.on('onChangeTabActiveQuickOrder', this.callback);
    }

    componentWillUnmount() {
        emitter.removeListener('onChangeTabActiveQuickOrder', this.callback);
        this.mounted = false
    }

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    onChangeItemSelect = (key) => {
        this._setState({ itemSelectKey: key })
    }

    toggleTradeAuthQuickOrderModal = () => {
        const { setIsOpenModalHaveData, listIsOpenModal } = this.props
        setIsOpenModalHaveData("QuickOrder", {
            isOpenModalTradeQuickOrder: !listIsOpenModal["QuickOrder"].isOpenModalTradeQuickOrder
        })
    };

    onCloseTradeAuthQuickOrder = (event) => {
        const { setIsOpenModalHaveData, listIsOpenModal } = this.props
        setIsOpenModalHaveData("QuickOrder", {
            isOpenModalTradeQuickOrder: false
        })
    }

    setOpenQuickOrder = () => {
        const { setIsOpenModalHaveData, listIsOpenModal, updatePlaceQuickOrder, orderTypeUse } = this.props
        const { editOnly } = { ...this.props.placeQuickOrderCurrent }
        // Clear trạng thái editOnly nếu đang set
        if (editOnly) {
            // updatePlaceQuickOrder({
            //     editOnly: false
            // }, orderTypeUse)
            this.props.clearDataPlaceQuickOrder(orderTypeUse, ['symbolCurrent', 'action'])
        }
        setIsOpenModalHaveData("QuickOrder", {
            isOpenQuickOrder: false,
        })
    }

    calculateQuickOrderHeigh = () => {
        let quickOrderBlock = document.getElementById(`quick-order-block-to`);
        if (quickOrderBlock) {
            return (quickOrderBlock.offsetHeight + 2 + 'px');
        } else {
            return '591px';
        }
    }

    setIsDisableEscapeClose = async (isStatus) => {
        this._setState({ isDisableEscapeClose: isStatus })
    }

    render() {
        const { custodycd, itemSelectKey, isDisableEscapeClose } = this.state;
        const { isOpen, accountQuickOrderInfo, listIsOpenModal, placeQuickOrderCurrent } = this.props
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }
        let listReconfirmOrder = { ...placeQuickOrderCurrent }

        const { editOnly } = { ...this.props.placeQuickOrderCurrent }

        let quickOrderHeight = this.calculateQuickOrderHeigh();

        //     <DraggableModal
        //     isOpen={isOpen}
        //     toggle={this.toggleTradeAuthQuickOrderModal}
        //     onClose={this.setOpenQuickOrder}
        //     titleId='common.confirm-trade'
        //     showHeader={false}
        //     backdrop={false}
        // >
        let fullName = this.isBroker() ? (curCustomer.fullName || '') : this.props.customerFullName;
        let dialogStyleToApply = dialogStyles;
        const isMobileScreen = window.innerWidth <= 769 && window.innerWidth != 0;
        if (itemSelectKey === 1) {
            dialogStyleToApply = { ...dialogStyleToApply, height: `${quickOrderHeight}` };
        }

        if (isMobileScreen) {
            dialogStyleToApply = { ...dialogStyleToApply, top: `105px`, left: '3px' }; // header +  menutool nnằm ngang
        }
        if (!editOnly) {
            return (

                isOpen && <DragableSkyLight
                    isOpen={isOpen}
                    close={this.setOpenQuickOrder}
                    // title={'common.confirm-trade'}
                    dialogStyles={dialogStyleToApply}
                    isLight={true}
                    className={'quick-order-skylight ' + (itemSelectKey === 1 ? 'place-quick-order' : '')}
                    defaultLeft={false}
                    isDisableEscapeClose={isDisableEscapeClose}
                >
                    <div id='quick-order-block-to' className={"quick-order " + (isOpen ? "quick-order-open" : "quick-order-close")}>
                        {isOpen &&
                            <div className="block-quick-order">
                                <div className="header-quick-order dragHandler">
                                    <div className="title-header txt---700-14-20">
                                        <FormattedMessage id="quick-order.title" />
                                    </div>
                                    <div className="btn-icon-fm" onClick={this.setOpenQuickOrder} onTouchStart={this.setOpenQuickOrder}>
                                        <i className="fal fa-times"></i>
                                    </div>
                                </div>

                                <div className="block-selector-account">
                                    {<SelectorAccountCustomer
                                        ref={this.selectAccountCustomerRef}
                                        curCustomer={curCustomer}
                                        selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER}
                                        saveAccountInfo={true} // Lưu tài khoản với môi giới
                                    />}
                                    <SelectorAccountId
                                        selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER}
                                        accountInfo={accountQuickOrderInfo}
                                    />
                                    <div class="selector-account-customer account-name custom-form-group txt---500-14-20 txt-left">
                                        <TextToolTip
                                            tooltipText={fullName}
                                            targetID='customer-name-quick-order'
                                        ></TextToolTip>
                                        <div id='customer-name-quick-order' tabIndex="-1" className="custom-form-control txt---500-14-20" dir="rtl">{fullName}</div>
                                    </div>

                                </div>
                                <div className="line-divide"></div>
                                <div className="list-item-quick-order-select">
                                    {DF_LIST_ITEM_SELECT.map((item, key) => {
                                        return (
                                            <div className="item-select" onClick={() => this.onChangeItemSelect(item.key)}>
                                                <div className={"item-text txt---500-14-20 " + (item.key === itemSelectKey ? "active" : "")}>
                                                    <FormattedMessage id={item.id} />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                {
                                    itemSelectKey === 1 &&
                                    <div className="block-info-symbol" >
                                        <PlaceOrderQuickOrder
                                            orderTypeUse={this.props.orderTypeUse}
                                            setTradeOrderHeigh={this.setTradeOrderHeigh}
                                            setIsDisableEscapeClose={this.setIsDisableEscapeClose}
                                        />
                                    </div>
                                }
                                {
                                    itemSelectKey === 2 &&
                                    <div className="block-order-book" >
                                        <OrderBookQuickOrder
                                            onChangeItemSelect={this.onChangeItemSelect}
                                            orderTypeUse={this.props.orderTypeUse}
                                        />
                                    </div>
                                }
                                {
                                    itemSelectKey === 3 &&
                                    <div className="block-category" >
                                        <PortfolioQuickOrder
                                            onChangeItemSelect={this.onChangeItemSelect}
                                            orderTypeUse={this.props.orderTypeUse}
                                        />
                                    </div>
                                }
                                {
                                    itemSelectKey === 4 &&
                                    <div className="item-total-assets" >
                                        <Assets
                                            orderTypeUse={this.props.orderTypeUse}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </div >
                </DragableSkyLight>

            )
        } else {
            return (
                isOpen && <Modal
                    isOpen={isOpen}
                    centered={true}
                    className="quick-order.edit-title"
                >
                    <ModalBody className="quick-order-modal-body">
                        <div id='quick-order-block-to' className={"quick-order edit-only " + (isOpen ? "quick-order-open" : "quick-order-close")}>
                            {isOpen &&
                                <div className="block-quick-order">
                                    <div className="header-quick-order">
                                        <div className="title-header txt---700-14-20">
                                            <FormattedMessage id="quick-order.edit-title" />
                                        </div>
                                        <div className="btn-icon-fm" onClick={this.setOpenQuickOrder}>
                                            <i className="fal fa-times"></i>
                                        </div>
                                    </div>

                                    <div className="block-selector-account">
                                        {<SelectorAccountCustomer
                                            curCustomer={curCustomer}
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER}
                                            saveAccountInfo={true} // Lưu tài khoản với môi giới
                                            editOnly={editOnly} // true nếu không cho chọn tk careby 
                                        />}
                                        <SelectorAccountId
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER}
                                            accountInfo={accountQuickOrderInfo}
                                            editOnly={editOnly} // true nếu không cho chọn tk careby
                                        />
                                        <div class="selector-account-customer account-name custom-form-group txt---500-14-20 txt-left">
                                            <TextToolTip
                                                tooltipText={fullName}
                                                targetID='customer-name-quick-order'
                                            ></TextToolTip>
                                            <div id='customer-name-quick-order' tabIndex="-1" className="custom-form-control txt---500-14-20" dir="rtl">{fullName}</div>
                                        </div>

                                    </div>
                                    <div className="line-divide"></div>
                                    <div className="block-info-symbol" >
                                        <PlaceOrderQuickOrder
                                            orderTypeUse={this.props.orderTypeUse}
                                            setIsDisableEscapeClose={this.setIsDisableEscapeClose}
                                            editOnly={editOnly}
                                        />
                                    </div>
                                </div>
                            }
                        </div >
                    </ModalBody>
                </Modal >
            )
        }
    }
}

const mapStateToProps = state => {

    let _currentLayoutPageActive = "C&B"
    let _placeQuickOrder = state.orderNew.placeQuickOrder
    // const currentSymbol = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive] && _placeQuickOrder[_currentLayoutPageActive]["symbolCurrent"]
    // const currentSymbol = _currentLayoutPageActive && CommonUtils.getObjIsVal(_placeQuickOrder, "C&B") && _placeQuickOrder[_currentLayoutPageActive]["symbolCurrent"]
    const currentSymbol = CommonUtils.getObjIsVal(_placeQuickOrder, _currentLayoutPageActive + "|symbolCurrent")
    const quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]]
    const placeQuickOrderCurrent = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive]
    const _accountQuickOrderInfo = placeQuickOrderCurrent && placeQuickOrderCurrent["accountQuickOrderInfo"]
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    return {
        role: state.user.userInfo.role,
        custodycd: state.user.userInfo.custodycd,
        accountQuickOrderInfo: _accountQuickOrderInfo,
        listIsOpenModal: state.layout.listIsOpenModal["C&B"],
        placeQuickOrderCurrent: placeQuickOrderCurrent,
        customerFullName: state.user.customerFullName,
        currentSymbol: currentSymbol,
        focusOnRender: state.orderNew.focusOnRender
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickOrder);
