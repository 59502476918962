import React, { Component } from "react";
import { push } from "connected-react-router";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

// const ORDER_TYPES_KEY = { LIMIT: 'LIMIT', MARKET: 'MARKET', CONDITION: 'CONDITION' };
import { FormattedPrice } from '../../../../../components/Formating';
import PriceInputOrder from '../../../../../components/Input/PriceInputOrder';
import { accountService, tradeApiService, userService } from "../../../../../services";
import * as actions from "../../../../../store/actions";
import {
    ACTION_USER_ORDER, listPercentOrder, ACTION_METHOD_CONDITION,
    CommonUtils, CommonWidgetUtils,
    derivativeExchanges, EXCHANGE, LoadStatus, ORDER_TYPE_USE, orderActions, Random, Role, ToastUtil, TYPE_DAY, KeyCodeUtils, CommonOrderUtils
} from "../../../../../utils";

import './ConditionOrderConditionOCO.scss';
import moment from "moment/moment";
import DatePicker from '../../../../../components/Input/DatePicker';
import config from '../../../../../combineConfig';
import { emitter } from 'utils/EventEmitter';
import { TYPE_ACCOUNT } from './../../../../../utils/constants';
import QuantityInputOrder from './../../../../../components/Input/QuantityInputOrder';
class ConditionOrderConditionOCO extends Component {

    initialState = {
        loadStatus: LoadStatus.NONE,
        //trang thai modal canh bao
        showRateWanningPopup: false,
        rateWanningCallback: null,

        accountInfo: {},

        msgErrQtty: "",
        msgErrPrice: "",
        listOrderPriceSelect: [],
        sysvar: null,
        orderPriceStep: null,

        stopLimitPriceCheck: 0,
        stopLoPriceCheck: 0,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }

        // const currentDate = new Date(this.props.currentDate * 1000);
        // currentDate.setHours(0, 0, 0, 0);
        // this.state.toDate = currentDate;
        // this.state.fromDate = currentDate;

        this.priceOrderRef = React.createRef();
        this.quantityRef = React.createRef();
        this.radioTypeDayRef = React.createRef()
        this.datePickerRef = React.createRef()
        this.btnPlaceOrderRef = React.createRef()
        this.listenToTheEmitter();
    };

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        const { currentSymbol, config, updatePlaceQuickOrder, orderTypeUse } = this.props;
        this.getSysvar();
        this.renderPrice();
        if (currentSymbol) {

        };
    }

    callback = (data) => {
        this.renderPrice(data ? data : '');
    }

    listenToTheEmitter() {
        emitter.on("DATA_INSTRUMENT_QUICK_ORDER", this.callback);
    }

    componentWillUnmount() {
        emitter.removeListener("DATA_INSTRUMENT_QUICK_ORDER", this.callback);
        this.mounted = false
    }

    renderPrice = (instrument = {}) => {
        // //console.log("renderPrice1", instrument)
        const { currentSymbol, quote, accountQuickOrderInfo, placeQuickOrderCurrent, actionMethodConditon } = this.props;
        const { typeAccount } = { ...accountQuickOrderInfo }
        const { action } = { ...placeQuickOrderCurrent }
        let value = "0.00"
        let _instrument = null
        if (currentSymbol) {
            if (instrument && Object.keys(instrument).length > 0) {
                _instrument = instrument
            } else {
                let instruments = CommonUtils.getInstruments();
                _instrument = instruments[currentSymbol.symbol]
            }
            let isCheck = CommonOrderUtils.checkCompareCeilFloorReference({ quote, instrument: _instrument })
            //console.log("binh_renderPrice_1", { instrument, isCheck })
            if (quote && _instrument) {
                if (isCheck) {
                    //console.log("binh_renderPrice_2", { instrument, isCheck })
                    if (typeAccount === TYPE_ACCOUNT.BASE && actionMethodConditon === ACTION_METHOD_CONDITION.TOP_WAIT_MATCH) {
                        if (action === orderActions.BUY) {
                            //console.log("binh_renderPrice_3", { instrument, isCheck })
                            value = CommonUtils.getPriceInOutHourByKey(_instrument, "B1", quote.reference)
                        } else {
                            //console.log("binh_renderPrice_4", { instrument, isCheck })
                            value = CommonUtils.getPriceInOutHourByKey(_instrument, "S1", quote.reference)
                        }
                        //console.log("binh_renderPrice_5", { value })
                    }
                    else {
                        value = CommonUtils.getPriceInOutHourByKey(_instrument, "CP", quote.reference)
                        //console.log("binh_renderPrice_6", { value })
                    }
                } else {
                    value = quote.reference
                }
            }
        }

        this._setState({
            stopLimitPriceCheck: value,
            stopLoPriceCheck: value,
        })
    }

    getSysvar = async () => {
        const { currentSymbol, quote, accountQuickOrderInfo, currentAccount, userInfo, setIsOpenModalHaveData } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            userService.getSysvarByTypeAccount(true)
                .then(responses => {
                    let [orderDate, orderPriceStep] = responses
                    if (orderDate) {
                        this._setState({ sysvar: orderDate[0] }
                            , () => { this.setDateDisplay() })
                    }
                    if (orderPriceStep) {
                        this._setState({ orderPriceStep: orderPriceStep[0] })
                    }
                }).catch(error => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                })
        }
        else {
            userService.getSysvarByTypeAccount(false)
                .then(responses => {
                    let [orderDate, orderPriceStep] = responses
                    if (orderDate) {
                        this._setState({ sysvar: orderDate[0] }
                            , () => { this.setDateDisplay() })
                    }
                    if (orderPriceStep) {
                        this._setState({ orderPriceStep: orderPriceStep[0] })
                    }
                }).catch(error => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                })
        }
    }


    setDateDisplay = () => {
        const { orderConditionOCO, currentSymbol, accountQuickOrderInfo } = this.props
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        let currentDate = this.props.currentDate * 1000;
        const { sysvar } = this.state;
        const { fromDate, toDate, validUntilOrder } = orderConditionOCO
        let _toDate = currentDate

        if (validUntilOrder === TYPE_DAY.MUCH_DAY) {
            // Nếu có mã rồi thì check xem có ngày đáo hạn không nếu có có thì lấy min((ngày hệ thống + số ngày tối đa), ngày đáo hạn)
            let _lastTradingDate = null
            let numberToMax = (sysvar && sysvar.varvalue * 24 * 60 * 60 * 1000) || 0 //number config flex
            let systemDate = currentDate + numberToMax

            if (typeAccount === TYPE_ACCOUNT.FDS) {
                let instruments = CommonUtils.getInstruments();
                const instrument = currentSymbol ? instruments[currentSymbol.symbol] : {};
                _lastTradingDate = (instrument && instrument["LTD"]) || ""

                if (_lastTradingDate) {
                    _toDate = Math.min(systemDate, CommonUtils.convertDateToTime(_lastTradingDate))
                } else {
                    _toDate = systemDate
                }
            } else {
                _toDate = systemDate
            }
        }

        this.props.updatePlaceQuickOrderConditon({
            fromDate: currentDate,
            toDate: _toDate,
            toDateConst: _toDate
        }, this.props.orderTypeUse)
    }

    onStopPriceLoChanged = (price, valid, validStep, isValidMarketPrice = true) => {
        //console.log("onStopPriceChanged", price, valid, validStep)
        const { updatePlaceQuickOrderConditon } = this.props
        updatePlaceQuickOrderConditon({
            stopPriceLo: price || 0,
            quoteStopPriceLoValid: valid,
            stepStopPriceLoValid: validStep,
        }, this.props.orderTypeUse)
    };


    onStopPriceChanged = (price, valid, validStep, isValidMarketPrice = true) => {
        const { orderPriceStep } = this.state
        // //console.log("onStopPriceChanged", price, price + 0.5, valid, validStep)
        const { placeQuickOrderCurrent, accountQuickOrderInfo, quote } = this.props;
        const { typeAccount } = { ...accountQuickOrderInfo }
        const { action } = { ...placeQuickOrderCurrent }
        const { updatePlaceQuickOrderConditon } = this.props

        let _quotePrice = 0
        let _orderPriceStep = orderPriceStep && orderPriceStep.varvalue || 0
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            _quotePrice = (action === orderActions.BUY ? (Number(price) + Number(_orderPriceStep)) : (Number(price) - _orderPriceStep))
        } else {
            _quotePrice = (action === orderActions.BUY ? (Number(price) + Number(_orderPriceStep * 1000)) : (Number(price) - _orderPriceStep * 1000))
        }


        let ceilPrice = 0
        let floorPrice = 0
        let _validQuotePrice = true
        if (quote) {
            ceilPrice = CommonUtils.divide(quote.ceiling, 1)
            floorPrice = CommonUtils.divide(quote.floor, 1)
        }

        if (floorPrice > _quotePrice || ceilPrice < _quotePrice) {
            _validQuotePrice = false
        }

        updatePlaceQuickOrderConditon({
            stopPrice: price || 0,
            stepStopPriceValid: validStep,
            quoteStopPriceValid: valid,

            quotePrice: _quotePrice < 0 ? 0 : _quotePrice,
            quotePriceValid: _validQuotePrice,
            stepPriceValid: validStep,
        }, this.props.orderTypeUse)
    };

    onQuotePriceChanged = (price, valid, validStep, isValidMarketPrice = true) => {
        const { updatePlaceQuickOrderConditon } = this.props
        updatePlaceQuickOrderConditon({
            quotePrice: price || 0,
            quotePriceValid: valid,
            stepPriceValid: validStep,
        }, this.props.orderTypeUse)
    };


    onQuantityChanged = (quantity, valid) => {
        const { updatePlaceQuickOrderConditon } = this.props
        updatePlaceQuickOrderConditon({
            quantity: quantity,
            quantityValid: valid
        }, this.props.orderTypeUse)
    };

    componentDidUpdate(prevProps, prevState) {
        const { orderConditionOCO, accountQuickOrderInfo, placeQuickOrderCurrent, actionMethodConditon, quote
        } = this.props;
        const { typeAccount } = { ...accountQuickOrderInfo }
        const { toDate, validUntilOrder } = { ...orderConditionOCO }
        const { action } = placeQuickOrderCurrent
        const {
            orderConditionOCO: prevOrderConditionOCO,
            accountQuickOrderInfo: prevAccountQuickOrderInfo,
            placeQuickOrderCurrent: prevPlaceQuickOrderCurrent,
            actionMethodConditon: prevActionMethodConditon,
            quote: prevQuote,
        } = prevProps;

        const { validUntilOrder: prevValidUntilOrder } = { ...prevOrderConditionOCO }
        const { typeAccount: prevTypeAccount } = { ...prevAccountQuickOrderInfo }
        const { action: prevAction } = prevPlaceQuickOrderCurrent

        if (!(_.isEqual(orderConditionOCO, prevOrderConditionOCO))) {
            if ((!toDate) || (validUntilOrder !== prevValidUntilOrder)) {
                this.renderPrice();
                this.setDateDisplay()
            }
        }

        if (actionMethodConditon !== prevActionMethodConditon) {
            this.renderPrice();
        }

        if (!(_.isEqual(placeQuickOrderCurrent, prevPlaceQuickOrderCurrent))) {
            if (action !== prevAction) {
                this.renderPrice();
            }
        }

        if (typeAccount !== prevTypeAccount) {
            this.getSysvar()
        }

        if (!(_.isEqual(quote, prevQuote))) {
            this.renderPrice();
        }
    }

    getPlaceOrderData = () => {
        const { securitiesInfo, accountInfo } = this.state
        const { quote, accountQuickOrderInfo, currentSymbol, placeQuickOrderCurrent, actionMethodConditon } = this.props;
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }
        const { stopPriceLo, stopPrice, quotePrice, quantity, toDate, fromDate, validUntilOrder } = { ...this.props.orderConditionOCO };

        const { action } = placeQuickOrderCurrent
        let ceilPrice = null;
        let floorPrice = null;
        if (quote != null) {
            ceilPrice = quote['ceiling'];
            floorPrice = quote['floor'];
        }

        //console.log("getPlaceOrderData", this.props.orderConditionOCO)
        let _fromDate = moment(new Date(fromDate)).format(config.app.SERVER_DATE_FORMAT)
        let _toDate = moment(new Date(toDate)).format(config.app.SERVER_DATE_FORMAT)
        const data = {
            // "side": action === orderActions.BUY ? 'buy' : 'sell',
            // "instrument": currentSymbol && currentSymbol.id,
            // "qtty": Number(quantity),
            // "fromdate": "13/07/2021",
            // "todate": "14/07/2021",
            // "timetype": "G",
            // "activetype": "C",
            // "type": "limit",
            // "orderprice": Number(stopPrice),
            // "price": Number(quotePrice)

            "side": action === orderActions.BUY ? 'buy' : 'sell',
            "instrument": currentSymbol && currentSymbol.id,
            "qtty": Number(quantity),
            "priceLO": Number(stopPriceLo),
            "sideLO": action === orderActions.BUY ? 'buy' : 'sell',
            "instrumentLO": currentSymbol && currentSymbol.id,
            "qttyLO": Number(quantity),
            "price": Number(quotePrice),
            "orderprice": Number(stopPrice),
            "fromdate": _fromDate,
            "todate": validUntilOrder === TYPE_DAY.DURING_DAY ? _fromDate : _toDate,
            "timetype": validUntilOrder,
            "activetype": actionMethodConditon,
            "type": "limit"
        };

        return data;
    }

    validateValue = () => {
        const { stopLimitPriceCheck, stopLoPriceCheck } = this.state
        const { orderConditionOCO, quote, accountQuickOrderInfo, placeQuickOrderCurrent, availableTrade } = this.props
        const { stopPriceLo, stepStopPriceLoValid, quoteStopPriceLoValid, stopPrice, quoteStopPriceValid,
            stepStopPriceValid, quotePrice, stepPriceValid, quotePriceValid,
            quantity, quantityValid, validUntilOrder, fromDate, toDate, toDateConst } = orderConditionOCO
        const { action } = placeQuickOrderCurrent
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        let ceilPrice = 0;
        let floorPrice = 0;
        if (quote != null) {
            if (typeAccount === TYPE_ACCOUNT.FDS) {
                ceilPrice = CommonUtils.divide(quote.ceiling, 1)
                floorPrice = CommonUtils.divide(quote.floor, 1)
            } else {
                ceilPrice = CommonUtils.divide(quote.ceiling, 1000)
                floorPrice = CommonUtils.divide(quote.floor, 1000)
            }
        }

        let _stopPrice = 0
        let _quotePrice = 0
        let _stopPriceLo = 0
        let _stopLimitPriceCheck = 0
        let _stopLoPriceCheck = 0
        let _valueQttyMax = 0
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            _stopPrice = CommonUtils.divide(stopPrice, 1)
            _quotePrice = CommonUtils.divide(quotePrice, 1)
            _stopPriceLo = CommonUtils.divide(stopPriceLo, 1)
            _stopLimitPriceCheck = CommonUtils.divide(stopLimitPriceCheck, 1)
            _stopLoPriceCheck = CommonUtils.divide(stopLoPriceCheck, 1)
        } else {
            _stopPrice = CommonUtils.divide(stopPrice, 1000)
            _quotePrice = CommonUtils.divide(quotePrice, 1000)
            _stopPriceLo = CommonUtils.divide(stopPriceLo, 1000)
            _stopLimitPriceCheck = CommonUtils.divide(stopLimitPriceCheck, 1000)
            _stopLoPriceCheck = CommonUtils.divide(stopLoPriceCheck, 1000)
        }


        let _fromDate = new Date(fromDate)
        let _toDate = new Date(toDate);
        let _toDateConst = new Date(toDateConst);
        _fromDate = _fromDate.setHours(0, 0, 0, 0);
        _fromDate = _fromDate + 86400000 // + thêm 1 ngày để check điều kiện > hơn ngày hiện tại
        _toDate = _toDate.setHours(0, 0, 0, 0);
        _toDateConst = _toDateConst.setHours(0, 0, 0, 0);

        if (action === orderActions.BUY) {
            if (typeAccount === TYPE_ACCOUNT.FDS) {
                _valueQttyMax = parseFloat(availableTrade.maxbuyqtty) || 0
            } else {
                _valueQttyMax = parseFloat(availableTrade.maxqtty) || 0
            }
        } else {
            if (typeAccount === TYPE_ACCOUNT.FDS) {
                _valueQttyMax = parseFloat(availableTrade.maxsellqtty) || 0
            } else {
                _valueQttyMax = parseFloat(availableTrade.trade) || 0
            }
        }

        // //console.log("validateValue1", orderConditionOCO, _stopPrice, _quotePrice, _stopPriceLo)
        // //console.log("validateValue2", stop_price_lo, stop_price_limit, id_qtty_max)
        // //console.log("validateValue3", _stopLoPriceCheck, _stopPriceLo)
        if (orderConditionOCO) {
            if (_stopPriceLo) {

                if (validUntilOrder === TYPE_DAY.DURING_DAY) {
                    if (!quoteStopPriceLoValid && !!_stopPriceLo) {
                        ToastUtil.error('FE003013');
                        return false
                    } else if (!stepStopPriceLoValid && quoteStopPriceLoValid && !!_stopPriceLo) {
                        ToastUtil.error('FE003018')
                        return false
                    }
                }

                if (_stopLoPriceCheck) {
                    let _value = Number(_stopLoPriceCheck)
                    if (action === orderActions.BUY) {
                        if (_stopPriceLo > _value) {
                            ToastUtil.error('FE003010');
                            return false
                        }
                    } else {
                        if (_stopPriceLo < _value) {
                            ToastUtil.error('FE003010');
                            return false
                        }
                    }

                }

            } else {
                if (_stopPriceLo < 0 || _stopPriceLo === 0) {
                    ToastUtil.error('FE003011');
                    return false
                }
            }

            if (_stopPrice) {

                if (validUntilOrder === TYPE_DAY.DURING_DAY) {
                    if (!quoteStopPriceValid && !!_stopPrice) {
                        ToastUtil.error('FE003012');
                        return false
                    } else if (!stepStopPriceValid && quoteStopPriceValid && !!_stopPrice) {
                        ToastUtil.error('FE003015')
                        return false
                    }
                }
                if (_stopLimitPriceCheck) {
                    let _value = Number(_stopLimitPriceCheck)
                    if (action === orderActions.BUY) {
                        if (_stopPrice < _value || _stopPrice == _value) {
                            ToastUtil.error('FE003001');
                            return false
                        }
                    } else {
                        if (_stopPrice > _value || _stopPrice == _value) {
                            ToastUtil.error('FE003001');
                            return false
                        }
                    }

                }

            } else {
                if (_stopPrice < 0 || _stopPrice === 0) {
                    ToastUtil.error('FE003002');
                    return false
                }
            }


            if (_quotePrice) {

                if (validUntilOrder === TYPE_DAY.DURING_DAY) {

                    if (!quotePriceValid && !!_quotePrice) {
                        ToastUtil.error('FE003004');
                        return false
                    } else if (!stepPriceValid && quotePriceValid && !!_quotePrice) {
                        ToastUtil.error('FE003016')
                        return false
                    }
                }

                if (action === orderActions.BUY) {
                    if (_quotePrice < _stopPrice || _quotePrice == _stopPrice) {
                        ToastUtil.error('FE003005');
                        return false
                    }
                } else {
                    if (_quotePrice > _stopPrice || _quotePrice == _stopPrice) {
                        ToastUtil.error('FE003005');
                        return false
                    }
                }
            } else {
                ToastUtil.error('FE003003');
                return false
            }

            if (quantity) {
                if (quantity == "0") {
                    ToastUtil.error('FE003006');
                    return false
                }

                if (!quantityValid) {
                    ToastUtil.error('FE003017');
                    return false
                }

                if (validUntilOrder === TYPE_DAY.DURING_DAY) {
                    if (quantity > _valueQttyMax) {
                        ToastUtil.error('FE003014');
                        return false
                    }
                }
            } else {
                ToastUtil.error('FE003006');
                return false
            }

            if (validUntilOrder === TYPE_DAY.MUCH_DAY) {
                if (_fromDate && _toDate && _toDateConst) {
                    if (_toDate > _toDateConst) {
                        ToastUtil.error('FE003019');
                        return false
                    }
                    if (_fromDate > _toDate) {
                        ToastUtil.error('FE003020');
                        return false
                    }
                }
            }
        }

        return true
    }



    precheck = (doOrder) => {
        let data = this.getPlaceOrderData();
        const { quote, accountQuickOrderInfo, intl, userInfo } = this.props;
        const { action, quantity } = { ...this.props.orderConditionOCO }
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }


        //validate
        if (!this.validateValue()) {
            return
        }

        let isBroker = this.isBroker();
        let requestId = (isBroker ? 'B' : 'C') + Random.randomOrderRequestId(userInfo.username)

        const precheckAndCb = () => {
            tradeApiService.initTwoFactorAuth({ accountId: currentAccountId })
                .then((data) => {
                    if (data) {
                        const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                        this.props.updateInfoCheckAuth({
                            transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                        })
                        this.props.setIsOpenModalHaveData("QuickOrder", {
                            isOpenModalTradeQuickOrder: true,
                            onTradeQuickOrderAuthenCallback: (authInfo) => {
                                this.callApiDoPlaceOrder(authInfo, requestId);
                            }
                        })
                    }
                })
                .catch(error => {
                    this._setState({ loadStatus: LoadStatus.FAIL })
                    ToastUtil.errorApi(error, 'CM2');
                });
        };
        accountService.precheckConditionOCO(currentAccountId, data)
            .then((data) => {
                precheckAndCb();
            }).catch(error => {
                ToastUtil.errorApi(error, 'trade.place-order.place-order-fail');
            });
    }


    placeOrder = async () => {
        if (this.props.isMobile) window.scrollTo(0, 0);
        const { securitiesInfo, accountInfo } = this.state
        const { quote, accountQuickOrderInfo, showReconfirmOrderConfig, orderTypeUse, currentSymbol } = this.props;
        const { action, quantity, validUntilOrder } = { ...this.props.orderConditionOCO }
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }


        if (currentAccountId == null) {
            return;
        }


        if (quote && currentSymbol && currentSymbol.id) {
            let isCheck = await CommonOrderUtils.checkInfoQuote(currentSymbol.id, quote)
            if (!isCheck) {
                return
            }
        }

        if (typeAccount === TYPE_ACCOUNT.BASE && validUntilOrder === TYPE_DAY.MUCH_DAY) {
            this.props.setIsOpenModalHaveData("QuickOrder", {
                dataShow: {
                    noteMessageId: ["CM000003"]
                },
            })
        }

        this.props.updatePlaceQuickOrder({
            actionUserOrder: ACTION_USER_ORDER.PLACE_ORDER,
        }, orderTypeUse)


        const instrument = {
            EX: quote && quote.exchange,
            ST: quote && quote.StockType
        }

        this.precheck();
    }

    onFinishPlaceOrder = () => {
        const { clearDataPlaceQuickOrder, setIsOpenModalHaveData } = this.props
        CommonUtils.middlewareOrder()
        setIsOpenModalHaveData("QuickOrder", {
            isOpenModalTradeQuickOrder: false,
            loadStatusQuickOrderModalTrade: LoadStatus.NONE,
            dataShow: {},
        })
    };

    focusRadioTypeDay = () => {
        this.radioTypeDayRef.current && this.radioTypeDayRef.current.focus();
    };

    focusDatePicker = () => {
        this.datePickerRef.current && this.datePickerRef.current.flatpickr.input.focus();
    };

    focusBtnPlaceOrder = () => {
        this.btnPlaceOrderRef.current && this.btnPlaceOrderRef.current.focus();
    };

    onFocusQttyWithPercent = (value, isEdittingOrder, id) => {
        const self = this
        document.getElementById(id).addEventListener("keydown", event => {
            if (event && event.code === "Enter") {
                this.setQttyWithPercent(value, isEdittingOrder)
                self.focusRadioTypeDay();
            }
        });
    }

    onFocusTypeDay = (value, id) => {
        const self = this
        document.getElementById(id).addEventListener("keydown", event => {
            if (event && event.code === "Enter") {
                if (value === TYPE_DAY.MUCH_DAY) {
                    this.onChangeValidUntilOrder(value)
                    self.focusDatePicker();
                } else if (value === TYPE_DAY.DURING_DAY) {
                    this.onChangeValidUntilOrder(value)
                    self.focusBtnPlaceOrder();
                }
            }
        });
    }


    callApiDoPlaceOrder = (authInfo, requestId) => {
        const { currentSymbol, quote, accountQuickOrderInfo, currentAccount, userInfo, setIsOpenModalHaveData } = this.props;
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }
        //console.log("callApiDoPlaceOrder ===>", authInfo)
        let isBroker = this.isBroker();
        let data = this.getPlaceOrderData();
        let _data = { ...data, ...authInfo };
        // submit
        // LoadStatusQuicOrder
        setIsOpenModalHaveData("QuickOrder", {
            loadStatusQuickOrderModalTrade: LoadStatus.LOADING,
        })
        tradeApiService.tradePlaceOrderConditionOCO(currentAccountId, requestId, _data)
            .then(() => {
                setIsOpenModalHaveData("QuickOrder", {
                    loadStatusQuickOrderModalTrade: LoadStatus.LOADED,
                })
                ToastUtil.success('trade.place-order.place-order-success');
                this.onFinishPlaceOrder();
            })
            .catch((error) => {
                setIsOpenModalHaveData("QuickOrder", {
                    loadStatusQuickOrderModalTrade: LoadStatus.FAIL,
                })
                // if (!error.errorCode.startsWith("FO")) {
                //     this.props.setTradeAuthSuccess();
                // }
                ToastUtil.errorApi(error, 'trade.place-order.place-order-fail');
                CommonUtils.clearTradeAuthInfo();
            });
    }

    setQttyWithPercent = (value, isEdittingOrder) => {
        const { accountQuickOrderInfo, placeQuickOrderCurrent, currentSymbol, availableTrade } = this.props
        const { typeAccount } = { ...accountQuickOrderInfo }
        const { action } = { ...placeQuickOrderCurrent }
        if (isEdittingOrder) {
            return
        }

        if (currentSymbol) {
            let _value = null
            //console.log("setQttyWithPercent1", _value)

            if (action === orderActions.BUY) {
                if (typeAccount === TYPE_ACCOUNT.FDS) {
                    _value = parseFloat(availableTrade.maxbuyqtty) * value
                } else {
                    _value = parseFloat(availableTrade.maxqtty) * value
                }
            } else if (action === orderActions.SELL) {
                if (typeAccount === TYPE_ACCOUNT.FDS) {
                    _value = parseFloat(availableTrade.maxsellqtty) * value
                } else {
                    _value = parseFloat(availableTrade.trade) * value
                }
            }
            //console.log("setQttyWithPercent2", _value)
            if (_value) {
                let parsedPrice = parseInt(_value);
                if (typeAccount === TYPE_ACCOUNT.FDS) {

                }
                else {
                    if (parsedPrice <= 100) {

                    } else {
                        parsedPrice = Math.floor(parsedPrice)
                        parsedPrice = (parsedPrice - (parsedPrice % 100));
                    }
                }
                //console.log("setQttyWithPercent3", parsedPrice)
                this.props.updatePlaceQuickOrderConditon({
                    quantity: parsedPrice,
                }, this.props.orderTypeUse)
            }
        }
    }


    onChangeValidUntilOrder = (value) => {
        const { updatePlaceQuickOrderConditon } = this.props
        let _value = value
        updatePlaceQuickOrderConditon({
            validUntilOrder: _value,
        }, this.props.orderTypeUse)
    }

    onToDateChanged = (dates) => {
        // let _toDate = moment(dates[0]).format(config.app.SERVER_DATE_FORMAT)
        let _toDate = dates[0]
        //console.log("onToDateChanged", dates[0])
        this.props.updatePlaceQuickOrderConditon({
            toDate: _toDate,
        }, this.props.orderTypeUse)
    };

    onHandleKeyDownPriceOrder = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            this.placeOrder()
        }
        if (keyCode === KeyCodeUtils.TAB) {
            if (this.isBroker()) {
                emitter.emit("onTabSymbolTrade_Broker", true)
            } else {
                emitter.emit("onTabSymbolTrade_Customer", true)
            }
            event.preventDefault();
        }
    }

    render() {
        const { stopLoPriceCheck, stopLimitPriceCheck } = this.state
        const { placeQuickOrderCurrent, quote, accountQuickOrderInfo, currentSymbol } = this.props

        const {
            stopPriceLo,
            stepStopPriceLoValid,
            quoteStopPriceLoValid,
            quotePrice,
            quotePriceValid,
            stepPriceValid,
            quantity,
            quantityValid,
            subOrderType,
            stopPrice,
            stepStopPriceValid,
            quoteStopPriceValid,
            validUntilOrder,
            fromDate,
            toDate
        } = { ...this.props.orderConditionOCO }
        const { action } = { ...placeQuickOrderCurrent }
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }

        //console.log("render", this.props.orderConditionOCO)
        let isEdittingOrder = false
        let isDisablePlaceOrder = false

        let instrumentQtty = {
            EX: quote && quote.exchange,
            ST: quote && quote.StockType
        }

        let ceilPrice = null;
        let floorPrice = null;
        if (quote != null) {
            if (typeAccount === TYPE_ACCOUNT.FDS) {
                ceilPrice = CommonUtils.divide(quote.ceiling, 1)
                floorPrice = CommonUtils.divide(quote.floor, 1)
            } else {
                ceilPrice = CommonUtils.divide(quote.ceiling, 1000)
                floorPrice = CommonUtils.divide(quote.floor, 1000)
            }
        }
        let stepInputQuantity = currentSymbol ? (currentSymbol.exchange == EXCHANGE.HOSE ? 100 : (quote.exchange === derivativeExchanges[0] ? 1 : 100)) : 1
        return (
            <div className="condition-order-oco">
                <div className="wrap-border-block">
                    <div className="title-block">
                        <span className="text-green">
                            LO
                        </span>
                    </div>
                    <div className="type-view price-order">
                        <div className="content content-left text-left txt---500-14-20">
                            <FormattedMessage id="trade.place-order.order-price" />
                        </div>
                        <div className="content content-right item-center txt---400-14-20">
                            <div className="row-input">
                                <PriceInputOrder
                                    ref={this.priceOrderRef}
                                    name="limit-price"
                                    subOrderTypes={""} // Hay gây lỗi
                                    quote={this.props.quote ? this.props.quote : ""}
                                    step={10}
                                    min={0}
                                    max={99999999999}
                                    value={stopPriceLo}
                                    orderType={"limit"}
                                    subOrderType={"LO"}
                                    valid={quoteStopPriceLoValid}
                                    validStep={stepStopPriceLoValid}
                                    onChange={this.onStopPriceLoChanged}
                                    onKeyDown={this.onInputKeyDown}
                                    onBlur={this.onBlurPrice}
                                    allowZero={false}
                                    allowDecimal={true}
                                    allowNegative={false}
                                    onFocus={this.onFocusPrice}
                                    decimalScale={2}
                                />
                            </div>
                            <div className="row-note text-left">
                                <span>
                                    {action === orderActions.BUY ? "≤ " : "≥ "}
                                </span>
                                <span>
                                    {typeAccount === TYPE_ACCOUNT.FDS && <FormattedPrice value={stopLoPriceCheck} shortForm={true} isFormatZero={true} isNotThousand={true} />}
                                    {typeAccount === TYPE_ACCOUNT.BASE && <FormattedPrice value={stopLoPriceCheck} shortForm={true} isFormatZero={true} />}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap-border-block">
                    <div className="title-block">
                        <span className="text-red">
                            Stop Limit
                        </span>
                    </div>
                    <div className="type-view price-order">
                        <div className="content content-left text-left txt---500-14-20">
                            <FormattedMessage id="trade.place-order.stopprice" />
                        </div>
                        <div className="content content-right item-center txt---400-14-20">
                            <div className="row-input">
                                <PriceInputOrder
                                    ref={this.priceOrderRef}
                                    name="limit-price"
                                    subOrderTypes={""} // Hay gây lỗi
                                    quote={this.props.quote ? this.props.quote : ""}
                                    step={10}
                                    min={0}
                                    max={99999999999}
                                    value={stopPrice}
                                    orderType={"limit"}
                                    subOrderType={"LO"}
                                    valid={quoteStopPriceValid}
                                    validStep={stepStopPriceValid}
                                    onChange={this.onStopPriceChanged}
                                    onKeyDown={this.onInputKeyDown}
                                    onBlur={this.onBlurPrice}
                                    allowZero={false}
                                    allowDecimal={true}
                                    allowNegative={false}
                                    onFocus={this.onFocusPrice}
                                    decimalScale={2}
                                />
                            </div>
                            <div className="row-note text-left">
                                <span>
                                    {action === orderActions.BUY ? "> " : "< "}
                                </span>
                                <span>
                                    {typeAccount === TYPE_ACCOUNT.FDS && <FormattedPrice value={stopLimitPriceCheck} shortForm={true} isFormatZero={true} isNotThousand={true} />}
                                    {typeAccount === TYPE_ACCOUNT.BASE && <FormattedPrice value={stopLimitPriceCheck} shortForm={true} isFormatZero={true} />}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="type-view price-order last-type-view">
                        <div className="content content-left text-left txt---500-14-20">
                            <FormattedMessage id="trade.place-order.order-price" />
                        </div>
                        <div className="content content-right item-center txt---400-14-20">
                            <div className="row-input">
                                <PriceInputOrder
                                    name="limit-price"
                                    ref={this.priceOrderRef}
                                    subOrderTypes={""}  // Hay gây lỗi
                                    quote={this.props.quote ? this.props.quote : ""}
                                    step={10}
                                    min={0}
                                    max={99999999999}
                                    value={quotePrice}
                                    orderType={"limit"}
                                    subOrderType={"LO"}
                                    valid={quotePriceValid}
                                    validStep={stepPriceValid}
                                    onChange={this.onQuotePriceChanged}
                                    onKeyDown={this.onInputKeyDown}
                                    onBlur={this.onBlurPrice}
                                    allowZero={false}
                                    allowDecimal={true}
                                    allowNegative={false}
                                    onFocus={this.onFocusPrice}
                                    decimalScale={2}
                                    onReLoadValue={true}
                                />
                            </div>
                            <div className="row-note text-left">
                                {action === orderActions.BUY ? "> " : "< "}
                                <span>
                                    {typeAccount === TYPE_ACCOUNT.FDS && <FormattedPrice value={stopPrice} shortForm={true} isFormatZero={true} isNotThousand={true} />}
                                    {typeAccount === TYPE_ACCOUNT.BASE && <FormattedPrice value={stopPrice} shortForm={true} isFormatZero={true} />}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="type-view price-order">
                    <div className="content content-left text-left txt---500-14-20">
                        <FormattedMessage id="trade.place-order.mass" />
                    </div>
                    <div className="content content-right txt---400-14-20">
                        <div className="row-input row-qtty">
                            <QuantityInputOrder
                                name="quantity"
                                step={stepInputQuantity}
                                min={null}
                                max={999999999999}
                                instrument={instrumentQtty}
                                value={quantity}
                                valid={quantityValid}
                                onChange={this.onQuantityChanged}
                                // onKeyDown={this.onInputKeyDown}
                                allowZero={false}
                                allowDecimal={false}
                                allowNegative={false}
                                orderType={''}
                                ref={this.quantityRef}
                                // onFocus={this.onFocusQtty}
                                onBlur={this.onBlurQtty}
                                readOnly={false}
                                isNotOddLot={true}
                            />
                        </div>
                        <div className="row-note text-left">
                        </div>
                    </div>
                </div>

                <div className="type-view present-order">
                    <div className="content content-left text-left">

                    </div>
                    <div className="content content-right item-center txt---400-14-20">
                        {listPercentOrder.map((item, index) => {
                            return <div
                                tabIndex="0"
                                className={"focus-item select-present txt---400-14-20 "}
                                onClick={() => { this.setQttyWithPercent(item.value, isEdittingOrder) }}
                                onFocus={() => this.onFocusQttyWithPercent(item.value, isEdittingOrder, "qtty-percent" + index)}
                                id={"qtty-percent" + index}
                            >{item.title}</div>
                        })}
                    </div>
                </div>

                <div className="type-view valid-until-order">
                    <div className="content content-left text-left txt---500-14-20">
                        <FormattedMessage id="quick-order.place-order.effect" />
                    </div>
                    <div className="content content-right item-center txt---400-14-20">
                        <div className={"contaier-input " + (action === orderActions.BUY ? 'contaier-input-buy' : 'contaier-input-sell')}>
                            <div className="radio-left">
                                <label className='container-radio' htmlFor="during-day">
                                    <span className="txt---500-14-20"> <FormattedMessage id="quick-order.place-order.in-day" /></span>
                                    <input
                                        ref={this.radioTypeDayRef}
                                        id="during-day"
                                        type="radio"
                                        name="during-day"
                                        value={TYPE_DAY.DURING_DAY}
                                        checked={validUntilOrder === TYPE_DAY.DURING_DAY ? true : false}
                                        onFocus={() => this.onFocusTypeDay(TYPE_DAY.DURING_DAY, "during-day")}
                                        onChange={() => this.onChangeValidUntilOrder(TYPE_DAY.DURING_DAY)} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="radio-right">
                                <label className='container-radio' htmlFor="much-day">
                                    <span className="txt---500-14-20"><FormattedMessage id="quick-order.place-order.much-day" /></span>
                                    <input
                                        id="much-day"
                                        type="radio"
                                        name="much-day"
                                        value={TYPE_DAY.MUCH_DAY}
                                        checked={validUntilOrder === TYPE_DAY.MUCH_DAY ? true : false}
                                        onFocus={() => this.onFocusTypeDay(TYPE_DAY.MUCH_DAY, "much-day")}
                                        onChange={() => this.onChangeValidUntilOrder(TYPE_DAY.MUCH_DAY)} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>

                    </div>
                </div>

                {validUntilOrder === TYPE_DAY.MUCH_DAY &&
                    <div className="type-view select-date">
                        <div className="content content-left text-left ">
                        </div>
                        <div className="content content-right item-center txt---400-14-20">
                            <div className='txt---500-14-20'><FormattedMessage id="common.to-date" /></div>
                            <div className="custom-form-group-n">
                                <DatePicker
                                    className="focus-item custom-form-control text-left txt---400-14-20"
                                    value={toDate}
                                    onChange={this.onToDateChanged}
                                    ref={this.datePickerRef}
                                />
                            </div>
                        </div>
                    </div>
                }


                <div className="type-view ">
                    <div className={"action-container "}>
                        <button ref={this.btnPlaceOrderRef} className={"focus-item txt---700-14-20 btn-action item-center " + (CommonUtils.getClassbyAction(action))} onKeyDown={this.onHandleKeyDownPriceOrder} onClick={this.placeOrder} disabled={isDisablePlaceOrder}>
                            {
                                action === orderActions.BUY && <FormattedMessage id="quick-order.place-order.buy" />
                            }
                            {
                                action === orderActions.SELL && <FormattedMessage id="quick-order.place-order.sell" />
                            }
                            {
                                !action && <FormattedMessage id="common.transaction" />
                            }
                        </button>
                    </div>
                </div>

                {isEdittingOrder && <div className="type-view ">
                    <div className={"action-container "}>
                        <button ref={this.btnPlaceOrderRef} className="focus-item txt---700-14-20 btn-cancel-input btn-action" onClick={this.onReset}>
                            <FormattedMessage id="quick-order.place-order.cancel" />
                        </button>
                    </div>
                </div>}
            </div>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    const { orderTypeUse } = ownProps
    let _placeQuickOrderCurrent = null
    let currentSymbol = null
    let quote = null
    let _accountQuickOrderInfo = null
    let _currentLayoutPageActive = null

    if (orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER) {
        _currentLayoutPageActive = "C&B"
        let _placeQuickOrder = state.orderNew.placeQuickOrder

        currentSymbol = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive] && _placeQuickOrder[_currentLayoutPageActive]["symbolCurrent"]
        quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]]
        _placeQuickOrderCurrent = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive]
        _accountQuickOrderInfo = _placeQuickOrderCurrent && _placeQuickOrderCurrent["accountQuickOrderInfo"]

    } else if (orderTypeUse === ORDER_TYPE_USE.NORMAL_ORDER) {
        _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        let _placeNormalOrder = state.orderNew.placeNormalOrder
        currentSymbol = _currentLayoutPageActive && _placeNormalOrder[_currentLayoutPageActive] && _placeNormalOrder[_currentLayoutPageActive]["symbolCurrent"]
        quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]]

        let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
        _accountQuickOrderInfo = _layoutPage && _layoutPage["accountInfo"]
        _placeQuickOrderCurrent = _currentLayoutPageActive && _placeNormalOrder[_currentLayoutPageActive]
    }
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;
    const instrument = currentSymbol ? state.symbol.instruments[currentSymbol.id] || {} : {};

    const orderConditionOCO = _placeQuickOrderCurrent["orderConditionOCO"]
    return {
        config: state.user.userInfo.config,
        permissionInfo: permissionInfo,
        userInfo: userInfo,
        currentSymbol: currentSymbol,
        instrument: instrument,
        placeQuickOrderCurrent: _placeQuickOrderCurrent,
        orderConditionOCO: orderConditionOCO,
        accountQuickOrderInfo: _accountQuickOrderInfo,

        quote,
        language: state.app.language,
        role: state.user.userInfo.role,
        showReconfirmOrderConfig: state.app.showReconfirmOrderConfig,

        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        currentDate: state.app.currentDate,
        defaultTheme: state.user.userInfo.defaultTheme,
        actionMethodConditon: state.user.userInfo.actionMethodConditon
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        updatePlaceQuickOrderConditon: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrderConditon(data, typeOrder)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        fetchSymbolOrderbook: (symbolId) => dispatch(actions.fetchSymbolOrderbook(symbolId)),

        saveTradeAuthenticated: () => dispatch(actions.saveTradeAuthenticated()),
        setTradeAuthSuccess: () => dispatch(actions.setTradeAuthSuccess()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConditionOrderConditionOCO);
