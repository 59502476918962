import { push } from "connected-react-router";
import _ from "lodash";
import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";

import CustomScrollbars from "components/CustomScrollbars";
import RetryableContent from 'components/RetryableContent';
import { ACTION_USER_ORDER, activeOrderExecType, LanguageUtils, ORDER_TYPE_USE, Random, TYPE_SUBMIT_ORDER } from 'utils';
import { emitter, FOCUS_PRICE_INPUT } from "utils/EventEmitter";

import { FormattedPrice, FormattedQuantity } from '../../../components/Formating';
import { fdsInquiryService, inquiryService, tradeApiService } from "../../../services";
import * as actions from "../../../store/actions";
import { CommonUtils, fullSubOrderTypes, LoadStatus, modules, Role, ToastUtil } from "../../../utils";
import { TYPE_ACCOUNT } from './../../../utils/constants';
import ModalQuickOrderConditonDetail from './ModalQuickOrderConditonDetail/ModalQuickOrderConditonDetail';

import './OrderBookQuickOrder.scss';

const DF_LIST_STATUS = [
    {
        title: "quick-order.order-book.list-status.all",
        key: 1
    },
    {
        title: "quick-order.order-book.list-status.wait",
        key: 2
    },
    {
        title: "quick-order.order-book.list-status.matched",
        key: 3
    },
    {
        title: "quick-order.order-book.list-status.cancel",
        key: 4
    },
    {
        title: "quick-order.order-book.list-status.condition",
        key: 5
    }
]


const DF_LIST_STATUS_BROKER = [
    {
        title: "quick-order.order-book.list-status.all",
        key: 1
    },
    {
        title: "quick-order.order-book.list-status.wait",
        key: 2
    },
    {
        title: "quick-order.order-book.list-status.matched",
        key: 3
    },
    {
        title: "quick-order.order-book.list-status.cancel",
        key: 4
    }
]
class OrderBookQuickOrder extends Component {
    initialState = {
        checkedAll: false,
        persistChecked: {},
        records: [],
        recordsCondition: [],
        recordsFilter: [],
        totalSumOrder: {
            buyValue: 0,
            sellValue: 0,
            netValue: 0,
        },
        loadStatus: LoadStatus.NONE,

        selectedCustodycd: "",

        accountId: "ALL",

        stautusFilter: 1, // Trạng thái chọn lọc
        keyActive: null, // Trạng thái chọn dòng trong bảng
        isShowCheckBox: false,
        successListTotal: [],
        errListTotal: [],

        isOpenModalQuickOrderConditionDetail: false,
        dataFill: [],
        allOrderId: []
    };

    state = _.cloneDeep(this.initialState)
    //     {
    //         ...this.initialState
    // }


    constructor(props) {
        super(props);
        this.listenToTheEmitter();
    };

    callback = (data) => {
        let self = this
        if (data) {
            self.onChangeStatusSelect(data)
        }
    }

    listenToTheEmitter() {
        emitter.on('onChangeStatusQuickOrderBook', this.callback);
    }

    componentWillUnmount() {
        emitter.removeListener('onChangeStatusQuickOrderBook', this.callback);
        this.mounted = false
    }


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    onReset = () => {
        this._setState({
            ...this.initialState,
            persistChecked: {}
        });
    }

    apiSeq = new Date().getTime();
    debounceLoadData = _.debounce(() => {
        this.apiSeq = new Date().getTime();
        this.loadData(this.apiSeq);
    }, 50);

    loadData = (apiSeq) => {
        const { userInfo, currentCustomer } = this.props;

        const { accountQuickOrderInfo } = this.props;
        const { currentAccountId, curCustomer } = { ...accountQuickOrderInfo }

        const { isForBroker } = this.props; //xem co phai so lenh moi gioi khong
        let isBroker = this.isBroker();

        let custid = null;

        if (isBroker) {
            custid = curCustomer ? curCustomer.custid : null;
            // custid = userInfo ? userInfo.custid : null;
            custid && this.getAllOrder(custid, currentAccountId, apiSeq);
        } else {
            custid = userInfo ? userInfo.custid : null;
            this.getAllOrder(custid, currentAccountId, apiSeq);
        };
    };

    getAllOrder = (custid, accountId, apiSeq) => {
        const { records, keyActive, stautusFilter } = this.state
        const { accountQuickOrderInfo, language } = this.props;
        const { currentAccountId, curCustomer, typeAccount } = { ...accountQuickOrderInfo }
        //neula so lenh moi gioi thi goi api lay so lenh moi gioi - nguoc lai lay so lenh khach hang
        const getOrderFunc = this.isBroker() ? inquiryService.getOrders : inquiryService.getOrders;
        const getOrderFDSFunc = this.isBroker() ? fdsInquiryService.getAllOrdersFDS : fdsInquiryService.getAllOrdersFDS;

        this._setState({
            loadStatus: LoadStatus.LOADING,
        })

        if (typeAccount === TYPE_ACCOUNT.FDS) {
            fdsInquiryService.getAllOrdersConditionFDS(accountId)
                .then((data) => {
                    if (apiSeq < this.apiSeq) return;
                    this._setState({
                        loadStatus: LoadStatus.LOADED,
                    }, () => {
                        this.processOrderConditonData(data, "FDS");
                    })
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                    this._setState({
                        records: [],
                        recordsFilter: [],
                        loadStatus: LoadStatus.FAIL,
                    });
                });
            getOrderFDSFunc(accountId)
                .then((data) => {
                    if (apiSeq < this.apiSeq) return;
                    this._setState({
                        loadStatus: LoadStatus.LOADED,
                    }, () => {
                        this._setState({
                            records: inquiryService.convert_GetOrdersFDS(data, language, this.getStatusFDS),
                        },
                            () => {
                                this.genDataTable()
                            });
                    })
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                    this._setState({
                        records: [],
                        recordsFilter: [],
                        loadStatus: LoadStatus.FAIL,
                    });
                });

        }
        else {
            if (!this.isBroker()) {
                inquiryService.getAllOrdersCondition(accountId)
                    .then((data) => {
                        if (apiSeq < this.apiSeq) return;
                        this._setState({
                            loadStatus: LoadStatus.LOADED,
                        }, () => {
                            this.processOrderConditonData(data, "BASE");
                        })
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-data');
                        this._setState({
                            records: [],
                            recordsFilter: [],
                            loadStatus: LoadStatus.FAIL,
                        });
                    });
            }

            getOrderFunc(accountId)
                .then((data) => {
                    if (apiSeq < this.apiSeq) return;
                    this._setState({
                        loadStatus: LoadStatus.LOADED,
                    }, () => {
                        let converteData = inquiryService.convert_GetOrders(data, language, { convertTime: this.convertTime });
                        this._setState({
                            records: converteData && converteData.length > 0 ? converteData : [],
                        },
                            () => {
                                this.genDataTable()
                                // this.setSelectAllStatus()
                            });
                    })
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                    this._setState({
                        records: [],
                        recordsFilter: [],
                        loadStatus: LoadStatus.FAIL,
                    });
                });
        }
    };


    processOrderConditonData = (dataArr, type) => { // convert data cơ sở
        let { persistChecked } = this.state;
        if (type == "FDS") {
            if (dataArr.length > 0) {
                for (let i = 0; i < dataArr.length; i++) {
                    let element = dataArr[i];
                    let cancelOrderPermission = (element['iscancel'] === "Y");
                    let amendOrderPermission = (element['isadmend'] === "Y");

                    element.cancelOrderPermission = cancelOrderPermission;
                    element.amendOrderPermission = amendOrderPermission;
                    element.price = element['quoteprice']
                    element.qtty = element['orderqtty']
                    element.odorderid = element['orderid']
                    element.sideCode = element['exectype']
                    element.afacctno = `FDS${element['afacctno']}`
                }
            }
            this._setState({
                recordsCondition: dataArr,
            },
                () => {
                    this.genDataTable()
                });
        } else {
            if (dataArr.length > 0) {
                for (let i = 0; i < dataArr.length; i++) {
                    let element = dataArr[i];
                    let cancelOrderPermission = (element['iscancel'] === "Y");
                    let amendOrderPermission = (element['isamend'] === "Y");

                    element.cancelOrderPermission = cancelOrderPermission;
                    element.amendOrderPermission = amendOrderPermission;
                    element.price = element['quoteprice']
                    element.qtty = element['orderqtty']
                    element.odorderid = element['quoteid']
                    element.sideCode = element['side'] == 'B' ? activeOrderExecType.BUY : activeOrderExecType.SELL
                    element.afacctno = element['afacctno']
                    element.sideCode = element['sidecode']
                }
            }
            this._setState({
                recordsCondition: dataArr,
            },
                () => {
                    this.genDataTable()
                });
        }
    };

    processOrderDataFDS = (dataArr) => { // convert data phái sinh giống cơ sở
        let { persistChecked } = this.state;
        if (dataArr.length > 0) {
            for (let i = 0; i < dataArr.length; i++) {
                let element = dataArr[i];
                let cancelOrderPermission = (element['iscancel'] === "Y");
                element.checked = cancelOrderPermission ? persistChecked[element.id] == true : null;
                let amendOrderPermission = (element['isadmend'] === "Y");
                element.cancelOrderPermission = cancelOrderPermission;
                element.amendOrderPermission = amendOrderPermission;
                element.price = element['limitprice']
                element.qtty = element['qty']
                element.symbol = element['instrument']
                element.odorderid = element['id']
                element.sideCode = element['exectype']
                element.pricetype = element['subtypecd']
                element.statusCode = element['statuscode']
                element.afacctno = `FDS${element['afacctno']}`
                element.status = this.getStatusFDS(element['status'], "vi", element)
                element.en_status = this.getStatusFDS(element['status'], "en", element)


                element.rootodorderid = element['id'] //  SHL gốc
                element.quoteamt = element['filledqty'] //  Giá trị đặt
                element.execqtty = element['avgprice'] // Khối lượng khớp
                element.execprice = element['avgprice'] //  Giá khớp
                element.execamt = element['exec_amt'] // GT khớp
                element.remainqtty = element['remain_qtty'] // KL còn lại
                element.remainamt = element['remain_amt'] // Giá trị còn lại
                element.odtimestamp = element['txtime'] // Thời gian đặt lệnh
                element.transactTime = element['effdate'] // Hiệu lực

            }
        }
        this._setState({
            records: dataArr,
        },
            () => {
                this.genDataTable()
            });
    };

    getStatusFDS = (statusCode, language, element) => {
        let status = ""
        if (language == "en") {
            switch (statusCode) {
                case 'placing':
                    status = 'Placing';
                    break;
                case 'working':
                    status = 'Working'
                    break;
                case 'filled':
                    status = (element.remainQtty != null && element.remainQtty == 0) ? 'Filled' : 'Filled';
                    break;
                case 'rejected':
                    status = 'Rejected'
                    break;
                case 'cancelled':
                    status = 'Cancelled'
                    break;
                default:
                    status = '';
                    break;
            }
        } else {
            switch (statusCode) {
                case 'placing':
                    status = 'Chờ gửi';
                    break;
                case 'working':
                    status = 'Đã gửi'
                    break;
                case 'filled':
                    status = (element.remainQtty != null && element.remainQtty == 0) ? 'Khớp hết' : 'Đã khớp';
                    break;
                case 'rejected':
                    status = 'Đã huỷ'
                    break;
                case 'cancelled':
                    status = 'Đã huỷ'
                    break;
                default:
                    status = '';
                    break;
            }
        }


        return status
        // return LanguageUtils.getMessageByKey("status", language);
    }


    checkPermission = (accountid) => {
        const { permissionInfo, userInfo } = this.props;
        if (userInfo.role === Role.CUSTOMER && accountid && permissionInfo) {
            return (permissionInfo.accounts[accountid] && permissionInfo.accounts[accountid][modules.ORDINPUT]) ? true : false
        }
        if (userInfo.role === Role.BROKER && userInfo && permissionInfo) {
            return (permissionInfo.accounts[userInfo.custid] && permissionInfo.accounts[userInfo.custid][modules.ORDINPUT]) ? true : false
        }
    };

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
    
    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        // this.debounceLoadData();
        // setInterval(() => {
        this.debounceLoadData();
        // }, 1000);
    }

    componentDidUpdate(prevProps, prevState) {
        const { accountQuickOrderInfo, socketODAction, socketCODAction, custSocketODActionCounter, socketDOAction } = this.props;
        const { currentAccountId } = { ...accountQuickOrderInfo }
        const { accountQuickOrderInfo: prevAccountQuickOrderInfo,
            socketODAction: prevSocketODAction, socketDOAction: prevSocketDOAction,
            custSocketODActionCounter: prevCustSocketODActionCounter,
            socketCODAction: prevSocketCODAction
        } = prevProps
        const { currentAccountId: prevCurrentAccountId } = { ...prevAccountQuickOrderInfo }

        if (currentAccountId !== prevCurrentAccountId) {
            this.onReset()
            this.debounceLoadData();
        }

        if (socketODAction[currentAccountId] !== prevSocketODAction[currentAccountId] ||
            socketDOAction[currentAccountId] !== prevSocketDOAction[currentAccountId] ||
            socketCODAction[currentAccountId] !== prevSocketCODAction[currentAccountId]
        ) {
            this.debounceLoadData();
        }
    }

    fillEditInfo = (order) => {
        const { accountQuickOrderInfo, isQuickOrder } = this.props;
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }
        //set lai account id hien tai
        // setCurrentAccount(order['afacctno']);
        const action = CommonUtils.getOrderActionFromSideCode(order['sideCode']);
        const orderType = CommonUtils.getOrderTypeFromPriceType(order['pricetype']);
        //console.log("fillEditInfo", order)
        let orderInput = {
            actionUserOrder: ACTION_USER_ORDER.EDIT_ORDER,
            symbolCurrent: order['symbol'],
            accountQuickOrderInfo: {
                ...accountQuickOrderInfo,
                currentAccountId: order['afacctno'],
            },
            action: action,
            orderType: orderType,
            quantity: Number(order['qtty']),
            quotePriceValid: true,
            stepPriceValid: true,
            editingOrder: order
        }

        if (orderType === 'limit') {
            orderInput.limitPrice = order['price'];
            orderInput.subOrderType = fullSubOrderTypes.LO
        } else if (orderType === 'market') {
            //huy.quang: voi lenh MTL thi coi nhu sua lenh limit
            orderInput.limitPrice = order['price'];
            orderInput.orderType = 'limit';
            orderInput.subOrderType = fullSubOrderTypes.LO
        };

        this.props.updatePlaceQuickOrder(orderInput)
        this.props.onChangeItemSelect(1)
        let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, action); clearTimeout(timer) }, 0);
    }

    foundOrder = (id) => {
        const { recordsFilter, keyActive } = this.state
        let orderFound = {}
        orderFound = recordsFilter.find((item, index) => item["odorderid"] === id);
        return orderFound
    }

    onEditOrder = (orderId) => {
        const { setCurrentCustomerByCustodycd, } = this.props;
        let order = this.foundOrder(orderId)
        let arrKeep = ["action"]
        this.props.clearDataPlaceQuickOrder(ORDER_TYPE_USE.QUICK_ORDER, arrKeep)
        const fillEditInfo = () => {
            this.fillEditInfo(order);
        };
        if (this.isBroker()) {
            // setCurrentCustomerByCustodycd(order['custodycd'], fillEditInfo);
            fillEditInfo();
        } else {
            fillEditInfo();
        }
    };


    onCancelOrder = (orderId) => {
        const { accountQuickOrderInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }
        let order = this.foundOrder(orderId)

        let currentAccount = CommonUtils.getAccountUtils().currentAccount

        tradeApiService.initTwoFactorAuth({ accountId: currentAccountId })
            .then((data) => {
                const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                this.props.updateInfoCheckAuth({
                    transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                })
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-customer-acccounts-list');
                return;
            })

        let listReconfirmOrder = this.getListReconfirmOrder([{ ...order }]);

        let orderInput = {
            actionUserOrder: ACTION_USER_ORDER.CANCEL_ORDER,
            typeSubmitOrder: TYPE_SUBMIT_ORDER.ORDER,
            cancelingOrder: listReconfirmOrder
        }

        this.props.updatePlaceQuickOrder(orderInput)
        this.cancelOrder(this.doCancelOrder, order);
    };

    doCancelOrder = (order, authInfo) => {
        this.callApiCancelOrder(order, authInfo);
    };

    callApiCancelOrder = (order, authInfo) => {
        const { recordsFilter, stautusFilter } = this.state
        this.props.setIsOpenModalHaveData("QuickOrder", {
            loadStatusQuickOrderModalTrade: LoadStatus.LOADING,
        })
        let requestId = Random.randomOrderRequestId()

        let body = {
            ...authInfo,
            orderId: order['odorderid'],
            requestId,
        }
        tradeApiService.tradeCancelOrder(order['afacctno'], body)
            .then(() => {
                // this.props.setIsOpenModalHaveData("QuickOrder", {
                //     loadStatusQuickOrderModalTrade: LoadStatus.LOADED,
                // })
                this.onFinish();
                ToastUtil.success('trade.order.cancel-order-success');
            })
            .catch((error) => {
                this.props.setIsOpenModalHaveData("QuickOrder", {
                    loadStatusQuickOrderModalTrade: LoadStatus.FAIL,
                })
                ToastUtil.errorApi(error, 'trade.order.cancel-order-fail');
            });
    }

    toogleHideShowInfo = (id) => {
        const { recordsFilter, keyActive } = this.state
        if (keyActive !== id) {
            this._setState({ keyActive: id })
        } else {
            if (keyActive !== null) {
                this._setState({ keyActive: null })
            } else {
                this._setState({ keyActive: id })
            }
        }
    }

    onChangeStatusSelect = (keySelect) => {
        this._setState({ stautusFilter: keySelect, keyActive: null }, () => {
            this.genDataTable()
        })
    }

    genDataTable = () => {
        const { records, keyActive, stautusFilter, recordsCondition } = this.state

        const { accountQuickOrderInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }

        let dataArr = []
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            if (stautusFilter === 1) {
                dataArr = records
            } else if (stautusFilter === 2) {
                dataArr = records.filter((item, index) => {
                    return ((item.statusCode == 'N' || item.statusCode == 'B' || item.statusCode == 'S') || ((item.statusCode == 'T') && (item.qtty - item.filledqty) > 0))
                })
            } else if (stautusFilter === 3) {
                dataArr = records.filter((item, index) => {
                    return item.statuscode == 'T'
                })
            } else if (stautusFilter === 4) {
                dataArr = records.filter((item, index) => {
                    return item.statuscode == 'D'
                })
            }
        } else {
            if (stautusFilter === 1) {
                dataArr = records
            } else if (stautusFilter === 2) {
                dataArr = records.filter((item, index) => {
                    return (item.statusCode == '2' || item.statusCode == '4' || item.statusCode == '8')
                })
            } else if (stautusFilter === 3) {
                dataArr = records.filter((item, index) => {
                    return (item.statusCode == '4' || item.statusCode == '12' || item.execqtty > 0)
                })
            } else if (stautusFilter === 4) {
                dataArr = records.filter((item, index) => {
                    return (item.statusCode == '3' || item.statusCode == '5')
                })
            } else if (stautusFilter === 5) {
                dataArr = recordsCondition;
            }
        }

        this._setState({
            recordsFilter: dataArr, allOrderId: dataArr && dataArr.length > 0 ? this.getCancelableId(dataArr) : []
        }, () => { this.getToTalSumOrder(); this.setSelectAllStatus() })
    }

    getCancelableId = (dataArr) => {
        let idArr = [];
        dataArr.forEach(item => {
            if (item['cancelOrderPermission'] === true) {
                idArr.push(item['odorderid'])
            }
        })
        return idArr;
    }

    getToTalSumOrder = () => {
        const { recordsFilter, totalSumOrder } = this.state
        const { accountQuickOrderInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        let _buyValue = 0;
        let _sellValue = 0;
        let _netValue = 0;
        for (let i = 0; i < recordsFilter.length; i++) {
            if (typeAccount === TYPE_ACCOUNT.FDS) {
                if (recordsFilter[i].sideCode === 'NB') {
                    _buyValue += recordsFilter[i].price * recordsFilter[i].qtty * recordsFilter[i].nvalue;
                } else {
                    _sellValue += recordsFilter[i].price * recordsFilter[i].qtty * recordsFilter[i].nvalue;
                }
            } else {
                // Không tính tổng đối với các trạng thái lệnh : 3: Đã hủy, 5: Hết hiệu lực, 10: Đã sửa (trừ case khớp 1 phần , phần còn lại hủy hoặc sửa thì trạng thái là hủy /sửa nhưng có khối lượng khớp > 0 vẫn phải cộng vào tổng)
                if (recordsFilter[i].statusCode === '3' || recordsFilter[i].statusCode === '5' || recordsFilter[i].statusCode === '10' || recordsFilter[i].statusCode === '6' || recordsFilter[i].statusCode === 'E' || recordsFilter[i].statusCode === 'R') {
                    if (recordsFilter[i].execqtty && recordsFilter[i].execqtty > 0) {
                        if (recordsFilter[i].sideCode === 'NB') {
                            _buyValue += recordsFilter[i].price * recordsFilter[i].execqtty;
                        } else {
                            _sellValue += recordsFilter[i].price * recordsFilter[i].execqtty;
                        }
                        continue;
                    } else {
                        continue;
                    }
                }
                // Case với lệnh ĐIỀU KIỆN không tính tổng với lệnh có tostatus = R (Lệnh hủy)
                if (recordsFilter[i].tostatus && recordsFilter[i].tostatus === 'R') continue;
                if (recordsFilter[i].sideCode === 'NB') {
                    _buyValue += recordsFilter[i].price * recordsFilter[i].qtty;
                } else {
                    _sellValue += recordsFilter[i].price * recordsFilter[i].qtty;
                }
            }
        }

        _netValue = _buyValue - _sellValue
        this._setState({ totalSumOrder: { buyValue: _buyValue, sellValue: _sellValue, netValue: _netValue } })
    }

    onHandleCancel = () => {
        this._setState({ isShowCheckBox: !this.state.isShowCheckBox })
        this.clearAllCheckbox()
    }

    onHandleCancelAll = () => {
        this._setState({ isShowCheckBox: true })
        this.handleCheckAll()
    }

    clearAllCheckbox = () => {
        var { recordsFilter, persistChecked } = this.state;
        persistChecked = {};
        this.state.checkedAll = false;
        for (const order of recordsFilter) {
            if (order.checked != null) {
                order.checked = false
            }
        }
        this.state.checkedAll = false;
        this._setState({ recordsFilter, persistChecked })
    };


    handleCheckAll = () => {
        let { persistChecked } = this.state;

        this._setState({
            checkedAll: !this.state.checkedAll
        }, () => {
            let newRecords = []
            for (const order of this.state.recordsFilter) {
                if (order.cancelOrderPermission) {
                    // order.checked = this.state.checkedAll;
                    persistChecked[order.odorderid] = this.state.checkedAll;
                }
                newRecords.push(order)
            }
            this._setState({
                persistChecked: persistChecked,
                recordsFilter: newRecords
            });
        })
    };

    handleCheckBox = (e, orderId) => {
        let { persistChecked, recordsFilter } = this.state;
        let record = this.foundOrder(orderId)
        persistChecked[record.odorderid] = e.target.checked;
        let newRecords = []
        for (const order of recordsFilter) {
            if (order.odorderid == record.odorderid) {
                order.checked = e.target.checked;
            }
            newRecords.push(order)
        }
        this._setState({
            persistChecked: persistChecked,
            recordsFilter: newRecords
        }, () => {
            this.setSelectAllStatus();
        });
    };

    getListReconfirmOrder = (listOrder) => {
        let listReconfirmOrder = [];
        listOrder.forEach(order => {
            const action = CommonUtils.getOrderActionFromSideCode(order['sideCode']);
            const orderType = CommonUtils.getOrderTypeFromPriceType(order['pricetype']);
            let limitPrice = null;
            let stopPrice = null;

            let quantity = order['remainqtty'];
            listReconfirmOrder.push({
                orderid: order['odorderid'],
                side: order['sideCode'],
                action,
                currentOrderType: orderType,
                limitPrice: order['limitprice'],
                stopPrice,
                quantity,
                symbolCurrent: {
                    id: order['symbol']
                },
                marketPrice: order['price'],
                subOrderType: order['pricetype'],
                cancelingOrder: true,
                accountId: order['afacctno'],
                afacctnoext: order['producttypename'],
                orderValue: order['price'] * quantity,
                custodycd: order['custodycd'] || '',
                fullname: order['fullname'] || '',
            });
        });
        return listReconfirmOrder;
    };

    getCheckedOrders = () => {
        const { persistChecked } = this.state
        let listOrder = _.filter(this.state.recordsFilter, (record) => {
            if (record.cancelOrderPermission) {
                return persistChecked[record.odorderid] === true;
            } else return false
        });
        //console.log("getCheckedOrders", listOrder)
        return listOrder
    };


    onCancelMultipleOrder = () => {
        const { accountQuickOrderInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }
        let listOrder = this.getCheckedOrders();
        if (!listOrder || listOrder.length === 0) {
            ToastUtil.error('common.no-cancel-order-allow');
            return;
        }

        tradeApiService.initTwoFactorAuth({ accountId: currentAccountId })
            .then((data) => {
                const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                this.props.updateInfoCheckAuth({
                    transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                })
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-customer-acccounts-list');
                return;
            })

        let listReconfirmOrder = this.getListReconfirmOrder(listOrder);

        let orderInput = {
            actionUserOrder: ACTION_USER_ORDER.CANCEL_ORDER,
            typeSubmitOrder: TYPE_SUBMIT_ORDER.ORDER,
            cancelingOrder: listReconfirmOrder
        }
        this.props.updatePlaceQuickOrder(orderInput)
        this.cancelOrder(this.doCancelMultipleOrder, listReconfirmOrder);
    };

    cancelOrder = (func, input) => {
        this.props.setIsOpenModalHaveData("QuickOrder", {
            isOpenModalTradeQuickOrder: true,
            onTradeQuickOrderAuthenCallback: (authInfo) => {
                func(input, authInfo);
                this.clearAllCheckbox()
            }
        })
    };

    handleCheckAccountInBroker = (func, dataInforOrder, authinfor) => {
        const { currentCustomer } = this.props;
        let currentAccount = CommonUtils.getAccountUtils().currentAccount
        inquiryService.getCustomerAccounts(currentCustomer.custodycd)
            .then((data) => {
                if (data) {
                    //Check tiểu khoản đang hiển thị có nằm trong tài khoàn call api không
                    let isAccountInBroker = data.some(item => currentAccount.id === item.account);
                    if (!isAccountInBroker) {
                        ToastUtil.error('common.account-not-in-broker-err');
                        return;
                    }
                    else {
                        func(dataInforOrder, authinfor);
                    }
                }
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-customer-acccounts-list');
                return;
            })
    }

    doCancelMultipleOrder = (listOrder, authInfo) => {
        // if (this.isBroker()) {
        //     this.handleCheckAccountInBroker(this.callApiCancelMultpleOrder, listOrder, authInfo);
        // }
        // else {
        //     this.callApiCancelMultpleOrder(listOrder, authInfo);
        // }
        this.callApiCancelMultpleOrder(listOrder, authInfo);

    };

    callApiCancelMultpleOrder = (listOrder, authInfo) => {

        let orderIds = []

        let requestId = Random.randomOrderRequestId()

        _.forEach(listOrder, (item, index) => {
            orderIds.push({
                accountid: item.accountId,
                orderid: item.orderid
            })
        });
        //console.log("callApiCancelMultpleOrder", listOrder, orderIds)

        let body = {
            ...authInfo,
            orderIds: orderIds,
            requestId,
        }

        this.props.setIsOpenModalHaveData("QuickOrder", {
            loadStatusQuickOrderModalTrade: LoadStatus.LOADING,
        })

        tradeApiService.tradeCancelMultiOrder(body)
            .then((data) => {
                this.props.setIsOpenModalHaveData("QuickOrder", {
                    loadStatusQuickOrderModalTrade: LoadStatus.LOADED,
                })
                this.handleMultipleCancelFinish(listOrder, data);
            }
            ).catch(error => {
                this.props.setIsOpenModalHaveData("QuickOrder", {
                    loadStatusQuickOrderModalTrade: LoadStatus.NONE,
                })
                ToastUtil.errorApi(error, 'trade.order.cancel-order-fail');
            });
    }


    handleMultipleCancelFinish = (listOrder, res) => {
        const { intl, language } = this.props;
        this.onFinish();

        let successListTotal = res.filter((item) => {
            return item["errcode"] === "0"
        })

        let errListTotal = res.filter((item) => {
            return item["errcode"] !== "0"
        })

        //console.log("callApiCancelMultpleOrder_Finish", successListTotal, errListTotal, listOrder, res)
        let succmsgList = successListTotal.map(succ => {
            let orderid = succ['orderid'];
            let order = _.find(listOrder, od => {
                return od['orderid'] === orderid;
            });
            ////console.log("callApiCancelMultpleOrder_Finish_1", successListTotal, errListTotal, listOrder, res)
            let side = LanguageUtils.getMessageByKey('common.exec-types.' + order['side'], language),
                symbol = order['symbolCurrent'].id,
                accountId = order['accountId'];
            // let orderAccount = accounts.find(account => {
            //     return account.id === accountId
            // })
            return side + ' : ' + symbol;
        });
        let errmsgList = errListTotal.map(err => {
            let orderid = err['orderid'];
            let order = _.find(listOrder, od => {
                return od['orderid'] === orderid;
            });
            let side = LanguageUtils.getMessageByKey('common.exec-types.' + order['side'], language),
                symbol = order['symbolCurrent'].id,
                accountId = order['accountId'],
                errmsg = err.errmsg;
            return side + ' : ' + symbol + `(${errmsg})`;
        });
        //console.log("callApiCancelMultpleOrder_Finish_2", succmsgList, errmsgList)
        if (succmsgList && succmsgList.length > 0) ToastUtil.successRaw('trade.order.cancel-order-success', succmsgList);
        if (errmsgList && errmsgList.length > 0) ToastUtil.errorRaw('trade.order.cancel-order-fail', errmsgList, 15000);
        this._setState({ successListTotal: [], errListTotal: [] });
    }

    onFinish = () => {
        const { clearDataPlaceQuickOrder, setIsOpenModalHaveData } = this.props
        clearDataPlaceQuickOrder()
        this.props.setIsOpenModalHaveData("QuickOrder", {
            isOpenModalTradeQuickOrder: false,
            onTradeQuickOrderAuthenCallback: null,
            dataShow: {},
        })
        CommonUtils.middlewareOrder()
    };

    renColorStatusIcon = (status) => {
        const { accountQuickOrderInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        let _class = ""
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            if (status === "N" || status === "B" || status === "S") {
                _class = "bg-yellow"
            } else if (status === "T") {
                _class = "bg-green"
            } else {
                _class = "bg-red"
            }
        } else {
            if (status === "2" || status === "8" || status === "P") {
                _class = "bg-yellow"
            } else if (status === "4" || status === "12" || status === "A") {
                _class = "bg-green"
            } else {
                _class = "bg-red"
            }
        }
        return _class
    }


    renColorStatusIconCondition = (status) => {
        const { accountQuickOrderInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        let _class = ""

        if (typeAccount === TYPE_ACCOUNT.FDS) {
            if (status === "P" || status === "I" || status === "A" || status === "W") {
                _class = "bg-yellow"
            } else if (status === "F") {
                _class = "bg-green"
            } else {
                _class = "bg-red"
            }
        } else {
            if (status === "P" || status === "I" || status === "W") {
                _class = "bg-yellow"
            } else if (status === "F") {
                _class = "bg-green"
            } else {
                _class = "bg-red"
            }
        }
        return _class
    }


    convertDate = (date) => {
        if (date) {
            let convert = date.split("-");
            let result = convert[0]
            let year = result.slice(0, 4)
            let month = result.slice(4, 6)
            let day = result.slice(6, 8)
            return day + "/" + month + "/" + year
        }
        return "-"
    }

    convertTime = (date) => { //"20230131-11:26:02.195"
        if (date) {
            let convert = date.split("-");
            let result = convert[1].split(".")[0]
            return result
        }
        return "-"
    }

    convertDateFDS = (date) => {
        if (date) {
            let result = date.split("T")[0];
            let year = result.split("-")[0]
            let month = result.split("-")[1]
            let day = result.split("-")[2]
            return day + "/" + month + "/" + year
        }
        return "-"
    }

    convertConditonDateBase = (date, type) => {
        if (date) {
            if (type == "time_quote") { //"2023-01-14T10:44:19.000Z"
                let result1 = date.split(".")[0];
                let result2 = result1.split("T")[1];
                return result2
            } else if (type == "fromdate" || type == "todate") { //"2023-01-13T00:00:00.000Z"
                let result1 = date.split("T")[0];
                let result2 = result1.split("-");
                let year = result2[0]
                let month = result2[1]
                let day = result2[2]
                return day + "/" + month + "/" + year
            }
            return "-"
        }
        return "-"
    }

    renderRootId = (item) => { //0068130721000049
        let result = ""
        result = item && ("..." + item.substring(item.length - 6))
        return result
    }

    isChecked = (id) => {
        const { records, keyActive, stautusFilter, isShowCheckBox, checkedAll, persistChecked, recordsFilter, totalSumOrder } = this.state

        if (persistChecked && Object.keys(persistChecked).length > 0) {
            return persistChecked[id] || false
        } else {
            return false
        }

    }

    setSelectAllStatus = () => {
        const { allOrderId, persistChecked } = this.state;
        let checkedIds = [];
        Object.keys(persistChecked).forEach(key => {
            if (persistChecked[key] === true && allOrderId.includes(key)) {
                checkedIds.push(key)
            }
            if (!allOrderId.includes(key)) {
                delete persistChecked[key];
            }
        })
        //console.log('laojahackgame======> SET CHECK ALL', { checkedIds, allOrderId });
        this._setState({ checkedAll: _.isEqual(allOrderId.sort(), checkedIds.sort()) })
    }


    renderTableNormalOrder = () => {
        const { records, keyActive, stautusFilter, isShowCheckBox, checkedAll, persistChecked, recordsFilter, totalSumOrder } = this.state
        const { accountQuickOrderInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        //console.log("binh----this.state", this.state)
        let persistCheckedLength = Object.keys(persistChecked)
        let totalNumOrderCancel = (persistCheckedLength.filter((item, index) => persistChecked[item] === true)).length
        return (
            <CustomScrollbars className='scroll-order-book'>
                <table className="basic-table table-normal">
                    <thead>
                        <tr>
                            {isShowCheckBox && <th>
                                <label className='container-checkbox' htmlFor={"cb_isShowCheckBox"}>
                                    <input
                                        id={"cb_isShowCheckBox"}
                                        className="select-all"
                                        type="checkbox"
                                        checked={this.state.checkedAll}
                                        onChange={this.handleCheckAll}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </th>}
                            <th className="txt---500-14-20 text-left text-not-active" style={{ width: "15%" }}> <FormattedMessage id="quick-order.order-book.symbol" /></th>
                            <th className="txt---500-14-20 text-not-active" style={{ width: "15%" }}><FormattedMessage id="quick-order.order-book.buy-sell" /></th>
                            <th className="txt---500-14-20 text-not-active" style={{ width: "20%" }}><FormattedMessage id="quick-order.order-book.qtty" /></th>
                            <th className="txt---500-14-20 text-not-active" style={{ width: "16%" }}><FormattedMessage id="quick-order.order-book.price-value" /></th>
                            <th className="txt---500-14-20 text-not-active" style={{ width: "34%" }}><FormattedMessage id="quick-order.order-book.status" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            recordsFilter.length > 0 && recordsFilter.map((item, index) => {
                                return (
                                    <>
                                        <tr key={index} >
                                            {isShowCheckBox && <td>
                                                <label className='container-checkbox' htmlFor={`cb_isShowCheckBox${index}`}>
                                                    <input
                                                        id={`cb_isShowCheckBox${index}`}
                                                        type="checkbox"
                                                        checked={this.isChecked(item.odorderid)}
                                                        disabled={!item.cancelOrderPermission}
                                                        onChange={(e) => this.handleCheckBox(e, item.odorderid)}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </td>}
                                            <td className="text-center text-active txt---500-14-20" onClick={() => this.toogleHideShowInfo(index)}>{item.symbol}</td>
                                            <td className={"text-center text-uppercase txt---400-14-20 " + (item['sideCode'] === activeOrderExecType.BUY ? "text-green" : "text-red")} onClick={() => this.toogleHideShowInfo(index)}>
                                                {/* {item.side} */}
                                                <FormattedMessage id={"common.exec-types." + item['sideCode']} />
                                            </td>
                                            <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)}><FormattedQuantity value={item.qtty} /></td>
                                            <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)}>
                                                <FormattedPrice value={Number(item.price)} shortForm={true} isNotThousand={typeAccount === TYPE_ACCOUNT.FDS ? true : false} />
                                            </td>
                                            <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)} style={{ width: "35%" }}>
                                                <span className="status-order">
                                                    <div className={"status-icon " + (this.renColorStatusIcon(item.statusCode))}>

                                                    </div>
                                                    {typeAccount == TYPE_ACCOUNT.FDS && (this.props.language === "vi" ? item.status : item.en_status)}
                                                    {typeAccount == TYPE_ACCOUNT.BASE && (this.props.language === "vi" ? item.status : item.en_status)}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr className={"block-info-detail-order " + (keyActive === index ? "active" : "not-active")}>
                                            <td colSpan="6">
                                                <div className="info-detail-order">
                                                    <div className=" wrap-content">
                                                        <div className="content-order content-right">
                                                            <div className="row-content">
                                                                <div className="label txt---400-12-18 text-not-active text-left">
                                                                    <FormattedMessage id="quick-order.order-book.accountid" />
                                                                </div>

                                                                <div className="value txt---400-12-18">
                                                                    {CommonUtils.formatFDS(item.producttypename)}
                                                                </div>
                                                            </div>
                                                            <div className="row-content">
                                                                <div className="label txt---400-12-18 text-not-active text-left">
                                                                    <FormattedMessage id="quick-order.order-book.orderid" />
                                                                </div>

                                                                <div className="value txt---400-12-18">
                                                                    {this.renderRootId(item.odorderid)}
                                                                </div>
                                                            </div>
                                                            <div className="row-content">
                                                                <div className="label txt---400-12-18 text-not-active text-left">
                                                                    <FormattedMessage id="quick-order.order-book.effect" />
                                                                </div>
                                                                <div className="value txt---400-12-18">
                                                                    {/* <FormattedDate value={item.transactTime} /> */}
                                                                    {typeAccount === TYPE_ACCOUNT.FDS && this.convertDateFDS(item.transactTime)}
                                                                    {typeAccount === TYPE_ACCOUNT.BASE && this.convertDate(item.transactTime)}
                                                                </div>
                                                            </div>
                                                            <div className="row-content">
                                                                <div className="label txt---400-12-18 text-not-active text-left">
                                                                    <FormattedMessage id="quick-order.order-book.time-order" />
                                                                </div>
                                                                <div className="value txt---400-12-18">
                                                                    {/* <FormattedPrice value={item.execprice} /> */}
                                                                    {typeAccount === TYPE_ACCOUNT.FDS && item.txtime}
                                                                    {typeAccount === TYPE_ACCOUNT.BASE && item.placedTime}
                                                                </div>
                                                            </div>
                                                            <div className="row-content">
                                                                <div className="label txt---400-12-18 text-not-active text-left">
                                                                    <FormattedMessage id="quick-order.order-book.time-matched" />
                                                                </div>
                                                                <div className="value txt---400-12-18">
                                                                    {typeAccount === TYPE_ACCOUNT.FDS && item.exec_time}
                                                                    {typeAccount === TYPE_ACCOUNT.BASE && item.matchedTime}
                                                                </div>
                                                            </div>
                                                            <div className="row-content">
                                                                <div className="label txt---400-12-18 text-not-active text-left">
                                                                    <FormattedMessage id="quick-order.order-book.price-matched" />
                                                                </div>

                                                                <div className="value txt---400-12-18">
                                                                    <FormattedPrice value={item.execprice} shortForm={true} isNotThousand={typeAccount === TYPE_ACCOUNT.FDS} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="content-order content-left">
                                                            <div className="row-content">
                                                                <div className="label txt---400-12-18 text-not-active text-left">
                                                                    <FormattedMessage id="quick-order.order-book.type-price" />
                                                                </div>

                                                                <div className="value txt---400-12-18">
                                                                    {item.pricetype}
                                                                </div>
                                                            </div>
                                                            <div className="row-content">
                                                                <div className="label txt---400-12-18 text-not-active text-left">
                                                                    <FormattedMessage id="quick-order.order-book.root-orderid" />
                                                                </div>

                                                                <div className="value txt---400-12-18">
                                                                    {this.renderRootId(item.rootodorderid)}
                                                                </div>
                                                            </div>
                                                            <div className="row-content">
                                                                <div className="label txt---400-12-18 text-not-active text-left">
                                                                    <FormattedMessage id="quick-order.order-book.finish" />
                                                                </div>

                                                                <div className="value txt---400-12-18">
                                                                    {typeAccount === TYPE_ACCOUNT.FDS && this.convertDateFDS(item.transactTime)}
                                                                    {typeAccount === TYPE_ACCOUNT.BASE && this.convertDate(item.transactTime)}
                                                                </div>
                                                            </div>
                                                            <div className="row-content">
                                                                <div className="label txt---400-12-18 text-not-active text-left">
                                                                    <FormattedMessage id="quick-order.order-book.value-order" />
                                                                </div>

                                                                <div className="value txt---400-12-18">
                                                                    <FormattedPrice value={item.quoteamt} />
                                                                </div>
                                                            </div>
                                                            <div className="row-content">
                                                                <div className="label txt---400-12-18 text-not-active text-left">
                                                                    <FormattedMessage id="quick-order.order-book.qtty-matched" />
                                                                </div>

                                                                <div className="value txt---400-12-18">
                                                                    <FormattedPrice value={item.execqtty} />
                                                                </div>
                                                            </div>
                                                            <div className="row-content">
                                                                <div className="label txt---400-12-18 text-not-active text-left">
                                                                    <FormattedMessage id="quick-order.order-book.value-matched-order" />
                                                                </div>

                                                                <div className="value txt---400-12-18">
                                                                    <FormattedPrice value={item.execamt} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="action-order">
                                                        <div className="container-btn">
                                                            <button className={"btn-action btn-fix txt---500-14-20 " + (!item.amendOrderPermission ? "" : "text-custom")} onClick={() => { this.onEditOrder(item.odorderid) }}
                                                                // disabled={true}
                                                                disabled={!item.amendOrderPermission}
                                                            >
                                                                <FormattedMessage id="quick-order.order-book.edit" />
                                                            </button>
                                                            <button className={"btn-action txt---500-14-20 " + (!item.cancelOrderPermission ? "" : "bg-sell text-custom")}
                                                                onClick={() => { this.onCancelOrder(item.odorderid) }}
                                                                disabled={!item.cancelOrderPermission}
                                                            >
                                                                <FormattedMessage id="quick-order.order-book.cancel" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })
                        }
                    </tbody>
                </table>
            </CustomScrollbars>
        )
    }


    onCloseModalQuickOrderConditionDetail = () => {
        this._setState({ isOpenModalQuickOrderConditionDetail: !this.state.isOpenModalQuickOrderConditionDetail })
    }

    onViewDetail = (order) => {
        this._setState({ recordConditon: order })
        const { lang, defaultTheme, accountQuickOrderInfo } = this.props
        const { typeAccount } = { ...accountQuickOrderInfo }
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            fdsInquiryService.getOrderChildConditionFDS(order['afacctno'], order['odorderid'])
                .then((data) => {
                    if (data) {
                        data = this.convert_GetOrderChildCondition(data, "FDS")
                        this._setState({ isOpenModalQuickOrderConditionDetail: true, dataFill: data })
                    } else {
                        this._setState({ dataFill: {} })
                    }
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'FE006001');
                });
        } else {
            inquiryService.getOrderChildCondition(order['afacctno'], order['odorderid'])
                .then((data) => {
                    if (data) {
                        data = this.convert_GetOrderChildCondition(data, "BASE")
                        this._setState({ isOpenModalQuickOrderConditionDetail: true, dataFill: data })
                    } else {
                        this._setState({ dataFill: {} })

                    }
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'FE006001');
                });
        }
    }


    convert_GetOrderChildCondition = (dataArr, type) => {
        // let { persistChecked } = this.state;
        if (type === "FDS") {
            if (dataArr.length > 0) {
                for (let i = 0; i < dataArr.length; i++) {
                    let element = dataArr[i];
                    let cancelOrderPermission = (element['iscancel'] === "Y");
                    let amendOrderPermission = (element['isadmend'] === "Y");
                    element.cancelOrderPermission = cancelOrderPermission;
                    element.amendOrderPermission = amendOrderPermission;
                    element.price = element['quoteprice']
                    element.qtty = element['orderqtty']
                    element.odorderid = element['orderid']
                    element.sideCode = element['exectype']
                    element.afacctno = `FDS${element['acctno']}`
                }
            }
        } else {
            if (dataArr.length > 0) {
                for (let i = 0; i < dataArr.length; i++) {
                    let element = dataArr[i];
                    let cancelOrderPermission = (element['iscancel_child'] === "Y");
                    let amendOrderPermission = (element['isadmend_child'] === "Y");
                    element.cancelOrderPermission = cancelOrderPermission;
                    element.amendOrderPermission = amendOrderPermission;
                    element.price = element['quoteprice']
                    element.qtty = element['orderqtty']
                    element.odorderid = element['orderid']
                    element.rootodorderid = element['quoteid']
                    element.sideCode = element['exectype']
                    element.afacctno = element['acctno']
                }
            }
        }

        return dataArr
    }

    renderTableConditionOrder = () => {
        const { recordConditon, keyActive, stautusFilter, isShowCheckBox, checkedAll, persistChecked, recordsCondition, totalSumOrder, dataFill } = this.state
        const { accountQuickOrderInfo, intl, language } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        //console.log("binh_renderTableConditionOrder", this.state, typeAccount)
        let persistCheckedLength = Object.keys(persistChecked)
        return (
            <CustomScrollbars className='scroll-order-book'>
                <table className="basic-table table-condition">
                    {this.state.isOpenModalQuickOrderConditionDetail &&
                        < ModalQuickOrderConditonDetail
                            isOpen={this.state.isOpenModalQuickOrderConditionDetail}
                            dataFill={dataFill}
                            // isOpen={true}
                            language={this.props.language}
                            onClose={this.onCloseModalQuickOrderConditionDetail}
                            record={recordConditon}
                            defaultTheme={this.props.defaultTheme}
                            typeAccount={typeAccount}
                            addClass="quick-order"
                            onEditOrder={this.onEditOrder}
                            onChangeItemSelect={this.props.onChangeItemSelect}
                        />}
                    <thead>
                        <tr>

                            <th className="txt---500-14-20 text-left text-not-active" style={{ width: "15%" }}> <FormattedMessage id="quick-order.order-book.symbol" /></th>
                            <th className="txt---500-14-20 text-not-active" style={{ width: "15%" }}><FormattedMessage id="quick-order.order-book.buy-sell" /></th>
                            <th className="txt---500-14-20 text-not-active" style={{ width: "16%" }}>Loại lệnh</th>
                            <th className="txt---500-14-20 text-not-active" style={{ width: "20%" }}><FormattedMessage id="quick-order.order-book.qtty" /></th>
                            <th className="txt---500-14-20 text-not-active" style={{ width: "34%" }}><FormattedMessage id="quick-order.order-book.status" /></th>
                        </tr>
                    </thead>
                    {typeAccount === TYPE_ACCOUNT.FDS &&
                        <tbody>
                            {
                                recordsCondition.length > 0 && recordsCondition.map((item, index) => {
                                    return (
                                        <>
                                            <tr key={index} >
                                                <td className="text-center text-active txt---500-14-20" onClick={() => this.toogleHideShowInfo(index)}>{item.symbol}</td>
                                                <td className={"text-center text-uppercase txt---400-14-20 " + (item['sideCode'] === activeOrderExecType.BUY ? "text-green" : "text-red")} onClick={() => this.toogleHideShowInfo(index)}>
                                                    {/* {item.side} */}
                                                    <FormattedMessage id={"common.exec-types." + item['sideCode']} />
                                                </td>
                                                <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)}>
                                                    {item.classcd}
                                                </td>
                                                <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)}><FormattedQuantity value={item.qtty} /></td>
                                                <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)} style={{ width: "35%" }}>
                                                    <span className="status-order">
                                                        <div className={"status-icon " + (this.renColorStatusIconCondition(item.status))}>

                                                        </div>
                                                        {language == "vi" ? item.status_desc : item.en_status_desc}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className={"block-info-detail-order " + (keyActive === index ? "active" : "not-active")}>
                                                <td colSpan="6">
                                                    <div className="info-detail-order">
                                                        <div className=" wrap-content">
                                                            <div className="content-order content-left">
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.accountid" />
                                                                    </div>
                                                                    <div className="value txt---400-12-18">
                                                                        {CommonUtils.formatFDS(item.afacctno)}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="trade.place-order.stop-price" />
                                                                    </div>
                                                                    <div className="value txt---400-12-18">
                                                                        {item.stopprice}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="trade.place-order.effect" />
                                                                    </div>
                                                                    <div className="value txt---400-12-18">
                                                                        {item.fromdate}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="trade.place-order.order-price" />
                                                                    </div>
                                                                    <div className="value txt---400-12-18">
                                                                        <FormattedPrice isFormatZero={true} value={Number(item.price)} shortForm={true} isNotThousand={true} />
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.value-order" />
                                                                    </div>
                                                                    <div className="value txt---400-12-18">
                                                                        <FormattedPrice value={Number(item.price) * Number(item.qtty)} shortForm={true} isNotThousand={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="content-order content-right">
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        {this.props.language === "vi" ? item.errdesc : item.en_errdesc}
                                                                    </div>
                                                                    <div className="value txt---400-12-18">
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.time-order" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.time_quote}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.finish" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.todate}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.active-time" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.activetime}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.active-date" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.activedate}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="action-order">
                                                            <div className="container-btn">
                                                                <button className={"btn-action btn-fix txt---500-14-20 text-custom"} onClick={() => { this.onViewDetail(item) }}
                                                                >
                                                                    <FormattedMessage id="quick-order.order-book.view" />
                                                                </button>
                                                                <button className={"btn-action txt---500-14-20 " + (!item.cancelOrderPermission ? "" : "bg-sell text-custom")}
                                                                    onClick={() => { this.onCancelOrderOrderConditon(item) }}
                                                                    disabled={!item.cancelOrderPermission}
                                                                >
                                                                    <FormattedMessage id="quick-order.order-book.cancel" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    }
                    {typeAccount === TYPE_ACCOUNT.BASE &&
                        <tbody>
                            {
                                recordsCondition.length > 0 && recordsCondition.map((item, index) => {
                                    return (
                                        <>
                                            <tr key={index} >
                                                <td className="text-center text-active txt---500-14-20" onClick={() => this.toogleHideShowInfo(index)}>{item.symbol}</td>
                                                <td className={"text-center text-uppercase txt---400-14-20 " + (item['sidecode'] === activeOrderExecType.BUY ? "text-green" : "text-red")} onClick={() => this.toogleHideShowInfo(index)}>
                                                    {/* {item.side} */}
                                                    <FormattedMessage id={"common.exec-types." + item['sidecode']} />
                                                </td>
                                                <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)}>
                                                    {item.pttype}
                                                </td>
                                                <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)}><FormattedQuantity value={item.orderqtty} /></td>
                                                <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)} style={{ width: "35%" }}>
                                                    <span className="status-order">
                                                        <div className={"status-icon " + (this.renColorStatusIconCondition(item.status))}>

                                                        </div>
                                                        {language == "vi" ? item.orstatus : item.orstatus_en}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className={"block-info-detail-order " + (keyActive === index ? "active" : "not-active")}>
                                                <td colSpan="6">
                                                    <div className="info-detail-order">
                                                        <div className=" wrap-content">
                                                            <div className="content-order content-left">
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.accountid" />
                                                                    </div>
                                                                    <div className="value txt---400-12-18">
                                                                        {item.producttypename}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="trade.order.exec-qtty" />
                                                                    </div>
                                                                    <div className="value txt---400-12-18">
                                                                        {item.execqtty || 0}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="trade.place-order.effect" />
                                                                    </div>
                                                                    <div className="value txt---400-12-18">
                                                                        {/*this.convertConditonDateBase(item.fromdate, "fromdate")*/}
                                                                        {item.fromdate || ''}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.finish" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {/*this.convertConditonDateBase(item.todate, "todate")*/}
                                                                        {item.todate}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="trade.order.detail.order" />
                                                                    </div>
                                                                    {/**Số hiệu lệnh lấy 6 số cuối để tránh bị tràn trên đặt lệnh nhanh */}
                                                                    <div className="value txt---400-12-18">
                                                                        {item.orderid ? '...' + item.orderid.substring(item.orderid.length - 6, item.orderid.length) : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="content-order content-right">
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        {this.props.language === "vi" ? item.order_info : item.en_order_info}
                                                                    </div>
                                                                    <div className="value txt---400-12-18">
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="trade.order.price-type" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {/*this.convertConditonDateBase(item.time_quote, "time_quote")*/}
                                                                        {item.pricetype || ''}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="common.close" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {/*this.convertConditonDateBase(item.todate, "todate")*/}
                                                                        {item.execprice}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.active-time" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.odtxtime || ''}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.active-date" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.odtxdate || ''}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="action-order">
                                                            <div className="container-btn">
                                                                {/*<button className={"btn-action btn-fix txt---500-14-20 text-custom"} onClick={() => { this.onViewDetail(item) }}
                                                                >
                                                                    <FormattedMessage id="quick-order.order-book.view" />
                                                                </button>*/}
                                                                <button className={"btn-action full txt---500-14-20 " + (!item.cancelOrderPermission ? "" : "bg-sell text-custom")}
                                                                    onClick={() => { this.onCancelOrderOrderConditon(item) }}
                                                                    disabled={!item.cancelOrderPermission}
                                                                >
                                                                    <FormattedMessage id="quick-order.order-book.cancel" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    }
                </table>
            </CustomScrollbars>
        )
    }



    onCancelOrderOrderConditon = (order) => {
        const { accountQuickOrderInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }
        tradeApiService.initTwoFactorAuth({ accountId: currentAccountId })
            .then((data) => {
                const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                this.props.updateInfoCheckAuth({
                    transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                })
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-customer-acccounts-list');
                return;
            })

        let listReconfirmOrder = this.getListReconfirmOrderOrderConditon([{ ...order }]);

        let orderInput = {
            actionUserOrder: ACTION_USER_ORDER.CANCEL_ORDER,
            typeSubmitOrder: TYPE_SUBMIT_ORDER.ORDER,
            cancelingOrder: listReconfirmOrder
        }

        this.props.updatePlaceQuickOrder(orderInput)
        this.cancelOrderOrderConditon(this.doCancelOrderOrderConditon, order);
    };

    doCancelOrderOrderConditon = (order, authInfo) => {
        this.callApiCancelOrderOrderConditon(order, authInfo);
    };


    setLoadStatusModal = (status) => {
        this.props.setIsOpenModalHaveData("QuickOrder", {
            loadStatusQuickOrderModalTrade: status,
        })
    }


    cancelOrderOrderConditon = (func, input) => {
        this.props.setIsOpenModalHaveData("QuickOrder", {
            isOpenModalTradeQuickOrder: true,
            onTradeQuickOrderAuthenCallback: (authInfo) => {
                func(input, authInfo);
            }
        })
    };


    callApiCancelOrderOrderConditon = (order, authInfo) => {
        this.setLoadStatusModal(LoadStatus.LOADING)
        let requestId = Random.randomOrderRequestId()
        const { accountQuickOrderInfo, currentLayoutPageActive } = this.props
        const { typeAccount } = { ...accountQuickOrderInfo }
        //console.log("binh_callApiCancelOrder", order)
        let bodyFDS = {
            ...authInfo,
            requestId,
            quoteId: order['odorderid'],
            orderType: order['classcd'],
            orderId: order['odorderid'],
            subSide: order['subside'],
            isPending: order['ispending'],
        }

        let body = {
            ...authInfo,
            requestId,
            quoteId: order['odorderid'],
            orderType: order['pttype'],
            orderId: order['orderid'],
            subSide: order['subside'],
            isPending: order['ispending'],
        }
        tradeApiService.tradeCancelOrderCondition(order['afacctno'], typeAccount === TYPE_ACCOUNT.FDS ? bodyFDS : body)
            .then(() => {
                this.setLoadStatusModal(LoadStatus.LOADED)
                this.onFinish();
                ToastUtil.success('trade.order.cancel-order-success');
            })
            .catch((error) => {
                this.setLoadStatusModal(LoadStatus.FAIL)
                ToastUtil.errorApi(error, 'trade.order.cancel-order-fail');
            });
    }

    getListReconfirmOrderOrderConditon = (listOrder) => {
        let listReconfirmOrder = [];
        listOrder.forEach(order => {
            const action = CommonUtils.getOrderActionFromSideCode(order['sideCode']);
            const orderType = CommonUtils.getOrderTypeFromPriceType(order['pricetype']);
            let limitPrice = null;
            let stopPrice = null;

            listReconfirmOrder.push({
                orderid: order['odorderid'],
                side: order['side'],
                action,
                currentOrderType: orderType,
                limitPrice: Number(order['quoteprice']),
                stopPrice,
                quantity: order['orderqtty'],
                symbolCurrent: {
                    id: order['symbol']
                },
                marketPrice: Number(order['quoteprice']),
                orderValue: Number(order['quoteprice']) * Number(order['orderqtty']),
                subOrderType: order['pricetype'],
                cancelingOrder: true,
                accountId: order['afacctno'],
                afacctnoext: order['producttypename'],
                orderValue: order['quoteprice'] * order['orderqtty'],
                custodycd: order['custodycd'] || '',
                fullname: order['fullname'] || '',
            });
        });
        return listReconfirmOrder;
    };

    onFinishOrderConditon = () => {
        const { clearDataPlaceQuickOrder, setIsOpenModalHaveData } = this.props
        CommonUtils.middlewareOrder()
        setIsOpenModalHaveData("QuickOrder", {
            isOpenModalTradeQuickOrder: false,
            onTradeQuickOrderAuthenCallback: null,
            dataShow: {},
        })
    };

    render() {
        const { records, keyActive, stautusFilter, isShowCheckBox, checkedAll, persistChecked, recordsFilter, totalSumOrder } = this.state
        const { accountQuickOrderInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        //console.log("binh----this.state", this.state)
        let persistCheckedLength = Object.keys(persistChecked)
        let totalNumOrderCancel = (persistCheckedLength.filter((item, index) => persistChecked[item] === true)).length;
        const isBroker = this.isBroker();
        let DF_LIST_STATUS_DISPLAY = !isBroker ? DF_LIST_STATUS : DF_LIST_STATUS_BROKER;
        return (
            <RetryableContent status={this.state.loadStatus} disableRetry={true} >
                <div className='order-book-quick-order-container'>
                    <div className='block-1'>
                        {/* Giá trị tài sản ròng */}
                        <div className='row-block-1 txt---500-14-20'>
                            <div>
                                <FormattedMessage id="quick-order.order-book.total-buy" />
                            </div>
                            <div>
                                <FormattedPrice value={totalSumOrder.buyValue} />
                            </div>
                        </div>
                        {/* Tỷ lệ ký quỹ hiện tại */}
                        <div className='row-block-1 txt---500-14-20'>
                            <div>
                                <FormattedMessage id="quick-order.order-book.total-sell" />
                            </div>
                            <div>
                                <FormattedPrice value={totalSumOrder.sellValue} />
                            </div>
                        </div>
                        {/* Trạng thái tiểu khoản */}
                        <div className='row-block-1 txt---500-14-20'>
                            <div>
                                <FormattedMessage id="quick-order.order-book.value-net" />
                            </div>
                            <div>
                                <FormattedPrice value={totalSumOrder.netValue} />
                            </div>
                        </div>

                        <div className='block-wrap'>
                            <div className='block-content'>
                                <div className='block-2'>
                                    <div className={"list-status-filter " + (isBroker ? 'broker' : '')}>
                                        {DF_LIST_STATUS_DISPLAY.map((item, index) => {
                                            return <div className={"status-item txt---500-14-20 item-center " + (stautusFilter === item.key ? "bg-primary text-custom" : "")} onClick={() => { this.onChangeStatusSelect(item.key) }}><FormattedMessage id={item.title} /></div>
                                        })}
                                    </div>
                                </div>

                                <div className={'block-3 ' + ((stautusFilter === 1 || stautusFilter === 2) ? "content-filter" : "")}>
                                    {<div className="table-order-book">
                                        {stautusFilter !== 5 && this.renderTableNormalOrder()}
                                        {stautusFilter === 5 && this.renderTableConditionOrder()}
                                    </div>}
                                </div>
                                {
                                    (stautusFilter === 1 || stautusFilter === 2) && <div className='block-4'>
                                        <div className="container-btn">
                                            {
                                                !isShowCheckBox &&
                                                <button className="btn-action txt---500-14-20 " onClick={this.onHandleCancelAll}>
                                                    <FormattedMessage id="quick-order.order-book.cancel-all" />
                                                </button>
                                            }

                                            {
                                                !isShowCheckBox &&
                                                <button className="btn-action txt---500-14-20 " onClick={this.onHandleCancel}>
                                                    <FormattedMessage id="quick-order.order-book.choose-cancel-order" />
                                                </button>
                                            }
                                            {
                                                isShowCheckBox &&
                                                <button className={"btn-action txt---500-14-20 " + (isShowCheckBox ? "bg-sell text-custom" : "")} onClick={this.onCancelMultipleOrder}>
                                                    <FormattedMessage id="quick-order.order-book.cancel" /> {totalNumOrderCancel} mã
                                                </button>
                                            }
                                            {
                                                isShowCheckBox &&
                                                <button className="btn-action txt---500-14-20 " onClick={this.onHandleCancel}>
                                                    <FormattedMessage id="quick-order.order-book.cancel-choose" />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                }
                            </div >
                        </div >
                    </div >
                </div >
            </RetryableContent >
        );
    }

}

const mapStateToProps = (state) => {
    let _currentLayoutPageActive = "C&B"
    let _placeQuickOrder = state.orderNew.placeQuickOrder
    const symbolCurrent = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive] && _placeQuickOrder[_currentLayoutPageActive]["symbolCurrent"]
    const quote = symbolCurrent && state.symbol.quotes[symbolCurrent["id"]]
    const placeQuickOrderCurrent = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive]
    const _accountQuickOrderInfo = placeQuickOrderCurrent && placeQuickOrderCurrent["accountQuickOrderInfo"]
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;
    return {
        config: state.user.userInfo.config,
        permissionInfo: permissionInfo,
        userInfo: userInfo,
        symbolCurrent: symbolCurrent,
        placeQuickOrderCurrent: placeQuickOrderCurrent,
        accountQuickOrderInfo: _accountQuickOrderInfo,
        quote,
        language: state.app.language,
        role: state.user.userInfo.role,
        showReconfirmOrderConfig: state.app.showReconfirmOrderConfig,

        socketODAction: state.socketRealtime.socketODAction,
        socketDOAction: state.socketRealtime.socketDOAction,
        socketCODAction: state.socketRealtime.socketCODAction,
        custSocketODActionCounter: state.socketRealtime.custSocketODActionCounter,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),

        saveTradeAuthenticated: () => dispatch(actions.saveTradeAuthenticated()),
        setTradeAuthSuccess: () => dispatch(actions.setTradeAuthSuccess()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderBookQuickOrder);
