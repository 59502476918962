import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as socket from "../../../../socket";
import { Random, ORDER_TYPE_USE } from "../../../../utils";
import { emitter } from 'utils/EventEmitter';
import _ from "lodash";
import { FormattedPriceDiff } from 'components/Formating';
import { getPriceColorClassName } from 'components/Formating/NumberFormatCustom';
import { TYPE_ACCOUNT } from './../../../../utils/constants';
import CommonOrderUtils from './../../../../utils/CommonOrderUtils';
// import { loadAllMarketInfo } from '../../../../store/actions/marketinfo.actions';
// import { getMarketStatusKey } from '../../../../services/marketinfo.service';
// import { MarketInfoLabel } from 'labels/marketinfo.label';
// import * as SocketIO from '../../../../socket';
class BlockInfoSymbol extends Component {

    callerId = Random.randomComponentId();

    constructor(props) {
        super(props);
        this.state = {
            marketStatus: ''
        }
    };

    subscribleToTopic = (symbolId) => {
        if (!symbolId) {
            return;
        }

        socket.registerInstrumentTopic(symbolId, this.callerId);
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        const { symbolId } = this.props;
        this.subscribleToTopic(symbolId);
        // this.setStatusMarketIndex();
    }

    // fetchMarketIndexInfo = (indexConfig) => {
    //     this.props.dispatch(loadAllMarketInfo([indexConfig.code], [indexConfig.code], 'LOAD_MARKET_INFO'));
    //     this.setStatusMarketIndex();
    // }

    // setStatusMarketIndex = () => {
    //     const { currentMarketInfo } = this.props;
    //     if (currentMarketInfo && currentMarketInfo.status) {
    //         let statusKey = getMarketStatusKey(currentMarketInfo);
    //         this._setState({ marketStatus: MarketInfoLabel(this.props.language).statuses[statusKey] || '' })
    //     } else {
    //         this._setState({ marketStatus: '' });
    //     }
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { symbolId, instrument, quote, currentMarketInfo } = this.props;
        if (symbolId && symbolId !== prevProps.symbolId) {
            emitter.emit("DATA_INSTRUMENT_QUICK_ORDER", instrument);
            this.subscribleToTopic(symbolId);
        }

        if (!(_.isEqual(instrument, prevProps.instrument))) {
            emitter.emit("DATA_INSTRUMENT_QUICK_ORDER", instrument);
            // if (!_.isEmpty(quote) && quote['exchange']) {
            //     if (quote['exchange'] !== prevProps.quote['exchange']) {
            //         SocketIO.registerSingleMarketInfo(quote['exchange'], this.callerId);
            //         let indexConfig = config.ALL_INDEXES.find((i) => i.code === quote['exchange']);
            //         this.fetchMarketIndexInfo(indexConfig);
            //     }
            // } else {
            //     SocketIO.unregisterCallerId(this.callerId);
            //     this._setState({ marketStatus: '' });
            // }
        }


        // let currentStatus = currentMarketInfo && currentMarketInfo['status'] ? currentMarketInfo['status'] : '';
        // let prevStatus = prevProps.currentMarketInfo && prevProps.currentMarketInfo['status'] ? prevProps.currentMarketInfo['status'] : '';
        // if ((this.props.language !== prevProps.language) || (currentStatus !== prevStatus)) {
        //     this.setStatusMarketIndex();
        // }
    }

    componentWillUnmount() {
        socket.unregisterCallerId(this.callerId);
        this.mounted = false
    }


    render() {
        const { instrument, typeAccount, quote, orderTypeUse } = this.props;
        // //console.log("binh_this.props", this.props, instrument, quote)
        return (
            <div className="info-symbol">
                <div className="custom-row">
                    {
                        // instrument["SB"] && <div className="label txt---500-14-20 text-active">
                        quote["symbol"] && <div className={"text-active " + ((!orderTypeUse || orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER) ? 'txt---500-16-24' : 'txt---500-14-20')}>
                            {quote["symbol"]}
                            {typeAccount === TYPE_ACCOUNT.BASE &&
                                <span className="text-near-active txt---400-12-18">
                                    {" | "}
                                    {/* {instrument["EX"]} */}
                                    {quote["exchange"]}
                                </span>}
                        </div>
                    }
                    <div className={"value text-active " + ((!orderTypeUse || orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER) ? 'txt---500-16-24' : 'txt---500-14-20')}>
                        {orderTypeUse && orderTypeUse !== ORDER_TYPE_USE.QUICK_ORDER &&
                            <span className={"" + (getPriceColorClassName(instrument["CP"], instrument))}>
                                (<FormattedPriceDiff isNotThousand={false} isFormatZero={true} decimalScale={null} value={instrument["percent"] || 0} withIntrinsicColor={false} colorCalcValue={instrument["CP"]} instrument={instrument} shortForm={true} suffix="%" prefix={(instrument["percent"] > 0 ? '+' : '')} />)
                            </span>
                        }
                        <span> </span>
                        {CommonOrderUtils.returnPriceByExchange(instrument, instrument["CP"])}
                    </div>
                </div>
                {(!orderTypeUse || orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER) && (
                    <div className="custom-row">
                        {/* <div className="label txt---400-12-18 txt-not-active text-left">{instrument && instrument["FN"]}</div> */}
                        <div className="label txt---400-12-18 txt-not-active text-left">{quote && quote["FullName"]}</div>
                        <div className="value txt---500-16-24">
                            <span className={""}>
                                <FormattedPriceDiff isNotThousand={false} isFormatZero={true} decimalScale={null} value={instrument["CH"] || 0} withIntrinsicColor={false} colorCalcValue={instrument["CP"]} instrument={instrument} shortForm={true} prefix={instrument["CH"] > 0 ? '+' : ''} />
                            </span>&nbsp;
                            <span className={"" + (getPriceColorClassName(instrument["CP"], instrument))}>
                                (<FormattedPriceDiff isNotThousand={false} isFormatZero={true} decimalScale={null} value={instrument["percent"] || 0} withIntrinsicColor={false} colorCalcValue={instrument["CP"]} instrument={instrument} shortForm={true} suffix="%" prefix={(instrument["percent"] > 0 ? '+' : '')} />)
                            </span>
                        </div>
                    </div>
                )}
            </div>

        );
    }
}

const mapStateToProps = (state, props) => {
    const { symbolId } = props;
    const instrument = symbolId ? state.symbol.instruments[symbolId] || {} : {};
    const quote = symbolId ? state.symbol.quotes[symbolId] || {} : {};
    // let currentMarketInfo = null;
    // let marketInfos = state.marketInfoStore.marketInfos || {};
    // if (!_.isEmpty(quote) && quote['exchange']) {
    //     currentMarketInfo = marketInfos[`${quote['exchange']}`] || null;
    // }
    return {
        instrument: instrument,
        quote: quote,
        // language: state.app.language,
        // currentMarketInfo: currentMarketInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockInfoSymbol);