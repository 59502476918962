import React, { Component } from "react";
import RetryableContent from 'components/RetryableContent';
import { push } from "connected-react-router";
import Highcharts from 'highcharts';
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { ReactComponent as IconShowChartDark } from '../../../assets/icons_new/icon_show_chart_dark.svg'
import { ReactComponent as IconShowChartLight } from '../../../assets/icons_new/icon_show_chart_light.svg'
import { FormattedPercentage, FormattedPrice } from '../../../components/Formating';
import { fdsInquiryService, inquiryService } from "../../../services";
import * as actions from "../../../store/actions";
import {
    CommonOrderUtils,
    CommonUtils, LoadStatus, orderActions, ORDER_TYPE_USE, Role, ToastUtil, Random, LanguageUtils
} from "../../../utils";
import { batch } from 'react-redux'
import BlockInfoPortfolio from './BlockInfoPortfolio/BlockInfoPortfolio';

import './PortfolioQuickOrder.scss';
import { TYPE_ACCOUNT } from './../../../utils/constants';
import ModalPlaceOrderTPSL from './../../Trade/OpenPositions/ModalPlaceOrderTPSL/ModalPlaceOrderTPSL';
import CustomScrollbars from "components/CustomScrollbars";
import * as socket from '../../../socket';

export const chartColors = {
    yellow: "#fdff12",
    // yellow: "#F9B525",
    reduce: '#F93E3E',
    reduceBest: '#B3151A',
    increase: '#21DB77',
    increaseBest: '#199753'
};

class PortfolioQuickOrder extends Component {
    callerId = Random.randomComponentId();

    initialState = {
        records: [], // data

        loadStatus: LoadStatus.NONE,
        keyActive: null, // active row
        isShowChart: false, // Show chart
        dataSum: {
            sumCostValue: 0, // Giá trị vốn
            sumCostMarket: 0, // Giá trị thị trường
            sumUpnlAmt: 0, // Lãi lỗ
            percentPnl: 0, // % lãi lỗ
        },
        isOpenModalTPSL: false,
        fillDataModalTPSL: {},
        dataDetailTPSL: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
        this.debounceLoadData();
        this.getChart();
    }

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    instrumentsMap = {};

    initMaps = () => {
        this.instrumentsMap = {};
    };


    triggerChart = () => {
        const { isShowChart } = this.state;
        this._setState({
            isShowChart: !isShowChart
        });
    };

    renderChartBtn = () => {
        const { isShowChart } = this.state;
        let id = isShowChart ? 'quick-order.portfolio.hide-chart' : 'quick-order.portfolio.show-chart';
        return (
            <div className="button-container">
                {isShowChart &&
                    <button className="txt---400-14-20 btn-action item-center" onClick={this.triggerChart}>
                        <span style={{ paddingRight: "5px" }}>
                            {CommonUtils.renderIconTheme(<IconShowChartDark />, <IconShowChartLight />)}
                        </span>
                        <FormattedMessage id={id} />
                    </button>
                }
                {!isShowChart &&
                    <button button className="txt---400-14-20 btn-action btn-show-chart item-center" onClick={this.triggerChart}>
                        <span style={{ paddingRight: "5px" }}>
                            {CommonUtils.renderIconTheme(<IconShowChartDark />, <IconShowChartLight />)}
                        </span> <FormattedMessage id={id} />
                    </button>
                }
            </div>
        );
    };

    setChartData = () => {
        if (!this.chart) return;
        const { records } = this.state;
        //console.log("setChartData", records)
        let summaryData = [];

        let recordToSetData = records.filter(item => { return item['proportion'] >= 1 });

        // recordToSetData = _.sortBy(recordToSetData, item => { return item.pnlrate });

        let otherValueArr = records.filter(item => { return item['proportion'] < 1 }); // List các mã có tỷ trọng < 1%

        let reduceList = [];
        let increaseList = [];
        let refList = [];

        // let maxIncreaseSymbolData = _.maxBy(recordToSetData, item => { return item.pnlrate });
        // let minReduceSymbolData = _.minBy(recordToSetData, item => { return item.pnlrate });

        for (let i = 0; i < recordToSetData.length; i++) {
            let valueChart = {
                name: '',
                y: '',
                color: '',
            }

            // let pnlrate = Number(recordToSetData[i].pnlrate);

            valueChart = {
                name: recordToSetData[i]["symbol"],
                y: recordToSetData[i]["proportion"],
                basicPriceAmt: recordToSetData[i]["basicPriceAmt"]
            }

            if (recordToSetData[i]["proportion"] < 10) {
                valueChart.dataLabels = { distance: 10 }
            }

            if (recordToSetData[i]["pnlrate"] < 0) {
                valueChart.color = chartColors.reduce
                reduceList.push(valueChart);
            } else if (recordToSetData[i]["pnlrate"] > 0) {
                valueChart.color = chartColors.increase
                increaseList.push(valueChart);
            } else {
                valueChart.color = chartColors.yellow
                refList.push(valueChart);
            }


            // // Nếu là mã lãi nhất hoặc lỗ nhất ===> Màu sẽ đậm hơn so với màu lãi và lỗ
            // if ((maxIncreaseSymbolData && maxIncreaseSymbolData.pnlrate === pnlrate && maxIncreaseSymbolData['pnlrate'] > 0) || pnlrate >= 100) {
            //     valueChart.color = chartColors.increaseBest
            // }

            // if (minReduceSymbolData && minReduceSymbolData.pnlrate === pnlrate && minReduceSymbolData['pnlrate'] < 0) {
            //     valueChart.color = chartColors.reduceBest
            // }
        }

        summaryData = summaryData.concat(increaseList).concat(refList).concat(reduceList);

        let _summaryData = summaryData;

        let otherStockData = {
            "name": LanguageUtils.getMessageByKey("asset-total.other-stock", this.props.language),
            "y": 0,
            "basicPriceAmt": 0,
            "color": "rgb(85, 87, 90)",
            "dataLabels": {
                "distance": 10
            }
        }
        if (otherValueArr.length > 0) {
            otherValueArr.forEach(item => {
                otherStockData.y += item['proportion'];
                otherStockData.basicPriceAmt += item.basicPriceAmt;
            })
        }


        if (otherStockData.y > 0) {
            _summaryData = _summaryData.concat(otherStockData);
        }
        // let _summaryData = summaryData.sort(function (a, b) {
        //     const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        //     const nameB = b.name.toUpperCase(); // ignore upper and lowercase

        //     if (nameA < nameB) {
        //         return -1;
        //     }
        //     if (nameA > nameB) {
        //         return 1;
        //     }

        //     return 0;
        // });
        this.chart.series[0].setData(_summaryData);
    };

    componentDidUpdate(prevProps, prevState) {
        const { records, } = this.state;
        const { records: prevRecords } = prevState;

        const { placeQuickOrderCurrent, config, updatePlaceQuickOrder, currentSymbol, accountQuickOrderInfo, orderTypeUse,
            socketPPAction, socketSEAction, socketDOAction, socketCODAction
        } = this.props;

        const { currentAccountId } = { ...accountQuickOrderInfo }

        const {
            currentSymbol: prevCurrentSymbol,
            placeQuickOrderCurrent: prevPlaceQuickOrderCurrent,
            accountQuickOrderInfo: prevAccountQuickOrderInfo,
            socketPPAction: prevsocketPPAction, socketSEAction: prevSocketSEAction,
            socketDOAction: prevsocketDOAction,
            socketCODAction: prevSocketCODAction

        } = prevProps;

        const { currentAccountId: prevCurrentAccountId } = { ...prevAccountQuickOrderInfo }

        if ((currentAccountId !== prevCurrentAccountId)) {
            this.debounceLoadData();
        }

        if ((socketSEAction[currentAccountId] !== prevSocketSEAction[currentAccountId]) ||
            (socketDOAction[currentAccountId] !== prevsocketDOAction[currentAccountId]) ||
            (socketCODAction[currentAccountId] !== prevSocketCODAction[currentAccountId])
        ) {
            this.debounceLoadData();
        }


        if (!(_.isEqual(records, prevRecords))) {
            this.setChartData();
        }
    }

    registerTopics(listSymbols) {
        let instruments = CommonUtils.getInstruments();
        let _instruments = []
        const topics = _.map(listSymbols, (symbol) => {
            _instruments.push(instruments && instruments[symbol])
            return socket.socketRoomName.instrument + ':' + symbol;
        });
        socket.registerTopics(topics, this.callerId);
    }

    componentWillUnmount() {
        if (this.chart) this.chart.destroy()
        this.chart = undefined
        socket.unregisterCallerId(this.callerId);
        this.mounted = false
    }

    getChart = () => {
        if (this.chart) return this.chart;
        this.chart = new Highcharts.chart('chart-container-pie', {
            credits: {
                enabled: false
            },
            chart: {
                backgroundColor: null,
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                type: 'pie',
                reflow: true,
                style: {
                    //fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                    fontFamily: 'Helvetica Neue Regular',
                    // fontFamily: `"Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
                }
            },
            title: {
                text: null,
            },
            tooltip: {
                style: {
                    fontWeight: 'bold',
                    fontSize: 12
                },
                formatter: function () {
                    let label = `
                        <div>
                            <span style="color:${this.point.color}">\u25CF</span>
                            <div>
                                ${this.point.name}
                            </div>
                            <br>
                                ${Number(this.point.basicPriceAmt).toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} VND
                            <br>
                        </div>
                        `
                    return label;
                }
                //pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    innerSize: '40%',
                    allowPointSelect: false,
                    cursor: 'pointer',
                    center: ["50%", "50%"],
                    dataLabels: {
                        enabled: true,
                        distance: '-30%',
                        color: 'white',
                        // connectorWidth: 0,
                        // shadow: false,
                        style: {
                            textOutline: "ellipsis",
                            fontWeight: "0",
                            fontSize: 12
                        }
                    },
                    showInLegend: false
                }
            },
            series: [{
                // name: 'Brands',
                // colorByPoint: true,
                // innerSize: '50%',
                // type: 'pie',
                // size: '100%',
                // allowPointSelect: false,
                // innerSize: '50%',
                // size: '75%',
                // data: [],
                data: []
            }]
        })
        return this.chart;
    };

    subListSymbol = () => {
        const { records } = this.state
        let listSymbols = []
        if (records && records.length > 0) {
            listSymbols = records.map((record, index) => {
                if (record.symbol) {
                    return CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol || "")
                }
            })
        }
        this.registerTopics(listSymbols)
    }

    apiSeq = new Date().getTime();
    debounceLoadData = _.debounce(() => {
        this.apiSeq = new Date().getTime();
        this.loadData(this.apiSeq);
    }, 50);

    loadData = (apiSeq) => {
        const { accountQuickOrderInfo } = this.props;
        const { currentAccountId, typeAccount, curCustomer } = { ...accountQuickOrderInfo }



        const getSecuritiesPortfolioFunc = this.isBroker() ? inquiryService.getSecuritiesPortfolio : inquiryService.getSecuritiesPortfolio;

        const getSecuritiesPortfolioFDSFunc = this.isBroker() ? fdsInquiryService.getSecuritiesPortfolioFDS : fdsInquiryService.getSecuritiesPortfolioFDS;
        let custid = CommonUtils.isBroker() ? (curCustomer && curCustomer.custid) : this.props.userInfo.custid
        if (currentAccountId) {
            if (typeAccount === TYPE_ACCOUNT.FDS) {
                this._setState({ loadStatus: LoadStatus.LOADING });
                getSecuritiesPortfolioFDSFunc(currentAccountId, custid)
                    .then((data) => {
                        if (apiSeq < this.apiSeq) return;
                        this.initMaps();
                        this._setState({
                            loadStatus: LoadStatus.LOADED,
                            records: data,
                        }, () => {
                            this.processOrderDataFDS(true);
                            this.subListSymbol();
                        })
                        // this._setState({ records: data })
                    })
                    .catch((error) => {
                        this._setState({ loadStatus: LoadStatus.FAIL });
                        ToastUtil.errorApi(error, 'common.fail-to-load-open-positions');
                    });
            } else {
                this._setState({ loadStatus: LoadStatus.LOADING });
                getSecuritiesPortfolioFunc(currentAccountId, custid)
                    .then((data) => {
                        if (apiSeq < this.apiSeq) return;
                        this.initMaps();
                        this._setState({
                            loadStatus: LoadStatus.LOADED,
                            records: data,
                        }, () => {
                            this.processOrderData(true);
                            this.subListSymbol();
                        })
                        // this._setState({ records: data })
                    })
                    .catch((error) => {
                        this._setState({ loadStatus: LoadStatus.FAIL });
                        ToastUtil.errorApi(error, 'common.fail-to-load-open-positions');
                    });
            }
        }

    };

    getValidSumValue = (val) => {
        if (val == null || Number.isNaN(val)) {
            return 0;
        }
        return val;
    };

    processOrderData = (isFirst) => {
        const { records } = this.state
        let newRecords = _.cloneDeep(records)
        let instruments = isFirst ? CommonUtils.getInstruments() : this.instrumentsMap
        if (newRecords.length > 0) {
            let _sumCostValue = 0, _sumCostMarket = 0, _sumUpnlAmt = 0, _percentPnl = 0, _sumPnl = 0
            _.forEach(newRecords, (record) => {
                let _symbol = CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol)
                let instrument = instruments[_symbol] || {}
                if (instrument && isFirst) this.instrumentsMap[_symbol] = instrument;
                record.closePrice = instrument['CP'] || record['basicPrice']
                if (CommonUtils.checkSeqApi(instrument.s, record.s)) {
                    record.closePrice = record['basicPrice'] || 0
                }
                record['basicPrice'] = record.closePrice
                record.basicPriceAmt = this.getValidSumValue(record.closePrice * (record['total'] - record['sending']));
                record.pnlamt = (record.basicPriceAmt - record.costPriceAmt); // Lãi lỗ
                //Tính lại lãi lỗ + % lãi lỗ
                let compareTotal = record.total - record.sending;
                if (record.costPriceAmt == 0) {
                    if (compareTotal != 0) {
                        record.pnlrate = 100
                    } else if (compareTotal == 0) {
                        record.pnlrate = 0
                    }
                } else {
                    record.pnlrate = (record.pnlamt * 100) / record.costPriceAmt;
                }
                record.totalExt = record['total'] - record['sending'] // Tổng KL theo spec VIX
                _sumPnl += record['totalpnl'];
                // _sumCostValue += this.getValidSumValue(record['costPrice'] * (record['total'] - record['sending']));
                _sumCostValue += record.costPriceAmt;
                _sumCostMarket += this.getValidSumValue(record.closePrice * (record['total'] - record['sending']));
                _sumUpnlAmt += this.getValidSumValue(record.basicPriceAmt - record.costPriceAmt);
            })

            _.forEach(newRecords, (record) => {
                record.proportion = this.getValidSumValue((record.basicPriceAmt * 100) / _sumCostMarket) // Tỷ trọng
                record.proportion = CommonUtils.convertRound(record.proportion)
            })
            // if (_sumCostValue == 0) {
            //     _percentPnl = 100
            // } else {
            // if (_sumCostValue === 0) {
            //     _percentPnl = 100
            // }else {
            //     _percentPnl = (_sumUpnlAmt * 100 / _sumCostValue)
            // }
            // }
            if (_sumCostValue == 0) {
                if (_sumPnl > 0) {
                    _percentPnl = 100;
                } else if (_sumPnl = 0) {
                    _percentPnl = 0;
                }
            } else {
                _percentPnl = (_sumUpnlAmt * 100 / _sumCostValue)
            }
            this._setState({
                records: newRecords,
                dataSum: {
                    ...this.state.dataSum,
                    sumCostValue: _sumCostValue,
                    sumCostMarket: _sumCostMarket,
                    sumUpnlAmt: _sumUpnlAmt,
                    percentPnl: _percentPnl,
                }
            });
        } else {
            this._setState({
                records: [],
                dataSum: {
                    ...this.state.dataSum,
                    sumCostValue: 0,
                    sumCostMarket: 0,
                    sumUpnlAmt: 0,
                    percentPnl: 0,
                }
            });
        }
    };


    processOrderDataFDS = (isFirst) => {
        const { records } = this.state
        let newRecords = _.cloneDeep(records)
        let instruments = isFirst ? CommonUtils.getInstruments() : this.instrumentsMap
        if (newRecords.length > 0) {
            let _sumCostValue = 0, _sumCostMarket = 0, _sumUpnlAmt = 0, _percentPnl = 0
            _.forEach(newRecords, (record) => {
                let _symbol = CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol)
                let instrument = instruments[_symbol] | {}
                if (instrument && isFirst) this.instrumentsMap[_symbol] = instrument;
                record.closePrice = instrument['CP'] || record['price_secured']
                if (CommonUtils.checkSeqApi(instrument.s, record.s)) {
                    record.closePrice = record['price_secured'] || 0
                }
                record['price_secured'] = record.closePrice
                record.basicPriceAmt = record.closePrice * record.sumUpnlAmt;

                // record.qtty = Math.abs(record.qtty)
                record.qtty = Number(record.qtty)
                record['capvalue'] = Number(record.capvalue)
                let marvalue = this.getValidSumValue((Number(record.closePrice) * Number(record['qtty'])));
                // record['marvalue'] = Math.abs(marvalue)
                record['marvalue'] = Number(marvalue)
                record['pnlamt'] = 0
                record['pnlrate'] = 0

                // if (record.pecentnonrplamt != null && record.pecentnonrplamt != 0) {
                //     record['pnlamt'] = record['marvalue'] - record['capvalue']
                //     if (itemFDS['capvalue'] > 0) record['pnlrate'] = (record['pnlrate'] * 100) / record['capvalue'];
                // }
                record['pnlamt'] = this.getValidSumValue(record['marvalue'] - record['capvalue'])
                // if (record['capvalue'] > 0) {
                record['pnlrate'] = this.getValidSumValue((record['pnlamt'] * 100) / Math.abs(record['capvalue']));
                // }
                _sumUpnlAmt += this.getValidSumValue((record['marvalue'] - record['capvalue']) * record['nvalue'])
                _sumCostMarket += this.getValidSumValue(Math.abs(record['marvalue']) * record['nvalue'])
                _sumCostValue += this.getValidSumValue(Math.abs(record['capvalue']) * record['nvalue'])
                if (instrument && Object.keys(instrument).length > 0) {
                    record['totalplamt'] = this.getValidSumValue(record.pnlamt * record.nvalue)
                }
            })


            _.forEach(newRecords, (record) => {
                record.proportion = this.getValidSumValue((((Math.abs(record['marvalue']) * record['nvalue']) * 100) / _sumCostMarket)) // Tỷ trọng
                record.proportion = CommonUtils.convertRound(record.proportion)
            })
            //console.log("binh_processOrderDataFDS", newRecords)
            _percentPnl = (_sumUpnlAmt * 100 / _sumCostValue)
            this._setState({
                records: newRecords,
                dataSum: {
                    ...this.state.dataSum,
                    sumCostValue: _sumCostValue,
                    sumCostMarket: _sumCostMarket,
                    sumUpnlAmt: _sumUpnlAmt,
                    percentPnl: _percentPnl,
                }
            });
        } else {
            this._setState({
                records: [],
                dataSum: {
                    ...this.state.dataSum,
                    sumCostValue: 0,
                    sumCostMarket: 0,
                    sumUpnlAmt: 0,
                    percentPnl: 0,
                }
            });
        }
    };

    toogleHideShowInfo = (id) => {
        const { records, keyActive } = this.state
        if (keyActive !== id) {
            this._setState({ keyActive: id })
        } else {
            if (keyActive !== null) {
                this._setState({ keyActive: null })
            } else {
                this._setState({ keyActive: id })
            }
        }
    }

    onHandleBuy = (symbol) => {
        const { onChangeItemSelect, updatePlaceQuickOrder, clearDataPlaceQuickOrder } = this.props
        batch(() => {
            clearDataPlaceQuickOrder(ORDER_TYPE_USE.QUICK_ORDER)
            updatePlaceQuickOrder({
                symbolCurrent: symbol,
                action: orderActions.BUY,
                isCheckFillPrice: true,
            })
        })
        onChangeItemSelect(1)
    }

    onHandleSell = (symbol) => {
        const { onChangeItemSelect, updatePlaceQuickOrder, clearDataPlaceQuickOrder } = this.props
        batch(() => {
            clearDataPlaceQuickOrder(ORDER_TYPE_USE.QUICK_ORDER)
            updatePlaceQuickOrder({
                symbolCurrent: symbol,
                action: orderActions.SELL,
                isCheckFillPrice: true,
            })
        })
        onChangeItemSelect(1)
    }

    onHandleTPSL = (e, record) => {
        const { updateProTrade, updatePlaceQuickOrder, accountInfo } = this.props
        const { isDerivtypeAccount, currentAccountId } = { ...accountInfo }
        e.stopPropagation();

        let _dataFill = {
            ...record
        }

        if (record['bl_tpsl_quoteid'] !== null) {
            inquiryService.getCondorderDetailTPSL(record.accountID, record.bl_tpsl_quoteid)
                .then((data) => {
                    if (data && data.length > 0) {
                        this._setState({ isOpenModalTPSL: true, fillDataModalTPSL: _dataFill, dataDetailTPSL: data[0] })
                    }
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'CM2');
                });
        } else {
            this._setState({ isOpenModalTPSL: true, fillDataModalTPSL: _dataFill, dataDetailTPSL: {} })
        }
    }



    onCloseModalTPSL = () => {
        this._setState({ isOpenModalTPSL: false }, () => {
            this.props.clearDataPlaceQuickOrder(ORDER_TYPE_USE.QUICK_ORDER)
        })
    }

    onRowDataChange = (instrument) => {
        // //console.log("this.instrumentsMap_symbolId", symbolId, symbolId, instrument)
        const { accountQuickOrderInfo } = this.props
        const { typeAccount } = { ...accountQuickOrderInfo }
        let symbolId = instrument.SB
        this.instrumentsMap[symbolId] = instrument;
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            this.processOrderDataFDS()
        } else {
            this.processOrderData()
        }
        this.setChartData();
    };

    renderBlockAccountNomal = () => {
        const { records, keyActive, isShowChart, dataSum } = this.state
        const { accountQuickOrderInfo } = this.props
        const { currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        let _records = _.cloneDeep(records)
        return (
            <div className="info-portfolio-body">
                {this.state.isOpenModalTPSL &&
                    <ModalPlaceOrderTPSL
                        isOpen={this.state.isOpenModalTPSL}
                        toggle={this.onCloseModalTPSL}
                        onClose={this.onCloseModalTPSL}
                        fillDataModalTPSL={this.state.fillDataModalTPSL}
                        orderTypeUse={ORDER_TYPE_USE.QUICK_ORDER}
                        dataDetailTPSL={this.state.dataDetailTPSL}
                    />}
                {/* Haki_review.: Cần chuyển sang render component và listen instrument theo mã CK*/}
                {_records.length > 0 && _records.map((record, index) => {
                    return (
                        <BlockInfoPortfolio
                            typeAccount={typeAccount}
                            key={record.accountId + '_' + record.symbol + '_' + index}
                            index={index}
                            record={record}
                            keyActive={keyActive}
                            toogleHideShowInfo={this.toogleHideShowInfo}
                            onRowDataChange={this.onRowDataChange}
                            onHandleBuy={this.onHandleBuy}
                            onHandleSell={this.onHandleSell}
                            onHandleTPSL={this.onHandleTPSL}
                        />
                    )
                })}

            </div>
        )
    }

    renderBlockAccountDerivative = () => {
        const { records, keyActive, isShowChart, dataSum } = this.state
        const { accountQuickOrderInfo } = this.props
        const { currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        let instruments = CommonUtils.getInstruments();
        let _records = _.cloneDeep(records)
        return (
            <div className="info-portfolio-body">
                {/* Haki_review.: Cần chuyển sang render component và listen instrument theo mã CK*/}
                {_records.length > 0 && _records.map((record, index) => {
                    return (
                        <BlockInfoPortfolio
                            typeAccount={typeAccount}
                            key={record.accountId + '_' + record.symbol + '_' + index}
                            index={index}
                            record={record}
                            keyActive={keyActive}
                            toogleHideShowInfo={this.toogleHideShowInfo}
                            onRowDataChange={this.onRowDataChange}
                            onHandleBuy={this.onHandleBuy}
                            onHandleSell={this.onHandleSell}
                        />
                    )
                })}

            </div>
        )
    }

    render() {
        const { records, keyActive, isShowChart, dataSum } = this.state
        const { accountQuickOrderInfo } = this.props
        const { currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        let instruments = CommonUtils.getInstruments();
        return (
            <RetryableContent status={this.state.loadStatus} disableRetry={true} >
                <div className='portfolio-quick-order-container'>
                    <div className={'block-1 ' + (isShowChart ? 'show-chart' : '')}>
                        {/* Giá trị tài sản ròng */}
                        <div className='row-block-1 txt---500-14-20'>
                            <div>
                                <FormattedMessage id="quick-order.portfolio.sum-cost-value-short" />
                            </div>
                            <div>
                                <FormattedPrice value={dataSum.sumCostValue ? dataSum.sumCostValue : 0} />
                            </div>
                        </div>
                        {/* Tổng giá trị thị trường */}
                        <div className='row-block-1 txt---500-14-20'>
                            <div>
                                <FormattedMessage id="quick-order.portfolio.sum-cost-market-short" />
                            </div>
                            <div>
                                <FormattedPrice value={dataSum.sumCostMarket ? dataSum.sumCostMarket : 0} />
                            </div>
                        </div>
                        {/* Lãi/Lỗ */}
                        <div className='row-block-1 txt---500-14-20'>
                            <div>
                                <FormattedMessage id="quick-order.portfolio.sum-UpnlAmt" />
                            </div>
                            <div>
                                <span className={"" + (CommonUtils.getClassCheckValue(dataSum.sumUpnlAmt))}>
                                    {dataSum.sumUpnlAmt && dataSum.sumUpnlAmt > 0 && "+"}
                                    <FormattedPrice value={dataSum.sumUpnlAmt ? dataSum.sumUpnlAmt : 0} />
                                    &nbsp;
                                </span>
                                <span className={"" + (CommonUtils.getClassCheckValue(dataSum.percentPnl))}>
                                    {dataSum && "("}
                                    {dataSum.percentPnl && dataSum.percentPnl > 0 && "+"}
                                    <FormattedPercentage value={dataSum.percentPnl ? dataSum.percentPnl : 0} />
                                    {dataSum && "%)"}
                                </span>
                            </div>
                        </div>
                        <div className="info-chart">
                            <div id="chart-container-pie" className={"chart-container-pie " + (!isShowChart ? "hide-chart" : "")}></div>
                            {
                                this.renderChartBtn()
                            }
                        </div>


                        <div className='block-wrap'>
                            <div className='block-content'>
                                <div className='block-3'>
                                    <div className="info-portfolio-header">
                                        <div className="title-left text-not-active txt---400-14-20 txt-left">
                                            <FormattedMessage id="quick-order.portfolio.symbol" />
                                        </div>
                                        <div className="title-left text-not-active txt---400-14-20 txt-right">
                                            <FormattedMessage id="quick-order.portfolio.qtty" />
                                        </div>
                                        <div className="title-left text-not-active txt---400-14-20 txt-right">
                                            <FormattedMessage id="quick-order.portfolio.price-cost-short" />
                                        </div>
                                        <div className="title-left text-not-active txt---400-14-20 txt-right">
                                            <FormattedMessage id="quick-order.portfolio.market-price" />
                                        </div>
                                        <div className="title-right text-not-active txt---400-14-20 txt-right container-profit-loss-header">
                                            <FormattedMessage id="quick-order.portfolio.sum-UpnlAmt" />
                                        </div>
                                    </div>
                                    <CustomScrollbars
                                        className="block-3-scroll-container">
                                        <div className="info-portfolio">
                                            {typeAccount === TYPE_ACCOUNT.BASE && this.renderBlockAccountNomal()}
                                            {typeAccount === TYPE_ACCOUNT.FDS && this.renderBlockAccountDerivative()}
                                        </div>
                                    </CustomScrollbars>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </RetryableContent >
        );
    }

}

const mapStateToProps = (state) => {
    let _currentLayoutPageActive = "C&B"
    let _placeQuickOrder = state.orderNew.placeQuickOrder
    const symbolCurrent = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive] && _placeQuickOrder[_currentLayoutPageActive]["symbolCurrent"]
    const quote = symbolCurrent && state.symbol.quotes[symbolCurrent["id"]]
    const placeQuickOrderCurrent = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive]
    const _accountQuickOrderInfo = placeQuickOrderCurrent && placeQuickOrderCurrent["accountQuickOrderInfo"]
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    return {
        config: state.user.userInfo.config,
        permissionInfo: permissionInfo,
        userInfo: userInfo,
        symbolCurrent: symbolCurrent,
        placeQuickOrderCurrent: placeQuickOrderCurrent,
        accountQuickOrderInfo: _accountQuickOrderInfo,
        quote,
        language: state.app.language,
        role: state.user.userInfo.role,
        showReconfirmOrderConfig: state.app.showReconfirmOrderConfig,
        socketSEAction: state.socketRealtime.socketSEAction,
        socketDOAction: state.socketRealtime.socketDOAction,
        socketCODAction: state.socketRealtime.socketCODAction,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioQuickOrder);
