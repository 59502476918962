import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as socket from "../../../../socket";
import { CommonUtils, Random, derivativeExchanges, ORDER_TYPE_USE } from "../../../../utils";
class BlockPlacePrice extends Component {

    callerId = Random.randomComponentId();

    subscribleToTopic = (symbolId) => {
        if (!symbolId) {
            return;
        }

        socket.registerInstrumentTopic(symbolId, this.callerId);
    };

    componentDidMount() {
        const { symbolId } = this.props;
        this.subscribleToTopic(symbolId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { symbolId } = this.props;
        if (symbolId && symbolId !== prevProps.symbolId) {
            this.subscribleToTopic(symbolId);
        }
    }

    componentWillUnmount() {
        socket.unregisterCallerId(this.callerId);
    }

    render() {
        const { onHandelFillPrice, quote, orderTypeUse } = this.props;
        return (
            <div className="placeprice-container">
                {orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER && (
                    <div className="info-capacity">
                        <div className="row-capacity txt---400-12-18" style={{ marginBottom: "10px" }}>
                            <div className="capacity-content capacity-price">
                                <div className='price-block'>
                                    <div className='label'>Trần:</div>
                                    <div className="text-ceil-price" onClick={() => onHandelFillPrice("ceiling")}>
                                        {
                                            quote ? (quote.exchange === derivativeExchanges[0] ? CommonUtils.roundToTwo(quote.ceiling, 1) : CommonUtils.roundToTwo(quote.ceiling, 1000)) : "0.00"
                                        }
                                    </div>
                                </div>
                                <div className='price-block'>
                                    <div className='label'>Tham chiếu:</div>
                                    <div className="text-ref-price" onClick={() => onHandelFillPrice("reference")} >
                                        {
                                            quote ? (quote.exchange === derivativeExchanges[0] ? CommonUtils.roundToTwo(quote.reference, 1) : CommonUtils.roundToTwo(quote.reference, 1000)) : "0.00"
                                        }
                                    </div>
                                </div>
                                <div className='price-block'>
                                    <div className='label'>Sàn:</div>
                                    <div className="text-floor-price" onClick={() => onHandelFillPrice("floor")}>
                                        {
                                            quote ? (quote.exchange === derivativeExchanges[0] ? CommonUtils.roundToTwo(quote.floor, 1) : CommonUtils.roundToTwo(quote.floor, 1000)) : "0.00"
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockPlacePrice);