import React, { Component } from 'react';
import _ from "lodash";
import { connect } from 'react-redux';

import { FormattedPercentage, FormattedPrice, FormattedPriceDiff, FormattedQuantity } from "../../../../components/Formating";
import ValueChangeAnimation from "../../../../components/ValueChangeAnimation";
import { CommonOrderUtils, CommonUtils } from "../../../../utils";
import { FormattedMessage } from 'react-intl';
import { TYPE_ACCOUNT } from './../../../../utils/constants';
import { getPriceColorClassName } from "../../../../components/Formating/NumberFormatCustom";
class BlockInfoPortfolio extends Component {


    componentDidMount() {
        const { record, instrument, onRowDataChange } = this.props;
        if (instrument) {
            // onRowDataChange(CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol), instrument);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { onRowDataChange, instrument } = this.props;
        const { instrument: prevInstrument } = prevProps;
        if ((instrument.SB == prevInstrument.SB) && (instrument.CP && !(_.isEqual(instrument.CP, prevInstrument.CP)))) {
            let _instrument = {
                Id: instrument.Id,
                SB: instrument.SB,
                CP: instrument.CP,
                s: instrument.s
            }
            onRowDataChange(_instrument);
        }
    }

    componentWillUnmount() {
    }

    isCheckSymbolWFT = (symbol) => {
        if (!symbol) {
            return true
        }
        const config = '_WFT'
        if (symbol.endsWith(config)) {
            return false
        }
        return true
    }

    renderBlockAccountNomal = () => {
        let { instrument: _instrument, index, keyActive, toogleHideShowInfo, record, onHandleBuy, onHandleSell, onHandleTPSL, quote } = this.props
        let instrument = _.cloneDeep(_instrument)
        instrument["CP"] = record['basicPrice']
        if (CommonUtils.checkSeqApi(instrument.s, record.s)) {
            instrument["CP"] = record['basicPrice'] || 0
            instrument["percent"] = 0
        }
        // record.pnlamt = (record.total - record.withDraw - record.sending) * (instrument["CP"] - record.costPrice) //TLTP

        let isShowActionWFT = this.isCheckSymbolWFT(record.symbol)
        ////console.log("renderBlockAccountNomal_1", { instrument, record })

        let priceColorClass = getPriceColorClassName(instrument["CP"], instrument);
        ////console.log("renderBlockAccountNomal_2", { instrument, record })
        return (
            <div className="block-info-portfolio-body">
                <div className="info-general" onClick={() => toogleHideShowInfo(index)}>
                    <div className="info-row info-row-1 txt---400-14-20">

                        <span className={'txt-left ' + priceColorClass}>
                            {record.symbol}
                        </span>
                        {/**KL */}
                        <span className='txt-right'><FormattedQuantity value={record.totalExt} /></span>
                        {/**Giá Vốn */}
                        <span className='txt-right'><FormattedPrice value={Number(record.costPrice) || 0} shortForm={true} /></span>
                        {/**Giá TT */}
                        <span className='txt-right'>
                            <FormattedPrice value={instrument["CP"]} shortForm={true} decimalScale={null} instrument={instrument} />
                        </span>
                        {/**Lãi lỗ */}
                        <div className='container-profit-loss'>
                            <ValueChangeAnimation
                                tag="div"
                                value={record.pnlrate}
                                className={instrument["CP"] >= record.costPrice ? 'text-priceboard-green text-right' : 'text-priceboard-red text-right'}
                            >
                                <FormattedPriceDiff isFormatZero={true} decimalScale={2} value={record.pnlrate || 0} shortForm={false} prefix={(record.pnlrate > 0 ? '+' : '')} suffix="%" />
                            </ValueChangeAnimation>
                            <ValueChangeAnimation
                                tag="div"
                                value={record.pnlamt}
                                className={instrument["CP"] >= record.costPrice ? 'text-priceboard-green text-right' : 'text-priceboard-red text-right'}
                            >
                                <FormattedPriceDiff decimalScale={0} value={record.pnlamt || 0} shortForm={false} prefix={(record.pnlamt > 0 ? '+' : '')} />
                            </ValueChangeAnimation>
                        </div>


                        {/*<div className="info-row-1-left">
                            <span>
                                {record.symbol}
                                <span className="text-not-active">
                                    {instrument && ` | ${quote["exchange"]}`}
                                </span>
                            </span>
                            <span>
                                <FormattedPrice value={instrument["CP"]} shortForm={true} decimalScale={null} />
                            </span>
                            <span className={""}>
                                <FormattedPriceDiff isNotThousand={false} isFormatZero={true} decimalScale={null} value={instrument["percent"] || 0} withIntrinsicColor={false} colorCalcValue={instrument["CP"]} instrument={instrument} shortForm={true} suffix="%" prefix={"" + (instrument["percent"] > 0 ? '+' : '')} />
                            </span>
                            {isShowActionWFT && <span className={"btn-tpsl " + ((record['bl_tpsl_quoteid'] !== null) ? "active" : " ")} onClick={(e) => onHandleTPSL(e, record)}>
                                TP/SL
                            </span>
                            }
                        </div>
                        <div className={"info-row-1-right " + (CommonUtils.getClassCheckValue(record.pnlrate))}>
                            <ValueChangeAnimation
                                tag="td"
                                value={record.pnlrate}
                                className={closePrice >= record.costPrice ? 'text-priceboard-green text-right' : 'text-priceboard-red text-right'}
                            >
                                <FormattedPriceDiff isFormatZero={true} decimalScale={2} value={record.pnlrate || 0} shortForm={false} prefix={(record.pnlrate > 0 ? '+' : '')} suffix="%" />
                            </ValueChangeAnimation>
                        </div>*/}
                    </div>
                    {/*<div className="info-row info-row-2 txt---400-14-20">
                        <div className="info-row-1-left" >
                            <span className="text-not-active"><FormattedMessage id="quick-order.portfolio.qtty" />:</span>
                            <span><FormattedQuantity value={record.total} /></span>
                            <span className="text-not-active"><FormattedMessage id="quick-order.portfolio.price-cost" />:</span>
                            <span><FormattedPrice value={Number(record.costPrice) || 0} shortForm={true} /></span>
                        </div>
                        <div className={"info-row-2-right " + (CommonUtils.getClassCheckValue(record.pnlamt))}>
                            <span>
                                {record.pnlamt > 0 && "+"}
                            </span>
                            <ValueChangeAnimation
                                tag="td"
                                value={record.pnlamt}
                                className={closePrice >= record.costPrice ? 'text-priceboard-green text-right' : 'text-priceboard-red text-right'}
                            >
                                <FormattedAmount value={record.pnlamt} />
                            </ValueChangeAnimation>
                            <FormattedPrice value={record.pnlamt} />
                        </div>
                    </div>*/}
                </div>
                <div className={"info-detail " + (keyActive === index ? "active" : "not-active")}>
                    {/* <div className="info-detail-order"> */}
                    <div className=" wrap-content">
                        <div className="content-order content-right">
                            <div className="row-content">
                                <div className="label text-not-active txt---400-12-18">
                                    <FormattedMessage id="quick-order.portfolio.qtty-availability" />
                                </div>
                                <div className="value te txt---400-12-18">
                                    <FormattedQuantity value={record.trade} />
                                </div>
                            </div>
                            <div className="row-content">
                                <div className="label text-not-active txt---400-12-18">
                                    <FormattedMessage id="quick-order.portfolio.T0" />
                                </div>
                                <div className="value te txt---400-12-18">
                                    <FormattedQuantity value={record.receivingT0} />
                                </div>
                            </div>
                            <div className="row-content">
                                <div className="label text-not-active txt---400-12-18">
                                    <FormattedMessage id="quick-order.portfolio.T1" />
                                </div>
                                <div className="value te txt---400-12-18">
                                    <FormattedQuantity value={record.receivingT1} />
                                </div>
                            </div>
                            <div className="row-content">
                                <div className="label text-not-active txt---400-12-18">
                                    <FormattedMessage id="quick-order.portfolio.T2" />
                                </div>
                                <div className="value te txt---400-12-18">
                                    <FormattedQuantity value={record.receivingT2} />
                                </div>
                            </div>
                        </div>
                        <div className="content-order content-left">
                            <div className="row-content">
                                <div className="label text-not-active txt---400-12-18">
                                    <FormattedMessage id="quick-order.portfolio.value-cost" />
                                </div>

                                <div className="value te txt---400-12-18">
                                    <FormattedPrice value={record.costPriceAmt} />
                                </div>
                            </div>
                            <div className="row-content">
                                <div className="label text-not-active txt---400-12-18">
                                    <FormattedMessage id="quick-order.portfolio.value-market" />
                                </div>

                                <div className="value te txt---400-12-18">
                                    <FormattedPrice value={record.basicPriceAmt} />
                                </div>
                            </div>
                            <div className="row-content">
                                <div className="label text-not-active txt---400-12-18">
                                    <FormattedMessage id="quick-order.portfolio.rate-loan" />
                                </div>

                                <div className="value te txt---400-12-18">
                                    {record.mrratioloan}%
                                </div>
                            </div>
                            <div className="row-content">
                                <div className="label text-not-active txt---400-12-18">
                                    <FormattedMessage id="quick-order.portfolio.proportion" />
                                </div>
                                <div className="value te txt---400-12-18">
                                    <FormattedPercentage value={record.proportion} />%
                                </div>
                            </div>
                        </div>
                    </div>
                    {isShowActionWFT && <div className="action-order">
                        <div className="container-btn">
                            <button className="btn-action btn-fix bg-buy text-custom txt---500-14-20"
                                onClick={() => onHandleBuy(record.symbol)}
                            >
                                <FormattedMessage id="quick-order.portfolio.buy" />
                            </button>
                            <button className={"btn-action btn-cancel txt---500-14-20 " + (record.issell === "N" ? "" : "bg-red text-custom")}
                                disabled={record.issell === "N"}
                                onClick={() => onHandleSell(record.symbol)}
                            >
                                <FormattedMessage id="quick-order.portfolio.sell" />
                            </button>
                        </div>
                    </div>
                    }
                    {/* </div> */}
                </div>
            </div >
        )
    }

    renderBlockAccountDerivative = () => {
        const { instrument: _instrument, index, keyActive, toogleHideShowInfo, record, onHandleBuy, onHandleSell } = this.props
        let instrument = _.cloneDeep(_instrument)
        if (CommonUtils.checkSeqApi(instrument.s, record.s)) {
            instrument["CP"] = record['price_secured'] || 0
            instrument["percent"] = 0
        }
        return (
            <div className="block-info-portfolio-body">
                <div className="info-general" onClick={() => toogleHideShowInfo(index)}>
                    <div className="info-row info-row-1 txt---400-14-20">
                        <div className="info-row-1-left">
                            <span className='txt---500-14-20'>
                                {record.symbol}
                                <span className="text-not-active txt---400-14-20">
                                    {instrument && " |"}
                                </span>
                            </span>
                            <span>
                                <FormattedPrice value={instrument["CP"]} shortForm={true} isNotThousand={true} />
                            </span>
                            <span className={""}>
                                <FormattedPriceDiff isNotThousand={false} isFormatZero={true} decimalScale={null} value={instrument["percent"] || 0} withIntrinsicColor={false} colorCalcValue={instrument["CP"]} instrument={instrument} shortForm={true} suffix="%" prefix={(instrument["percent"] > 0 ? '+' : '')} />
                            </span>
                        </div>
                        <div className={"info-row-1-right " + (CommonUtils.getClassCheckValue(record.pnlrate))}>


                            <FormattedPriceDiff isFormatZero={true} decimalScale={2} value={record.pnlrate || 0} shortForm={false} prefix={(record.pnlrate > 0 ? '+' : '')} suffix="%" />
                        </div>
                    </div>
                    <div className="info-row info-row-2 txt---400-14-20">
                        <div className="info-row-1-left" >
                            <span className="text-not-active"><FormattedMessage id="quick-order.portfolio.qtty" />:</span>
                            <span><FormattedQuantity value={record.qtty} /></span>
                            <span className="text-not-active"><FormattedMessage id="quick-order.portfolio.price-cost" />:</span>
                            <span><FormattedPrice value={Number(record.vwap) || 0} shortForm={true} isNotThousand={true} /></span>
                        </div>
                        <div className={"info-row-2-right "}>
                            <FormattedPriceDiff value={Number(record.totalplamt || 0) || 0} shortForm={true} isNotThousand={true} withIntrinsicColor={true} prefix={(Number(record.totalplamt || 0) > 0 ? '+' : '')} />
                        </div>
                    </div>
                </div>
                <div className={"info-detail " + (keyActive === index ? "active" : "not-active")}>
                    {/* <div className="info-detail-order"> */}
                    <div className=" wrap-content">
                        <div className="content-order content-right">
                            <div className="row-content">
                                <div className="label text-not-active txt---400-12-18">
                                    <FormattedMessage id="quick-order.portfolio.proportion" />
                                </div>
                                <div className="value te txt---400-12-18">
                                    <FormattedPercentage value={record.proportion} />%
                                </div>
                            </div>
                            <div className="row-content">
                                <div className="label text-not-active txt---400-12-18">
                                    <FormattedMessage id="quick-order.portfolio.im" />
                                </div>
                                <div className="value te txt---400-12-18">
                                    <FormattedQuantity value={record.vrimamt} />
                                </div>
                            </div>
                        </div>
                        <div className="content-order content-left">
                            <div className="row-content">
                                <div className="label text-not-active txt---400-12-18">
                                    <FormattedMessage id="quick-order.portfolio.value-cost" />
                                </div>

                                <div className="value te txt---400-12-18">
                                    <FormattedPrice value={Math.abs(record.capvalue * record.nvalue)} />
                                </div>
                            </div>
                            <div className="row-content">
                                <div className="label text-not-active txt---400-12-18">
                                    <FormattedMessage id="quick-order.portfolio.value-market" />
                                </div>

                                <div className="value te txt---400-12-18">
                                    <FormattedPrice value={Math.abs(record.marvalue * record.nvalue)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="action-order">
                        <div className="container-btn">
                            <button className="btn-action btn-fix bg-buy text-custom"
                                onClick={() => onHandleBuy(record.symbol)}
                            >
                                <FormattedMessage id="quick-order.portfolio.buy" />
                            </button>
                            <button className={"btn-action btn-cancel " + (record.issell === "N" ? "" : "bg-sell text-custom")}
                                disabled={record.issell === "N"}
                                onClick={() => onHandleSell(record.symbol)}
                            >
                                <FormattedMessage id="quick-order.portfolio.sell" />
                            </button>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>

        )
    }

    render() {
        const { instrument, typeAccount, record } = this.props;
        //console.log("binh_record.pnlrate", record)
        return (
            <div>
                {typeAccount === TYPE_ACCOUNT.BASE && this.renderBlockAccountNomal()}
                {typeAccount === TYPE_ACCOUNT.FDS && this.renderBlockAccountDerivative()}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { record } = props;
    const instrument = record.symbol ? state.symbol.instruments[CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol)] || {} : {};
    // //console.log("aaaa", CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol), instrument)
    const quote = record.symbol ? state.symbol.quotes[CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol)] || {} : {};

    return {
        instrument: instrument,
        quote: quote,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockInfoPortfolio);