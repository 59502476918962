import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import * as socket from "../../../../socket";
import { CommonUtils, Random, derivativeExchanges, orderActions, LanguageUtils } from "../../../../utils";
import { FormattedAmount, FormattedPrice } from '../../../../components/Formating';
import * as actions from "../../../../store/actions";
import { ORDER_TYPE_USE, TYPE_ACCOUNT } from './../../../../utils/constants';
import { Fragment } from 'react';
import TextToolTip from "components/TextToolTip";
class BlockCapacityContainer extends Component {

    callerId = Random.randomComponentId();

    subscribleToTopic = (symbolId) => {
        if (!symbolId) {
            return;
        }

        socket.registerInstrumentTopic(symbolId, this.callerId);
    };

    componentDidMount() {
        const { symbolId } = this.props;
        this.subscribleToTopic(symbolId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { symbolId } = this.props;
        if (symbolId && symbolId !== prevProps.symbolId) {
            this.subscribleToTopic(symbolId);
        }
    }

    componentWillUnmount() {
        socket.unregisterCallerId(this.callerId);
    }

    getTextpp() {
        const { availableTrade, language } = this.props;
        let textPP = LanguageUtils.getMessageByKey('quick-order.place-order.cash', language) + ": " + (availableTrade && (availableTrade.cibalance === 0 || availableTrade.cibalance) ? (availableTrade.cibalance === 0 ? '0' : CommonUtils.formatAccounting(availableTrade.cibalance)) : '-') + '\n'
            + LanguageUtils.getMessageByKey('quick-order.place-order.debt-root', language) + ": " + (availableTrade && (availableTrade.prinamt === 0 || availableTrade.prinamt) ? (availableTrade.prinamt === 0 ? '0' : CommonUtils.formatAccounting(availableTrade.prinamt)) : '-') + '\n'
            + LanguageUtils.getMessageByKey('quick-order.place-order.temp-profit', language) + ": " + (availableTrade && (availableTrade.intamt === 0 || availableTrade.intamt) ? (availableTrade.intamt === 0 ? '0' : CommonUtils.formatAccounting(availableTrade.intamt)) : '-');
        return textPP;
    }


    render() {
        const { action, symbolId, typeAccount, onHandelFillQtty, availableTrade, isBroker } = this.props;
        let rowCapacityStyle = isBroker ? {
            marginBottom: "4px"
        } : {
            marginBottom: "4px"
        }

        const textTooltipToDisplay = this.getTextpp();
        if (CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_NM_ORDER || CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER) {
            return (
                <div className="capacity-container">
                    <div className="info-capacity" style={{ paddingRight: "16px" }}>
                        <div className="row-capacity txt---400-12-18" style={{ marginBottom: "4px" }}>
                            <div className="capacity-content">
                                <div className="label">
                                    <FormattedMessage id="quick-order.place-order.qtty-max" />:
                                </div>&nbsp;
                            </div>
                            <div className="capacity-content">
                                <div className="capacity-content">
                                    <div className="value">
                                        {
                                            typeAccount === TYPE_ACCOUNT.BASE && action === orderActions.BUY &&
                                            <span id="fill-qtty" className="fill-qtty" onClick={() => onHandelFillQtty(availableTrade.maxqtty)}>{symbolId && availableTrade && availableTrade.maxqtty ? <FormattedAmount value={availableTrade.maxqtty} /> : "0"}</span>
                                        }

                                        {
                                            typeAccount === TYPE_ACCOUNT.BASE && action === orderActions.SELL &&
                                            <span id="fill-qtty" className="fill-qtty" onClick={() => onHandelFillQtty(availableTrade.trade)}>{symbolId && availableTrade && availableTrade.trade ? <FormattedAmount value={availableTrade.trade} /> : "0"} </span>
                                        }

                                        {
                                            typeAccount === TYPE_ACCOUNT.FDS && action === orderActions.BUY &&
                                            <span id="fill-qtty" className="fill-qtty" onClick={() => onHandelFillQtty(availableTrade.maxbuyqtty)}>{symbolId && availableTrade && availableTrade.maxbuyqtty ? <FormattedAmount value={availableTrade.maxbuyqtty} /> : "0"}</span>
                                        }

                                        {
                                            typeAccount === TYPE_ACCOUNT.FDS && action === orderActions.SELL &&
                                            <span id="fill-qtty" className="fill-qtty" onClick={() => onHandelFillQtty(availableTrade.maxsellqtty)}>{symbolId && availableTrade && availableTrade.maxsellqtty ? <FormattedAmount value={availableTrade.maxsellqtty} /> : "0"} </span>
                                        }
                                        {!action && "0"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="info-capacity">
                        <div className="row-capacity txt---400-12-18" style={rowCapacityStyle}>
                            <div className="capacity-content">
                                <div className="label">
                                    <FormattedMessage id="quick-order.place-order.purchasing-ability" />:
                                </div>
                            </div>
                            <div className="capacity-content">
                                <div className="value">
                                    <span>
                                        {typeAccount === TYPE_ACCOUNT.FDS && <span>{availableTrade ? <FormattedAmount value={symbolId ? (action === orderActions.BUY ? availableTrade.ppse_long : availableTrade.ppse_short) : availableTrade.pp_long} /> : '0'}</span>}
                                        {typeAccount === TYPE_ACCOUNT.BASE && <span>{availableTrade ? <FormattedAmount value={availableTrade.pp || 0} /> : 0}</span>}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
        return (
            <div className="capacity-container">
                <div className="info-capacity" style={{ paddingRight: "16px" }}>
                    <div className="row-capacity txt---400-12-18" style={{ marginBottom: "4px" }}>
                        <div className="capacity-content capacity-price">
                            <div className="label">
                                <FormattedMessage id="quick-order.place-order.margin-rate" />:
                            </div>
                        </div>
                        <div className="capacity-content">
                            <div className="value">
                                {symbolId && availableTrade && parseFloat(availableTrade['marginrate']) ? <FormattedPrice value={availableTrade['marginrate'] || 0} /> : "--"}
                            </div>
                        </div>
                    </div>

                    <div className="row-capacity txt---400-12-18" style={{ marginBottom: "4px" }}>
                        <div className="capacity-content">
                            <div className="label">
                                <FormattedMessage id="quick-order.place-order.qtty-max" />:
                            </div>&nbsp;
                        </div>
                        <div className="capacity-content">
                            <div className="capacity-content">
                                <div className="value">
                                    {
                                        typeAccount === TYPE_ACCOUNT.BASE && action === orderActions.BUY &&
                                        <span id="fill-qtty" className="fill-qtty" onClick={() => onHandelFillQtty(availableTrade.maxqtty)}>{symbolId && availableTrade && availableTrade.maxqtty ? <FormattedAmount value={availableTrade.maxqtty} /> : "0"}</span>
                                    }

                                    {
                                        typeAccount === TYPE_ACCOUNT.BASE && action === orderActions.SELL &&
                                        <span id="fill-qtty" className="fill-qtty" onClick={() => onHandelFillQtty(availableTrade.trade)}>{symbolId && availableTrade && availableTrade.trade ? <FormattedAmount value={availableTrade.trade} /> : "0"} </span>
                                    }

                                    {
                                        typeAccount === TYPE_ACCOUNT.FDS && action === orderActions.BUY &&
                                        <span id="fill-qtty" className="fill-qtty" onClick={() => onHandelFillQtty(availableTrade.maxbuyqtty)}>{symbolId && availableTrade && availableTrade.maxbuyqtty ? <FormattedAmount value={availableTrade.maxbuyqtty} /> : "0"}</span>
                                    }

                                    {
                                        typeAccount === TYPE_ACCOUNT.FDS && action === orderActions.SELL &&
                                        <span id="fill-qtty" className="fill-qtty" onClick={() => onHandelFillQtty(availableTrade.maxsellqtty)}>{symbolId && availableTrade && availableTrade.maxsellqtty ? <FormattedAmount value={availableTrade.maxsellqtty} /> : "0"} </span>
                                    }
                                    {!action && "0"}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row-capacity txt---400-12-18">
                        {isBroker ?
                            <Fragment>
                                <div className="capacity-content">
                                    <div className="label">
                                        <FormattedMessage id="quick-order.place-order.rtt-rate" />:
                                    </div>
                                </div>
                                <div className="capacity-content">
                                    <div className="capacity-content">
                                        <div className="value">
                                            {availableTrade ? <FormattedAmount value={availableTrade.rtt || 0} decimalScale={2} /> : 0}
                                        </div>
                                    </div>
                                </div>
                            </Fragment> : <span></span>
                        }
                    </div>
                </div>

                <div className="info-capacity">
                    <div className="row-capacity txt---400-12-18" style={rowCapacityStyle}>
                        <div className="capacity-content">
                            <div className="label">
                                <FormattedMessage id="quick-order.place-order.purchasing-ability" />:
                            </div>
                        </div>
                        <div className="capacity-content">
                            <TextToolTip
                                tooltipText={textTooltipToDisplay}
                                targetID={'value-purchase'}
                                placement='bottom'
                            ></TextToolTip>
                            <div className="value" id='value-purchase'>
                                <span>
                                    {typeAccount === TYPE_ACCOUNT.FDS && <span>{availableTrade ? <FormattedAmount value={symbolId ? (action === orderActions.BUY ? availableTrade.ppse_long : availableTrade.ppse_short) : availableTrade.pp_long} /> : '0'}</span>}
                                    {typeAccount === TYPE_ACCOUNT.BASE && <span>{availableTrade ? <FormattedAmount value={availableTrade.pp || 0} /> : 0}</span>}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row-capacity txt---400-12-18">
                        {isBroker ?
                            <Fragment>
                                <div className="capacity-content">
                                    <div className="label">
                                        <FormattedMessage id="quick-order.place-order.guarantee" />:
                                    </div>
                                </div>
                                <div className="capacity-content">
                                    <div className="capacity-content">
                                        <div className="value">
                                            {availableTrade ? <FormattedAmount value={availableTrade.advanceline || 0} /> : 0}
                                        </div>
                                    </div>
                                </div>
                            </Fragment> :
                            <Fragment>
                                <div className="capacity-content">
                                    <div className="label">
                                        <FormattedMessage id="quick-order.place-order.rtt-rate" />:
                                    </div>
                                </div>
                                <div className="capacity-content">
                                    <div className="capacity-content">
                                        <div className="value">
                                            {availableTrade ? <FormattedAmount value={availableTrade.rtt || 0} decimalScale={2} /> : 0}
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        language: state.app.language,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockCapacityContainer);