import React, { Component } from "react";
import { push } from "connected-react-router";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

// const ORDER_TYPES_KEY = { LIMIT: 'LIMIT', MARKET: 'MARKET', CONDITION: 'CONDITION' };
import { accountService, tradeApiService, userService } from "../../../../../services";
import * as actions from "../../../../../store/actions";
import {
    ACTION_USER_ORDER, listPercentOrder,
    CommonUtils, CommonWidgetUtils,
    derivativeExchanges, EXCHANGE, initialQuickOrderInputState, LoadStatus, ORDER_TYPE_USE, orderActions, Random, Role, ToastUtil, TYPE_DAY, KeyCodeUtils, CommonOrderUtils
} from "../../../../../utils";

import './ConditionOrderTrailing.scss';
import moment from "moment/moment";
import DatePicker from '../../../../../components/Input/DatePicker';
import config from '../../../../../combineConfig';
import { TYPE_ACCOUNT } from './../../../../../utils/constants';
import QuantityInputOrder from './../../../../../components/Input/QuantityInputOrder';
import NormalInput from './../../../../../components/Input/NormalInput';
import { emitter } from "utils/EventEmitter";

const DF_DATA_SUBMIT = _.cloneDeep(initialQuickOrderInputState["orderConditionTrailing"])

class ConditionOrderTrailing extends Component {

    initialState = {
        loadStatus: LoadStatus.NONE,
        //trang thai modal canh bao
        showRateWanningPopup: false,
        rateWanningCallback: null,

        accountInfo: {},

        msgErrQtty: "",
        msgErrPrice: "",
        listOrderPriceSelect: [],
        sysvar: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }

        // const currentDate = new Date(this.props.currentDate * 1000);
        // currentDate.setHours(0, 0, 0, 0);
        // this.state.toDate = currentDate;
        // this.state.fromDate = currentDate;

        this.priceOrderRef = React.createRef();
        this.quantityRef = React.createRef();
        this.radioTypeDayRef = React.createRef()
        this.datePickerRef = React.createRef()
        this.btnPlaceOrderRef = React.createRef()
    };

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { currentSymbol, config, updatePlaceQuickOrder, orderTypeUse } = this.props;
        this.getSysvar();
        if (currentSymbol) {
        };
    }

    getSysvar = () => {
        const { currentSymbol, quote, accountQuickOrderInfo, currentAccount, userInfo, setIsOpenModalHaveData } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }

        if (typeAccount === TYPE_ACCOUNT.FDS) {
            userService.getSysvarByTypeAccount(true)
                .then(responses => {
                    let [orderDate, orderPriceStep] = responses
                    if (orderDate) {
                        this._setState({ sysvar: orderDate[0] }
                            , () => { this.setDateDisplay() })
                    }
                }).catch(error => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                })
        }
        else {
            userService.getSysvarByTypeAccount(false)
                .then(responses => {
                    let [orderDate, orderPriceStep] = responses
                    if (orderDate) {
                        this._setState({ sysvar: orderDate[0] }
                            , () => { this.setDateDisplay() })
                    }
                }).catch(error => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                })
        }
    }


    setDateDisplay = () => {
        const { orderConditionTrailing, currentSymbol, accountQuickOrderInfo } = this.props
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        let currentDate = this.props.currentDate * 1000;
        const { sysvar } = this.state;
        const { fromDate, toDate, validUntilOrder } = orderConditionTrailing
        let _toDate = currentDate
        if (validUntilOrder === TYPE_DAY.MUCH_DAY) {
            // Nếu có mã rồi thì check xem có ngày đáo hạn không nếu có có thì lấy min((ngày hệ thống + số ngày tối đa), ngày đáo hạn)
            let _lastTradingDate = null
            let numberToMax = (sysvar && sysvar.varvalue * 24 * 60 * 60 * 1000) || 0 //number config flex
            let systemDate = currentDate + numberToMax

            if (typeAccount === TYPE_ACCOUNT.FDS) {
                let instruments = CommonUtils.getInstruments();
                const instrument = currentSymbol ? instruments[currentSymbol.symbol] : {};
                _lastTradingDate = (instrument && instrument["LTD"]) || ""

                if (_lastTradingDate) {
                    _toDate = Math.min(systemDate, CommonUtils.convertDateToTime(_lastTradingDate))
                } else {
                    _toDate = systemDate
                }
            } else {
                _toDate = systemDate
            }
        }

        this.props.updatePlaceQuickOrderConditon({
            fromDate: currentDate,
            toDate: _toDate,
            toDateConst: _toDate
        }, this.props.orderTypeUse)
    }

    onDeltaChanged = (value, valid) => {
        const { updatePlaceQuickOrderConditon } = this.props
        //console.log("binh_onChange_onDeltaChanged", value)
        updatePlaceQuickOrderConditon({
            delta: value,
            deltaValid: true
        }, this.props.orderTypeUse)
    };


    onPriceStepChanged = (value, valid) => {
        const { updatePlaceQuickOrderConditon } = this.props
        updatePlaceQuickOrderConditon({
            priceStep: value,
            priceStepValid: true
        }, this.props.orderTypeUse)
    };


    onQuantityChanged = (quantity, valid) => {
        const { updatePlaceQuickOrderConditon } = this.props
        updatePlaceQuickOrderConditon({
            quantity: quantity,
            quantityValid: valid
        }, this.props.orderTypeUse)
    };

    componentDidUpdate(prevProps, prevState) {
        const { orderConditionTrailing, accountQuickOrderInfo
        } = this.props;

        const { typeAccount } = { ...accountQuickOrderInfo }

        const { action, toDate, validUntilOrder } = { ...orderConditionTrailing }
        const {
            orderConditionTrailing: prevOrderConditionTrailing,
            accountQuickOrderInfo: prevAccountQuickOrderInfo
        } = prevProps;
        const { validUntilOrder: prevValidUntilOrder } = { ...prevOrderConditionTrailing }

        const { typeAccount: prevTypeAccount } = { ...prevAccountQuickOrderInfo }
        if (!(_.isEqual(orderConditionTrailing, prevOrderConditionTrailing))) {
            if ((!toDate) || (validUntilOrder !== prevValidUntilOrder)) {
                this.setDateDisplay()
            }
        }

        if (typeAccount !== prevTypeAccount) {
            this.getSysvar()
        }
    }

    getPlaceOrderData = () => {
        const { securitiesInfo, accountInfo } = this.state
        const { quote, accountQuickOrderInfo, currentSymbol, placeQuickOrderCurrent, actionMethodConditon } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        const { delta, priceStep, quantity, orderType, subOrderType, toDate, fromDate, validUntilOrder } = { ...this.props.orderConditionTrailing };

        const { action } = placeQuickOrderCurrent
        let ceilPrice = null;
        let floorPrice = null;
        if (quote != null) {
            ceilPrice = quote['ceiling'];
            floorPrice = quote['floor'];
        }

        //console.log("getPlaceOrderData", this.props.orderConditionTrailing)
        let _fromDate = moment(new Date(fromDate)).format(config.app.SERVER_DATE_FORMAT)
        let _toDate = moment(new Date(toDate)).format(config.app.SERVER_DATE_FORMAT)
        let _delta = Number(delta)
        let _pricestep = Number(priceStep)
        if (typeAccount === TYPE_ACCOUNT.BASE) { // Cơ sở * 1000
            _delta = _delta * 1000
            _pricestep = _pricestep * 1000
        }
        const data = {
            "side": action === orderActions.BUY ? 'buy' : 'sell',
            "instrument": currentSymbol && currentSymbol.id,
            "qtty": Number(quantity),
            "fromdate": _fromDate,
            "todate": validUntilOrder === TYPE_DAY.DURING_DAY ? _fromDate : _toDate,
            "timetype": validUntilOrder,
            "activetype": actionMethodConditon,
            "type": "limit",
            "deltatype": "D",
            "deltavalue": _delta,
            "pricestep": _pricestep,
        };

        return data;
    }


    validateValue = () => {
        const { orderConditionTrailing, quote, accountQuickOrderInfo, placeQuickOrderCurrent, currentSymbol } = this.props
        const { delta, priceStep, editingOrder, quantity, quantityValid, fromDate, toDate, toDateConst, validUntilOrder } = orderConditionTrailing
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        const { action } = placeQuickOrderCurrent
        let ceilPrice = 0;
        let floorPrice = 0;
        if (quote != null) {
            if (typeAccount === TYPE_ACCOUNT.FDS) {
                ceilPrice = CommonUtils.divide(quote.ceiling, 1)
                floorPrice = CommonUtils.divide(quote.floor, 1)
            } else {
                ceilPrice = CommonUtils.divide(quote.ceiling, 1000)
                floorPrice = CommonUtils.divide(quote.floor, 1000)
            }
        }
        //console.log("validateValue", delta, ceilPrice - floorPrice)

        let _fromDate = new Date(fromDate)
        let _toDate = new Date(toDate);
        let _toDateConst = new Date(toDateConst);
        _fromDate = _fromDate.setHours(0, 0, 0, 0);
        _fromDate = _fromDate + 86400000 // + thêm 1 ngày để check điều kiện > hơn ngày hiện tại
        _toDate = _toDate.setHours(0, 0, 0, 0);
        _toDateConst = _toDateConst.setHours(0, 0, 0, 0);

        let id_detal = document.getElementById("id-detal")
        if (orderConditionTrailing) {

            if (delta) {
                if (id_detal) {
                    let _value = Number(id_detal.innerHTML)
                    if (delta > _value) {
                        ToastUtil.error('FE003008');
                        return false
                    }
                }

            } else {
                ToastUtil.error('FE003007');
                return false
            }

            if (priceStep) {
                if (typeAccount === TYPE_ACCOUNT.FDS) {
                } else {
                    let flag = CommonOrderUtils.checkPriceStepTrailingStop(currentSymbol["id"], action, delta, priceStep)
                    if (!flag) {
                        ToastUtil.error('FE004005');
                        return flag
                    }
                }
            } else {
                ToastUtil.error('FE003009');
                return false
            }

            if (quantity) {
                if (quantity == "0") {
                    ToastUtil.error('FE003006');
                    return false
                }

                if (!quantityValid) {
                    ToastUtil.error('FE003017');
                    return false
                }
            } else {
                ToastUtil.error('FE003006');
                return false
            }

            if (validUntilOrder === TYPE_DAY.MUCH_DAY) {
                if (_fromDate && _toDate && _toDateConst) {
                    if (_toDate > _toDateConst) {
                        ToastUtil.error('FE003019');
                        return false
                    }
                    if (_fromDate > _toDate) {
                        ToastUtil.error('FE003020');
                        return false
                    }
                }
            }
        }

        return true
    }


    precheck = (doOrder) => {
        let data = this.getPlaceOrderData();
        const { quote, accountQuickOrderInfo, intl, userInfo } = this.props;
        const { action, quantity } = { ...this.props.orderConditionTrailing }
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }

        //validate
        if (!this.validateValue()) {
            return
        }

        let isBroker = this.isBroker();
        let requestId = (isBroker ? 'B' : 'C') + Random.randomOrderRequestId(userInfo.username)
        const precheckAndCb = () => {
            tradeApiService.initTwoFactorAuth({ accountId: currentAccountId })
                .then((data) => {
                    if (data) {
                        const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                        this.props.updateInfoCheckAuth({
                            transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                        })

                        this.props.setIsOpenModalHaveData("QuickOrder", {
                            isOpenModalTradeQuickOrder: true,
                            onTradeQuickOrderAuthenCallback: (authInfo) => {
                                this.callApiDoPlaceOrder(authInfo, requestId);
                            }
                        })
                    }
                })
                .catch(error => {
                    this._setState({ loadStatus: LoadStatus.FAIL })
                    ToastUtil.errorApi(error, 'CM2');
                });
        };
        accountService.precheckConditionTSO(currentAccountId, data)
            .then((data) => {
                precheckAndCb();
            }).catch(error => {
                ToastUtil.errorApi(error, 'trade.place-order.place-order-fail');
            });
    }


    placeOrder = () => {
        if (this.props.isMobile) window.scrollTo(0, 0);
        const { securitiesInfo, accountInfo } = this.state
        const { quote, accountQuickOrderInfo, showReconfirmOrderConfig, orderTypeUse } = this.props;
        const { action, quantity, validUntilOrder } = { ...this.props.orderConditionTrailing }
        const { curCustomer, accounts, typeAccount, currentAccountId } = { ...accountQuickOrderInfo }

        if (typeAccount === TYPE_ACCOUNT.BASE && validUntilOrder === TYPE_DAY.MUCH_DAY) {
            this.props.setIsOpenModalHaveData("QuickOrder", {
                dataShow: {
                    noteMessageId: ["CM000003"]
                },
            })
        }

        this.props.updatePlaceQuickOrder({
            actionUserOrder: ACTION_USER_ORDER.PLACE_ORDER,
        }, orderTypeUse)

        if (currentAccountId == null) {
            return;
        }

        this.precheck();
    }

    doPlaceOrder = (isEdittingOrder, authInfo) => {
        if (isEdittingOrder) {
            this.validateOrderAndProcessEditOrder(authInfo);
        } else {
            this.validateOrderAndProcess(authInfo);
        }
    };


    validateOrderAndProcess = (authInfo) => {
        // let data = this.getPlaceOrderData();
        let isBroker = this.isBroker();
        // const requestId = (isBroker ? 'B' : 'C') + Random.randomOrderRequestId(userInfo.username);
        // data = { ...data, ...authInfo };
        if (isBroker) {
            this.handleCheckAccountInBroker(this.callApiDoPlaceOrder, authInfo);
        }
        else {
            this.callApiDoPlaceOrder(authInfo);
        }

    };

    validateOrderAndProcessEditOrder = (authInfo) => {
        let isBroker = this.isBroker();
        if (isBroker) {
            this.handleCheckAccountInBroker(this.callApiUpdatePlaceOrder, authInfo);
        }
        else {
            this.callApiUpdatePlaceOrder(authInfo);
        }

    };

    onFinishPlaceOrder = () => {
        const { clearDataPlaceQuickOrder, setIsOpenModalHaveData } = this.props
        CommonUtils.middlewareOrder()
        setIsOpenModalHaveData("QuickOrder", {
            isOpenModalTradeQuickOrder: false,
            loadStatusQuickOrderModalTrade: LoadStatus.NONE,
            dataShow: {},
        })
    };

    focusRadioTypeDay = () => {
        this.radioTypeDayRef.current && this.radioTypeDayRef.current.focus();
    };

    focusDatePicker = () => {
        this.datePickerRef.current && this.datePickerRef.current.flatpickr.input.focus();
    };

    focusBtnPlaceOrder = () => {
        this.btnPlaceOrderRef.current && this.btnPlaceOrderRef.current.focus();
    };

    onFocusQttyWithPercent = (value, isEdittingOrder, id) => {
        const self = this
        document.getElementById(id).addEventListener("keydown", event => {
            if (event && event.code === "Enter") {
                this.setQttyWithPercent(value, isEdittingOrder)
                self.focusRadioTypeDay();
            }
        });
    }

    onFocusTypeDay = (value, id) => {
        const self = this
        document.getElementById(id).addEventListener("keydown", event => {
            if (event && event.code === "Enter") {
                if (value === TYPE_DAY.MUCH_DAY) {
                    this.onChangeValidUntilOrder(value)
                    self.focusDatePicker();
                } else if (value === TYPE_DAY.DURING_DAY) {
                    this.onChangeValidUntilOrder(value)
                    self.focusBtnPlaceOrder();
                }
            }
        });
    }


    callApiDoPlaceOrder = (authInfo) => {
        const { currentSymbol, quote, accountQuickOrderInfo, currentAccount, userInfo, setIsOpenModalHaveData } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        //console.log("callApiDoPlaceOrder ===>", authInfo)
        let isBroker = this.isBroker();
        let data = this.getPlaceOrderData();
        const requestId = (isBroker ? 'B' : 'C') + Random.randomOrderRequestId(userInfo.username);
        let _data = { ...data, ...authInfo };
        // submit
        // LoadStatusQuicOrder
        setIsOpenModalHaveData("QuickOrder", {
            loadStatusQuickOrderModalTrade: LoadStatus.LOADING,
        })
        tradeApiService.tradePlaceOrderConditionTSO(currentAccountId, requestId, _data)
            .then(() => {
                setIsOpenModalHaveData("QuickOrder", {
                    loadStatusQuickOrderModalTrade: LoadStatus.LOADED,
                })
                ToastUtil.success('trade.place-order.place-order-success');
                this.onFinishPlaceOrder();
            })
            .catch((error) => {
                setIsOpenModalHaveData("QuickOrder", {
                    loadStatusQuickOrderModalTrade: LoadStatus.FAIL,
                })
                // if (!error.errorCode.startsWith("FO")) {
                //     this.props.setTradeAuthSuccess();
                // }
                ToastUtil.errorApi(error, 'trade.place-order.place-order-fail');
                CommonUtils.clearTradeAuthInfo();
            });
    }

    setQttyWithPercent = (value, isEdittingOrder) => {
        const { accountQuickOrderInfo, placeQuickOrderCurrent, currentSymbol, availableTrade } = this.props
        const { typeAccount } = { ...accountQuickOrderInfo }
        const { action } = { ...placeQuickOrderCurrent }
        if (isEdittingOrder) {
            return
        }

        if (currentSymbol) {
            let _value = null
            //console.log("setQttyWithPercent1", _value)

            if (action === orderActions.BUY) {
                if (typeAccount === TYPE_ACCOUNT.FDS) {
                    _value = parseFloat(availableTrade.maxbuyqtty) * value
                } else {
                    _value = parseFloat(availableTrade.maxqtty) * value
                }
            } else if (action === orderActions.SELL) {
                if (typeAccount === TYPE_ACCOUNT.FDS) {
                    _value = parseFloat(availableTrade.maxsellqtty) * value
                } else {
                    _value = parseFloat(availableTrade.trade) * value
                }
            }
            //console.log("setQttyWithPercent2", _value)
            if (_value) {
                let parsedPrice = parseInt(_value);
                if (typeAccount === TYPE_ACCOUNT.FDS) {

                }
                else {
                    if (parsedPrice <= 100) {

                    } else {
                        parsedPrice = Math.floor(parsedPrice)
                        parsedPrice = (parsedPrice - (parsedPrice % 100));
                    }
                }
                //console.log("setQttyWithPercent3", parsedPrice)
                this.props.updatePlaceQuickOrderConditon({
                    quantity: parsedPrice,
                }, this.props.orderTypeUse)
            }
        }
    }


    onChangeValidUntilOrder = (value) => {
        const { updatePlaceQuickOrderConditon } = this.props
        let _value = value
        updatePlaceQuickOrderConditon({
            validUntilOrder: _value,
        }, this.props.orderTypeUse)
    }

    onToDateChanged = (dates) => {
        // let _toDate = moment(dates[0]).format(config.app.SERVER_DATE_FORMAT)
        let _toDate = dates[0]
        //console.log("onToDateChanged", dates[0])
        this.props.updatePlaceQuickOrderConditon({
            toDate: _toDate,
        }, this.props.orderTypeUse)
    };

    onHandleKeyDownPriceOrder = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            this.placeOrder()
        }

        if (keyCode === KeyCodeUtils.TAB) {
            if (this.isBroker()) {
                emitter.emit("onTabSymbolTrade_Broker", true)
            } else {
                emitter.emit("onTabSymbolTrade_Customer", true)
            }
            event.preventDefault();
        }
    }

    render() {
        const { placeQuickOrderCurrent, quote, accountQuickOrderInfo, currentSymbol } = this.props

        const { delta,
            deltaValid,
            priceStep,
            priceStepValid,
            quantity,
            quantityValid,
            validUntilOrder,
            fromDate,
            toDate
        } = { ...this.props.orderConditionTrailing }
        const { action } = { ...placeQuickOrderCurrent }
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }

        //console.log("render", this.props.orderConditionTrailing)
        let isEdittingOrder = false
        let isDisablePlaceOrder = false

        let instrumentQtty = {
            EX: quote && quote.exchange,
            ST: quote && quote.StockType
        }

        let ceilPrice = null;
        let floorPrice = null;
        let scopeDelta = 0

        if (quote != null) {
            if (typeAccount === TYPE_ACCOUNT.FDS) {
                ceilPrice = CommonUtils.divide(quote.ceiling, 1)
                floorPrice = CommonUtils.divide(quote.floor, 1)
            } else {
                ceilPrice = CommonUtils.divide(quote.ceiling, 1000)
                floorPrice = CommonUtils.divide(quote.floor, 1000)
            }
            scopeDelta = ceilPrice - floorPrice
        }
        let stepInputQuantity = currentSymbol ? (currentSymbol.exchange == EXCHANGE.HOSE ? 100 : (quote.exchange === derivativeExchanges[0] ? 1 : 100)) : 1
        return (
            <div className="condition-order-trailing-stop">
                <div className="type-view price-order">
                    <div className="content content-left text-left txt---500-14-20">
                        <FormattedMessage id="trade.place-order.delta" />
                    </div>
                    <div className="content content-right item-center txt---400-14-20">
                        <div className="row-input">
                            <NormalInput
                                name="quantity"
                                step={0.1}
                                min={null}
                                max={999999999999}
                                value={delta}
                                valid={deltaValid}
                                onChange={this.onDeltaChanged}
                                // onKeyDown={this.onInputKeyDown}
                                allowZero={false}
                                allowDecimal={true}
                                allowNegative={false}
                                ref={this.quantityRef}
                                // onFocus={this.onFocusQtty}
                                onBlur={this.onBlurQtty}
                                readOnly={false}
                                decimalScale={2}
                            />
                        </div>
                        <div className="row-note text-left">
                            <span>
                                {"≤ "}
                            </span>
                            <span id="id-detal">
                                {CommonUtils.roundToTwo(scopeDelta, 1)}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="type-view price-order">
                    <div className="content content-left text-left txt---500-14-20">
                        <FormattedMessage id="trade.place-order.step-price" />
                    </div>
                    <div className="content content-right item-center txt---400-14-20">
                        <div className="row-input">
                            <NormalInput
                                name="quantity"
                                step={0.1}
                                min={null}
                                max={999999999999}
                                instrument={instrumentQtty}
                                value={priceStep}
                                valid={priceStepValid}
                                onChange={this.onPriceStepChanged}
                                // onKeyDown={this.onInputKeyDown}
                                allowZero={false}
                                allowDecimal={true}
                                allowNegative={false}
                                orderType={''}
                                ref={this.quantityRef}
                                // onFocus={this.onFocusQtty}
                                onBlur={this.onBlurQtty}
                                readOnly={false}
                                decimalScale={2}
                            />
                        </div>
                        <div className="row-note text-left">

                        </div>
                    </div>
                </div>
                <div className="type-view price-order">
                    <div className="content content-left text-left txt---500-14-20">
                        <FormattedMessage id="trade.place-order.mass" />
                    </div>
                    <div className="content content-right txt---400-14-20">
                        <div className="row-input">
                            <QuantityInputOrder
                                name="quantity"
                                step={stepInputQuantity}
                                min={null}
                                max={999999999999}
                                instrument={instrumentQtty}
                                value={quantity}
                                valid={quantityValid}
                                onChange={this.onQuantityChanged}
                                // onKeyDown={this.onInputKeyDown}
                                allowZero={false}
                                allowDecimal={false}
                                allowNegative={false}
                                orderType={''}
                                ref={this.quantityRef}
                                // onFocus={this.onFocusQtty}
                                onBlur={this.onBlurQtty}
                                readOnly={false}
                                isNotOddLot={true}
                            />
                        </div>
                        <div className="row-note text-left">
                        </div>
                    </div>
                </div>

                <div className="type-view present-order">
                    <div className="content content-left text-left">

                    </div>
                    <div className="content content-right item-center txt---400-14-20">
                        {listPercentOrder.map((item, index) => {
                            return <div
                                tabIndex="0"
                                className={"focus-item select-present txt---400-14-20 "}
                                onClick={() => { this.setQttyWithPercent(item.value, isEdittingOrder) }}
                                onFocus={() => this.onFocusQttyWithPercent(item.value, isEdittingOrder, "qtty-percent" + index)}
                                id={"qtty-percent" + index}
                            >{item.title}</div>
                        })}
                    </div>
                </div>

                <div className="type-view valid-until-order">
                    <div className="content content-left text-left txt---500-14-20">
                        <FormattedMessage id="quick-order.place-order.effect" />
                    </div>
                    <div className="content content-right item-center txt---400-14-20">
                        <div className={"contaier-input " + (action === orderActions.BUY ? 'contaier-input-buy' : 'contaier-input-sell')}>
                            <div className="radio-left">
                                <label className='container-radio' htmlFor="during-day">
                                    <span className="txt---500-14-20"> <FormattedMessage id="quick-order.place-order.in-day" /></span>
                                    <input
                                        ref={this.radioTypeDayRef}
                                        id="during-day"
                                        type="radio"
                                        name="during-day"
                                        value={TYPE_DAY.DURING_DAY}
                                        checked={validUntilOrder === TYPE_DAY.DURING_DAY ? true : false}
                                        onFocus={() => this.onFocusTypeDay(TYPE_DAY.DURING_DAY, "during-day")}
                                        onChange={() => this.onChangeValidUntilOrder(TYPE_DAY.DURING_DAY)} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="radio-right">
                                <label className='container-radio' htmlFor="much-day">
                                    <span className="txt---500-14-20"><FormattedMessage id="quick-order.place-order.much-day" /></span>
                                    <input
                                        id="much-day"
                                        type="radio"
                                        name="much-day"
                                        value={TYPE_DAY.MUCH_DAY}
                                        checked={validUntilOrder === TYPE_DAY.MUCH_DAY ? true : false}
                                        onFocus={() => this.onFocusTypeDay(TYPE_DAY.MUCH_DAY, "much-day")}
                                        onChange={() => this.onChangeValidUntilOrder(TYPE_DAY.MUCH_DAY)} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>

                    </div>
                </div>

                {validUntilOrder === TYPE_DAY.MUCH_DAY &&
                    <div className="type-view select-date">
                        <div className="content content-left text-left ">
                        </div>
                        <div className="content content-right item-center txt---400-14-20">
                            <div className='txt---500-14-20'><FormattedMessage id="common.to-date" /></div>
                            <div className="custom-form-group-n">
                                <DatePicker
                                    className="focus-item custom-form-control text-left txt---400-14-20"
                                    value={toDate}
                                    onChange={this.onToDateChanged}
                                    ref={this.datePickerRef}
                                />
                            </div>
                        </div>
                    </div>
                }


                <div className="type-view ">
                    <div className={"action-container "}>
                        <button ref={this.btnPlaceOrderRef} className={"focus-item txt---700-14-20 btn-action item-center " + (CommonUtils.getClassbyAction(action))} onClick={this.placeOrder} onKeyDown={this.onHandleKeyDownPriceOrder} disabled={isDisablePlaceOrder}>
                            {
                                action === orderActions.BUY && <FormattedMessage id="quick-order.place-order.buy" />
                            }
                            {
                                action === orderActions.SELL && <FormattedMessage id="quick-order.place-order.sell" />
                            }
                            {
                                !action && <FormattedMessage id="common.transaction" />
                            }
                        </button>
                    </div>
                </div>

                {isEdittingOrder && <div className="type-view ">
                    <div className={"action-container "}>
                        <button ref={this.btnPlaceOrderRef} className="focus-item txt---700-14-20 btn-cancel-input btn-action" onClick={this.onReset}>
                            <FormattedMessage id="quick-order.place-order.cancel" />
                        </button>
                    </div>
                </div>}
            </div>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    const { orderTypeUse } = ownProps
    let _placeQuickOrderCurrent = null
    let currentSymbol = null
    let quote = null
    let _accountQuickOrderInfo = null
    let _currentLayoutPageActive = null

    if (orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER) {
        _currentLayoutPageActive = "C&B"
        let _placeQuickOrder = state.orderNew.placeQuickOrder

        currentSymbol = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive] && _placeQuickOrder[_currentLayoutPageActive]["symbolCurrent"]
        quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]]
        _placeQuickOrderCurrent = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive]
        _accountQuickOrderInfo = _placeQuickOrderCurrent && _placeQuickOrderCurrent["accountQuickOrderInfo"]

    } else if (orderTypeUse === ORDER_TYPE_USE.NORMAL_ORDER) {
        _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        let _placeNormalOrder = state.orderNew.placeNormalOrder
        currentSymbol = _currentLayoutPageActive && _placeNormalOrder[_currentLayoutPageActive] && _placeNormalOrder[_currentLayoutPageActive]["symbolCurrent"]
        quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]]

        let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
        _accountQuickOrderInfo = _layoutPage && _layoutPage["accountInfo"]
        _placeQuickOrderCurrent = _currentLayoutPageActive && _placeNormalOrder[_currentLayoutPageActive]
    }
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;
    const instrument = currentSymbol ? state.symbol.instruments[currentSymbol.id] || {} : {};

    const orderConditionTrailing = _placeQuickOrderCurrent["orderConditionTrailing"]
    return {
        config: state.user.userInfo.config,
        permissionInfo: permissionInfo,
        userInfo: userInfo,
        currentSymbol: currentSymbol,
        instrument: instrument,
        placeQuickOrderCurrent: _placeQuickOrderCurrent,
        orderConditionTrailing: orderConditionTrailing,
        accountQuickOrderInfo: _accountQuickOrderInfo,

        quote,
        language: state.app.language,
        role: state.user.userInfo.role,
        showReconfirmOrderConfig: state.app.showReconfirmOrderConfig,

        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        currentDate: state.app.currentDate,
        defaultTheme: state.user.userInfo.defaultTheme,
        actionMethodConditon: state.user.userInfo.actionMethodConditon
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        updatePlaceQuickOrderConditon: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrderConditon(data, typeOrder)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        fetchSymbolOrderbook: (symbolId) => dispatch(actions.fetchSymbolOrderbook(symbolId)),

        saveTradeAuthenticated: () => dispatch(actions.saveTradeAuthenticated()),
        setTradeAuthSuccess: () => dispatch(actions.setTradeAuthSuccess()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConditionOrderTrailing);
