import React, { Component } from "react";
import { push } from "connected-react-router";
import _ from 'lodash';
import { connect } from "react-redux";
import { emitter } from "utils/EventEmitter";

// import SelectSymbolBySuggest from '../../../../components/SelectSymbolBySuggest';
import * as Config from '../../../../constants/config';
import * as socket from '../../../../socket';
import * as actions from "../../../../store/actions";
import { CommonUtils, LoadStatus, Random, Role, ToastUtil, ORDER_TYPE_USE, KeyCodeUtils, FOCUS_KEYS } from '../../../../utils';

import inquiryService from './../../../../services/inquiryService';
import BlockInfoSymbol from './../BlockInfoSymbol/BlockInfoSymbol';
import SymbolSuggestPlaceOrderQuick from './../SymbolSuggestPlaceOrderQuick/SymbolSuggestPlaceOrderQuick';

import './SearchSymbolQuickOrder.scss';
import { EXCHANGE, TYPE_ACCOUNT } from './../../../../utils/constants';
import LanguageUtils from './../../../../utils/LanguageUtils';
class SearchSymbolQuickOrder extends Component {

    callerId = Random.randomComponentId();

    initialState = {
        symbolListOption: [],
        isValidSymbol: false,
        symbolToDisplay: "",
        currentSymbol: "",
        records: [],
        listSymbolShow: {},
        listSymbolTPRL: {},
        listSymbolShowBuy: {},
        listSymbolShowBuyDerivative: {},
        isShowSearch: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
        this.blockInfoSymbolRef = React.createRef();
    };

    onSymbolChange = async (symbol, valid, symbolObj) => {
        const { updatePlaceQuickOrder, orderTypeUse, updatedSymbolLayoutPage } = this.props
        let _symbolToDisplay = this.getSymbolToDisplay(symbolObj);
        this._setState({ currentSymbol: symbol, symbolToDisplay: _symbolToDisplay, isValidSymbol: valid }, async () => {
            let arrKeep = ["action"]
            if (valid) {
                this.onSetShowSearch(false)
                //CommonUtils.fetchApiInstrumentsBySymbol([symbol], [orderTypeUse]) // thêm async để loadapi xong thì mới cập nhật giá vào input giá
                if (orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER
                    || orderTypeUse === ORDER_TYPE_USE.BLB_TRADE_ORDER
                ) {
                    this.props.clearDataPlaceQuickOrder(orderTypeUse, arrKeep)
                    updatePlaceQuickOrder({
                        symbolCurrent: symbol,
                    }, this.props.orderTypeUse)
                }
                if (orderTypeUse === ORDER_TYPE_USE.NORMAL_ORDER) {
                    updatedSymbolLayoutPage(symbol, "search_layout")
                    updatePlaceQuickOrder({
                        symbolCurrent: symbol,
                    }, this.props.orderTypeUse)
                }
            }
            else {
                this.onSetShowSearch(true)
                if (!symbol) {
                    this.props.clearDataPlaceQuickOrder(orderTypeUse, arrKeep)
                    updatedSymbolLayoutPage(null, "search_layout")
                }
            }
        })
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
        const { currentSymbol, symbolSearchRef, focusOnRender } = this.props;
        this._setState({
            symbolToDisplay: this.getSymbolToDisplay(currentSymbol),
            currentSymbol: currentSymbol ? currentSymbol.id : "",
            isValidSymbol: currentSymbol ? true : false
        })
        if (currentSymbol) {
            this.onSetShowSearch(false)
        } else {
            this.onSetShowSearch(true)
        }
        // this.loadData() // off :load data danh sách mã chứng khoán sở hữu
        this.prepareData()

        document.addEventListener('keydown', this.handlerKeyDown);
        this.listenToTheEmitter();

        if (focusOnRender === FOCUS_KEYS.SYMBOL) {
            if (this.props.isOpenScreenConfirm === true) return;
            this._setState({ isShowSearch: true })
            this.props.symbolSearchRef.current && this.props.symbolSearchRef.current.onHandleFocus();
            this.props.setFocusOnRenderOrder();
        }
    }
    callback = () => {
        if (this.props.isOpenScreenConfirm === true) return;
        this._setState({ isShowSearch: true })
        this.props.symbolSearchRef.current && this.props.symbolSearchRef.current.onHandleFocus();
        // if (!this.state.currentSymbol) this.props.symbolSearchRef.current && this.props.symbolSearchRef.current.showAllSymbol();
    }

    listenToTheEmitter() {
        emitter.on("FOCUS_SYMBOL", this.callback);
    }


    handlerKeyDown = (event) => {
        const inputFocus = this.blockInfoSymbolRef.current ? (document.activeElement === this.blockInfoSymbolRef.current ? true : false) : false;
        //console.log("inputFocus---: ", inputFocus, this.blockInfoSymbolRef.current, document.activeElement)
        if (!inputFocus) {
            return
        }
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.SPACE) {
            this._setState({ isShowSearch: true })
            this.props.symbolSearchRef.current.onHandleFocus()
        }
    }


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };


    loadData = () => {
        const { placeQuickOrderCurrent, symbolWithIndex, accountQuickOrderInfo } = this.props
        const { typeAccount, currentAccountId } = { ...accountQuickOrderInfo }

        this._setState({ loadStatus: LoadStatus.LOADING });

        const getSecuritiesPortfolioFunc = this.isBroker() ? inquiryService.getSecuritiesPortfolio : inquiryService.getSecuritiesPortfolio;
        if (currentAccountId) {
            if (typeAccount === TYPE_ACCOUNT.FDS) {

            } else {
                getSecuritiesPortfolioFunc(currentAccountId)
                    .then((data) => {
                        if (data && data.length > 0) {
                            data = data.filter((item, index) => ((!item.symbol.includes('_WFT')) && Number(item.trade) > 0))
                            this._setState({
                                loadStatus: LoadStatus.LOADED,
                                records: data
                            })
                        } else {
                            this._setState({
                                loadStatus: LoadStatus.LOADED,
                                records: []
                            })
                        }
                    })
                    .catch((error) => {
                        this._setState({ loadStatus: LoadStatus.FAIL });
                        ToastUtil.errorApi(error, 'common.fail-to-load-open-positions');
                    });
            }
        }
    };

    UNSAFE_componentWillReceiveProps(prevProps) {
        const { currentSymbol } = this.props;
        const { currentSymbol: prevCurrentSymbol } = prevProps;
        if (currentSymbol !== prevCurrentSymbol) {
            let isBroker = this.isBroker();
            if (isBroker) {
                emitter.emit(Config.Event.ON_CHANGE_SYMBOL_TRADE_BROKER, prevCurrentSymbol)
            }
        };
    };

    getSymbolToDisplay(symbolObj) {
        let _id = (symbolObj && symbolObj.id) || ''
        return _id
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentSymbol, placeQuickOrderCurrent, accountQuickOrderInfo } = this.props;
        const { currentSymbol: prevCurrentSymbol, placeQuickOrderCurrent: prevPlaceQuickOrderCurrent, accountQuickOrderInfo: prevAccountQuickOrderInfo } = prevProps;

        const { action } = { ...placeQuickOrderCurrent }
        const { action: prevAction } = { ...prevPlaceQuickOrderCurrent }

        const { typeAccount, currentAccountId } = { ...accountQuickOrderInfo }
        const { typeAccount: prevTypeAccount, currentAccountId: prevCurrentAccountId } = { ...prevAccountQuickOrderInfo }
        if (!(_.isEqual(currentSymbol, prevCurrentSymbol))) {
            if (currentSymbol && currentSymbol.id) {
                this.onSetShowSearch(false)
                this._setState({
                    symbolToDisplay: this.getSymbolToDisplay(currentSymbol),
                    currentSymbol: currentSymbol ? currentSymbol.id : "",
                    isValidSymbol: currentSymbol ? true : false
                })
            } else {
                this.onSetShowSearch(true)
                this._setState({
                    symbolToDisplay: this.getSymbolToDisplay(currentSymbol),
                    currentSymbol: "",
                    isValidSymbol: false,
                })
            }
        }


        // if (action !== prevAction) {
        //     action && action === orderActions.SELL && this.loadData();
        //     this.genSymbolShow()
        // }

        if (typeAccount !== prevTypeAccount) {
            this.genSymbolShow()
        }

    }

    componentWillUnmount() {
        emitter.removeListener("FOCUS_SYMBOL", this.callback);
        socket.unregisterCallerId(this.callerId);
        document.removeEventListener('keydown', this.handlerKeyDown);
        document.getElementById('block-info-symbol') && document.getElementById('block-info-symbol').removeEventListener("keydown", this.callbackDocument);
        this.mounted = false
    }

    prepareData = () => {
        const { placeQuickOrderCurrent, symbolWithIndex, accountQuickOrderInfo, securities } = this.props
        const { action } = { ...placeQuickOrderCurrent }
        const { typeAccount } = { ...accountQuickOrderInfo }
        const { records } = this.state
        let objSymbol = {}
        let objSymbolTPRL = {}
        let objSymbolDerivative = {}
        let objSymbolDerivativeVN30 = {}

        objSymbol = _.keyBy(symbolWithIndex, function (o) {
            if ((!CommonUtils.isDerivativeExchange(o.exchange))) {
                return o.id
            }
        });

        objSymbolDerivative = _.keyBy(symbolWithIndex, function (o) {
            if ((CommonUtils.isDerivativeExchange(o.exchange))) {
                if (!o.id.startsWith("VN30")) {
                    return o.id
                }
            }
        });

        objSymbolDerivativeVN30 = _.keyBy(symbolWithIndex, function (o) {
            if ((CommonUtils.isDerivativeExchange(o.exchange))) {
                if (o.id.startsWith("VN30")) {
                    return o.id
                }
            }
        });

        objSymbolDerivativeVN30 = Object.keys(objSymbolDerivativeVN30).sort().reduce(
            (obj, key) => {
                obj[key] = objSymbolDerivativeVN30[key];
                return obj;
            },
            {}
        );
        objSymbolDerivative = { ...objSymbolDerivativeVN30, ...objSymbolDerivative }
        if (CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_NM_ORDER || CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER) {
            objSymbolTPRL = _.keyBy(symbolWithIndex, function (o) {
                if ((o.exchange == EXCHANGE.TPRL)) {
                    return o.id
                }
            });
        }
        delete objSymbolDerivative["undefined"] // danh sách mã phái sinh Đang bao gồm cả mã cổ phiếu: YTC
        delete objSymbolTPRL["undefined"] // danh sách mã TPRL Đang bao gồm cả mã cổ phiếu: YTC
        // console.log("prepareData===3", objSymbolTPRL, objSymbol, objSymbolDerivativeVN30, objSymbolDerivative)
        this._setState({
            listSymbolShowBuy: objSymbol, listSymbolTPRL: objSymbolTPRL, listSymbolShowBuyDerivative: objSymbolDerivative
        }, () => {
            this.genSymbolShow();
        })
    }

    genSymbolShow = () => {
        const { placeQuickOrderCurrent, symbolWithIndex, accountQuickOrderInfo } = this.props
        const { records, listSymbolShowBuyDerivative, listSymbolShowBuy, listSymbolTPRL } = this.state
        const { action, focusSymbol } = { ...placeQuickOrderCurrent }
        const { typeAccount } = { ...accountQuickOrderInfo }
        let objSymbol = {}


        if (typeAccount === TYPE_ACCOUNT.FDS) {
            objSymbol = { ...this.state.listSymbolShowBuyDerivative }
        } else {
            objSymbol = { ...this.state.listSymbolShowBuy }
        }

        if (CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_NM_ORDER || CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER) {
            objSymbol = _.cloneDeep(listSymbolTPRL)
        }

        // Hiển thị symbol theo mua và bán
        // if (action === orderActions.BUY) {
        //     if (typeAccount === TYPE_ACCOUNT.FDS) {
        //         objSymbol = { ...this.state.listSymbolShowBuyDerivative }
        //     } else {
        //         objSymbol = { ...this.state.listSymbolShowBuy }
        //     }
        // } else if (action === orderActions.SELL) {
        //     if (typeAccount === TYPE_ACCOUNT.FDS) {
        //         _.forEach(Object.keys(symbolWithIndex), (item) => {
        //             if (CommonUtils.isDerivativeExchange(symbolWithIndex[item].exchange)) {
        //                 objSymbol = {
        //                     ...objSymbol,
        //                     [item]: symbolWithIndex[item]
        //                 }
        //             }
        //         })
        //     }
        //     else {
        //         _.forEach(records, (item) => {
        //             objSymbol = {
        //                 ...objSymbol,
        //                 [item.symbol]: symbolWithIndex[item.symbol]
        //             }
        //         })
        //     }
        // }

        // //console.log('laojahackgame=====> DATA SEARCH', { objSymbol, action });
        this._setState({ listSymbolShow: objSymbol }, () => {
            // focus ô nhập mã vaf clear cờ focus
            if (focusSymbol) {
                this.props.updatePlaceQuickOrder({
                    focusSymbol: false,
                }, this.props.orderTypeUse)
                this._setState({ isShowSearch: true })
                this.props.symbolSearchRef.current && this.props.symbolSearchRef.current.onHandleFocus();
                this.props.symbolSearchRef.current && this.props.symbolSearchRef.current.showAllSymbol();
            }
        })
    }

    checkShowBlock = () => {
        const { isShowSearch } = this.state
        let a = document.getElementById(`search-symbol-quick-order`)
        let b = document.getElementById(`block-info-symbol`)
        if (a && b) {
            if (isShowSearch) {
                a.style.display = "block";
                b.style.display = "none";
            } else {
                a.style.display = "none";
                b.style.display = "block";
            }
        }
    }

    onSetShowSearch = (status, isEdit = false, isFocus) => {
        if (isEdit) return
        if (isFocus) {
            let a = document.getElementById(`search-symbol-quick-order`)
            a.style.display = "block";
            this.props.symbolSearchRef.current.onHandleFocus()
        }
        this._setState({ isShowSearch: status })
    }

    callbackDocument = event => {
        if (event) {
            // event.preventDefault();
            this._setState({ isShowSearch: true })
            this.props.symbolSearchRef.current && this.props.symbolSearchRef.current.onHandleFocus();
            // this.props.symbolSearchRef.current && this.props.symbolSearchRef.current.showAllSymbol();

        }
    }

    onFocusSymbolBlock = () => {
        document.getElementById('block-info-symbol').addEventListener("keydown", this.callbackDocument);
    }

    render() {
        const { isValidSymbol, symbolToDisplay, currentSymbol, listSymbolShow } = this.state;
        const { placeQuickOrderCurrent, symbolWithIndex, isEdittingOrder, accountQuickOrderInfo, symbolSearchRef, orderTypeUse } = this.props
        const { typeAccount, currentAccountId } = { ...accountQuickOrderInfo }
        // //console.log('laojahackgame======> CURRENT SYMBOL', currentSymbol);
        this.checkShowBlock()
        return (
            <div className="search-symbol-quick-order" >
                <div ref={this.blockInfoSymbolRef} onFocus={this.onFocusSymbolBlock} tabIndex={0} id="block-info-symbol" className={"block-info-symbol " + (orderTypeUse !== ORDER_TYPE_USE.QUICK_ORDER ? 'short' : '')} onClick={() => this.onSetShowSearch(true, isEdittingOrder, true)}>
                    <BlockInfoSymbol
                        typeAccount={typeAccount}
                        symbolId={isValidSymbol && currentSymbol}
                        orderTypeUse={orderTypeUse}
                    />
                </div>
                <div id="search-symbol-quick-order" >
                    <SymbolSuggestPlaceOrderQuick
                        ref={symbolSearchRef}
                        isOrder={true}
                        symbolValid={isValidSymbol}
                        currentSymbol={currentSymbol}
                        symbolToDisplay={symbolToDisplay}
                        isDisplayLabel={false}
                        handleInputChange={this.onSymbolChange}
                        isRedirect={false}
                        suggestId="symbol-search-quick-order"
                        placeholder={LanguageUtils.getMessageByKey("common.placeholder-symbol", this.props.language)}
                        symbolWithIndex={listSymbolShow}
                        disabled={isEdittingOrder}
                        isNotSort={typeAccount === TYPE_ACCOUNT.FDS ? true : false}
                        currentAccountId={currentAccountId}
                        handleBlur={this.props.handleBlur}
                    />
                </div>
            </div >
        );
    }

}

const mapStateToProps = (state) => {
    return {
        language: state.app.language,
        role: state.user.userInfo.role,
        symbolWithIndex: state.symbol.allQuotesWithIndex,
        focusOnRender: state.orderNew.focusOnRender,
        isOpenScreenConfirm: state.layout.listIsOpenModal["C&B"]["ScreenConfirm"].isOpenScreenConfirm,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),
        updatedSymbolLayoutPage: (symbolId, from, id) => dispatch(actions.updatedSymbolLayoutPage(symbolId, from, id)),
        setFocusOnRenderOrder: (focusKey) => dispatch(actions.setFocusOnRenderOrder(focusKey)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SearchSymbolQuickOrder);