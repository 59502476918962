import React, { Component } from "react";
import { push } from "connected-react-router";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

// const ORDER_TYPES_KEY = { LIMIT: 'LIMIT', MARKET: 'MARKET', CONDITION: 'CONDITION' };
import * as actions from "../../../../store/actions";
import { TYPE_ORDER_CONDITION, CommonWidgetUtils, initialQuickOrderInputState, LoadStatus, ORDER_TYPE_USE, Role
} from "../../../../utils";
import './ConditionOrder.scss';
import ConditionOrderStopLimit from "./ConditionOrderStopLimit/ConditionOrderStopLimit";
import ConditionOrderTrailing from './ConditionOrderTrailing/ConditionOrderTrailing';
import ConditionOrderConditionOCO from './ConditionOrderConditionOCO/ConditionOrderConditionOCO';

const listOrderTypeCondition = [
    {
        title: "Stop limit",
        value: TYPE_ORDER_CONDITION.STOP_LIMIT,
    },
    {
        title: "Trailing Stop",
        value: TYPE_ORDER_CONDITION.TRAILING_STOP,
    },
    {
        title: "OCO",
        value: TYPE_ORDER_CONDITION.OCO,
    }
]

const DF_DATA_SUBMIT = {
    ...initialQuickOrderInputState,
}


class ConditionOrder extends Component {

    initialState = {
        loadStatus: LoadStatus.NONE,
        dataSubmit: {
            ...DF_DATA_SUBMIT
        },
        isOpenModalActionMethod: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
        this.priceOrderRef = React.createRef();
        this.quantityRef = React.createRef();
    };

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };


    onChangeTypeOrderCondition = (e) => {
        this.props.updatePlaceQuickOrder({
            typeOrderCondition: e.target.value
        }, this.props.orderTypeUse)
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }
	
    componentDidMount() {
        this.mounted = true
	}

    componentDidUpdate(prevProps, _prevState) {
        const { placeQuickOrderCurrent, config, updatePlaceQuickOrder, currentSymbol, accountQuickOrderInfo, orderTypeUse,
            socketCIAction, socketSEAction
        } = this.props;
        const { action, editingOrder, typeOrderCondition } = { ...placeQuickOrderCurrent }
        const { currentAccountId } = { ...accountQuickOrderInfo }

        const {
            currentSymbol: prevCurrentSymbol,
            placeQuickOrderCurrent: prevPlaceQuickOrderCurrent,
            accountQuickOrderInfo: prevAccountQuickOrderInfo,
            socketCIAction: prevSocketCIAction, socketSEAction: prevSocketSEAction
        } = prevProps;

        const { typeOrderCondition: prevTypeOrderCondition } = { ...prevPlaceQuickOrderCurrent }

        if ((typeOrderCondition !== prevTypeOrderCondition)) {
            // this.props.clearDataPlaceQuickOrder(orderTypeUse)
        }
    }


    render() {
        const { orderTypeUse, placeQuickOrderCurrent, selectTypeOrderConditionRef } = this.props
        const { typeOrderCondition } = placeQuickOrderCurrent
        return (
            <div className="condition-order">
                <div className="type-view price-order">
                    <div className="content content-left text-left txt---500-14-20">
                        <FormattedMessage id="trade.place-order.type-order" />
                    </div>
                    <div className="content content-right txt---400-14-20 row-select-order-condition">
                        <div className="custom-form-select-option">
                            <select
                                ref={selectTypeOrderConditionRef}
                                onChange={(e) => this.onChangeTypeOrderCondition(e)}
                                className="focus-item custom-control-select-option"
                            >
                                {listOrderTypeCondition && listOrderTypeCondition.map((item, key) => {
                                    return (
                                        <option key={key} value={item.value} selected={typeOrderCondition == item.value ? true : false}>
                                            {item.title}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                        {/* <div className="container-settings item-center">
                            <span style={{ cursor: 'pointer' }} onClick={() => { this._setState({ isOpenModalActionMethod: true }) }}>
                                {this.props.defaultTheme === "dark" ? <IconSettingDark /> : <IconSettingLight />}
                            </span>

                        </div> */}
                    </div>
                </div>
                {typeOrderCondition === TYPE_ORDER_CONDITION.STOP_LIMIT &&
                    <ConditionOrderStopLimit
                        orderTypeUse={orderTypeUse}
                        availableTrade={this.props.availableTrade}
                    />
                }
                {typeOrderCondition === TYPE_ORDER_CONDITION.TRAILING_STOP &&
                    <ConditionOrderTrailing
                        orderTypeUse={orderTypeUse}
                        availableTrade={this.props.availableTrade}
                    />
                }
                {typeOrderCondition === TYPE_ORDER_CONDITION.OCO &&
                    <ConditionOrderConditionOCO
                        orderTypeUse={orderTypeUse}
                        availableTrade={this.props.availableTrade}
                    />
                }
            </div>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    const { orderTypeUse } = ownProps
    let _placeQuickOrderCurrent = null
    let currentSymbol = null
    let quote = null
    let _accountQuickOrderInfo = null
    let _currentLayoutPageActive = null

    if (orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER) {
        _currentLayoutPageActive = "C&B"
        let _placeQuickOrder = state.orderNew.placeQuickOrder

        currentSymbol = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive] && _placeQuickOrder[_currentLayoutPageActive]["symbolCurrent"]
        quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]]
        _placeQuickOrderCurrent = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive]
        _accountQuickOrderInfo = _placeQuickOrderCurrent && _placeQuickOrderCurrent["accountQuickOrderInfo"]

    } else if (orderTypeUse === ORDER_TYPE_USE.NORMAL_ORDER) {
        _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        let _placeNormalOrder = state.orderNew.placeNormalOrder
        currentSymbol = _currentLayoutPageActive && _placeNormalOrder[_currentLayoutPageActive] && _placeNormalOrder[_currentLayoutPageActive]["symbolCurrent"]
        quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]]

        let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
        _accountQuickOrderInfo = _layoutPage && _layoutPage["accountInfo"]
        _placeQuickOrderCurrent = _currentLayoutPageActive && _placeNormalOrder[_currentLayoutPageActive]
    }
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;
    const instrument = currentSymbol ? state.symbol.instruments[currentSymbol.id] || {} : {};
    return {
        config: state.user.userInfo.config,
        permissionInfo: permissionInfo,
        userInfo: userInfo,
        currentSymbol: currentSymbol,
        instrument: instrument,
        placeQuickOrderCurrent: _placeQuickOrderCurrent,
        accountQuickOrderInfo: _accountQuickOrderInfo,

        quote,
        language: state.app.language,
        role: state.user.userInfo.role,
        showReconfirmOrderConfig: state.app.showReconfirmOrderConfig,

        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        defaultTheme: state.user.userInfo.defaultTheme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        fetchSymbolOrderbook: (symbolId) => dispatch(actions.fetchSymbolOrderbook(symbolId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ConditionOrder);
