import React, { Component } from 'react';
import { connect } from 'react-redux';

import "./BlockInputQtty.scss"
import QuantityInputOrder from './../../../../components/Input/QuantityInputOrder';
class BlockInputQtty extends Component {

    render() {
        const { } = this.props;
        return (
            <div className="block-input-qtty">
                <QuantityInputOrder
                    name={this.props.name}
                    step={this.props.step}
                    min={this.props.min}
                    max={this.props.max}
                    instrument={this.props.instrument}
                    value={this.props.value}
                    valid={this.props.valid}
                    onChange={this.props.onChange}
                    onKeyDown={this.props.onKeyDown}
                    allowZero={this.props.allowZero}
                    allowDecimal={this.props.allowDecimal}
                    allowNegative={this.props.allowNegative}
                    orderType={this.props.orderType}
                    ref={this.props.ref}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    readOnly={this.props.readOnly || false}
                />
            </div>

        );
    }
}

const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockInputQtty);