import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import './ModalQuickOrderConditonDetail.scss';
import DraggableModal from '../../../../components/Modal/DraggableModal';
import { activeOrderExecType, ToastUtil, ACTION_USER_ORDER, CommonOrderUtils, Random, LoadStatus, ORDER_TYPE_USE, CommonUtils } from "utils";
import { tradeApiService } from "services";
import { push } from "connected-react-router";
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions";
import { Role, TYPE_ACCOUNT, TYPE_SUBMIT_ORDER } from '../../../../utils/constants';
import FormattedPrice from './../../../../components/Formating/FormattedPrice';
import { fullSubOrderTypes } from './../../../../constants/config';
class ModalQuickOrderConditonDetail extends Component {

    initialState = {
        keyActive: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }
	
    componentDidMount() {
        this.mounted = true
	}	

    setLoadStatusModal = (status) => {
        let getInfoOrderLayoutCurrent = CommonOrderUtils.getInfoOrderLayoutCurrent()
        const { listOrderLayoutCurrent } = getInfoOrderLayoutCurrent
        if (listOrderLayoutCurrent && listOrderLayoutCurrent.length > 0) {
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.NORMAL_ORDER)) {
                this.props.setIsOpenModalHaveData("QuickOrder", {
                    loadStatusQuickOrderModalTrade: status,
                })
            }
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.PRO_TRADE_ORDER)) {
                this.props.setIsOpenModalHaveData("ProTrade", {
                    loadStatusProTradeModalTrade: status,
                })
            }
        }
    }


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    onFinish = () => {
        const { clearDataPlaceQuickOrder, setIsOpenModalHaveData, onClose } = this.props
        CommonUtils.middlewareOrder()
        setIsOpenModalHaveData("QuickOrder", {
            isOpenModalTradeQuickOrder: false,
            onTradeQuickOrderAuthenCallback: null,
            dataShow: {},
        })
        onClose()
    };

    cancelOrder = (func, input) => {
        this.props.setIsOpenModalHaveData("QuickOrder", {
            isOpenModalTradeQuickOrder: true,
            onTradeQuickOrderAuthenCallback: (authInfo) => {
                func(input, authInfo);
            }
        })
    };


    getListReconfirmOrder = (listOrder) => {
        let listReconfirmOrder = [];
        listOrder.forEach(order => {
            const action = CommonUtils.getOrderActionFromSideCode(order['exectype']);
            const orderType = CommonUtils.getOrderTypeFromPriceType(order['pricetype']);
            let limitPrice = null;
            let stopPrice = null;

            let quantity = order['remainqtty'];
            listReconfirmOrder.push({
                orderid: order['orderid'],
                side: order['exectype'],
                action,
                currentOrderType: orderType,
                limitPrice: order['quoteprice'],
                stopPrice,
                quantity,
                symbolCurrent: {
                    id: order['symbol']
                },
                marketPrice: order['quoteprice'],
                subOrderType: order['pricetype'],
                cancelingOrder: true,
                accountId: order['acctno'],
                afacctnoext: order['afacctno_ext']
            });
        });
        return listReconfirmOrder;
    };

    onCancelOrder = (order) => {
        const { accountQuickOrderInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }

        tradeApiService.initTwoFactorAuth({ accountId: currentAccountId })
            .then((data) => {
                const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                this.props.updateInfoCheckAuth({
                    transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                })
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-customer-acccounts-list');
                return;
            })

        let listReconfirmOrder = this.getListReconfirmOrder([{ ...order }]);

        let orderInput = {
            actionUserOrder: ACTION_USER_ORDER.CANCEL_ORDER,
            typeSubmitOrder: TYPE_SUBMIT_ORDER.ORDER,
            cancelingOrder: listReconfirmOrder
        }

        this.props.updatePlaceQuickOrder(orderInput)
        this.cancelOrder(this.doCancelOrder, order);
    };

    doCancelOrder = (order, authInfo) => {
        this.callApiCancelOrder(order, authInfo);
    };

    callApiCancelOrder = (order, authInfo) => {
        const { recordsFilter, stautusFilter } = this.state
        this.props.setIsOpenModalHaveData("QuickOrder", {
            loadStatusQuickOrderModalTrade: LoadStatus.LOADING,
        })
        let requestId = Random.randomOrderRequestId()

        let body = {
            ...authInfo,
            orderId: order['odorderid'],
            requestId,
        }

        tradeApiService.tradeCancelOrder(order['afacctno'], body)
            .then(() => {
                this.onFinish();
                ToastUtil.success('trade.order.cancel-order-success');
            })
            .catch((error) => {
                this.props.setIsOpenModalHaveData("QuickOrder", {
                    loadStatusQuickOrderModalTrade: LoadStatus.FAIL,
                })
                ToastUtil.errorApi(error, 'trade.order.cancel-order-fail');
            });
    }


    renderGridCellSideCode = (record, language) => {
        let className = (record === activeOrderExecType.BUY) ? 'text-green' : 'text-red';
        return (
            <Fragment>
                {/* <span className={className}>{language === "en" ? record.en_exectypedesc : record.exectypedesc}</span> */}
                <span className={className}>{record === activeOrderExecType.BUY ? "Mua" : "Bán"}</span>
            </Fragment>
        )
    }

    renColorStatusIcon = (status) => {
        const { accountQuickOrderInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        let _class = ""
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            if (status === "N" || status === "B" || status === "S") {
                _class = "bg-yellow"
            } else if (status === "T") {
                _class = "bg-green"
            } else {
                _class = "bg-red"
            }
        } else {
            if (status === "2" || status === "8" || status === "P") {
                _class = "bg-yellow"
            } else if (status === "4" || status === "12" || status === "A") {
                _class = "bg-green"
            } else {
                _class = "bg-red"
            }
        }
        return _class
    }

    convertDate = (date) => {
        if (date) {
            let convert = date.split("-");
            let result = convert[0]
            let year = result.slice(0, 4)
            let month = result.slice(4, 6)
            let day = result.slice(6, 8)
            return day + "/" + month + "/" + year
        }
        return "-"
    }

    convertDateFDS = (date) => {
        if (date) {
            let result = date.split("T")[0];
            let year = result.split("-")[0]
            let month = result.split("-")[1]
            let day = result.split("-")[2]
            return day + "/" + month + "/" + year
        }
        return "-"
    }

    renderRootId = (item) => { //0068130721000049
        let result = ""
        result = item && ("..." + item.substring(item.length - 6))
        return result
    }


    toogleHideShowInfo = (id) => {
        const { recordsFilter, keyActive } = this.state
        if (keyActive !== id) {
            this._setState({ keyActive: id })
        } else {
            if (keyActive !== null) {
                this._setState({ keyActive: null })
            } else {
                this._setState({ keyActive: id })
            }
        }
    }

    fillEditInfo = (order) => {
        const { accountQuickOrderInfo, isQuickOrder } = this.props;
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }
        const action = CommonUtils.getOrderActionFromSideCode(order['sideCode']);
        const orderType = CommonUtils.getOrderTypeFromPriceType(order['pricetype']);
        //console.log("fillEditInfo", order)
        let orderInput = {
            actionUserOrder: ACTION_USER_ORDER.EDIT_ORDER,
            symbolCurrent: order['symbol'],
            accountQuickOrderInfo: {
                ...accountQuickOrderInfo,
            },
            action: action,
            orderType: orderType,
            quantity: Number(order['qtty']),
            quotePriceValid: true,
            stepPriceValid: true,
            editingOrder: order
        }

        orderInput.limitPrice = order['price'];
        orderInput.subOrderType = fullSubOrderTypes.LO

        this.props.updatePlaceQuickOrder(orderInput)
        this.props.onChangeItemSelect(1)
    }

    onEditOrder = (order) => {
        let arrKeep = ["action"]
        this.props.clearDataPlaceQuickOrder(ORDER_TYPE_USE.QUICK_ORDER, arrKeep)
        const fillEditInfo = () => {
            this.fillEditInfo(order);
        };
        if (this.isBroker()) {
            return
        } else {
            fillEditInfo();
        }
    };


    renderFDS = () => {
        const { isOpen, onClose, actionMethod, dataFill, language, record, defaultTheme, accountQuickOrderInfo } = this.props;
        const { keyActive } = this.state
        const { typeAccount } = accountQuickOrderInfo
        return (
            <div className="wrap-body">
                <div className="table-order-book">
                    <table className="basic-table table-condition">
                        <thead>
                            <tr>
                                <th className="txt---500-14-20 text-left text-not-active" style={{ width: "20%" }}>Loại lệnh</th>
                                <th className="txt---500-14-20 text-not-active" style={{ width: "20%" }}><FormattedMessage id="quick-order.order-book.buy-sell" /></th>
                                <th className="txt---500-14-20 text-not-active" style={{ width: "20%" }}><FormattedMessage id="quick-order.order-book.price-value" /></th>
                                <th className="txt---500-14-20 text-not-active" style={{ width: "20%" }}><FormattedMessage id="quick-order.order-book.qtty" /></th>
                                <th className="txt---500-14-20 text-not-active" style={{ width: "20%" }}><FormattedMessage id="quick-order.order-book.status" /></th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                dataFill.length > 0 && dataFill.map((item, index) => {
                                    return (
                                        <>
                                            <tr key={index} >
                                                <td className="text-left text-active txt---500-14-20" onClick={() => this.toogleHideShowInfo(index)}>{item.pricetype}</td>
                                                <td className={"text-center text-uppercase txt---400-14-20 " + (item['sideCode'] === activeOrderExecType.BUY ? "text-green" : "text-red")} onClick={() => this.toogleHideShowInfo(index)}>
                                                    <FormattedMessage id={"common.exec-types." + item['sideCode']} />
                                                </td>
                                                <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)}>
                                                    <FormattedPrice value={Number(item.quoteprice)} shortForm={true} isNotThousand={true} />
                                                </td>
                                                <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)}>
                                                    {item.orderqtty}
                                                </td>
                                                <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)} style={{ width: "35%" }}>
                                                    <span className="status-order">
                                                        <div className={"status-icon " + (this.renColorStatusIcon(item.statusCode))}>

                                                        </div>
                                                        {language === "vi" ? item.orstatus_desc : item.en_orstatus_desc}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className={"block-info-detail-order " + (keyActive === index ? "active" : "not-active")}>
                                                <td colSpan="6">
                                                    <div className="info-detail-order">
                                                        <div className="time-order">
                                                            <div className="label-time-order txt---400-12-18 text-not-active">
                                                                <FormattedMessage id="quick-order.order-book.time-order" />
                                                            </div>
                                                            <div className="value-time-order txt---400-12-18">
                                                                <span>
                                                                    {item.txtime}
                                                                </span>
                                                                -
                                                                <span>
                                                                    {this.convertDateFDS(item.txdate)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className=" wrap-content">
                                                            <div className="content-order content-right">
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.accountid" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {CommonUtils.formatFDS(item.afacctno)}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.orderid" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {this.renderRootId(item.odorderid)}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.effect" />
                                                                    </div>
                                                                    <div className="value txt---400-12-18">
                                                                        {this.convertDateFDS(item.txdate)}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.price-matched" />
                                                                    </div>
                                                                    <div className="value txt---400-12-18">
                                                                        -
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.cancel-volumn" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.cancelqtty}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.quantity-remain" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.remainqtty}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="content-order content-left">
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.type-order" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.pricetype}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.root-orderid" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {this.renderRootId(item.rootodorderid)}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.finish" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {this.convertDateFDS(item.txdate)}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.qtty-matched" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.execqtty}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.quantity-edit" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        -
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="action-order">
                                                            <div className="container-btn">
                                                                <button className={"btn-action btn-fix txt---500-14-20 " + (!item.amendOrderPermission ? "" : "text-custom")} onClick={() => { this.onEditOrder(item) }}
                                                                    // disabled={true}
                                                                    disabled={!item.amendOrderPermission}
                                                                >
                                                                    <FormattedMessage id="quick-order.order-book.edit" />
                                                                </button>
                                                                <button className={"btn-action txt---500-14-20 " + (!item.cancelOrderPermission ? "" : "bg-sell text-custom")}
                                                                    onClick={() => { this.onCancelOrder(item) }}
                                                                    disabled={!item.cancelOrderPermission}
                                                                >
                                                                    <FormattedMessage id="quick-order.order-book.cancel" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>

                    </table>
                </div>
            </div>
        )
    }

    renderBasic = () => {
        const { isOpen, onClose, actionMethod, dataFill, language, record, defaultTheme, accountQuickOrderInfo } = this.props;
        const { keyActive } = this.state
        const { typeAccount } = accountQuickOrderInfo
        return (
            <div className="wrap-body">
                <div className="table-order-book">
                    <table className="basic-table table-condition">
                        <thead>
                            <tr>
                                <th className="txt---500-14-20 text-left text-not-active" style={{ width: "20%" }}>Loại lệnh</th>
                                <th className="txt---500-14-20 text-not-active" style={{ width: "20%" }}><FormattedMessage id="quick-order.order-book.buy-sell" /></th>
                                <th className="txt---500-14-20 text-not-active" style={{ width: "20%" }}><FormattedMessage id="quick-order.order-book.price-value" /></th>
                                <th className="txt---500-14-20 text-not-active" style={{ width: "20%" }}><FormattedMessage id="quick-order.order-book.qtty" /></th>
                                <th className="txt---500-14-20 text-not-active" style={{ width: "20%" }}><FormattedMessage id="quick-order.order-book.status" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataFill.length > 0 && dataFill.map((item, index) => {
                                    return (
                                        <>
                                            <tr key={index} >
                                                <td className="text-left text-active txt---500-14-20" onClick={() => this.toogleHideShowInfo(index)}>{item.pricetype}</td>
                                                <td className={"text-center text-uppercase txt---400-14-20 " + (item['sideCode'] === activeOrderExecType.BUY ? "text-green" : "text-red")} onClick={() => this.toogleHideShowInfo(index)}>
                                                    <FormattedMessage id={"common.exec-types." + item['sideCode']} />
                                                </td>
                                                <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)}>
                                                    <FormattedPrice value={Number(item.quoteprice)} shortForm={true} isFormatZero={true} />
                                                </td>
                                                <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)}>
                                                    {item.orderqtty}
                                                </td>
                                                <td className="text-center text-near-active txt---400-14-20" onClick={() => this.toogleHideShowInfo(index)} style={{ width: "35%" }}>
                                                    <span className="status-order">
                                                        <div className={"status-icon " + (this.renColorStatusIcon(item.orstatusvalue))}>

                                                        </div>
                                                        {language === "vi" ? item.orstatus : item.en_orstatus}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className={"block-info-detail-order " + (keyActive === index ? "active" : "not-active")}>
                                                <td colSpan="6">
                                                    <div className="info-detail-order">
                                                        <div className="time-order">
                                                            <div className="label-time-order txt---400-12-18 text-not-active">
                                                                <FormattedMessage id="quick-order.order-book.time-order" />
                                                            </div>
                                                            <div className="value-time-order txt---400-12-18">
                                                                <span>
                                                                    {item.txtime}
                                                                </span>
                                                                -
                                                                <span>
                                                                    {this.convertDateFDS(item.txdate)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className=" wrap-content">
                                                            <div className="content-order content-right">
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.accountid" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.afacctno}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.orderid" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {this.renderRootId(item.odorderid)}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.effect" />
                                                                    </div>
                                                                    <div className="value txt---400-12-18">
                                                                        {this.convertDateFDS(item.txdate)}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.price-matched" />
                                                                    </div>
                                                                    <div className="value txt---400-12-18">
                                                                        -
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.cancel-volumn" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.cancelqtty}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.quantity-remain" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.remainqtty}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="content-order content-left">
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.type-order" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.pricetype}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.root-orderid" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {this.renderRootId(item.rootodorderid)}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.finish" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {this.convertDateFDS(item.txdate)}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.qtty-matched" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        {item.execqtty}
                                                                    </div>
                                                                </div>
                                                                <div className="row-content">
                                                                    <div className="label txt---400-12-18 text-not-active text-left">
                                                                        <FormattedMessage id="quick-order.order-book.quantity-edit" />
                                                                    </div>

                                                                    <div className="value txt---400-12-18">
                                                                        -
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="action-order">
                                                            <div className="container-btn">
                                                                <button className={"btn-action btn-fix txt---500-14-20 " + (!item.amendOrderPermission ? "" : "text-custom")} onClick={() => { this.onEditOrder(item) }}
                                                                    // disabled={true}
                                                                    disabled={!item.amendOrderPermission}
                                                                >
                                                                    <FormattedMessage id="quick-order.order-book.edit" />
                                                                </button>
                                                                <button className={"btn-action txt---500-14-20 " + (!item.cancelOrderPermission ? "" : "bg-sell text-custom")}
                                                                    onClick={() => { this.onCancelOrder(item) }}
                                                                    disabled={!item.cancelOrderPermission}
                                                                >
                                                                    <FormattedMessage id="quick-order.order-book.cancel" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    render() {
        const { actionSelect } = this.state
        const { isOpen, onClose, actionMethod, dataFill, language, record, defaultTheme, addClass, accountQuickOrderInfo } = this.props;
        const { typeAccount } = accountQuickOrderInfo
        let _className = `${addClass} modal-quick-order-condition-detail`
        let titleId = record.symbol
        return (
            <DraggableModal
                isOpen={isOpen}
                onClose={onClose}
                className={_className}
                titleId={titleId}
                toggle={onClose}
            >
                <div className="body">
                    {typeAccount === TYPE_ACCOUNT.FDS && this.renderFDS()}
                    {typeAccount === TYPE_ACCOUNT.BASE && this.renderBasic()}
                </div>
            </DraggableModal>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    let _currentLayoutPageActive = "C&B"
    let _placeQuickOrder = state.orderNew.placeQuickOrder
    const symbolCurrent = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive] && _placeQuickOrder[_currentLayoutPageActive]["symbolCurrent"]
    const quote = symbolCurrent && state.symbol.quotes[symbolCurrent["id"]]
    const placeQuickOrderCurrent = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive]
    const _accountQuickOrderInfo = placeQuickOrderCurrent && placeQuickOrderCurrent["accountQuickOrderInfo"]
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;
    return {
        config: state.user.userInfo.config,
        permissionInfo: permissionInfo,
        userInfo: userInfo,
        symbolCurrent: symbolCurrent,
        placeQuickOrderCurrent: placeQuickOrderCurrent,
        accountQuickOrderInfo: _accountQuickOrderInfo,
        quote,
        language: state.app.language,
        role: state.user.userInfo.role,
        showReconfirmOrderConfig: state.app.showReconfirmOrderConfig,

        socketODAction: state.socketRealtime.socketODAction,
        custSocketODActionCounter: state.socketRealtime.custSocketODActionCounter,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        updateProTrade: (data, typeOrder) => dispatch(actions.updateProTrade(data, typeOrder)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),


        saveTradeAuthenticated: () => dispatch(actions.saveTradeAuthenticated()),
        setTradeAuthSuccess: () => dispatch(actions.setTradeAuthSuccess()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalQuickOrderConditonDetail);

