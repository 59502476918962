import React, { Component } from "react";
import { connect } from 'react-redux';
import { ACTION_USER_ORDER, CommonWidgetUtils } from "utils";

import DraggableModal from '../../../../components/Modal/DraggableModal';
import * as actions from "../../../../store/actions";

import { ORDER_TYPE_USE } from './../../../../utils/constants';
import TradeAuthQuickOrder from "./TradeAuthQuickOrder";

import './TradeAuthQuickOrderModal.scss';
class TradeAuthQuickOrderModal extends Component {
    shouldComponentUpdate(nextProps) {
        const props = this.props;
        return props.isOpen !== nextProps.isOpen
            || props.toggle !== nextProps.toggle
            || props.tradeOrderInputMsg !== nextProps.tradeOrderInputMsg;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isOpen != prevProps.isOpen && !this.props.isOpen) {
            let isMobileScreen = window.innerWidth <= 769 && window.innerWidth != 0;
            isMobileScreen && window.scrollTo(0, 0);
        }
    }

    focusFirstElement = () => {
        document.getElementById("prevent-outside-tab").focus();
    }

    render() {
        const { isOpen, toggle, onClose, placeQuickOrderCurrent, accountQuickOrderInfo, orderTypeUse } = this.props;
        const listReconfirmOrder = { ...placeQuickOrderCurrent }
        const isPlaceOrder = listReconfirmOrder && listReconfirmOrder.actionUserOrder === ACTION_USER_ORDER.PLACE_ORDER;
        const isEdit = listReconfirmOrder && listReconfirmOrder.actionUserOrder === ACTION_USER_ORDER.EDIT_ORDER;
        const isCancel = listReconfirmOrder && listReconfirmOrder.actionUserOrder === ACTION_USER_ORDER.CANCEL_ORDER;
        let titleId = isPlaceOrder ? "trade.auth.title" : isEdit ? "trade.auth.edit-title" : isCancel ? "trade.auth.cancel-title" : "trade.auth.title";

        let _className = orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER ? "trade-auth-quick-order-modal" : "trade-auth-normal-order-modal"
        return (
            <DraggableModal
                isOpen={isOpen}
                toggle={toggle}
                onClose={onClose}
                className={_className}
                titleId={titleId}
            >
                <div className="body">
                    <TradeAuthQuickOrder
                        isPlaceOrder={isPlaceOrder}
                        isEdit={isEdit} //
                        isCancel={isCancel} //
                        onFinish={onClose}
                        listReconfirmOrder={listReconfirmOrder} //
                        accountQuickOrderInfo={accountQuickOrderInfo}
                        quote={this.props.quote}
                    />
                </div>
            </DraggableModal>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    const { orderTypeUse } = ownProps
    let _placeQuickOrderCurrent = null
    let currentSymbol = null
    let quote = null
    let _accountQuickOrderInfo = null
    let _currentLayoutPageActive = null

    if (orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER) {
        _currentLayoutPageActive = "C&B"
        let _placeQuickOrder = state.orderNew.placeQuickOrder

        currentSymbol = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive] && _placeQuickOrder[_currentLayoutPageActive]["symbolCurrent"]
        quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]]
        _placeQuickOrderCurrent = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive]
        _accountQuickOrderInfo = _placeQuickOrderCurrent && _placeQuickOrderCurrent["accountQuickOrderInfo"]

    } else if (orderTypeUse === ORDER_TYPE_USE.NORMAL_ORDER) {
        _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        let _placeNormalOrder = state.orderNew.placeNormalOrder
        currentSymbol = _currentLayoutPageActive && _placeNormalOrder[_currentLayoutPageActive] && _placeNormalOrder[_currentLayoutPageActive]["symbolCurrent"]
        quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]]

        let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
        _accountQuickOrderInfo = _layoutPage && _layoutPage["accountInfo"]
        _placeQuickOrderCurrent = _currentLayoutPageActive && _placeNormalOrder[_currentLayoutPageActive]
    }

    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;
    return {
        config: state.user.userInfo.config,
        permissionInfo: permissionInfo,
        userInfo: userInfo,
        currentSymbol: currentSymbol,

        placeQuickOrderCurrent: _placeQuickOrderCurrent,
        accountQuickOrderInfo: _accountQuickOrderInfo,

        quote,
        language: state.app.language,
        role: state.user.userInfo.role,
        showReconfirmOrderConfig: state.app.showReconfirmOrderConfig,

        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradeAuthQuickOrderModal);