import React, { Component } from 'react';
import { FormattedPrice } from 'components/Formating';
import RetryableContent from 'components/RetryableContent';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import ArrowIconDark from '../../../assets/icons/AngleUpIcon_Dark.svg'
import ArrowIconLight from '../../../assets/icons/AngleUpIcon_Light.svg'
import { fdsInquiryService, inquiryService } from "../../../services";
import * as actions from "../../../store/actions";
import { CommonUtils, CommonWidgetUtils, COUNT_DOWN_RELOAD, LoadStatus, ToastUtil, CommonOrderUtils } from "../../../utils";
import './Assets.scss';
import { TYPE_ACCOUNT } from './../../../utils/constants';
import CustomScrollbars from 'components/CustomScrollbars';
import BlockInfoPortfolio from '../PortfolioQuickOrder/BlockInfoPortfolio/BlockInfoPortfolio';

class Assets extends Component {

    initialState = {
        isOpen1: false,
        isOpen2: false,
        isOpen3: false,
        isOpen4: false,
        isOpen5: false,
        isOpen6: false, // eflex

        loadStatus: LoadStatus.NONE,
        loadStatusReload: LoadStatus.NONE,

        sumCostMarket: 0,               //Chứng khoán
        records: [],
        // netAssetValue: 0,               //Giá trị tài sản ròng 

        basis: {                                           //cơ sở
            // marginrate: 0,                              //Tỷ lệ ký quỹ hiện tại
            // status: "---",                              //Trạng thái tiểu khoản
            // money_at_bsc: 0,                            //Tiền tại VIX (balance - tdbalance)
            // cibalance: 0,                               //Tiền không kỳ hạn
            // receivingamt: 0,                            //Tiền bán CK chờ về
            // careceiving: 0,                             //Tiền cổ tức chờ về
            // interestbalance: 0,                         //Lãi tiền gửi chưa thanh toán
            // tdbalance: 0,                               //iFlex
            // securitiesamt: 0,                           //Chứng khoán
            // totaldebtamt: 0,                            //Phải trả
            // t0debtamt: 0,                               //Nợ bảo lãnh
            // marginamt: 0,                               //Nợ vay ký quỹ
            // advancedamt: 0,                             //Nợ ứng trước
            // secureamt: 0,                               //Nợ tiền mua CK trong ngày
            // cidepofeeacr: 0,                            //Nợ phí lưu ký
            // pp0: 0,                                     //Thặng dư ký quỹ
            // bankavlbal: 0,                              //Tiền tại @BIDV  //Số dư khả dụng tại ngân hàng                        
            // holdbalance: 0,                             //Số dư phong tỏa
            // mrcrlimitmax: 0,                            //Hạn mức vay tối đa
        },
        derivative: {},
        time: 0,
        isCoreBank: false,
        profitlostvm: 0,
    };

    instrumentsMap = {};


    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
        this.counter = null
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
    
    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        this.debounceLoadData();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const { accountQuickOrderInfo,
            socketCIAction, socketSEAction, socketDTAction
        } = this.props;
        const { currentAccountId } = { ...accountQuickOrderInfo }
        const { accountQuickOrderInfo: prevAccountQuickOrderInfo,
            socketCIAction: prevSocketCIAction, socketSEAction: prevSocketSEAction,
            socketDTAction: prevSocketDTAction,
        } = prevProps;
        const { records, derivative } = this.state;
        const { records: prevRecords, derivative: prevDerivative } = prevState;
        const { currentAccountId: prevCurrentAccountId } = { ...prevAccountQuickOrderInfo }

        if (currentAccountId !== prevCurrentAccountId) {
            this.debounceLoadData();
        }

        if (!(_.isEqual(records, prevRecords)) || !(_.isEqual(derivative, prevDerivative))) {
            this.calculateTotal(true);
        }

        if (socketCIAction[currentAccountId] !== prevSocketCIAction[currentAccountId]
            || socketSEAction[currentAccountId] !== prevSocketSEAction[currentAccountId]
            || socketDTAction[currentAccountId] !== prevSocketDTAction[currentAccountId]
        ) {
            this.debounceLoadData();
        }

        if (!(_.isEqual(records, prevRecords))) {
            this.onUpdateInstrusment()
        }
    }

    onUpdateInstrusment = () => {
        const { records, } = this.state;
        if (records && records.length > 0) {
            let symbols = records.map((item, index) => {
                return CommonOrderUtils.getSymbolOfSymbolSpecial(item.symbol)
            })
            symbols && symbols.length > 0 && CommonUtils.fetchApiInstrumentsBySymbol(symbols)
        }
    }


    initMaps = () => {
        this.instrumentsMap = {};
    };

    getValidSumValue = (val) => {
        if (val == null || Number.isNaN(val)) {
            return 0;
        }
        return val;
    };


    calculateTotal = (isFirst) => {
        const { records, derivative } = this.state
        const { accountQuickOrderInfo } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        let newRecords = _.cloneDeep(records)
        let instruments = isFirst ? CommonUtils.getInstruments() : this.instrumentsMap
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            let lailovithehientai = 0
            let lailovithedadong = 0;
            _.forEach(newRecords, (record) => {
                let _symbol = CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol)
                let instrument = instruments[_symbol] || {}
                if (instrument && isFirst) this.instrumentsMap[_symbol] = instrument;
                record.closePrice = instrument['CP'] || record['price_secured'] 
                if (CommonUtils.checkSeqApi(instrument.s, record.s)) {
                    record.closePrice = record['price_secured'] || 0
                }
                record.price_secured = record.closePrice
                let pnlValue = (Math.abs((record.closePrice * (record.qtty ? record.qtty : 0))) - Math.abs((record.capvalue ? record.capvalue : 0))) * (record.nvalue ? record.nvalue : 0);

                if (record.qtty != 0) {
                    pnlValue = pnlValue * (record.qtty / (Math.abs(record.qtty)));
                }
                lailovithehientai += pnlValue;
                lailovithedadong += (record.vrplamt ? record.vrplamt * -1 : 0);
            })

            let netAssetValue = derivative.vimcash + derivative.vcashonhand + derivative.wsecured +
                derivative.wtransfer + lailovithehientai + lailovithedadong - derivative.mstpay
            // //console.log('Haki22.:netAssetValueFDS=22222', netAssetValue, derivative.vimcash, derivative.vcashonhand, derivative.wsecured,
            //     derivative.wtransfer, lailovithehientai, lailovithedadong, derivative.mstpay)
            let profitlostvm = lailovithehientai + lailovithedadong
            this._setState({ netAssetValueFDS: netAssetValue, profitlostvm: profitlostvm });

        } else {
            let _sumCostMarket = 0;
            _.forEach(newRecords, (record) => {
                let _symbol = CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol)
                let instrument = instruments[_symbol] || {}
                if (instrument && isFirst) this.instrumentsMap[_symbol] = instrument;
                record.closePrice = instrument['CP'] || record['basicPrice']
                if (CommonUtils.checkSeqApi(instrument.s, record.s)) {
                    record.closePrice = record['basicPrice'] || 0
                }
                record.basicPrice = record.closePrice
                record.basicPriceAmt = this.getValidSumValue(record.closePrice * record['totalpnl']);
                _sumCostMarket += this.getValidSumValue(record.closePrice * record['totalpnl']);
                // //console.log("calculateTotal", record.symbol, record.closePrice, record.basicPriceAmt, _sumCostMarket)
            });
            this._setState({
                sumCostMarket: _sumCostMarket
            });
        }
    }

    apiSeq = new Date().getTime();
    debounceLoadData = _.debounce(() => {
        this.apiSeq = new Date().getTime();
        this.loadData(null, this.apiSeq);
    }, 50);

    loadData = (clearExisting, apiSeq) => {
        const { accountQuickOrderInfo } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }

        if (clearExisting === true) {
            this._setState({
                ...this.initialState
            });
        }

        let filterAccount = accounts.find(item => item.id === currentAccountId)
        if (filterAccount && filterAccount.corebank == 'Y') {
            this._setState({ isCorebank: true })
        } else {
            this._setState({ isCorebank: false })
        }
        let custid = CommonUtils.isBroker() ? (curCustomer && curCustomer.custid) : this.props.userInfo.custid
        if (accountQuickOrderInfo && currentAccountId && typeAccount === TYPE_ACCOUNT.FDS) {
            this._setState({ loadStatus: LoadStatus.LOADING, records: [] });
            this.loadSummaryAccountFDS(apiSeq)
            fdsInquiryService.getSecuritiesPortfolioFDS(currentAccountId, custid)
                .then((data) => {
                    if (apiSeq < this.apiSeq) return;
                    if (data && data.length > 0) {
                        this._setState({ records: data, loadStatus: LoadStatus.LOADED })
                    }
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fail-to-load-account-summary');
                });
        }

        if (accountQuickOrderInfo && currentAccountId && typeAccount === TYPE_ACCOUNT.BASE) {
            this._setState({ loadStatus: LoadStatus.LOADING, records: [] });
            this.loadSummaryAccount(false, apiSeq)
            inquiryService.getSecuritiesPortfolio(currentAccountId, custid)
                .then((data) => {
                    if (apiSeq < this.apiSeq) return;
                    this.initMaps();
                    this._setState({ records: data, loadStatus: LoadStatus.LOADED });
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fail-to-load-open-positions');
                });
        }
    };

    // onHandleReload = (e) => {
    //     const { accountQuickOrderInfo } = this.props;
    //     const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
    //     e.stopPropagation();
    //     let { time } = this.state;
    //     if (time === 0) {
    //         inquiryService.getCorebankBalance(currentAccountId)
    //             .then((data) => {
    //                 this.loadSummaryAccount(true)
    //             })
    //             .catch((error) => {
    //                 ToastUtil.errorApi(error, 'common.fail-find-benefit-bank');
    //             });

    //     }
    // }

    timer = () => {
        this.counter = setInterval(() => {
            let { time } = this.state;
            time = time - 1;
            if (time === 0) {
                this.clearTimer();
            }
            this._setState({
                time: time
            })
        }, 1000);
    }

    clearTimer = () => {
        if (this.counter) {
            clearInterval(this.counter)
        }
    }

    loadSummaryAccount = (isReload = false, apiSeq) => {
        const { accountQuickOrderInfo } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        if (isReload) {
            this._setState({ loadStatusReload: LoadStatus.LOADING })
        } else {
            this._setState({ loadStatus: LoadStatus.LOADING });

        }
        inquiryService.getSummaryAccount(currentAccountId)
            .then((data) => {
                if (apiSeq < this.apiSeq) return;
                this._setState({
                    basis: data,
                    loadStatus: LoadStatus.LOADED,
                });
                if (isReload) {
                    this._setState({ loadStatusReload: LoadStatus.LOADED, time: COUNT_DOWN_RELOAD },
                        () => this.timer())
                } else {
                    this._setState({
                        loadStatus: LoadStatus.LOADED,
                    });
                }
            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                if (isReload) {
                    this._setState({ loadStatusReload: LoadStatus.LOADED })
                }
                ToastUtil.errorApi(error, 'common.fail-to-load-account-summary');
            });
    }


    loadSummaryAccountFDS = (apiSeq) => {
        const { accountQuickOrderInfo } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        this._setState({ loadStatus: LoadStatus.LOADING });
        fdsInquiryService.getSummaryAccount(currentAccountId)
            .then((data) => {
                if (apiSeq < this.apiSeq) return;
                if (data && data.length > 0) {
                    this._setState({
                        derivative: data[0],
                        loadStatus: LoadStatus.LOADED,
                    });
                }
            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.fail-to-load-account-summary');
            });
    }

    setisOpen1 = () => {
        const { isOpen1 } = this.state
        this._setState({ isOpen1: !isOpen1 });
    }

    setisOpen2 = () => {
        const { isOpen2 } = this.state
        this._setState({ isOpen2: !isOpen2 });
    }

    setisOpen3 = () => {
        const { isOpen3 } = this.state
        this._setState({ isOpen3: !isOpen3 });
    }

    setisOpen4 = () => {
        const { isOpen4 } = this.state
        this._setState({ isOpen4: !isOpen4 });
    }

    setisOpen5 = () => {
        const { isOpen5 } = this.state
        this._setState({ isOpen5: !isOpen5 });
    }

    setisOpen6 = (e) => {
        e.stopPropagation()
        const { isOpen6 } = this.state
        this._setState({ isOpen6: !isOpen6 });
    }

    onRedirectScreenIflex = () => {
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...this.props.accountQuickOrderInfo }
        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: true,
            curScreenModalActive: "menu-sidebar.title-3.6",
            menuActive: 3,
            accountInfo: {
                "curCustomer": curCustomer,               //Thông tin khách hàng dành cho MG
                "accounts": accounts,                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": currentAccountId,          //Tiểu khoản đang active
                "typeAccount": typeAccount,    //Xem phải tiểu khoản phái sinh không
            }
        })
    }


    getSymbolOfSymbolSpecial = (symbolSpecial) => {
        const config = '_WFT'
        if (symbolSpecial.endsWith(config)) {
            return symbolSpecial.replace(config, "")
        }
        return symbolSpecial
    }

    componentWillUnmount() {
        if (this.counter) {
            this.clearTimer();
        }
        this.mounted = false
    }

    floatTwoDecFromString(value) {
        let newValue = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
        newValue =
            newValue.indexOf('.') !== -1
                ? newValue.slice(0, newValue.indexOf('.') + 3)
                : newValue;
        return newValue;
    }

    onRowDataChange = (instrument) => {
        let symbolId = instrument.SB
        this.instrumentsMap[symbolId] = instrument;
        this.calculateTotal();
    };

    render() {
        const { isOpen1, isOpen2, isOpen3, isOpen4, isOpen5, isOpen6, basis, derivative, sumCostMarket, loadStatusReload, time, isCorebank, netAssetValueFDS, profitlostvm } = this.state
        const { accountQuickOrderInfo, defaultTheme } = this.props
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }

        const status = typeAccount === TYPE_ACCOUNT.FDS ? derivative && derivative.warning_status : basis && basis.status
        //Giá trị tài sản ròng
        const netAssetValue = typeAccount === TYPE_ACCOUNT.FDS ? netAssetValueFDS : (basis.balance + sumCostMarket - basis.totaldebtamt)
        const marginrate = typeAccount === TYPE_ACCOUNT.FDS ? (derivative && derivative.prsafe) : (basis && basis.marginrate)
        //console.log("marginrate---", this.state)
        let _records = _.cloneDeep(this.state.records)
        return (
            <RetryableContent status={this.state.loadStatus} disableRetry={true} >
                <div className='assets-container'>
                    {_records.length > 0 && _records.map((record, index) => {
                        return (
                            <div style={{ display: "none" }}>
                                <BlockInfoPortfolio
                                    typeAccount={typeAccount}
                                    key={record.accountId + '_' + record.symbol + '_' + index}
                                    index={index}
                                    record={record}
                                    keyActive={null}
                                    toogleHideShowInfo={null}
                                    onRowDataChange={this.onRowDataChange}
                                    onHandleBuy={null}
                                    onHandleSell={null}
                                    onHandleTPSL={null}
                                />
                            </div>
                        )
                    })}
                    <div className='block-1'>
                        {/* Giá trị tài sản ròng */}
                        <div className='row-block-1 txt---500-14-20'>
                            <div>
                                <FormattedMessage id="quick-order.assets.net-asset-value" />
                            </div>
                            <div>
                                <FormattedPrice value={netAssetValue ? netAssetValue : 0} />
                            </div>
                        </div>
                        {/* Tỷ lệ ký quỹ hiện tại */}
                        <div className='row-block-1 txt---500-14-20'>
                            <div>
                                {typeAccount === TYPE_ACCOUNT.BASE && <FormattedMessage id="quick-order.assets.current-margin" />}
                                {typeAccount === TYPE_ACCOUNT.FDS && <FormattedMessage id="quick-order.assets.current-margin-ccp" />}
                            </div>
                            <div>
                                <FormattedPrice shortForm={true} value={Number((marginrate ? marginrate : 0))} isNotThousand={true} suffix="%" />
                            </div>
                        </div>
                        {/* Trạng thái tiểu khoản */}
                        {/*<div className='row-block-1 txt---500-14-20'>
                            <div>
                                <FormattedMessage id="quick-order.assets.sub-account-status" />
                            </div>
                            <div>
                                {status === null ? "---" : status}
                            </div>
                        </div>*/}
                        <div className='block-wrap'>
                            <div className='block-content'>
                                <CustomScrollbars
                                    height={"calc(100vh - 310px) "}
                                    className="block-2-scroll-container">
                                    {/* Phái sinh */}
                                    {
                                        typeAccount === TYPE_ACCOUNT.FDS &&
                                        <div className='block-2'>
                                            {/* Tiền */}
                                            <div className='row-block-2 cursor-pointer row-block-first' onClick={() => this.setisOpen4()}>
                                                <div className='d-flex'>
                                                    <div className='container-icon'>
                                                        <img className={"effect-rotate-90" + (isOpen4 ? " effect-forward " : "")} src={defaultTheme === "dark" ? ArrowIconDark : ArrowIconLight} alt="" />
                                                    </div>
                                                    <div className='txt---400-14-20 label text-left text-left'>
                                                        <FormattedMessage id="quick-order.assets.d-money" />
                                                    </div>
                                                </div>
                                                <div className='txt---500-14-20 value'>
                                                    <FormattedPrice value={derivative.vcashonhand + derivative.vimcash + derivative.wsecured + derivative.wtransfer} />
                                                </div>
                                            </div>
                                            {
                                                isOpen4 &&
                                                <div>
                                                    {/* Tiền tại VIX */}
                                                    <div className='row-block-2'>
                                                        <div className='txt---400-14-20 label text-left'>
                                                            <FormattedMessage id="quick-order.assets.d-money-at-VIX" />
                                                        </div>
                                                        <div className='txt---500-14-20 value'>
                                                            <FormattedPrice value={derivative.vcashonhand} />
                                                        </div>
                                                    </div>
                                                    {/* Tiền ký quỹ CCP */}
                                                    <div className='row-block-2'>
                                                        <div className='txt---400-14-20 label text-left'>
                                                            <FormattedMessage id="quick-order.assets.d-deposit-CCP" />
                                                        </div>
                                                        <div className='txt---500-14-20 value'>
                                                            <FormattedPrice value={derivative.vimcash} />
                                                        </div>
                                                    </div>
                                                    {/* Tiền chờ ký quỹ */}
                                                    <div className='row-block-2'>
                                                        <div className='txt---400-14-20 label text-left'>
                                                            <FormattedMessage id="quick-order.assets.d-deposit-waiting-money" />
                                                        </div>
                                                        <div className='txt---500-14-20 value'>
                                                            <FormattedPrice value={derivative.wsecured} />
                                                        </div>
                                                    </div>
                                                    {/* Tiền ký quỹ chờ rút */}
                                                    <div className='row-block-2'>
                                                        <div className='txt---400-14-20 label text-left'>
                                                            <FormattedMessage id="quick-order.assets.d-deposit-waiting-for-withdrawal" />
                                                        </div>
                                                        <div className='txt---500-14-20 value'>
                                                            <FormattedPrice value={derivative.wtransfer} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {/* Lãi/lỗ VM */}
                                            <div className='row-block-2'>
                                                <div className='txt---400-14-20 label text-left ml-20'>
                                                    <FormattedMessage id="quick-order.assets.d-profit/loss-VM" />
                                                </div>
                                                <div className='txt---500-14-20 value'>
                                                    <FormattedPrice value={profitlostvm || 0} />
                                                </div>
                                            </div>
                                            {/* Phải trả */}
                                            <div className='row-block-2 cursor-pointer' onClick={() => this.setisOpen5()}>
                                                <div className='d-flex'>
                                                    <div className='container-icon'>
                                                        <img className={"effect-rotate-90" + (isOpen5 ? " effect-forward " : "")} src={defaultTheme === "dark" ? ArrowIconDark : ArrowIconLight} alt="" />
                                                    </div>
                                                    <div className='txt---400-14-20 label text-left'>
                                                        <FormattedMessage id="quick-order.assets.d-must-return" />
                                                    </div>
                                                </div>
                                                <div className='txt---500-14-20 value'>
                                                    <FormattedPrice value={derivative.mstpay} />
                                                </div>
                                            </div>
                                            {
                                                isOpen5 &&
                                                <div>
                                                    {/* Phí giao dịch */}
                                                    <div className='row-block-2'>
                                                        <div className='txt---400-14-20 label text-left'>
                                                            <FormattedMessage id="quick-order.assets.d-transaction-fee" />
                                                        </div>
                                                        <div className='txt---500-14-20 value'>
                                                            <FormattedPrice value={derivative.vtradefeeacr + derivative.vecafeeamt} />
                                                        </div>
                                                    </div>
                                                    {/* Phí vị thế cộng dồn */}
                                                    <div className='row-block-2'>
                                                        <div className='txt---400-14-20 label text-left'>
                                                            <FormattedMessage id="quick-order.assets.d-cumulative-position-fees" />
                                                        </div>
                                                        <div className='txt---500-14-20 value'>
                                                            <FormattedPrice value={derivative.vposfeeacr} />
                                                        </div>
                                                    </div>
                                                    {/* Phí ký quỹ cộng dồn */}
                                                    <div className='row-block-2'>
                                                        <div className='txt---400-14-20 label text-left'>
                                                            <FormattedMessage id="quick-order.assets.d-cumulative-deposit-fee" />
                                                        </div>
                                                        <div className='txt---500-14-20 value'>
                                                            <FormattedPrice value={derivative.vecafeeacr} />
                                                        </div>
                                                    </div>
                                                    {/* Thuế TNCN */}
                                                    <div className='row-block-2'>
                                                        <div className='txt---400-14-20 label text-left'>
                                                            <FormattedMessage id="quick-order.assets.d-income-tax" />
                                                        </div>
                                                        <div className='txt---500-14-20 value'>
                                                            <FormattedPrice value={derivative.incometax} />
                                                        </div>
                                                    </div>
                                                    {/* Phí SMS */}
                                                    <div className='row-block-2'>
                                                        <div className='txt---400-14-20 label text-left'>
                                                            <FormattedMessage id="quick-order.assets.d-SMS-fee" />
                                                        </div>
                                                        <div className='txt---500-14-20 value'>
                                                            <FormattedPrice value={derivative.smsmatchfeeacr} />
                                                        </div>
                                                    </div>
                                                    {/* Tổng nợ thấu chi */}
                                                    <div className='row-block-2'>
                                                        <div className='txt---400-14-20 label text-left'>
                                                            <FormattedMessage id="quick-order.assets.d-total-overdraft-debt" />
                                                        </div>
                                                        <div className='txt---500-14-20 value'>
                                                            <FormattedPrice value={derivative.odintacr + derivative.voverdraftamt} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {/* Thặng dư ký quỹ */}
                                            {/* <div className='row-block-2'>
                                            <div className='txt---500-14-20 label text-left ml-20'>
                                                <FormattedMessage id="quick-order.assets.d-margin-surplus" />
                                            </div>
                                            <div className='txt---500-14-20 value'>
                                                <FormattedPrice value={derivative.pp} />
                                            </div>
                                        </div> */}
                                        </div>
                                    }
                                    {/* Cơ sở */}
                                    {typeAccount === TYPE_ACCOUNT.BASE && <div className='block-2'>
                                        {/* Tiền tại VIX */}
                                        <div className='row-block-2 cursor-pointer row-block-first' onClick={() => this.setisOpen1()}>
                                            <div className='d-flex'>
                                                <div className='container-icon'>
                                                    <img className={"effect-rotate-90" + (isOpen1 ? " effect-forward " : "")} src={defaultTheme === "dark" ? ArrowIconDark : ArrowIconLight} alt="" />
                                                </div>
                                                <div className='txt---400-14-20 label text-left title'>
                                                    <FormattedMessage id="quick-order.assets.money-at-vix" />
                                                </div>
                                            </div>
                                            <div className='txt---500-14-20 value'>
                                                {/* <FormattedPrice value={basis && basis.balance && basis.tdbalance ? (basis.balance - basis.tdbalance) : 0} /> */}
                                                <FormattedPrice value={this.getValidSumValue(basis.balance - basis.tdbalance - basis.tdintamt)} />
                                            </div>
                                        </div>
                                        {
                                            isOpen1 &&
                                            <div>
                                                {/* Tiền không kỳ hạn */}
                                                <div className='row-block-2'>
                                                    <div className='txt---400-14-20 label text-left text-near-active'>
                                                        <FormattedMessage id="quick-order.assets.unlimited-money" />
                                                    </div>
                                                    <div className='txt---500-14-20 text-near-active value'>
                                                        <FormattedPrice value={basis.cibalance} />
                                                    </div>
                                                </div>
                                                {/* Tiền bán CK chờ về */}
                                                <div className='row-block-2'>
                                                    <div className='txt---400-14-20 label text-left text-near-active'>
                                                        <FormattedMessage id="quick-order.assets.money-from-selling-securities-waiting-for-return" />
                                                    </div>
                                                    <div className='txt---500-14-20 text-near-active value'>
                                                        <FormattedPrice value={basis.receivingamt} />
                                                    </div>
                                                </div>
                                                {/* Tiền cổ tức chờ về */}
                                                <div className='row-block-2'>
                                                    <div className='txt---400-14-20 label text-left text-near-active'>
                                                        <FormattedMessage id="quick-order.assets.dividend-waiting-for-return" />
                                                    </div>
                                                    <div className='txt---500-14-20 text-near-active value'>
                                                        <FormattedPrice value={basis.careceiving} />
                                                    </div>
                                                </div>
                                                {/* Lãi tiền gửi chưa thanh toán */}
                                                <div className='row-block-2'>
                                                    <div className='txt---400-14-20 label text-left text-near-active'>
                                                        <FormattedMessage id="quick-order.assets.unpaid-deposit-interest" />
                                                    </div>
                                                    <div className='txt---500-14-20 text-near-active value'>
                                                        <FormattedPrice value={basis.interestbalanceweb} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/* iFlex */}
                                        {/* <div className='row-block-2' onClick={this.onRedirectScreenIflex} style={{ cursor: 'pointer' }}>
                                        <div className='txt---500-14-20 label text-left ml-20 text-secondary-1 text-redirect' >
                                            <FormattedMessage id="quick-order.assets.iFlex" />
                                        </div>
                                        <div className='txt---500-14-20 value'>
                                            <FormattedPrice value={basis.tdbalance} />
                                        </div>
                                    </div> */}
                                        {/* <div className='row-block-2 cursor-pointer' onClick={(e) => this.setisOpen6(e)}>
                                            <div className='d-flex'>
                                                <div className='container-icon'>
                                                    <img className={"effect-rotate-90" + (isOpen6 ? " effect-forward " : "")} src={defaultTheme === "dark" ? ArrowIconDark : ArrowIconLight} alt="" />
                                                </div>
                                                <div className='txt---500-14-20 label text-left text-secondary-1 text-redirect' onClick={this.onRedirectScreenIflex} >
                                                    <FormattedMessage id="quick-order.assets.iFlex" />
                                                </div>
                                            </div>
                                            <div className='txt---500-14-20 value'>
                                                <FormattedPrice value={this.getValidSumValue(basis.tdbalance + basis.tdintamt)} />
                                            </div>
                                        </div> */}
                                        {
                                            // isOpen6 &&
                                            // <div>
                                            //     {/* Số dư gốc */}
                                            //     <div className='row-block-2'>
                                            //         <div className='txt---500-14-20 label text-left text-near-active'>
                                            //             <FormattedMessage id="quick-order.assets.principal-balance" />
                                            //         </div>
                                            //         <div className='txt---500-14-20 text-near-active value'>
                                            //             <FormattedPrice value={basis.tdbalance} />
                                            //         </div>
                                            //     </div>
                                            //     {/* Lãi tạm tính */}
                                            //     <div className='row-block-2'>
                                            //         <div className='txt---500-14-20 label text-left text-near-active'>
                                            //             <FormattedMessage id="quick-order.assets.temporary-interest" />
                                            //         </div>
                                            //         <div className='txt---500-14-20 text-near-active value'>
                                            //             <FormattedPrice value={basis.tdintamt} />
                                            //         </div>
                                            //     </div>
                                            // </div>
                                        }
                                        {/* Chứng khoán */}
                                        <div className='row-block-2'>
                                            <div className='txt---400-14-20 label title text-left ml-20'>
                                                <FormattedMessage id="quick-order.assets.stock-sum" />
                                            </div>
                                            <div className='txt---500-14-20 value'>
                                                <FormattedPrice value={sumCostMarket} />
                                            </div>
                                        </div>
                                        {/* Phải trả */}
                                        <div className='row-block-2 cursor-pointer' onClick={() => this.setisOpen2()}>
                                            <div className='d-flex'>
                                                <div className='container-icon'>
                                                    <img className={"effect-rotate-90" + (isOpen2 ? " effect-forward " : "")} src={defaultTheme === "dark" ? ArrowIconDark : ArrowIconLight} alt="" />
                                                </div>
                                                <div className='txt---400-14-20 label title text-left'>
                                                    <FormattedMessage id="quick-order.assets.must-return" />
                                                </div>
                                            </div>
                                            <div className='txt---500-14-20 value'>
                                                <FormattedPrice value={basis.totaldebtamt} />
                                            </div>
                                        </div>
                                        {
                                            isOpen2 &&
                                            <div>
                                                {/* Nợ bảo lãnh */}
                                                <div className='row-block-2'>
                                                    <div className='txt---400-14-20 label text-left text-near-active'>
                                                        <FormattedMessage id="quick-order.assets.guarantee-debt" />
                                                    </div>
                                                    <div className='txt---500-14-20 value text-near-active'>
                                                        <FormattedPrice value={basis.t0debtamt} />
                                                    </div>
                                                </div>
                                                {/* Nợ vay ký quỹ */}
                                                <div className='row-block-2'>
                                                    <div className='txt---400-14-20 label text-left text-near-active'>
                                                        <FormattedMessage id="quick-order.assets.margin-loan" />
                                                    </div>
                                                    <div className='txt---500-14-20 value text-near-active'>
                                                        <FormattedPrice value={basis.marginamt} />
                                                    </div>
                                                </div>
                                                {/* Nợ ứng trước */}
                                                <div className='row-block-2'>
                                                    <div className='txt---400-14-20 label text-left text-near-active'>
                                                        <FormattedMessage id="quick-order.assets.advance-debt" />
                                                    </div>
                                                    <div className='txt---500-14-20 value text-near-active'>
                                                        <FormattedPrice value={basis.advancedamt} />
                                                    </div>
                                                </div>
                                                {/* Nợ tiền mua CK trong ngày */}
                                                <div className='row-block-2'>
                                                    <div className='txt---400-14-20 label text-left text-near-active'>
                                                        <FormattedMessage id="quick-order.assets.debt-to-buy-securities-during-the-day" />
                                                    </div>
                                                    <div className='txt---500-14-20 value text-near-active'>
                                                        <FormattedPrice value={basis.secureamt} />
                                                    </div>
                                                </div>
                                                {/* Nợ phí lưu ký */}
                                                <div className='row-block-2'>
                                                    <div className='txt---400-14-20 label text-left text-near-active'>
                                                        <FormattedMessage id="quick-order.assets.debt-custody-fee" />
                                                    </div>
                                                    <div className='txt---500-14-20 value text-near-active'>
                                                        <FormattedPrice value={basis.cidepofeeacr} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* Thặng dư ký quỹ */}
                                        {/*<div className='row-block-2'>
                                            <div className='txt---500-14-20 label text-left ml-20'>
                                                <FormattedMessage id="quick-order.assets.margin-surplus" />
                                            </div>
                                            <div className='txt---500-14-20 value'>
                                                <FormattedPrice value={basis.pp0} />
                                            </div>
                                        </div>*/}
                                        {/* Tiền tại @BIDV */}
                                        {/*<div className='row-block-2 cursor-pointer' onClick={() => this.setisOpen3()}>
                                            <div className='d-flex'>
                                                <div className='container-icon'>
                                                    <img className={"effect-rotate-90" + (isOpen3 ? " effect-forward " : "")} src={defaultTheme === "dark" ? ArrowIconDark : ArrowIconLight} alt="" />
                                                </div>
                                                <div className='txt---500-14-20 label text-left'>
                                                    <FormattedMessage id="quick-order.assets.money-at-bidv" />
                                                </div>
                                            </div>
                                            <div className='txt---500-14-20 value'>
                                                <FormattedPrice value={basis.bankavlbal + basis.holdbalance} />
                                                {isCorebank && <span style={{ paddingLeft: "5px" }} onClick={(e) => this.onHandleReload(e)}>
                                                    <img className={"effect-rotate-360" + (loadStatusReload === LoadStatus.LOADING ? " effect-loading " : "") + (time !== 0 ? " effect-loaded" : "")} src={defaultTheme === "dark" ? ReloadIconDark : ReloadIconLight} alt="icon-reload" />
                                                </span>}
                                            </div>
                                        </div>*/}
                                        {/*
                                            isOpen3 &&
                                            <div>

                                                <div className='row-block-2'>
                                                    <div className='txt---500-14-20 label text-left text-near-active'>
                                                        <FormattedMessage id="quick-order.assets.available-balance-at-the-bank" />
                                                    </div>
                                                    <div className='txt---500-14-20 text-near-active value'>
                                                        <FormattedPrice value={basis.bankavlbal} />
                                                    </div>
                                                </div>

                                                <div className='row-block-2'>
                                                    <div className='txt---500-14-20 label text-left text-near-active'>
                                                        <FormattedMessage id="quick-order.assets.blocked-balance" />
                                                    </div>
                                                    <div className='txt---500-14-20 text-near-active value'>
                                                        <FormattedPrice value={basis.holdbalance} />
                                                    </div>
                                                </div>
                                            </div>
                                        */}
                                        {/* Hạn mức vay tối đa */}
                                        <div className='row-block-2'>
                                            <div className='txt---400-14-20 label title text-left ml-20'>
                                                <FormattedMessage id="quick-order.assets.maximun-loan-limit" />
                                            </div>
                                            <div className='txt---500-14-20 value'>
                                                <FormattedPrice value={basis.mrcrlimitmax} />
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </CustomScrollbars>
                            </div>
                        </div>
                    </div>
                </div>
            </RetryableContent >

        );
    }
}

const mapStateToProps = state => {
    let _currentLayoutPageActive = "C&B"
    let _placeQuickOrder = state.orderNew.placeQuickOrder
    const placeQuickOrderCurrent = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive]
    const _accountQuickOrderInfo = placeQuickOrderCurrent && placeQuickOrderCurrent["accountQuickOrderInfo"]
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    return {
        userInfo: state.user.userInfo,
        curScreenModalActive: state.layout.curScreenModalActive,
        isOpenScreenModal: state.layout.isOpenScreenModal,
        currentLayoutPageActive: CommonWidgetUtils.getReplaceLayoutPageActive(),
        accountQuickOrderInfo: _accountQuickOrderInfo,
        defaultTheme,
        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        socketDTAction: state.socketRealtime.socketDTAction,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        // setIsOpenScreenModal: (isOpenScreenModal, curScreenModalActive) => dispatch(actions.setIsOpenScreenModal(isOpenScreenModal, curScreenModalActive))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Assets);
