import React, { Component } from 'react';
import CustomScrollbars from 'components/CustomScrollbars';
import { FormattedAmount, FormattedPrice } from 'components/Formating';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import RetryableContent from "../../../../components/RetryableContent";
import { tradeApiService } from "../../../../services";
import * as actions from "../../../../store/actions";
import { LoadStatus, TYPE_DAY, TYPE_SUBMIT_ORDER, CommonUtils, Role, TYPE_ORDER_CONDITION, orderActions } from "../../../../utils";

import CustomRowItem from './../../../../components/CustomRowItem/CustomRowItem';
import _ from "lodash";
import './TradeAuthQuickOrder.scss';
import TradeAuth2 from './../../../../components/TradeAuth2/TradeAuth2';
import moment from 'moment';
import config from '../../../../combineConfig';
import { FormattedQuantity, FormattedDate } from './../../../../components/Formating';
import { TYPE_ACCOUNT } from './../../../../utils/constants';

const actionType = {
    BUY: "NB",
    SELL: "NS"
};

const textTypeAction = {
    BUY: "buy",
    SELL: "sell"
};
class TradeAuthQuickOrder extends Component {

    propAttrs = {
        // props from redux
        // listAuthType: this.props.listAuthType,    // list các loại hình xác thực được phép chọn
        permissionInfo: this.props.permissionInfo,
        custodycd: this.props.custodycd,      // biến phục vụ TransAuth Component
        currentAccountId: this.props.currentAccountId,      // biến phục vụ TransAuth Component
        tradeAuthenticated: this.props.tradeAuthenticated,    // biến phục vụ TransAuth Component

        currentReconfirmOrderType: this.props.currentReconfirmOrderType,  // biến truyền vào loại sổ lệnh cần confirm là thường hay điều kiện
        showReconfirmOrder: this.props.showReconfirmOrder,    // biến truyền vào true khi người dùng tích enable xác thực 2 lớp
        currentCustomer: this.props.currentCustomer,

        //props từ component cha
        intl: this.props.intl,        // thu vien
        onFinish: this.props.onFinish,   // function callback để thực hiện đóng modal
        transFunction: this.props.transFunction,     // function callback dùng để initTransaction
    };

    state = {
        loadStatus: LoadStatus.NONE,
        listReconfirmOrder: this.props.listReconfirmOrder ? this.props.listReconfirmOrder : [],
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.state
        }
    };

    twoFactorAuthCb = (data) => {

        let a = this.props.triggerTradeQuickOrderAuthenCallback(data);
        //console.log('twoFactorAuthCb.:twoFactorAuthCb===', a)
    };

    onFinish = () => {
        const { onFinish } = this.propAttrs;
        if (onFinish) {
            onFinish();
        }
    };


    isBroker = () => {
        const { userinfo } = this.props;
        const role = userinfo ? userinfo.role : '';
        return role === Role.BROKER;
    };
    
    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { saveTradeAuthenticate, tradeAuthenticated, listIsOpenModal, saveTradeAuthenticated } = this.props;
        const { isSaveTradeAuthenticated } = listIsOpenModal
        if (!tradeAuthenticated) {
            if (isSaveTradeAuthenticated) saveTradeAuthenticated();
        } else {
            if (!isSaveTradeAuthenticated) saveTradeAuthenticated();
        };
    }

    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };

    renderValue = (data) => {
        //console.log("renderValue", data)
        const { showLabel, showValue, isQtty, isPrice, typeAccount, isDate, symbolCurrent, nvalue, isValueText, isToFixed, isNotThousand } = data;
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            if (isValueText) {
                return <FormattedMessage id={showValue} />
            } else {
                if (isQtty) {
                    return (
                        <FormattedQuantity value={showValue} />
                    )
                } else if (isPrice) {
                    return (
                        <FormattedPrice value={Number(nvalue ? showValue * nvalue : showValue)} shortForm={true} isNotThousand={true} />
                    )
                } else {
                    return (
                        <span>
                            {showValue}
                        </span>
                    )
                }
            }

        } else {
            if (isValueText) {
                return <FormattedMessage id={showValue} />
            } else {
                if (isQtty) {
                    return (
                        <FormattedQuantity value={showValue} />
                    )
                } else if (isPrice) {
                    if (isToFixed) {
                        return (
                            <FormattedPrice value={showValue} shortForm={true} isNotThousand={isNotThousand} />
                        )
                    }
                    return (
                        <FormattedPrice value={showValue} />
                    )
                } else if (isDate) {
                    return (
                        <FormattedDate value={showValue} />
                    )
                } else {
                    return (
                        <span>
                            {showValue}
                        </span>
                    )
                }
            }
        }
    }

    renderNormalOrderTable = () => {
        const { isCancel, isEdit, isPlaceOrder, accountQuickOrderInfo } = this.props
        if (isEdit) {
            return this.renderNormalOrderTableEdit()
        } else if (isCancel) {
            return this.renderNormalOrderTableCancel()
        } else if (isPlaceOrder)
            return this.renderNormalOrderTableOrder()
    }

    renderNormalOrderTableOrder = () => {
        const { listReconfirmOrder } = this.state
        const { quote, isEdit, isCancel, accountQuickOrderInfo, userinfo } = this.props;
        let _listReconfirmOrder = _.cloneDeep(listReconfirmOrder)

        if (_listReconfirmOrder.orderType === "limit") {
            _listReconfirmOrder.orderType = "LO"
        } else if (_listReconfirmOrder.orderType === "market") {
            _listReconfirmOrder.orderType = _listReconfirmOrder.subOrderType
            if (_listReconfirmOrder.action === orderActions.BUY) {
                _listReconfirmOrder.limitPrice = quote.ceiling
            } else if (_listReconfirmOrder.action === orderActions.SELL) {
                _listReconfirmOrder.limitPrice = quote.floor
            }
        }
        let { action, quantity, orderTypeShort, limitPrice,
            orderType, marketPrice, subOrderType, afacctnoext, symbolCurrent, validUntilOrder, fromDate, toDate } = { ..._listReconfirmOrder };
        const { currentAccountId, typeAccount, accounts, curCustomer } = { ...accountQuickOrderInfo }
        let filterAccount = accounts.find(item => item.id === currentAccountId)
        let _fromDate = moment(fromDate).format(config.app.SERVER_DATE_FORMAT)
        let _toDate = moment(toDate).format(config.app.SERVER_DATE_FORMAT)
        let custodycd = '';
        let fullname = ''
        if (this.isBroker()) {
            custodycd = curCustomer.custodycd || ''; // Môi giới sửa lệnh lấy tài khoản của khách hàng để hiển thị
            fullname = curCustomer.fullName || '';
        } else {
            custodycd = userinfo.custodycd || '';
            fullname = userinfo.fullname || '';
        }

        return (
            <>
                {validUntilOrder === TYPE_DAY.DURING_DAY &&
                    <div>
                        <CustomRowItem
                            showLabel="trade.order.custodycd2"
                            showValue={`${custodycd}`}
                        />
                        <CustomRowItem
                            showLabel="trade.order.customer"
                            showValue={`${fullname}`}
                        />
                        <CustomRowItem
                            showLabel="trade.order.account"
                            showValue={`${filterAccount.cdcontent}`}
                        />
                        <CustomRowItem
                            showLabel="trade.order.symbol"
                            showValue={symbolCurrent && symbolCurrent.id}
                        />
                        <CustomRowItem
                            showLabel="trade.order.side"
                            showValue={action && action === textTypeAction.BUY ? "trade.order.buy" : "trade.order.sell"}
                            classNameValue={"text-uppercase " + (action === textTypeAction.BUY ? "text-green" : "text-red")}
                            isValueText={true}
                        />
                        <CustomRowItem
                            showLabel="trade.order.price-type"
                            showValue={orderType && orderType}
                        />
                        <CustomRowItem
                            showLabel="trade.order.price-ex"
                            showValue={limitPrice && limitPrice}
                            isPrice={true}
                            isToFixed={true}
                            typeAccount={typeAccount}
                        />
                        <CustomRowItem
                            showLabel="trade.order.place-qtty"
                            showValue={quantity && quantity}
                            isQtty={true}
                        />
                        <CustomRowItem
                            showLabel="trade.order.total-value"
                            showValue={parseFloat(limitPrice) * parseFloat(quantity)}
                            classNameValue={""}
                            isPrice={true}
                            typeAccount={typeAccount}
                            nvalue={symbolCurrent && symbolCurrent.nvalue}
                        />
                    </div>
                }
                {validUntilOrder === TYPE_DAY.MUCH_DAY &&
                    <div>
                        <CustomRowItem
                            showLabel="trade.order.account"
                            showValue={`${filterAccount.cdcontent}`}
                        />
                        <CustomRowItem
                            showLabel="trade.order.customer"
                            showValue={`${fullname}`}
                        />
                        <CustomRowItem
                            showLabel="trade.order.symbol"
                            showValue={symbolCurrent && symbolCurrent.id}
                        />
                        <CustomRowItem
                            showLabel="trade.order.side"
                            showValue={action && action === textTypeAction.BUY ? "trade.order.buy" : "trade.order.sell"}
                            classNameValue={"text-uppercase " + (action === textTypeAction.BUY ? "text-green" : "text-red")}
                            isValueText={true}
                        />
                        <CustomRowItem
                            showLabel="trade.order.order-type"
                            showValue={orderType && orderType}
                        />
                        <CustomRowItem
                            showLabel="trade.order.price"
                            showValue={limitPrice && limitPrice}
                            isPrice={true}
                            isToFixed={true}
                            typeAccount={typeAccount}
                        />
                        <CustomRowItem
                            showLabel="trade.order.qtty"
                            showValue={quantity && quantity}
                            isQtty={true}
                        />
                        <CustomRowItem
                            showLabel="trade.place-order.effective-date"
                            showValue={_fromDate}
                        />
                        <CustomRowItem
                            showLabel="trade.place-order.finish-date"
                            showValue={_toDate}
                        />
                    </div>
                }
            </>
        );
    };

    renderNormalOrderTableEdit = () => {
        const { listReconfirmOrder } = this.state
        const { quote, isEdit, isCancel, accountQuickOrderInfo, userinfo } = this.props;
        let _listReconfirmOrder = _.cloneDeep(listReconfirmOrder)
        const { editingOrder } = { ..._listReconfirmOrder }

        if (_listReconfirmOrder.orderType === "limit") {
            _listReconfirmOrder.orderType = "LO"
        } else if (_listReconfirmOrder.orderType === "market") {
            _listReconfirmOrder.orderType = _listReconfirmOrder.subOrderType
            if (_listReconfirmOrder.action === orderActions.BUY) {
                _listReconfirmOrder.limitPrice = quote.ceiling
            } else if (_listReconfirmOrder.action === orderActions.SELL) {
                _listReconfirmOrder.limitPrice = quote.floor
            }
        }
        // let { afacctno, symbol, side, orderType, pricetype, qtty, price, sideCode } = { ...editingOrder };
        let { action, quantity, orderTypeShort, limitPrice,
            orderType, marketPrice, subOrderType, afacctnoext, symbolCurrent } = { ..._listReconfirmOrder };

        const { currentAccountId, typeAccount, accounts, curCustomer } = { ...accountQuickOrderInfo }

        // let custodycd = userinfo.custodycd;
        // if (this.isBroker()) {
        //     custodycd = editingOrder.custodycd // Môi giới sửa lệnh lấy tài khoản của khách hàng để hiển thị
        // }

        let custodycd = '';
        let fullname = ''
        if (this.isBroker()) {
            custodycd = editingOrder.custodycd || ''; // Môi giới sửa lệnh lấy tài khoản của khách hàng để hiển thị
            fullname = curCustomer.fullName || '';
        } else {
            custodycd = userinfo.custodycd || '';
            fullname = userinfo.fullname || '';
        }
        let filterAccount = accounts.find(item => item.id === currentAccountId)
        // //console.log("renderNormalOrderTableEdit", parseFloat(limitPrice) * parseFloat(quantity))
        return (
            <>
                <div>
                    <CustomRowItem
                        showLabel="trade.order.custodycd2"
                        showValue={`${custodycd || ''}`}
                    />
                    <CustomRowItem
                        showLabel="trade.order.customer"
                        showValue={`${fullname}`}
                    />
                    <CustomRowItem
                        showLabel="trade.order.account"
                        showValue={`${filterAccount.cdcontent}`}
                    />
                    <CustomRowItem
                        showLabel="trade.order.symbol"
                        showValue={symbolCurrent && symbolCurrent.id}
                    />
                    <CustomRowItem
                        showLabel="trade.order.side"
                        showValue={action && action === textTypeAction.BUY ? "trade.order.buy" : "trade.order.sell"}
                        classNameValue={"text-uppercase " + (action === textTypeAction.BUY ? "text-green" : "text-red")}
                        isValueText={true}
                    />
                    <CustomRowItem
                        showLabel="trade.order.price-type"
                        showValue={orderType && orderType}
                    />
                    <CustomRowItem
                        showLabel="trade.order.price-ex"
                        showValue={limitPrice && limitPrice}
                        isPrice={true}
                        isToFixed={true}
                        typeAccount={typeAccount}
                    />
                    <CustomRowItem
                        showLabel="trade.order.place-qtty"
                        showValue={quantity && quantity}
                        isQtty={true}
                    />
                    <CustomRowItem
                        showLabel="trade.order.total-value"
                        showValue={parseFloat(limitPrice) * parseFloat(quantity)}
                        classNameValue={""}
                        typeAccount={typeAccount}
                        isTotal={true}
                        nvalue={symbolCurrent && symbolCurrent.nvalue}
                    />
                </div>
            </>
        );
    };

    renderNormalOrderTableCancel = () => {
        const { listReconfirmOrder } = this.state
        const { quote, isEdit, isCancel, accountQuickOrderInfo, userinfo } = this.props;
        let _listReconfirmOrder = _.cloneDeep(listReconfirmOrder)
        const { currentAccountId, typeAccount, accounts } = { ...accountQuickOrderInfo }
        let filterAccount = accounts.find(item => item.id === currentAccountId)
        let custodycd = _listReconfirmOrder.cancelingOrder && _listReconfirmOrder.cancelingOrder.length > 0 && _listReconfirmOrder.cancelingOrder[0]['custodycd'] || '';
        let fullname = _listReconfirmOrder.cancelingOrder && _listReconfirmOrder.cancelingOrder.length > 0 && _listReconfirmOrder.cancelingOrder[0]['fullname'] || '';
        return (
            <div>
                <CustomRowItem
                    showLabel="trade.order.custodycd2"
                    showValue={`${custodycd}`}
                />
                <CustomRowItem
                    showLabel="trade.order.customer"
                    showValue={`${fullname}`}
                />
                <CustomScrollbars
                    className="normal-order-cancel"
                    autoHeight={true}
                    autoHeightMin={200}
                    autoHeightMax={400}
                >
                    <table className="basic-table">
                        <thead>
                            <tr>
                                <th className="text-center"><FormattedMessage id='trade.order.order' /></th>
                                <th className="text-center"><FormattedMessage id='trade.order.account' /></th>
                                <th className="text-center"><FormattedMessage id='trade.order.symbol' /></th>
                                <th className="text-center"><FormattedMessage id='trade.order.qtty-short' /></th>
                                <th className="text-center"><FormattedMessage id='trade.order.price-short' /></th>
                                <th className="text-center"><FormattedMessage id='trade.order.amt-value' /></th>
                            </tr>
                        </thead>

                        <tbody>
                            {_listReconfirmOrder.cancelingOrder && _listReconfirmOrder.cancelingOrder.length > 0 && _listReconfirmOrder.cancelingOrder.map((orderInfo, index) => {

                                if (orderInfo.orderType === "limit") {
                                    orderInfo.orderType = "LO"
                                } else if (orderInfo.orderType === "market") {
                                    orderInfo.orderType = orderInfo.subOrderType
                                    if (orderInfo.action === orderActions.BUY) {
                                        orderInfo.limitPrice = quote.ceiling
                                    } else if (orderInfo.action === orderActions.SELL) {
                                        orderInfo.limitPrice = quote.floor
                                    }
                                }
                                let { action, quantity, orderTypeShort, symbolCurrent,
                                    orderType, marketPrice, subOrderType, afacctnoext, orderValue } = { ...orderInfo };
                                return (
                                    <tr key={index} >
                                        <td className={"text-center text-uppercase " + (action === textTypeAction.BUY ? "text-green" : "text-red")}>
                                            {action && action === textTypeAction.BUY ? <FormattedMessage id="trade.order.buy" /> : <FormattedMessage id="trade.order.sell" />}
                                        </td>
                                        <td className="text-center">{afacctnoext}</td>
                                        <td className="text-center">{symbolCurrent && symbolCurrent.id}</td>
                                        <td className="text-center"><FormattedAmount value={quantity} /></td>
                                        <td className="text-center">
                                            <FormattedPrice value={marketPrice} shortForm={true} isNotThousand={typeAccount === TYPE_ACCOUNT.FDS ? true : false} />
                                        </td>
                                        <td className="text-center">
                                            <FormattedPrice value={orderValue} shortForm={true} isNotThousand={typeAccount === TYPE_ACCOUNT.FDS ? true : false} />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </CustomScrollbars>
            </div>
        )
    };

    renderConditionOrderTable = () => {
        const { isCancel, isEdit, isPlaceOrder, accountQuickOrderInfo } = this.props
        if (isEdit) {

        } else if (isCancel) {
            return this.renderConditionOrderTableCancel()
        } else if (isPlaceOrder)
            return this.renderConditionOrderTableOrder()
    }


    renderConditionOrderTableOrder = () => {
        const { listReconfirmOrder } = this.state
        const { quote, isEdit, isCancel, accountQuickOrderInfo } = this.props;
        let _listReconfirmOrder = _.cloneDeep(listReconfirmOrder)

        let { typeOrderCondition, symbolCurrent, action } = { ..._listReconfirmOrder };
        const { stopPrice, quotePrice, quantity, fromDate, toDate, validUntilOrder,
            delta, priceStep,
            stopPriceLo,

        } = { ..._listReconfirmOrder[typeOrderCondition] }
        const { currentAccountId, typeAccount, accounts } = { ...accountQuickOrderInfo }
        let filterAccount = accounts.find(item => item.id === currentAccountId)
        let _fromDate = moment(fromDate).format(config.app.SERVER_DATE_FORMAT)
        let _toDate = moment(toDate).format(config.app.SERVER_DATE_FORMAT)
        return (
            <>
                {typeOrderCondition === TYPE_ORDER_CONDITION.STOP_LIMIT &&
                    <div>
                        <CustomRowItem
                            showLabel="trade.order.account"
                            showValue={`${filterAccount.cdcontent}`}
                        />
                        <CustomRowItem
                            showLabel="trade.order.symbol"
                            showValue={symbolCurrent && symbolCurrent.id}
                        />
                        <CustomRowItem
                            showLabel="trade.order.side"
                            showValue={action && action === textTypeAction.BUY ? "trade.order.buy" : "trade.order.sell"}
                            classNameValue={"text-uppercase " + (action === textTypeAction.BUY ? "text-green" : "text-red")}
                            isValueText={true}
                        />
                        <CustomRowItem
                            showLabel="trade.place-order.stopprice"
                            showValue={stopPrice && stopPrice}
                            isPrice={true}
                            typeAccount={typeAccount}
                            isToFixed={true}
                        />
                        <CustomRowItem
                            showLabel="trade.place-order.order-price"
                            showValue={quotePrice && quotePrice}
                            isPrice={true}
                            typeAccount={typeAccount}
                            isToFixed={true}
                        />
                        <CustomRowItem
                            showLabel="trade.place-order.mass"
                            showValue={quantity && quantity}
                            isQtty={true}
                        />
                        <CustomRowItem
                            showLabel="trade.place-order.effective-date"
                            showValue={_fromDate}
                        />
                        <CustomRowItem
                            showLabel="trade.place-order.finish-date"
                            showValue={validUntilOrder === TYPE_DAY.DURING_DAY ? _fromDate : _toDate}
                        />
                    </div>
                }
                {typeOrderCondition === TYPE_ORDER_CONDITION.TRAILING_STOP &&
                    <div>
                        <CustomRowItem
                            showLabel="trade.order.account"
                            showValue={`${filterAccount.cdcontent}`}
                        />
                        <CustomRowItem
                            showLabel="trade.order.symbol"
                            showValue={symbolCurrent && symbolCurrent.id}
                        />
                        <CustomRowItem
                            showLabel="trade.order.side"
                            showValue={action && action === textTypeAction.BUY ? "trade.order.buy" : "trade.order.sell"}
                            classNameValue={"text-uppercase " + (action === textTypeAction.BUY ? "text-green" : "text-red")}
                            isValueText={true}
                        />
                        <CustomRowItem
                            showLabel="trade.place-order.delta"
                            showValue={delta && (typeAccount === TYPE_ACCOUNT.BASE ? delta * 1000 : delta)}
                            isPrice={true}
                            typeAccount={typeAccount}
                            isToFixed={typeAccount === TYPE_ACCOUNT.FDS}
                            isNotThousand={true}
                        />
                        <CustomRowItem
                            showLabel="trade.place-order.step-price"
                            showValue={priceStep && priceStep}
                            isPrice={true}
                            typeAccount={typeAccount}
                            isToFixed={true}
                            isNotThousand={true}
                        />
                        <CustomRowItem
                            showLabel="trade.place-order.mass"
                            showValue={quantity && quantity}
                            isQtty={true}
                        />
                        <CustomRowItem
                            showLabel="trade.place-order.effective-date"
                            showValue={_fromDate}
                        />
                        <CustomRowItem
                            showLabel="trade.place-order.finish-date"
                            showValue={validUntilOrder === TYPE_DAY.DURING_DAY ? _fromDate : _toDate}
                        />
                    </div>
                }
                {typeOrderCondition === TYPE_ORDER_CONDITION.OCO &&
                    <div>
                        <CustomRowItem
                            showLabel="trade.order.account"
                            showValue={`${filterAccount.cdcontent}`}
                        />
                        <CustomRowItem
                            showLabel="trade.order.symbol"
                            showValue={symbolCurrent && symbolCurrent.id}
                        />
                        <CustomRowItem
                            showLabel="trade.order.side"
                            showValue={action && action === textTypeAction.BUY ? "trade.order.buy" : "trade.order.sell"}
                            classNameValue={"text-uppercase " + (action === textTypeAction.BUY ? "text-green" : "text-red")}
                            isValueText={true}
                        />
                        <div className="custom-row-oco">
                            <div className="custom-row-group">
                                <div className="custom-row-form row m-0 pb-0 pt-20"
                                    style={{ paddingTop: "20px" }}
                                >
                                    <div className="col-6 p-0">
                                        <div className="label text-green">
                                            LO
                                        </div>
                                    </div>
                                    <div className="col-6 p-0">
                                        <div className="value">

                                        </div>
                                    </div>
                                </div>
                                <div className="custom-row-form row m-0"
                                    style={{ paddingTop: "10px" }}
                                >
                                    <div className="col-6 p-0">
                                        <div className="label">
                                            <FormattedMessage id="trade.place-order.order-price" />
                                        </div>
                                    </div>
                                    <div className="col-6 p-0">
                                        <div className="value">
                                            {this.renderValue({
                                                showValue: stopPriceLo,
                                                isPrice: true,
                                                typeAccount: typeAccount,
                                                isToFixed: true,
                                                isNotThousand: false
                                            })}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="custom-row-oco">
                            <div className="custom-row-group">
                                <div className="custom-row-form row m-0 pb-0"
                                    style={{ paddingTop: "20px" }}
                                >
                                    <div className="col-6 p-0">
                                        <div className="label text-red">
                                            Stop Limit
                                        </div>
                                    </div>
                                    <div className="col-6 p-0">
                                        <div className="value">

                                        </div>
                                    </div>
                                </div>
                                <div className="custom-row-form row m-0 pb-0"
                                    style={{ paddingTop: "10px" }}
                                >
                                    <div className="col-6 p-0">
                                        <div className="label">
                                            <FormattedMessage id="trade.place-order.stopprice" />
                                        </div>
                                    </div>
                                    <div className="col-6 p-0">
                                        <div className="value">
                                            {this.renderValue({
                                                showValue: Number(stopPrice),
                                                isPrice: true,
                                                typeAccount: typeAccount,
                                                isToFixed: true,
                                                isNotThousand: false
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-row-form row m-0">
                                    <div className="col-6 p-0">
                                        <div className="label">
                                            <FormattedMessage id="trade.place-order.order-price" />
                                        </div>
                                    </div>
                                    <div className="col-6 p-0">
                                        <div className="value">
                                            {this.renderValue({
                                                showValue: quotePrice,
                                                isPrice: true,
                                                typeAccount: typeAccount,
                                                isToFixed: true,
                                                isNotThousand: false
                                            })}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="custom-row-group" >
                            <div className="custom-row-form row m-0"
                                style={{ paddingTop: "20px" }}
                            >
                                <div className="col-6 p-0">
                                    <div className="label">
                                        <FormattedMessage id="trade.place-order.mass" />
                                    </div>
                                </div>
                                <div className="col-6 p-0">
                                    <div className="value">
                                        {this.renderValue({
                                            showValue: quantity && quantity,
                                            isQtty: true,
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CustomRowItem
                            showLabel="trade.place-order.effective-date"
                            showValue={_fromDate}
                        />
                        <CustomRowItem
                            showLabel="trade.place-order.finish-date"
                            showValue={validUntilOrder === TYPE_DAY.DURING_DAY ? _fromDate : _toDate}
                        />
                    </div>
                }
            </>
        );
    };

    renderConditionOrderTableCancel = () => {
        const { listReconfirmOrder } = this.state
        const { quote, isEdit, isCancel, accountQuickOrderInfo } = this.props;
        let _listReconfirmOrder = _.cloneDeep(listReconfirmOrder)
        const { currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        let fullname = _listReconfirmOrder.cancelingOrder && _listReconfirmOrder.cancelingOrder.length > 0 && _listReconfirmOrder.cancelingOrder[0]['fullname'] || '';
        return (
            <CustomScrollbars
                className="normal-order-cancel"
                autoHeight={true}
                autoHeightMin={100}
                autoHeightMax={300}
            >
                <table className="basic-table">

                    <tbody>
                        {_listReconfirmOrder.cancelingOrder && _listReconfirmOrder.cancelingOrder.length > 0 && _listReconfirmOrder.cancelingOrder.map((orderInfo, index) => {

                            if (orderInfo.orderType === "limit") {
                                orderInfo.orderType = "LO"
                            } else if (orderInfo.orderType === "market") {
                                orderInfo.orderType = orderInfo.subOrderType
                                if (orderInfo.action === orderActions.BUY) {
                                    orderInfo.limitPrice = quote.ceiling
                                } else if (orderInfo.action === orderActions.SELL) {
                                    orderInfo.limitPrice = quote.floor
                                }
                            }
                            let { action, quantity, orderTypeShort, symbolCurrent,
                                orderType, marketPrice, subOrderType, afacctnoext } = { ...orderInfo };
                            return (
                                <tr key={index} >
                                    <td className={"text-center text-uppercase " + (action === textTypeAction.BUY ? "text-green" : "text-red")}>
                                        {action && action === textTypeAction.BUY ? <FormattedMessage id="trade.order.buy" /> : <FormattedMessage id="trade.order.sell" />}
                                    </td>
                                    <td className="text-center">{symbolCurrent && symbolCurrent.id}</td>
                                    <td className="text-center"><FormattedAmount value={quantity} /></td>
                                    <td className="text-center">
                                        <FormattedPrice value={marketPrice} shortForm={true} isNotThousand={typeAccount === TYPE_ACCOUNT.FDS ? true : false} />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </CustomScrollbars>
        )
    };


    renderOrderConfirmView = () => {
        const { listReconfirmOrder } = this.state;
        const { typeSubmitOrder, orderType, typeOrderCondition } = { ...listReconfirmOrder }
        if (typeSubmitOrder === TYPE_SUBMIT_ORDER.ORDER) {
            if (orderType === "limit") {
                return this.renderNormalOrderTable();
            } else if (orderType === "market") {
                return this.renderNormalOrderTable();
            } else if (orderType === "condition") {
                return this.renderConditionOrderTable();
            }
        } else if (typeSubmitOrder === TYPE_SUBMIT_ORDER.DRAP_ORDER) {

        }
    };

    processTradeAuth = (authInfo) => {
        return new Promise(function (resolve, reject) {
            return resolve(authInfo);
        });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { listIsOpenModal } = nextProps
        const { loadStatusQuickOrderModalTrade } = listIsOpenModal
        this.setLoadStatus(loadStatusQuickOrderModalTrade ? loadStatusQuickOrderModalTrade : this.state.loadStatus)
    }

    getConfirmBtnClass() {
        const { listReconfirmOrder } = this.state
        let _listReconfirmOrder = _.cloneDeep(listReconfirmOrder);
        let btnConfirmClass = '';
        if (_listReconfirmOrder.action === orderActions.BUY || listReconfirmOrder.action === orderActions.SELL) {
            btnConfirmClass = CommonUtils.getClassbyAction(_listReconfirmOrder.action);
        }
        return btnConfirmClass;
    }

    render() {
        const {
            autoCb,
            transFunction
        } = this.propAttrs; // Chỉ dành cho các props không thay đổi giá trị
        const { tradeAuthenticated, tradeAuthShowConfirm, accounts, role, listIsOpenModal, isPlaceOrder } = this.props;
        const { dataShow } = listIsOpenModal
        const genAuthFunc = tradeApiService.resendTwoFactorCode;    // gen lại mã xác thực ở tradeApi
        const twoFactorAuthFunc = this.processTradeAuth // check biến (transFunction) để biết xem nên gọi function validate mã xác thực ở server nào (trade, transaction)
        const initAuthFunc = tradeApiService.initTwoFactorAuth; // check biến (transFunction) để biết xem nên gọi function init Transaction (trade, transaction)
        let btnConfirmClass = '';
        if (isPlaceOrder) {
            btnConfirmClass = this.getConfirmBtnClass();
        }
        return (
            <RetryableContent status={this.state.loadStatus} disableRetry={true} className="trade-auth-quick-order">
                <form onSubmit={e => { e.preventDefault(); }}>
                    {this.renderOrderConfirmView()}
                    <TradeAuth2
                        containerClass="row gutters-5 align-items-center auth-container"
                        labelClass="col-5"
                        inputClass="col-7 custom-form-group"
                        inputClassAuth="col-7 custom-form-group-effect"
                        btnContainerClass=""
                        onFinish={this.onFinish}

                        initAuthFunc={initAuthFunc}

                        genAuthFunc={genAuthFunc}

                        twoFactorAuthFunc={twoFactorAuthFunc}

                        twoFactorAuthCb={this.twoFactorAuthCb}

                        setLoadStatus={status => this.setLoadStatus(status)}

                        autoCb={autoCb}
                        isDisableConfirmBtn={this.state.loadStatus == LoadStatus.LOADING ? true : false}
                        showOrderSwitch={true}
                        autoFocus={true}
                        fillPassword={true}

                        // tradeOrderInputMsg={this.props.tradeOrderInputMsg}
                        showContentNote={listIsOpenModal && listIsOpenModal.showContentNote}
                        currentReconfirmOrderType={this.propAttrs.currentReconfirmOrderType}
                        isOrder={true}
                        noteMessageId={dataShow && dataShow.noteMessageId}
                        noteMessageClass="txt---400-14-20 text-not-active font-italic"
                        btnConfirmClass={btnConfirmClass}
                    />

                </form>
            </RetryableContent >
        );
    }
}

const mapStateToProps = state => {
    const userinfo = state.user.userInfo;
    const permissionInfo = userinfo.permissionInfo ? userinfo.permissionInfo : null;

    let _currentLayoutPageActive = "C&B"
    let _placeQuickOrder = state.orderNew.placeQuickOrder
    let currentSymbol = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive] && _placeQuickOrder[_currentLayoutPageActive]["symbolCurrent"]

    let currentReconfirmOrderType = 'ORDER' // hardCode truyền
    return {
        listIsOpenModal: state.layout.listIsOpenModal["C&B"]["QuickOrder"],

        permissionInfo: permissionInfo,
        userinfo: userinfo,
        custodycd: userinfo.custodycd,
        tradeAuthenticated: state.app.tradeAuthenticated,

        currentReconfirmOrderType: currentReconfirmOrderType,
        language: state.app.language,
        currentCustomer: state.customer.currentCustomer,

        accounts: state.account.accounts,
        role: state.user.userInfo.role,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        triggerTradeQuickOrderAuthenCallback: (data) => dispatch(actions.triggerTradeQuickOrderAuthenCallback(data)),
        saveTradeAuthenticated: () => dispatch(actions.saveTradeAuthenticated()),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TradeAuthQuickOrder));