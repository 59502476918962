import React, { Component, Fragment } from "react";
import { push } from "connected-react-router";
import _ from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import $ from 'jquery';

// const ORDER_TYPES_KEY = { LIMIT: 'LIMIT', MARKET: 'MARKET', CONDITION: 'CONDITION' };
import { FormattedPrice } from '../../../components/Formating';
import { accountService, fdsInquiryService, inquiryService, logService, tradeApiService } from "../../../services";
import * as actions from "../../../store/actions";
import {
    ACTION_USER_ORDER,
    CommonUtils, CommonWidgetUtils,
    derivativeExchanges, EXCHANGE, fullOrderTypes, fullSubOrderTypes, initialQuickOrderInputState, LoadStatus, modules, ORDER_TYPE_USE, orderActions, PRECHECK_ORDER_STATUS, Random, Role, subOrderTypes, ToastUtil, TYPE_DAY, TYPE_SUBMIT_ORDER, KeyCodeUtils, ModalConfirmType,
    LanguageUtils
} from "../../../utils";

import QuantityInputOrder from './../../../components/Input/QuantityInputOrder';
import SearchSymbolQuickOrder from './SearchSymbolQuickOrder/SearchSymbolQuickOrder';

import './PlaceOrderQuickOrder.scss';
import BlockCapacityContainer from "./BlockCapacityContainer/BlockCapacityContainer";
import BlockPlacePrice from "./BlockPlacePrice/BlockPlacePrice";
import ConditionOrder from './ConditionOrder/ConditionOrder';
import DatePicker from './../../../components/Input/DatePicker';
import moment from "moment";
import userService from './../../../services/userService';
import config from '../../../combineConfig';
import RetryableContent from 'components/RetryableContent';
import { FOCUS_KEYS, LIST_TYPE_DEFAULT_TPRL_OR, TYPE_ACCOUNT } from './../../../utils/constants';
import IconCartDark from '../../../assets/icons_new/icon_cart_dark.svg';
import IconCartLight from '../../../assets/icons_new/icon_cart_light.svg';
import { emitter, FOCUS_QUANTITY_INPUT, FOCUS_PRICE_INPUT } from 'utils/EventEmitter';
import CustomScrollbars from "components/CustomScrollbars";
import shallowCompare from 'react-addons-shallow-compare';
import { PriceInput } from "components/Input";
import TextTooltip from "components/TextToolTip";

const listOrderType = [
    {
        title: "quick-order.place-order.order-types.limit",
        value: "limit",
    },
    {
        title: "quick-order.place-order.order-types.market",
        value: "market",
    },
    {
        title: "quick-order.place-order.order-types.condition",
        value: "condition",
    }
]


const listOrderTypeTPRLOR = [
    {
        title: "quick-order.place-order.transaction-reports",
        value: LIST_TYPE_DEFAULT_TPRL_OR.TRANSACTION_REPORTS,
    },
    {
        title: "quick-order.place-order.agreement",
        value: LIST_TYPE_DEFAULT_TPRL_OR.AGREEMENT,
    }
]

const listPaymentTPRLOR = [
    {
        title: "quick-order.place-order.payment-now",
        value: LIST_TYPE_DEFAULT_TPRL_OR.PAYMENT_NOW,
    },
    {
        title: "quick-order.place-order.payment-endday",
        value: LIST_TYPE_DEFAULT_TPRL_OR.PAYMENT_ENDDAY,
    }
]


class PlaceOrderQuickOrder extends Component {

    initialState = {
        loadStatus: LoadStatus.NONE,
        sysvar: null,

        //trang thai modal canh bao
        showRateWanningPopup: false,
        rateWanningCallback: null,

        //
        availableTrade: null,
        accountInfo: {},

        //validate gia thi truong sai vi gia thi truong sai ko dc set vao orderinput
        // isValidMarketPrice: true,

        msgErrQtty: "",
        msgErrPrice: "",
        currentTabIndex: '',
        priceFocus: false, // true khi ô giá đang được focus
        activePlaceOrderStatus: LoadStatus.NONE // Tạm thêm biến tránh nhấn enter khi chưa clear hết dữ liệu hoặc đang gọi api có loading
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
        this.priceOrderRef = React.createRef();
        this.quantityRef = React.createRef();
        this.splitQuantityRef = React.createRef();
        this.parentRef = React.createRef()
        this.symbolSearchRef = React.createRef()
        this.radioTypeDayRef = React.createRef()
        this.datePickerRef = React.createRef()
        this.btnPlaceOrderRef = React.createRef()
        this.selectTypeOrderMarketRef = React.createRef()

        this.selectTypeOrderConditionRef = React.createRef()
        this.selectTypeOrderTPRLORref = React.createRef()

        this.listenToTheEmitter();
    };

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    getSubOrderType = (config, exchange) => {
        const { marketOrder } = config;
        if (!marketOrder || marketOrder.length === 0) {
            return []
        }
        if (marketOrder) {
            for (let i = 0; i < marketOrder.length; i++) {
                const exchangeConfig = marketOrder[i];
                if (exchangeConfig.name === exchange) {
                    let allowedOrderTypes = _.split(exchangeConfig.value, '|');
                    // Filter empty values
                    allowedOrderTypes = _.filter(allowedOrderTypes, (value) => {
                        return value !== '';
                    });

                    const mapObject = _.mapKeys(allowedOrderTypes);
                    return _.filter(subOrderTypes, (element) => {
                        return mapObject[element] != null;
                    });
                }
            }
        }

        return [];
    };

    getSysvar = async () => {
        const { currentSymbol, config, updatePlaceQuickOrder, accountQuickOrderInfo, placeQuickOrderCurrent } = this.props
        const { typeAccount } = { ...accountQuickOrderInfo }
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            userService.getSysvarByTypeAccount(true)
                .then(responses => {
                    let [orderDate, orderPriceStep] = responses
                    if (orderDate) {
                        this._setState({ sysvar: orderDate[0] }
                            , () => { this.setDateDisplay() })
                    }
                }).catch(error => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                })
        }
        else {
            userService.getSysvarByTypeAccount(false)
                .then(responses => {
                    let [orderDate, orderPriceStep] = responses
                    if (orderDate) {
                        this._setState({ sysvar: orderDate[0] }
                            , () => { this.setDateDisplay() })
                    }
                }).catch(error => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                })
        }
    }

    checkPermission = () => {
        const { accountQuickOrderInfo, permissionInfo, userInfo } = this.props
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }
        if (userInfo.role === Role.CUSTOMER && currentAccountId && permissionInfo && permissionInfo.accounts) {
            return (permissionInfo.accounts[currentAccountId] && permissionInfo.accounts[currentAccountId][modules.ORDINPUT]) ? true : false
        }
        if (userInfo.role === Role.BROKER && userInfo && permissionInfo && permissionInfo.accounts) {
            return (permissionInfo.accounts[userInfo.custid] && permissionInfo.accounts[userInfo.custid][modules.ORDINPUT]) ? true : false
        }
    };

    shouldComponentUpdate(nextProps, nextState, snapshot) {
        const { currentSymbol } = this.state;
        const { currentSymbol: nextCurrentSymbol } = nextState;
        const { symbolWithIndex, quotes, instrument, marketInfos, ...otherProps } = this.props;
        const { symbolWithIndex: nextSymbolWithIndex, quotes: nextQuotes, instrument: nextInstrument, marketInfos: nextMarketInfos, ...nextOtherProps } = nextProps;

        // Chỉ render lại với một số trường của instrument thay đổi
        const currentInstrument = instrument ?
            {
                EX: instrument['EX'],
                ST: instrument['ST']
            }
            || {} : {};
        const nextInstrumentCompare = nextInstrument ?
            {
                EX: nextInstrument['EX'],
                ST: nextInstrument['ST']
            }
            || {} : {};

        // //console.log('laojahackgame=======> RENDER SHOULD', shallowCompare({ props: otherProps, state: this.state }, nextOtherProps, nextState)
        //     || JSON.stringify(currentInstrument) != JSON.stringify(nextInstrumentCompare));

        return shallowCompare({ props: otherProps, state: this.state }, nextOtherProps, nextState)
            || JSON.stringify(currentInstrument) != JSON.stringify(nextInstrumentCompare);
    }

    async componentDidMount() {
        this.mounted = true
        const self = this
        const { currentSymbol, config, updatePlaceQuickOrder, orderTypeUse, placeQuickOrderCurrent, focusOnRender } = this.props;
        const { orderType, editingOrder } = placeQuickOrderCurrent
        await this.getSysvar();
        if (currentSymbol) {
            const subOrderTypes = this.getSubOrderType(config, currentSymbol.exchange);
            if (orderType === "limit") {
                updatePlaceQuickOrder({
                    subOrderTypes: subOrderTypes,
                    subOrderType: fullSubOrderTypes.LO
                }, orderTypeUse)
            }

            if (currentSymbol.id) {
                this.props.fetchSymbolOrderbook(currentSymbol.id);
                CommonUtils.fetchApiInstrumentsBySymbol([currentSymbol.id], [orderTypeUse]) // thêm async để loadapi xong thì mới cập nhật giá vào input giá
            }
        };
        this.debounceLoadAvailableTradeData();


        if (focusOnRender === FOCUS_KEYS.PRICE_INPUT) {
            let timer = setTimeout(function () {
                self.focusPriceInput();
                clearTimeout(timer)
            }, 100);
            this.props.setFocusOnRenderOrder();
        }

        // window.addEventListener("keydown", event => {

        // });
        this.addFocusEvent();


    }



    callback = () => {
        this.priceOrderRef.current && this.priceOrderRef.current.focus();
    }

    callback1 = (data) => {
        let self = this
        let timer = setTimeout(function () {
            self.focusPriceInput();
            clearTimeout(timer)
        }, 100);
    }

    callback2 = (data) => {
        let self = this
        let timer = setTimeout(function () {
            self.focusQuantityInput();
            clearTimeout(timer)
        }, 100);
    }

    listenToTheEmitter() {
        // emitter.on("FOCUS_ORDER_PRICE", this.callback);
        emitter.on(FOCUS_PRICE_INPUT, this.callback1);
        emitter.on(FOCUS_QUANTITY_INPUT, this.callback2);

    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentWillUnmount() {
        // emitter.removeListener('FOCUS_ORDER_PRICE', this.callback);
        emitter.removeListener(FOCUS_PRICE_INPUT, this.callback1);
        emitter.removeListener(FOCUS_QUANTITY_INPUT, this.callback2);
        this.mounted = false
    }

    /**
     * Focus element trước đấy có thể focus sử dụng jquerry
     * @param {*} elementArr 
     * @param {*} prevElement 
     * @param {*} currentIndex 
     */
    focusBackElement = (elementArr, prevElement, currentIndex) => {
        const rect = prevElement.getBoundingClientRect();
        const isVisiblePrevItem = rect.bottom > 0 && rect.top < window.innerHeight;
        if (isVisiblePrevItem) {
            prevElement.focus();
        } else {
            // Nếu item không tim thấy ====> focus vào element gần nhất vẫn có thể focus được
            for (let j = currentIndex - 1; j >= 0; j--) {
                let rectItem = elementArr[j].getBoundingClientRect();
                let isVisibleItem = rectItem.bottom > 0 && rectItem.top < window.innerHeight;
                if (isVisibleItem) {
                    elementArr[j].focus();
                    break;
                }
            }
        }
    }

    /**
     * Focus lại element đầu tiên hoặc element gần đầu có thể focus được
     * @param {*} elementArr 
     * @param {*} firstElement 
     * @param {*} currentIndex 
     */
    focusFirstElement = (elementArr, firstElement) => {
        let rectFirstItem = firstElement.getBoundingClientRect();
        let isVisibleFirstItem = rectFirstItem.bottom > 0 && rectFirstItem.top < window.innerHeight;
        // //console.log("laojahackgame====> ALL ELEMENT RE TAB", firstElement, isVisibleFirstItem);
        if (isVisibleFirstItem) {
            // //console.log("laojahackgame====> ALL ELEMENT RE TAB 55555555");
            firstElement.focus();
        } else {
            for (let k = 1; k < elementArr.length; k++) {
                let rectItem = elementArr[k].getBoundingClientRect();
                let isVisibleItem = rectItem.bottom > 0 && rectItem.top < window.innerHeight;
                if (isVisibleItem) {
                    elementArr[k].focus();
                    break;
                }

            }
        }
    }

    addFocusEvent = () => {
        const { orderTypeUse } = this.props;
        var itemTabIndexs = [];
        let idOrderBlock = '';
        if (orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER) {
            idOrderBlock = "quick-order-block-to";
        }

        if (orderTypeUse === ORDER_TYPE_USE.NORMAL_ORDER) {
            idOrderBlock = "place-order-normal-trade";
        }

        itemTabIndexs = $(`#${idOrderBlock} [tabIndex]`)         // find div with ID attribute
            .map(function () {
                if (this.tabIndex != -1) {
                    return this;
                }
            }) // convert to set of itemTabIndexs
            .get();
        // //console.log('laojahackgame====> ALL ELEMENT', itemTabIndexs);
        if (itemTabIndexs.length > 0) {
            for (let i = 0; i < itemTabIndexs.length; i++) {
                itemTabIndexs[i].onfocus = () => {
                    //console.log("Handler for `focus` called." + i);
                }
                if (i > 0 && i !== itemTabIndexs.length - 1) {
                    let tagName = itemTabIndexs[i].tagName;
                    let type = itemTabIndexs[i].type;
                    if (tagName === "INPUT" && type === 'text') {
                        itemTabIndexs[i].onkeydown = (e) => {
                            // //console.log("laojahackgame====> ALL ELEMENT 123" + i, itemTabIndexs[i - 1]);
                            if (e.target.selectionStart === 0 && e.keyCode === 37) {
                                this.focusBackElement(itemTabIndexs, itemTabIndexs[i - 1], i);
                            }
                        }
                    } else {
                        itemTabIndexs[i].onkeydown = (e) => {
                            // //console.log("laojahackgame====> ALL ELEMENT 321" + i, itemTabIndexs[i - 1]);
                            if (e.keyCode === 37) {
                                this.focusBackElement(itemTabIndexs, itemTabIndexs[i - 1], i);
                            }
                        }
                    }


                }

                // Nếu tab đến item cuối cùng ===> Cho focus lại element đầu tiên trong ô đặt lệnh
                if (i === itemTabIndexs.length - 1) {
                    itemTabIndexs[i].onkeydown = (e) => {

                        const isShiftKey = e.shiftKey;
                        if (!isShiftKey && e.keyCode === 9) { // không chặn shifttab
                            e.preventDefault(); // chặn chức năng mặc định của tab để ghi đè chức năng này
                            this.focusFirstElement(itemTabIndexs, itemTabIndexs[0]);
                        }

                        // add chức năng focus lại khi nhấn mũi tên cho element focus cuối cùng
                        if (e.keyCode === 37) {
                            this.focusBackElement(itemTabIndexs, itemTabIndexs[i - 1], i);
                        }
                    }
                }
            }
        }
    }

    handlerLeftArrow = (event) => {
        const { currentTabIndex } = this.state;
        if (event && event.code === "ArrowLeft") {
            if (currentTabIndex && currentTabIndex != 0) {
                let focusIndex = currentTabIndex - 1;
                $(`[TabIndex*="${focusIndex}"]`) && $(`[TabIndex*="${focusIndex}"]`).focus();
            }
        }
    }


    ScrollToParentElement = () => {
        this.parentRef.current && this.parentRef.current.scrollIntoView()
    }

    focusQuantityInput = () => {
        this.quantityRef.current && this.quantityRef.current.focus();
    };


    focusSearchSymbol = () => {
        this.symbolSearchRef.current && this.symbolSearchRef.current.onHandleFocus();
    };

    focusRadioTypeDay = () => {
        this.radioTypeDayRef.current && this.radioTypeDayRef.current.focus();
    };

    focusDatePicker = () => {
        this.datePickerRef.current && this.datePickerRef.current.flatpickr.input.focus();
    };

    focusBtnPlaceOrder = () => {
        this.btnPlaceOrderRef.current && this.btnPlaceOrderRef.current.focus();
    };

    focusSelectTypeOrderMarket = () => {
        this.selectTypeOrderMarketRef.current && this.selectTypeOrderMarketRef.current.focus();
    }

    focusSelectTypeOrderCondition = () => {
        this.selectTypeOrderConditionRef.current && this.selectTypeOrderConditionRef.current.focus();
    }

    focusPriceInput = () => {
        this.priceOrderRef.current && this.priceOrderRef.current.focus({
            preventScroll: true
        });
        // VIX bị giật màn hình khi focus mặc dù ô giá nằm trong màn ==> fix comment
        // this.ScrollToParentElement()
    };

    setDateDisplay = () => {
        const { placeQuickOrderCurrent, currentSymbol } = this.props
        let currentDate = this.props.currentDate * 1000;
        const { sysvar } = this.state;
        const { validUntilOrder } = placeQuickOrderCurrent
        let _toDate = currentDate

        if (validUntilOrder === TYPE_DAY.MUCH_DAY) {
            // Nếu có mã rồi thì check xem có ngày đáo hạn không nếu có có thì lấy min((ngày hệ thống + số ngày tối đa), ngày đáo hạn)
            let _lastTradingDate = null
            let numberToMax = (sysvar && sysvar.varvalue * 24 * 60 * 60 * 1000) || 0 //number config flex
            let systemDate = currentDate + numberToMax
            let instruments = CommonUtils.getInstruments();
            const instrument = currentSymbol ? instruments[currentSymbol.symbol] : {};
            _lastTradingDate = (instrument && instrument["LTD"]) || ""
            // //console.log("setDateDisplay", currentDate, numberToMax, systemDate, instruments, _lastTradingDate)
            if (_lastTradingDate && CommonUtils.isDerivativeExchange(instrument["EX"])) {
                _toDate = Math.min(systemDate, CommonUtils.convertDateToTime(_lastTradingDate))
            } else {
                _toDate = systemDate
            }
        }

        // //console.log("setDateDisplay", currentDate, _toDate)
        this.props.updatePlaceQuickOrder({
            fromDate: currentDate,
            toDate: _toDate,
            toDateConst: _toDate
        }, this.props.orderTypeUse)
    }

    loadAvailableTradeData = (apiSeqAvailableTrade) => {
        const { currentSymbol, quote, accountQuickOrderInfo, placeQuickOrderCurrent } = this.props;

        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        const { action, limitPrice, quotePriceValid, subOrderType, isValidMarketPrice, orderType } = placeQuickOrderCurrent;
        if (!action || action === orderActions.DEFAULT) return; // Nếu không chọn action (case nhấn hủy sửa ====> Không gọi api available trade

        const ceilPrice = quote ? quote['ceiling'] : "";
        const floorPrice = quote ? quote['floor'] : "";

        if (currentAccountId) {
            this._setState({
                // availableTrade: null,
                // loadStatus: LoadStatus.LOADING,
            });

            let price = ""
            if (subOrderType == "LO" && quotePriceValid) {
                price = limitPrice;
            }
            else if (isValidMarketPrice && orderType === 'market') {
                price = action === orderActions.BUY ? ceilPrice : floorPrice;
            }
            //console.log("loadAvailableTradeData", placeQuickOrderCurrent, price, quote)

            if (typeAccount === TYPE_ACCOUNT.FDS) {
                if (currentSymbol) {
                    fdsInquiryService.getAvailableTrade(currentAccountId, currentSymbol ? currentSymbol.id : '', price)
                        .then((data) => {
                            if (apiSeqAvailableTrade < this.apiSeqAvailableTrade) return;
                            this._setState({
                                availableTrade: data,
                                // loadStatus: LoadStatus.LOADED,
                            });
                        })
                        .catch((error) => {
                            this._setState({
                                // loadStatus: LoadStatus.NONE,
                            });
                            ToastUtil.errorApi(error, 'common.fail-to-load-available-trade');
                        });
                } else {
                    fdsInquiryService.getCustomerSummary(currentAccountId)
                        .then((data) => {
                            if (apiSeqAvailableTrade < this.apiSeqAvailableTrade) return;
                            this._setState({
                                availableTrade: data[0],
                                // loadStatus: LoadStatus.LOADED,
                            });
                        })
                        .catch((error) => {
                            this._setState({
                                // loadStatus: LoadStatus.NONE,
                            });
                            ToastUtil.errorApi(error, 'common.fail-to-load-available-trade');
                        });
                }
            } else {
                inquiryService.getAvailableTrade(currentAccountId, currentSymbol ? currentSymbol.id : '', price, action)
                    .then((data) => {
                        if (apiSeqAvailableTrade < this.apiSeqAvailableTrade) return;
                        this._setState({
                            availableTrade: data,
                            // loadStatus: LoadStatus.LOADED,
                        });
                    })
                    .catch((error) => {
                        this._setState({
                            // loadStatus: LoadStatus.NONE,
                        });
                        ToastUtil.errorApi(error, 'common.fail-to-load-available-trade');
                    });
            }

        } else {
            this._setState({
                availableTrade: null,
                // loadStatus: LoadStatus.NONE,
            });
        }
    };

    getTabClasses = (actionSelect, action, isEditingOrder, editingAction) => {
        let classes = [(actionSelect === orderActions.BUY) ? 'focus-item tab-item buy-tab-item' : 'focus-item tab-item sell-tab-item'];
        if (action === actionSelect) {
            classes.push('active');
        }
        if (isEditingOrder && editingAction !== actionSelect) {
            classes.push('disabled');
        }
        if (action == orderActions.DEFAULT || action !== actionSelect || isEditingOrder) {
            classes = classes.filter(item => item !== 'active'); //nếu ko chọn action nào thì ko active
        }
        return classes.join(' ');
    };

    isOrderInputValid = () => {
        const {
            orderType, subOrderType,
            quotePriceValid, quantityValid, quantity, stepPriceValid
        } = { ...this.props.placeQuickOrderCurrent };

        return (orderType === 'limit' && quotePriceValid && stepPriceValid && quantityValid && quantity > 0)
            || (orderType === 'market' && quantityValid && quantity > 0)
    };

    onOrderActionSelected = (action, isEdittingOrder) => {
        const { updatePlaceQuickOrder, editOnly } = this.props
        if (editOnly) {
            return;
        }
        if (isEdittingOrder) {
            this.onReset();
            // return
        }
        updatePlaceQuickOrder({
            action: action,
        }, this.props.orderTypeUse)
    };

    onChangeActiveTypeOrder = (value, isEdittingOrder) => {
        const { updatePlaceQuickOrder, symbolCurrent } = this.props

        if (isEdittingOrder) {
            return
        }

        if (value === 'condition') {
            // ToastUtil.errorApi("", 'Tính năng chưa phát triển');
            updatePlaceQuickOrder({
                orderType: value,
            }, this.props.orderTypeUse)
        } else if (value === "limit") {
            updatePlaceQuickOrder({
                orderType: value,
            }, this.props.orderTypeUse)
        }
        else if (value === "market") {
            updatePlaceQuickOrder({
                orderType: value,
            }, this.props.orderTypeUse)
            this._setState({
                msgErrQtty: "",
                msgErrPrice: ""
            })
        }

        this.genListOrderPriceSelect(value)
    }

    genListOrderPriceSelect = (orderType) => {
        const { currentSymbol, config, updatePlaceQuickOrder, accountQuickOrderInfo, placeQuickOrderCurrent } = this.props
        const { typeAccount } = { ...accountQuickOrderInfo }
        let arrSelect = []
        if (currentSymbol) {
            arrSelect = this.getSubOrderType(config, currentSymbol.exchange);
            if (typeAccount === TYPE_ACCOUNT.FDS) {
                updatePlaceQuickOrder({
                    subOrderType: 'MAK',
                }, this.props.orderTypeUse)
            } else {
                if (orderType == "market") {
                    if (currentSymbol.exchange === "HOSE") {
                        updatePlaceQuickOrder({
                            subOrderType: 'MP',
                        }, this.props.orderTypeUse)
                    } else if (currentSymbol.exchange === "HNX") {
                        updatePlaceQuickOrder({
                            subOrderType: 'MAK',
                        }, this.props.orderTypeUse)
                    } else if (currentSymbol.exchange === "UPCOM") {
                        updatePlaceQuickOrder({
                            subOrderType: 'LO',
                        }, this.props.orderTypeUse)
                    } else {

                    }
                } else {
                    updatePlaceQuickOrder({
                        subOrderType: 'LO',
                    }, this.props.orderTypeUse)
                }
            }
        } else {
            if (typeAccount === TYPE_ACCOUNT.FDS) {
                updatePlaceQuickOrder({
                    subOrderType: 'MAK',
                }, this.props.orderTypeUse)
            }
            arrSelect = fullOrderTypes.filter((item, index) => {
                return item !== "LO" || item !== "MP"
            })
        }

        updatePlaceQuickOrder({
            subOrderTypes: arrSelect
        }, this.props.orderTypeUse)
    }

    onChangeValidUntilOrder = (value) => {
        const { currentSymbol, config, updatePlaceQuickOrder, accountQuickOrderInfo } = this.props
        const { typeAccount } = { ...accountQuickOrderInfo }
        const { orderType, editingOrder } = this.props.placeQuickOrderCurrent
        const isEdittingOrder = editingOrder != null;
        if (isEdittingOrder) {
            return
        }

        let _value = value
        updatePlaceQuickOrder({
            validUntilOrder: _value,
        }, this.props.orderTypeUse)
    }

    onCheckSplitOrder = (val) => {
        const { updatePlaceQuickOrder, accountQuickOrderInfo, setTradeOrderHeigh } = this.props
        const { editingOrder } = this.props.placeQuickOrderCurrent
        const isEdittingOrder = editingOrder != null;
        if (isEdittingOrder) {
            return
        }
        updatePlaceQuickOrder({
            isCheckSplitOrder: val,
        }, this.props.orderTypeUse)

        let timer = setTimeout(() => {
            if (this.props.orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER) {
                setTradeOrderHeigh && setTradeOrderHeigh();
            }
            clearTimeout(timer)
        }, 100);

        let timer2 = setTimeout(() => {
            this.addFocusEvent();
            clearTimeout(timer2)
        }, 300);
    }

    checkValidMarketPrice = (marketTypeList, price) => {
        if (!price || parseFloat(price)) return true;
        if (marketTypeList === undefined) return true;
        if (marketTypeList && marketTypeList.length > 0 && marketTypeList.includes(price)) {
            return true;
        }
        return false;
    }

    onPriceChanged = (price, valid, validStep, isValidMarketPrice = true) => {
        // console.log('laojahackgame===> ON PRICE CHANGE', { price, valid, validStep })
        if (CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER) { // Outright k check giá, mệnh giá, lô được phép đặt với mọi giá, KL 
            valid = true
            validStep = true
        }
        const { updatePlaceQuickOrder, config, currentSymbol } = this.props
        const { validUntilOrder, editingOrder, limitPrice } = this.props.placeQuickOrderCurrent;
        const subOrderTypesSelects = currentSymbol ? this.getSubOrderType(config, currentSymbol.exchange) : [];

        const isEdittingOrder = editingOrder != null;
        // const isValidMarketPriceToSet = subOrderTypesSelects && subOrderTypesSelects.length > 0 ? ((!price || parseFloat(price)) ? true : subOrderTypesSelects.includes(price)) : (!price || parseFloat(price) ? true : false);
        const isValidMarketPriceToSet = this.checkValidMarketPrice(subOrderTypesSelects, price);
        if (subOrderTypesSelects.includes(price) && !isEdittingOrder) {
            // Case giá market đúng
            // this.onChangePriceSubOrderType(price, isEdittingOrder)
            // this._setState({ isValidMarketPrice: true });
            updatePlaceQuickOrder({
                orderType: 'market',
                subOrderType: price,
                limitPrice: price ? price : limitPrice,
                isValidMarketPrice: isValidMarketPriceToSet,
            }, this.props.orderTypeUse)
        } else {
            // this._setState({ isValidMarketPrice: false });
            //console.log("updatePlaceQuickOrder_onPriceChanged", price, valid, validStep)
            if (validUntilOrder === TYPE_DAY.DURING_DAY) {
                updatePlaceQuickOrder({
                    limitPrice: price || '',
                    quotePriceValid: parseFloat(price) ? valid : (isEdittingOrder ? false : true),
                    stepPriceValid: parseFloat(price) ? validStep : true,
                    orderType: "limit",
                    subOrderType: fullSubOrderTypes.LO,
                    isValidMarketPrice: isValidMarketPriceToSet,
                }, this.props.orderTypeUse)
            } if (validUntilOrder === TYPE_DAY.MUCH_DAY) {
                updatePlaceQuickOrder({
                    limitPrice: price || '',
                    quotePriceValid: isEdittingOrder ? false : true,
                    stepPriceValid: parseFloat(price) ? validStep : true,
                    orderType: "limit",
                    subOrderType: fullSubOrderTypes.LO,
                    isValidMarketPrice: isValidMarketPriceToSet,
                }, this.props.orderTypeUse)
            }
        }
    };


    apiSeqAvailableTrade = new Date().getTime();
    debounceLoadAvailableTradeData = _.debounce(() => {
        this.apiSeqAvailableTrade = new Date().getTime();
        this.loadAvailableTradeData(this.apiSeqAvailableTrade);
    }, 100);

    debounceLoadAvailableTradeDataBySocket = _.debounce(() => {
        this.apiSeqAvailableTrade = new Date().getTime();
        this.loadAvailableTradeData(this.apiSeqAvailableTrade);
    }, 50);

    onQuantityChanged = (quantity, valid) => {
        if (CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER) { // Outright k check giá, mệnh giá, lô được phép đặt với mọi giá, KL 
            valid = true
        }
        const { updatePlaceQuickOrder, setTradeOrderHeigh } = this.props
        updatePlaceQuickOrder({
            quantity: quantity,
            quantityValid: valid
        }, this.props.orderTypeUse)
        let timer = setTimeout(() => {
            if (this.props.orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER) {
                setTradeOrderHeigh && setTradeOrderHeigh();
            }
            clearTimeout(timer)
        }, 100);
    };

    onSplitQuantityChanged = (quantity, valid) => {
        const { updatePlaceQuickOrder } = this.props
        updatePlaceQuickOrder({
            splitQuantity: quantity,
            splitQuantityValid: valid
        }, this.props.orderTypeUse)
    };

    onChangePriceOrderType = (e) => {
        const { updatePlaceQuickOrder } = this.props
        updatePlaceQuickOrder({
            subOrderType: e.target.value
        }, this.props.orderTypeUse)
    };

    onChangePriceSubOrderType = (item, isEdittingOrder) => {
        if (isEdittingOrder) return;
        const { updatePlaceQuickOrder } = this.props
        if (item === 'LO') {
            updatePlaceQuickOrder({
                limitPrice: "",
                orderType: 'limit',
                subOrderType: ''
            }, this.props.orderTypeUse)
        } else {
            updatePlaceQuickOrder({
                limitPrice: item,
                orderType: 'market',
                subOrderType: item
            }, this.props.orderTypeUse)
        }
    };

    /**
     * xử lý khi dữ liệu order đã clear sau khi đặt lệnh
     */
    clearOrderDataDoneHandler = () => {
        this.props.updatePlaceQuickOrder({
            isCheckClearOrder: false,
        }, this.props.orderTypeUse)
        this.props.setIsOpenModalHaveData("QuickOrder", {
            isOpenModalTradeQuickOrder: false,
        })
        let timer = setTimeout(() => {
            this._setState({ activePlaceOrderStatus: LoadStatus.LOADED });
            this.btnPlaceOrderRef.current && this.btnPlaceOrderRef.current.blur();
            this.props.setIsOpenModalHaveData("QuickOrder", {
                loadStatusQuickOrderModalTrade: LoadStatus.NONE,
                dataShow: {},
            })
            this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
            clearTimeout(timer)
        }, 0);
    }

    async componentDidUpdate(prevProps, prevState) {
        const { placeQuickOrderCurrent, config, updatePlaceQuickOrder, currentSymbol, accountQuickOrderInfo, orderTypeUse,
            socketPPAction, socketSEAction, socketDOAction, socketCIAction, isOpenModalTradeQuickOrder,
        } = this.props;
        const { action, editingOrder, toDate, validUntilOrder, isCheckFillPrice, limitPrice, isCheckClearOrder } = { ...placeQuickOrderCurrent }
        const { currentAccountId } = { ...accountQuickOrderInfo }

        const {
            currentSymbol: prevCurrentSymbol,
            placeQuickOrderCurrent: prevPlaceQuickOrderCurrent,
            accountQuickOrderInfo: prevAccountQuickOrderInfo,
            socketPPAction: prevsocketPPAction, socketSEAction: prevSocketSEAction,
            socketDOAction: prevsocketDOAction,
            socketCIAction: prevSocketCIAction,
            isOpenModalTradeQuickOrder: prevIsOpenModalTradeQuickOrder
        } = prevProps;

        const { action: prevAction, validUntilOrder: prevValidUntilOrder, limitPrice: prevLimitPrice, isCheckClearOrder: prevIsCheckClearOrder } = { ...prevPlaceQuickOrderCurrent }
        const { currentAccountId: prevCurrentAccountId } = { ...prevAccountQuickOrderInfo }
        const isEdittingOrder = editingOrder != null;
        if ((currentAccountId !== prevCurrentAccountId)) {
            //VIX: đổi tiểu khoản không clear mã và action,
            this.props.clearDataPlaceQuickOrder(orderTypeUse, ['symbolCurrent', 'action']);
            // this.props.updatedSymbolLayoutPage(null)
        }

        if ((currentAccountId !== prevCurrentAccountId || (action !== prevAction) || !(_.isEqual(currentSymbol, prevCurrentSymbol)))) {
            this.debounceLoadAvailableTradeData();
            if (currentSymbol && currentSymbol.id) {
                this.props.fetchSymbolOrderbook(currentSymbol.id);
            }
        }
        if ((socketPPAction[currentAccountId] !== prevsocketPPAction[currentAccountId]) ||
            (socketSEAction[currentAccountId] !== prevSocketSEAction[currentAccountId]) ||
            (socketDOAction[currentAccountId] !== prevsocketDOAction[currentAccountId]) ||
            (socketCIAction[currentAccountId] !== prevSocketCIAction[currentAccountId])
        ) {
            this.debounceLoadAvailableTradeDataBySocket();
        }

        if (isCheckClearOrder && (isCheckClearOrder !== prevIsCheckClearOrder)) {
            // Cờ clear dữ liệu để đóng màn đặt lệnh nhanh\
            this.clearOrderDataDoneHandler();
        }


        if (!isOpenModalTradeQuickOrder && prevIsOpenModalTradeQuickOrder && (prevIsOpenModalTradeQuickOrder != isOpenModalTradeQuickOrder)) {
            this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
        }
        if (!(_.isEqual(placeQuickOrderCurrent, prevPlaceQuickOrderCurrent))) {
            if ((!toDate) || (validUntilOrder !== prevValidUntilOrder)) {
                this.setDateDisplay()
            }

            if (!(_.isEqual(currentSymbol, prevCurrentSymbol))) {
                if (currentSymbol) {
                    const subOrderTypes = this.getSubOrderType(config, currentSymbol.exchange);
                    updatePlaceQuickOrder({
                        subOrderTypes: subOrderTypes
                    }, this.props.orderTypeUse)
                } else {
                    updatePlaceQuickOrder({
                        subOrderType: '',
                        subOrderTypes: []
                    }, this.props.orderTypeUse)
                };
            }
            else {
                // VIX: Không tự động fill giá vào ô giá khi đổi mã
                // //console.log("binh_componentDidUpdate_2", { currentSymbol, isCheckFillPrice, prevIsCheckFillPrice })
                // if (currentSymbol && isCheckFillPrice && !isEdittingOrder) {
                //     this.onFillPriceSymbolChange()
                // }
            }
        }


        // Case thay đổi giá đặt lệnh ngoài nhập
        if (limitPrice != prevLimitPrice) {
            this.debounceLoadAvailableTradeData(); //bỏ đoạn check trạng thái bên dưới để ko miss gọi lại api sức mua khi click giá từ ngoài vào đặt lệnh 
            // if (!this.state.priceFocus) {
            //     this.debounceLoadAvailableTradeData();
            // }
        }

        if (prevProps.isOpenModalTradeQuickOrder !== this.props.isOpenModalTradeQuickOrder && !this.props.isOpenModalTradeQuickOrder) {
            this._setState({ activePlaceOrderStatus: LoadStatus.NONE });
            this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
        }
    }


    onFillPriceSymbolChange = async () => {
        const { updatePlaceQuickOrder, currentSymbol, placeQuickOrderCurrent, quote } = this.props
        const { action, editingOrder, toDate, validUntilOrder, orderType, symbolTempCheck, limitPrice } = { ...placeQuickOrderCurrent }
        //console.log("binh_onFillPrice", placeQuickOrderCurrent)
        if (currentSymbol && currentSymbol.id && orderType == 'limit') {
            if (currentSymbol.id == symbolTempCheck) {
                if (limitPrice && Number(limitPrice) > 0) {
                } else {
                    let _limitPrice = 0;
                    let instruments = CommonUtils.getInstruments();
                    let instrument = instruments[currentSymbol.id]
                    if (instrument) {
                        _limitPrice = instrument["CP"] || 0
                    }

                    if (!_limitPrice) {
                        if (quote && quote["reference"]) {
                            _limitPrice = Number(quote["reference"])
                        }
                    }

                    updatePlaceQuickOrder({
                        limitPrice: _limitPrice || '',
                        quotePriceValid: true,
                        stepPriceValid: true,
                        subOrderType: 'LO',
                        isCheckFillPrice: false,
                    }, this.props.orderTypeUse)
                }
            }
        }
    }


    getPlaceOrderData = () => {
        // splitQuantity, splitQuantityValid, isCheckSplitOrder
        const { availableTrade, securitiesInfo, accountInfo } = this.state
        const { quote, accountQuickOrderInfo, currentSymbol } = this.props;
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }
        const { action, limitPrice, quantity, orderType,
            subOrderType, validUntilOrder, fromDate, toDate, splitQuantity, isCheckSplitOrder,
            editingOrder, typeOrderSelectdTPRLOR, custodycdPartnerTPRLOR, partyIdTPRLOR } = { ...this.props.placeQuickOrderCurrent };
        const isEdittingOrder = editingOrder != null;
        let ceilPrice = null;
        let floorPrice = null;
        if (quote != null) {
            ceilPrice = quote['ceiling'];
            floorPrice = quote['floor'];
        }
        let data = {}
        if (CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER) {
            // - type: string -Loại lệnh
            // - side: string -bên đặt lệnh
            // - symbol: string -mã ck
            // - price: integer -giá
            // - qtty: integer -kl
            // - paymenttype: string -phương thức thanh toán 
            // - custodycdPartner: string -tk đối ứng
            // - partyId: string -mã thành viên đối ứng

            // - nhập TK cùng công ty 072C002762, Mã TV: 072
            // - nhập TK khác công ty 020C000001, Mã TV: 020 

            //072C441100
            data = {
                type: typeOrderSelectdTPRLOR,
                side: action === orderActions.BUY ? 'buy' : 'sell',
                symbol: currentSymbol && currentSymbol.id || "",
                price: Number(limitPrice) || 0,
                qtty: Number(quantity),
                paymenttype: "1",
                custodycdPartner: custodycdPartnerTPRLOR,//"072C002762"
                partyId: partyIdTPRLOR || 0, // truyền 0 dưới DB mới hiểu là c truyền cho toàn cty chứng khoán trên thị trường 
            };

        } else {
            data = {
                //data truyền api
                instrument: currentSymbol && currentSymbol.id,
                side: action === orderActions.BUY ? 'buy' : 'sell',
                type: orderType,
                qty: Number(quantity),
            };
            if (isEdittingOrder) {
                data.orderid = editingOrder.odorderid || ''
            }

            let _fromDate = moment(new Date(fromDate)).format(config.app.SERVER_DATE_FORMAT)
            let _toDate = moment(new Date(toDate)).format(config.app.SERVER_DATE_FORMAT)
            if (validUntilOrder === TYPE_DAY.MUCH_DAY) {
                data.fromdate = _fromDate
                data.todate = _toDate
                data.qtty = Number(quantity) // Cho lệnh nhiều ngày
            }

            // precheck input cho chia lệnh
            if (isCheckSplitOrder) {
                data.qttyPerOrder = Number(splitQuantity);
            }

            switch (orderType) {
                case 'limit':
                    data.limitPrice = Number(limitPrice);
                    break;
                case 'market':
                    let _subOrderType = subOrderType
                    let _orderType = orderType
                    if (currentSymbol.exchange === "UPCOM") {
                        if (action === orderActions.BUY) {
                            _subOrderType = quote.ceiling
                        }
                        if (action === orderActions.SELL) {
                            _subOrderType = quote.floor
                        }
                        _orderType = "limit"
                    }
                    data.limitPrice = _subOrderType;
                    data.type = _orderType
                    break;
                default:
                    break;
            }
        }
        return data;
    }

    validateValue = () => {
        const { quote, accountQuickOrderInfo, placeQuickOrderCurrent, currentSymbol } = this.props
        const { quantityValid, quantity, action, orderType, quotePriceValid,
            limitPrice, stepPriceValid, validUntilOrder, editingOrder, actionUserOrder,
            fromDate, toDate, toDateConst, typeOrderSelectdTPRLOR, custodycdPartnerTPRLOR, partyIdTPRLOR, typePaymentTPRLOR } = placeQuickOrderCurrent;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        const isEdittingOrder = editingOrder != null;
        let ceilPrice = 0;
        let floorPrice = 0;
        if (quote != null) {
            if (typeAccount === TYPE_ACCOUNT.FDS) {
                ceilPrice = CommonUtils.divide(quote.ceiling, 1)
                floorPrice = CommonUtils.divide(quote.floor, 1)
            } else {
                ceilPrice = CommonUtils.divide(quote.ceiling, 1000)
                floorPrice = CommonUtils.divide(quote.floor, 1000)
            }
        }

        let _fromDate = new Date(fromDate)
        let _toDate = new Date(toDate);
        // let _toDateConst = new Date(toDateConst);
        _fromDate = _fromDate.setHours(0, 0, 0, 0);
        // _fromDate = _fromDate + 86400000 // + thêm 1 ngày để check điều kiện > hơn ngày hiện tại
        _toDate = _toDate.setHours(0, 0, 0, 0);
        // _toDateConst = _toDateConst.setHours(0, 0, 0, 0);

        // //console.log("validateValue1", _fromDate, _toDate, _toDateConst)

        if (placeQuickOrderCurrent) {
            if (currentSymbol) {

                if (!action) {
                    ToastUtil.error('FE004021');
                    return false
                }

                if (orderType == 'limit') {
                    if (limitPrice) {

                    } else {
                        ToastUtil.error('FE004002');
                        return false
                    }
                }

                if (orderType == 'limit') {
                    if (validUntilOrder === TYPE_DAY.DURING_DAY) {
                        if (!quotePriceValid && !!limitPrice) {
                            ToastUtil.error('FE004004');
                            return false
                        }
                    }
                    if (typeAccount === TYPE_ACCOUNT.BASE) {
                        if (!stepPriceValid) {
                            ToastUtil.error('FE004005')
                            return false
                        }
                    }
                }

                if (quantity) {
                    if (quantity == "0") {
                        ToastUtil.error('FE004003');
                        return false
                    }
                    if (!quantityValid) {
                        if (orderType == 'limit') {
                            ToastUtil.error('FE004006');
                            return false
                        } else if (orderType == 'market') {
                            ToastUtil.error('FE004013');
                            return false
                        }



                    }
                } else {
                    ToastUtil.error('FE004003');
                    return false
                }

                if (typeAccount === TYPE_ACCOUNT.FDS && isEdittingOrder) {
                    if ((Number(editingOrder['qtty']) != Number(quantity)) || (Number(editingOrder['price']) != Number(limitPrice))) {
                        return true
                    } else {
                        ToastUtil.error('FE004008');
                        return false
                    }
                }


                // if (validUntilOrder === TYPE_DAY.MUCH_DAY) {

                //     if (_fromDate && _toDate && _toDateConst) {
                //         if (_toDate > _toDateConst) {
                //             ToastUtil.error('FE003019');
                //             return false
                //         }
                //         if (_fromDate > _toDate) {
                //             ToastUtil.error('FE003020');
                //             return false
                //         }
                //     }
                // }


                if (validUntilOrder === TYPE_DAY.MUCH_DAY) {
                    if (_fromDate && _toDate) {
                        if (_fromDate > _toDate) {
                            ToastUtil.error('FE003020');
                            return false
                        }
                    }
                }

                if (CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER) {
                    if (typeOrderSelectdTPRLOR == LIST_TYPE_DEFAULT_TPRL_OR.TRANSACTION_REPORTS) {
                        if (!partyIdTPRLOR) {
                            ToastUtil.error('FE004023');
                            return false
                        }
                    }
                }
            } else {
                ToastUtil.error('FE004007');
                return false
            }
        }
        return true
    }

    onOpenWarnning = (title, warning, warning_en, onSubmit) => {
        this.props.updateConfirmCheckAuth({
            typeInfo: ModalConfirmType.SPLIT_ORDER_BY_EXCHANGE_WARNNING,
            dataInfo: {
                title: title,
                warning,
                warning_en,
                focusConfirm: true,
                onSubmit: onSubmit,
            },
            onClose: () => {
                this.props.setIsOpenModalHaveData("ScreenConfirm", {
                    isOpenScreenConfirm: false,
                    isCheckAuth2: false,
                })
                this._setState({ activePlaceOrderStatus: LoadStatus.NONE });
            }
        })

        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: true,
            isCheckAuth2: false,
        })
    }

    precheck = (doOrder) => {
        // splitQuantity, splitQuantityValid, isCheckSplitOrder
        const { userInfo } = this.props
        const { availableTrade, securitiesInfo, accountInfo } = this.state
        const { quote, accountQuickOrderInfo, intl } = this.props;
        const { editingOrder, action, quantity, validUntilOrder, isCheckSplitOrder } = { ...this.props.placeQuickOrderCurrent }
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }
        const isEdittingOrder = editingOrder != null;

        //validate
        if (!this.validateValue()) {
            this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
            return
        }
        try {
            let isBroker = this.isBroker();
            let requestId = (isBroker ? 'B' : 'C') + Random.randomOrderRequestId(userInfo.username)
            let data = this.getPlaceOrderData();
            this.btnPlaceOrderRef.current && this.btnPlaceOrderRef.current.blur();

            // VIX: vì giữ lại mã và mua bán sau khi đặt lệnh nên focus vào giá sau khi đặt
            let timer = setTimeout(() => {
                this.focusPriceInput();
                clearTimeout(timer)
            }, 0);

            CommonUtils.middlewareOrder(); // clear xác thực

            const precheckAndCb = () => {
                this._setState({ activePlaceOrderStatus: LoadStatus.LOADING });
                tradeApiService.initTwoFactorAuth()
                    .then((data) => {
                        if (data) {
                            this._setState({ loadStatus: LoadStatus.LOADED })
                            const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                            this.props.updateInfoCheckAuth({
                                transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                            })

                            this.props.setIsOpenModalHaveData("QuickOrder", {
                                isOpenModalTradeQuickOrder: true,
                                onTradeQuickOrderAuthenCallback: (authInfo) => {
                                    this.doPlaceOrder(isEdittingOrder, authInfo, requestId);
                                }
                            })
                        }
                    })
                    .catch(error => {
                        this._setState({ activePlaceOrderStatus: LoadStatus.FAIL });
                        this._setState({ loadStatus: LoadStatus.FAIL })
                        this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
                        ToastUtil.errorApi(error, 'CM2');
                    });
            };

            // { "qttyPerOrder": 20,
            //  "requestId": "1234567891011",
            //   "instrument": "AAA",
            //    "qty": 500,
            //     "side": "buy",
            //      "type": "limit",
            //       "limitPrice": "12000",
            //        "stopPrice": 0,
            //         "durationType": "",
            //          "durationDateTime": 0,
            //           "stopLoss": 0, 
            //           "takeProfit": 0,
            //            "digitalSignature": "" }

            this._setState({ loadStatus: LoadStatus.LOADING })

            if (CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER) {
                accountService.precheckOutrightOrder(currentAccountId, { ...data, requestId })
                    .then((data) => {
                        this._setState({ loadStatus: LoadStatus.LOADED })
                        precheckAndCb();
                    }).catch(error => {
                        this._setState({ loadStatus: LoadStatus.FAIL })
                        this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
                        if (error.errorCode === "-700137") { // FIX: Mã lỗi trạng thái tiểu khoản => chặn đặt lệnh (Fix nếu có thêm lỗi cần chặn)
                            ToastUtil.errorApi(error, isEdittingOrder ? 'common.fails-to-edit-order-broker' : 'common.fails-to-place-order-broker');
                        } else {
                            ToastUtil.errorApi(error, 'CM2');
                        }
                    });
                return
            }

            if (validUntilOrder === TYPE_DAY.DURING_DAY) {
                if (!isCheckSplitOrder) {
                    accountService.precheck(currentAccountId, { ...data, requestId })
                        .then((data) => {
                            // Kiểm tra hiển thị cảnh báo chia lệnh theo sàn (với đặt lệnh)
                            if (data) {
                                let warnningData = data;
                                const { warning, warning_en, warning1, warning1_en } = warnningData;
                                if (warning !== "0") {
                                    this._setState({ loadStatus: LoadStatus.LOADED })
                                    this.onOpenWarnning("trade.order.split-order.title", warning, warning_en, () => {
                                        // Nhấn đồng ý 
                                        // Nếu có warnning tiếp theo ===> mở warnning tiếp theo
                                        // Nếu không có warnning tiếp theo ===> đóng modal ===>  Tiếp tục đặt lệnh
                                        if (warning1 !== "0") {
                                            this.onOpenWarnning("trade.order.noti-major-shareholder", warning1, warning1_en, () => {
                                                this.props.setIsOpenModalHaveData("ScreenConfirm", {
                                                    isOpenScreenConfirm: false,
                                                })
                                                precheckAndCb();
                                            })
                                            return;
                                        }

                                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                                            isOpenScreenConfirm: false,
                                        })
                                        precheckAndCb();

                                    })
                                    // this.props.updateConfirmCheckAuth({
                                    //     typeInfo: ModalConfirmType.SPLIT_ORDER_BY_EXCHANGE_WARNNING,
                                    //     dataInfo: {
                                    //         title: "trade.order.split-order.title",
                                    //         warning,
                                    //         warning_en,
                                    //         focusConfirm: true,
                                    //         onSubmit: () => {
                                    //             // Nhấn đồng ý ===> đóng modal ===>  Tiếp tục đặt lệnh
                                    //             this.props.setIsOpenModalHaveData("ScreenConfirm", {
                                    //                 isOpenScreenConfirm: false,
                                    //             })
                                    //             precheckAndCb();
                                    //         },
                                    //     },
                                    //     onClose: () => {
                                    //         this.props.setIsOpenModalHaveData("ScreenConfirm", {
                                    //             isOpenScreenConfirm: false,
                                    //             isCheckAuth2: false,
                                    //         })
                                    //         this._setState({ activePlaceOrderStatus: LoadStatus.NONE });
                                    //     }
                                    // })

                                    // this.props.setIsOpenModalHaveData("ScreenConfirm", {
                                    //     isOpenScreenConfirm: true,
                                    //     isCheckAuth2: false,
                                    // })
                                    return;
                                }


                                if (warning1 !== "0") {
                                    this._setState({ loadStatus: LoadStatus.LOADED })
                                    this.onOpenWarnning("trade.order.noti-major-shareholder", warning1, warning1_en, () => {
                                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                                            isOpenScreenConfirm: false,
                                        })
                                        precheckAndCb();
                                    })
                                    return;
                                }
                            }

                            precheckAndCb();


                        }).catch(error => {
                            this._setState({ loadStatus: LoadStatus.FAIL })
                            this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
                            if (error.errorCode === "-700137") { // FIX: Mã lỗi trạng thái tiểu khoản => chặn đặt lệnh (Fix nếu có thêm lỗi cần chặn)
                                ToastUtil.errorApi(error, isEdittingOrder ? 'common.fails-to-edit-order-broker' : 'common.fails-to-place-order-broker');
                            } else {
                                ToastUtil.errorApi(error, 'CM2');
                            }
                        });
                } else {
                    // Chia lệnh (Chỉ đặt lệnh môi giới)
                    accountService.precheckSplitOrder(data)
                        .then(data => {
                            // //console.log('laojahackgame======> PRECHECK DATA', data);
                            precheckAndCb();
                        })
                        .catch(error => {
                            this._setState({ loadStatus: LoadStatus.FAIL })
                            this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
                            if (error.errorCode === "-700137") { // FIX: Mã lỗi trạng thái tiểu khoản => chặn đặt lệnh (Fix nếu có thêm lỗi cần chặn)
                                ToastUtil.errorApi(error, 'common.fails-to-place-order-broker');
                            } else {
                                ToastUtil.errorApi(error, 'CM2');
                            }
                        })
                }
            } else {
                accountService.precheckMuchDay(currentAccountId, data)
                    .then((data) => {
                        precheckAndCb();
                    }).catch(error => {
                        this._setState({ loadStatus: LoadStatus.FAIL })
                        this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
                        if (error.errorCode === "-700137") { // FIX: Mã lỗi trạng thái tiểu khoản => chặn đặt lệnh (Fix nếu có thêm lỗi cần chặn)
                            ToastUtil.errorApi(error, isEdittingOrder ? 'common.fails-to-edit-order-broker' : 'common.fails-to-place-order-broker');
                        } else {
                            ToastUtil.errorApi(error, 'CM2');
                        }
                    });
            }
        } catch (error) {
            this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
            logService.error({ typeOn: "PlaceOrderQuickOrder.js_precheck()", error: error && error.toString() })
        }
    }

    callApiUpdatePlaceOrder = (authInfo, requestId) => {
        const { quote, currentAccount, userInfo, accountQuickOrderInfo, setIsOpenModalHaveData } = this.props;
        const { limitPrice, quantity, orderType, subOrderType, editingOrder } = this.props.placeQuickOrderCurrent;
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }
        if (!currentAccountId) {
            return
        }

        let ceilPrice = null;
        let floorPrice = null;
        if (quote != null) {
            ceilPrice = quote['ceiling'];
            floorPrice = quote['floor'];
        }

        let data = {
            qty: Number(quantity),
            requestId,
        };

        switch (orderType) {
            case 'limit':
                data.limitPrice = Number(limitPrice);
                break;
            case 'market':
                data.limitPrice = subOrderType;
                break;
            default:
                break;
        }

        data = {
            ...data,
            ...authInfo,
            orderid: editingOrder['odorderid']
        };

        setIsOpenModalHaveData("QuickOrder", {
            loadStatusQuickOrderModalTrade: LoadStatus.LOADING,
        })
        tradeApiService.tradeUpdateOrder(currentAccountId, data)
            .then(() => {
                setIsOpenModalHaveData("QuickOrder", {
                    loadStatusQuickOrderModalTrade: LoadStatus.LOADED,
                })
                this._setState({ activePlaceOrderStatus: LoadStatus.LOADED });
                this.onFinishOrderUpdate();
                ToastUtil.success('trade.place-order.edit-order-success');
            })
            .catch((error) => {
                this._setState({ activePlaceOrderStatus: LoadStatus.FAIL });
                emitter.emit("RE_FOCUS_AUTH");
                setIsOpenModalHaveData("QuickOrder", {
                    loadStatusQuickOrderModalTrade: LoadStatus.FAIL,
                })
                ToastUtil.errorApi(error, 'trade.place-order.edit-order-fail');
                CommonUtils.clearTradeAuthInfo();
            });
    }

    handleSplitOrderSuccess = (dataRes) => {
        let { intl } = this.props
        let successList = dataRes.filter((item) => {
            return item["errcode"] == "0"
        })

        let errList = dataRes.filter((item) => {
            return item["errcode"] != "0"
        })

        if (successList.length > 0) {
            let successMsg = intl.formatMessage({ id: "trade.place-order.split-order-success" }, { numb: successList.length });
            ToastUtil.successRaw('trade.place-order.place-order-success', successMsg);
            // if (errmsgList && errmsgList.length > 0) ToastUtil.errorRaw('trade.place-order.place-order-fail', errmsgList, 15000);
        }

        if (errList.length > 0) {
            let errorMsg = intl.formatMessage({ id: "trade.place-order.split-order-false" }, { numb: errList.length });
            ToastUtil.errorRaw('trade.place-order.place-order-fail', errorMsg);
        }

        // descriptionTransfer = intl.formatMessage({ id: "account.utilities.cash-transfer.transfer-inner" }, { custodycd: custodycd || '', accountsend: accountSend ? (accountSend.cdcontent || '') : '', accountreceive: accountReceived ? (accountReceived.cdcontent || '') : '' });

    }

    callApiDoPlaceOrder = (authInfo, requestId) => {
        const { validUntilOrder, limitPrice, quantity, orderType, subOrderType, editingOrder, isCheckSplitOrder } = this.props.placeQuickOrderCurrent;
        const { currentSymbol, quote, accountQuickOrderInfo, currentAccount, userInfo, setIsOpenModalHaveData } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        //console.log("callApiDoPlaceOrder ===>", authInfo)
        let isBroker = this.isBroker();
        let data = this.getPlaceOrderData();
        let _data = { ...data, ...authInfo };
        // submit
        // LoadStatusQuicOrder
        setIsOpenModalHaveData("QuickOrder", {
            loadStatusQuickOrderModalTrade: LoadStatus.LOADING,
        })

        if (CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER) {
            _data.requestId = requestId
            tradeApiService.tradePlaceOutrightOrder(currentAccountId, _data)
                .then(() => {
                    ToastUtil.success('trade.place-order.place-order-success');
                    this.onFinishPlaceOrder();
                })
                .catch((error) => {
                    emitter.emit("RE_FOCUS_AUTH");
                    setIsOpenModalHaveData("QuickOrder", {
                        loadStatusQuickOrderModalTrade: LoadStatus.FAIL,
                    })
                    ToastUtil.errorApi(error, 'trade.place-order.place-order-fail');
                    CommonUtils.clearTradeAuthInfo();
                    this._setState({ activePlaceOrderStatus: LoadStatus.FAIL });
                });
        } else {
            // this._setState({ activePlaceOrderStatus: false });
            if (validUntilOrder === TYPE_DAY.DURING_DAY) {
                if (!isCheckSplitOrder) {
                    tradeApiService.tradePlaceOrder(currentAccountId, requestId, _data)
                        .then(() => {
                            ToastUtil.success('trade.place-order.place-order-success');
                            this.onFinishPlaceOrder();
                        })
                        .catch((error) => {
                            emitter.emit("RE_FOCUS_AUTH");
                            setIsOpenModalHaveData("QuickOrder", {
                                loadStatusQuickOrderModalTrade: LoadStatus.FAIL,
                            })
                            ToastUtil.errorApi(error, 'trade.place-order.place-order-fail');
                            CommonUtils.clearTradeAuthInfo();
                            this._setState({ activePlaceOrderStatus: LoadStatus.FAIL });
                        });
                } else {
                    tradeApiService.tradePlaceSplitOrder(currentAccountId, requestId, _data)
                        .then((data) => {
                            // //console.log('laojahackgame=====> DATA SPLIT ORDER ', data);
                            if (data && data.length > 0) {
                                this.handleSplitOrderSuccess(data)
                            }
                            this.onFinishPlaceOrder();
                        })
                        .catch((error) => {
                            emitter.emit("RE_FOCUS_AUTH");
                            setIsOpenModalHaveData("QuickOrder", {
                                loadStatusQuickOrderModalTrade: LoadStatus.FAIL,
                            })

                            ToastUtil.errorApi(error, 'trade.place-order.place-order-fail');
                            CommonUtils.clearTradeAuthInfo();
                            this._setState({ activePlaceOrderStatus: LoadStatus.FAIL });
                        });
                }
            } else if (validUntilOrder === TYPE_DAY.MUCH_DAY) {
                tradeApiService.tradePlaceOrderMuchDay(currentAccountId, requestId, _data)
                    .then(() => {
                        ToastUtil.success('trade.place-order.place-order-success');
                        this.onFinishPlaceOrder();
                    })
                    .catch((error) => {
                        this._setState({ activePlaceOrderStatus: LoadStatus.FAIL });
                        emitter.emit("RE_FOCUS_AUTH");
                        setIsOpenModalHaveData("QuickOrder", {
                            loadStatusQuickOrderModalTrade: LoadStatus.FAIL,
                        })
                        ToastUtil.errorApi(error, 'trade.place-order.place-order-fail');
                        CommonUtils.clearTradeAuthInfo();
                    });
            }
        }
    }

    validateOrderAndProcess = (authInfo, requestId) => {
        // let data = this.getPlaceOrderData();
        let isBroker = this.isBroker();
        // const requestId = (isBroker ? 'B' : 'C') + Random.randomOrderRequestId(userInfo.username);
        // data = { ...data, ...authInfo };
        if (isBroker) {
            // VIX: Mỗi lần môi giới đặt lệnh bị gọi lại api account để check ===> Gây đặt lệnh bị chậm ===> Off
            // this.handleCheckAccountInBroker(this.callApiDoPlaceOrder, authInfo, requestId);
            this.callApiDoPlaceOrder(authInfo, requestId);
        }
        else {
            this.callApiDoPlaceOrder(authInfo, requestId);
        }
    };


    validateOrderAndProcessEditOrder = (authInfo, requestId) => {
        let isBroker = this.isBroker();
        if (isBroker) {
            // this.handleCheckAccountInBroker(this.callApiUpdatePlaceOrder, authInfo, requestId);
            this.callApiUpdatePlaceOrder(authInfo, requestId);
        }
        else {
            this.callApiUpdatePlaceOrder(authInfo, requestId);
        }

    };

    doPlaceOrder = (isEdittingOrder, authInfo, requestId) => {
        if (isEdittingOrder) {
            this.validateOrderAndProcessEditOrder(authInfo, requestId);
        } else {
            this.validateOrderAndProcess(authInfo, requestId);
        }
    };

    placeOrder = () => {
        if (this.props.isMobile) window.scrollTo(0, 0);
        this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(true)
        const { availableTrade, securitiesInfo, accountInfo } = this.state
        const { quote, accountQuickOrderInfo, showReconfirmOrderConfig, orderTypeUse } = this.props;
        const { editingOrder, action, quantity, validUntilOrder, orderType, splitQuantity, splitQuantityValid, isCheckSplitOrder } = { ...this.props.placeQuickOrderCurrent }
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        const isEdittingOrder = editingOrder != null;

        if (isEdittingOrder) {
            this.props.updatePlaceQuickOrder({
                actionUserOrder: ACTION_USER_ORDER.EDIT_ORDER,
                typeSubmitOrder: TYPE_SUBMIT_ORDER.ORDER,
            }, orderTypeUse)
        } else {
            this.props.updatePlaceQuickOrder({
                actionUserOrder: ACTION_USER_ORDER.PLACE_ORDER,
                typeSubmitOrder: TYPE_SUBMIT_ORDER.ORDER,
            }, orderTypeUse)
        }


        if (orderType === 'market' && validUntilOrder === TYPE_DAY.MUCH_DAY) {
            ToastUtil.error('FE004001');
            this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
            return;
        }

        if (typeAccount === TYPE_ACCOUNT.BASE && validUntilOrder === TYPE_DAY.MUCH_DAY) {
            this.props.setIsOpenModalHaveData("QuickOrder", {
                dataShow: {
                    noteMessageId: ["CM000003"]
                },
            })
        } else {
            this.props.setIsOpenModalHaveData("QuickOrder", {
                dataShow: {},
            })
        }

        // let permission = this.checkPermission();

        if (currentAccountId == null) {
            this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
            return;
        }

        const instrument = {
            EX: quote && quote.exchange,
            ST: quote && quote.StockType
        }

        // if (instrument && instrument.EX === EXCHANGE.HOSE && instrument.ST === STOCKTYPE.BOND) {
        //     ToastUtil.error("common.symbol-not-allow-to-place");
        //     return;
        // }

        // Check sức mua (chỉ check với lệnh trong ngày)
        if (validUntilOrder === TYPE_DAY.DURING_DAY) {
            if (action === orderActions.BUY && !isEdittingOrder && (accountInfo['corebank'] !== 'Y')) { // trường hợp không phải tài khoản corebank thì check sức mua
                // if (config.app.IS_CHECK_TRADE_QTTY == true) {
                if ((instrument.EX === EXCHANGE.HNX || instrument.EX === EXCHANGE.UPCOM) && quantity < 100 && quantity > 0) {
                    // trường hợp đặt lệnh lô lẻ sàn HNX và UPCOM thì không check sức mua
                } else if (availableTrade && quantity > availableTrade.maxqtty) {
                    ToastUtil.error('common.over-max-buy-qty-err');
                    this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
                    return;
                }
                // }
            }
            // if (config.app.IS_CHECK_TRADE_QTTY == true) {
            if (action === orderActions.SELL && availableTrade && (isEdittingOrder ? quantity > (availableTrade.trade + editingOrder.qtty) : quantity > availableTrade.trade)) {
                ToastUtil.error('common.over-max-sell-qty-err');
                this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
                return;
            }
        }
        // }

        // Thông báo số lệnh chia nếu môi giới chọn chia lệnh
        const isBroker = this.isBroker();

        if (isBroker && isCheckSplitOrder) {
            if ((Number(splitQuantity) % 100) !== 0 || (Number(splitQuantity) > Number(quantity)) || !splitQuantityValid) {
                ToastUtil.error('FE004022');
                this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
                return;
            }

            if (!splitQuantity || splitQuantity === 0 || splitQuantity === '0') {
                ToastUtil.error('FE004022');
                this.props.setIsDisableEscapeClose && this.props.setIsDisableEscapeClose(false)
                return;
            }

            let orderNumber = Math.floor(Number(quantity) / Number(splitQuantity));
            let qttyPerOrder = Math.floor(Number(quantity) / orderNumber);
            let oddOrderQtty = Number(quantity) % Number(splitQuantity);
            let oddOrderNumber = 0;
            let sumOrderNumb = orderNumber;
            if (oddOrderQtty !== 0) {
                sumOrderNumb = orderNumber + 1;
                oddOrderNumber = 1;
                qttyPerOrder = (Number(quantity) - oddOrderQtty) / orderNumber
            }

            this.props.updateConfirmCheckAuth({
                typeInfo: ModalConfirmType.SPLIT_ORDER_WARNNING,
                dataInfo: {
                    title: "trade.order.split-order.title",
                    sumOrderNumb: sumOrderNumb,          // Tổng số lệnh
                    orderNumber: orderNumber,            // Số lệnh chia tròn
                    qttyPerOrder: qttyPerOrder,          // Số lượng mỗi lệnh chia tròn
                    oddOrderNumber: oddOrderNumber,      // Số lệnh chia lẻ
                    oddOrderQtty: oddOrderQtty,          // Khối lượng của lệnh chia lẻ
                    focusConfirm: true,
                    onSubmit: () => {
                        // Nhấn đồng ý ===> đóng modal ===>  Tiếp tục đặt lệnh
                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: false,
                        })
                        this.precheck();
                    },
                },
                onClose: () => {
                    this.props.setIsOpenModalHaveData("ScreenConfirm", {
                        isOpenScreenConfirm: false,
                        isCheckAuth2: false,
                    })
                    this._setState({ activePlaceOrderStatus: LoadStatus.NONE });
                }
            })

            this.props.setIsOpenModalHaveData("ScreenConfirm", {
                isOpenScreenConfirm: true,
                isCheckAuth2: false,
            })
            return;
        }

        this.precheck();

    }


    placeDraftOrder = () => {
        const { validUntilOrder, limitPrice, quantity, orderType, subOrderType, editingOrder } = this.props.placeQuickOrderCurrent;
        const { currentSymbol, quote, accountQuickOrderInfo, currentAccount, userInfo, setIsOpenModalHaveData } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }

        //validate
        if (!this.validateValue()) {
            return
        }

        let isBroker = this.isBroker();
        let data = this.getPlaceOrderData();
        const requestId = (isBroker ? 'B' : 'C') + Random.randomOrderRequestId(userInfo.username);
        this._setState({ loadStatus: LoadStatus.LOADING })

        tradeApiService.tradeCreateDraftOrder(currentAccountId, requestId, data)
            .then(() => {
                this._setState({ loadStatus: LoadStatus.LOADED })
                ToastUtil.success('FE004019');
                emitter.emit("LOAD_DRAFT_ORDER_BOOK");
            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.NONE })
                ToastUtil.errorApi(error, 'FE004020');
            });
    }

    onFinishPlaceOrder = () => {
        const { clearDataPlaceQuickOrder, setIsOpenModalHaveData } = this.props;
        const isBroker = this.isBroker();

        //VIX: Đặt lệnh giữ lại symbol và action , không xóa đi cho đến khi kh tự thay đổi
        clearDataPlaceQuickOrder(this.props.orderTypeUse, ['symbolCurrent', 'action']);

        this.props.updatePlaceQuickOrder({
            isCheckClearOrder: true,
        }, this.props.orderTypeUse)

        // if (isBroker) {
        //     clearDataPlaceQuickOrder(this.props.orderTypeUse, ['symbolCurrent', 'action']);
        // } else {
        //     clearDataPlaceQuickOrder(this.props.orderTypeUse);
        // }

        // this.props.updatePlaceQuickOrder({
        //     editingOrder: null,
        // }, this.props.orderTypeUse)

        // setIsOpenModalHaveData("QuickOrder", {
        //     isOpenModalTradeQuickOrder: false,
        //     loadStatusQuickOrderModalTrade: LoadStatus.NONE,
        //     dataShow: {},
        // })

    };


    onFinishOrderUpdate = () => {
        const { clearDataPlaceQuickOrder, setIsOpenModalHaveData, editOnly } = this.props
        clearDataPlaceQuickOrder(this.props.orderTypeUse, ['symbolCurrent', 'action']);
        // this.props.updatedSymbolLayoutPage(null)
        // this.props.updatePlaceQuickOrder({
        //     editingOrder: null,
        // }, this.props.orderTypeUse)
        if (editOnly) {
            setIsOpenModalHaveData("QuickOrder", {
                isOpenQuickOrder: false,
                isOpenModalTradeQuickOrder: false,
                loadStatusQuickOrderModalTrade: LoadStatus.NONE,
                dataShow: {},
            })
        } else {
            setIsOpenModalHaveData("QuickOrder", {
                isOpenModalTradeQuickOrder: false,
                loadStatusQuickOrderModalTrade: LoadStatus.NONE,
                dataShow: {},
            })
        }
        CommonUtils.middlewareOrder()
    };

    // onBlurQtty = () => {
    //     const { quantityValid, quantity } = { ...this.props.placeQuickOrderCurrent };

    //     if (!quantityValid && quantity) {
    //         this._setState({ msgErrQtty: "trade.place-order.invalid-quantity-message" })
    //     } else {
    //         this._setState({ msgErrQtty: "" })
    //     }
    // }

    // onBlurPrice = () => {
    //     const { this.props.placeQuickOrderCurrent } = this.state;
    //     const { orderTyp, quotePriceValid, stepPriceValid, limitPrice } = { ...this.props.placeQuickOrderCurrent };

    //     if (orderType === 'limit' && !quotePriceValid && !!limitPrice) {
    //         this._setState({ msgErrPrice: "trade.place-order.invalid-price-message" })
    //     } else if (orderType === 'limit' && !stepPriceValid && quotePriceValid && !!limitPrice) {
    //         this._setState({ msgErrPrice: "common.invalid-step-price" })
    //     } else {
    //         this._setState({ msgErrPrice: "" })
    //     }

    //     this.loadAvailableTradeData();
    // };

    genTotalPrice = () => {
        const { availableTrade, msgErrQtty, msgErrPrice } = this.state
        const { quote, currentSymbol, accountQuickOrderInfo } = this.props
        const { action, orderType, subOrderTypes,
            limitPrice, quantity, stepPriceValid,
            quotePriceValid, quantityValid,
            editingOrder, validUntilOrder } = { ...this.props.placeQuickOrderCurrent }
        const { typeAccount } = { ...accountQuickOrderInfo }
        let value = 0
        if (currentSymbol) {
            if (orderType === "limit") {
                value = parseFloat(limitPrice) * parseFloat(quantity)
            } else if (orderType === "market") {
                if (action === orderActions.BUY) {
                    value = parseFloat(quote.ceiling) * parseFloat(quantity)
                } else {
                    value = parseFloat(quote.floor) * parseFloat(quantity)
                }
            }

            if (typeAccount === TYPE_ACCOUNT.FDS) {
                value = currentSymbol.nvalue * value
            }
        }
        return value || 0
    }

    setQttyWithPercent = (value, isEdittingOrder) => {
        const { availableTrade, msgErrQtty, msgErrPrice } = this.state
        const { accountQuickOrderInfo, updatePlaceQuickOrder, currentSymbol } = this.props
        const { typeAccount } = { ...accountQuickOrderInfo }
        const { action } = { ...this.props.placeQuickOrderCurrent }
        if (isEdittingOrder) {
            return
        }

        if (currentSymbol) {
            let _value = null

            if (action === orderActions.BUY) {
                if (typeAccount === TYPE_ACCOUNT.FDS) {
                    _value = parseFloat(availableTrade.maxbuyqtty) * value
                } else {
                    _value = parseFloat(availableTrade.maxqtty) * value
                }
            } else if (action === orderActions.SELL) {
                if (typeAccount === TYPE_ACCOUNT.FDS) {
                    _value = parseFloat(availableTrade.maxsellqtty) * value
                } else {
                    _value = parseFloat(availableTrade.trade) * value
                }
            }

            if (_value) {
                let parsedPrice = parseInt(_value);
                if (typeAccount === TYPE_ACCOUNT.FDS) {

                }
                else {
                    if (parsedPrice <= 100) {

                    } else {
                        parsedPrice = Math.floor(parsedPrice)
                        parsedPrice = (parsedPrice - (parsedPrice % 100));
                    }
                }
                updatePlaceQuickOrder({
                    quantity: parsedPrice,
                }, this.props.orderTypeUse)
            }
        }
    }

    handleCheckAccountInBroker = (func, dataInforOrder, requestId) => {
        const { currentSymbol, placeQuickOrderCurrent, accountQuickOrderInfo } = this.props;
        const { typeAccount, curCustomer, currentAccountId
        } = { ...accountQuickOrderInfo }
        inquiryService.getCustomerAccounts(curCustomer.custodycd)
            .then((data) => {
                if (data) {
                    //Check tiểu khoản đang hiển thị có nằm trong tài khoàn call api không
                    let isAccountInBroker = data.some(item => currentAccountId === item.id);
                    if (!isAccountInBroker) {
                        ToastUtil.error('common.account-not-in-broker-err');
                        return;
                    }
                    else {
                        func(dataInforOrder, requestId);
                    }
                }
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-customer-acccounts-list');
                return;
            })
    }


    onReset = () => {
        const { orderTypeUse, updatedSymbolLayoutPage } = this.props
        this.props.clearDataPlaceQuickOrder(orderTypeUse)

        if (orderTypeUse === ORDER_TYPE_USE.NORMAL_ORDER) {
            updatedSymbolLayoutPage(null, "search_layout")
        }
    }

    onHandelFillPrice = (typePrice) => {
        const { quote, placeQuickOrderCurrent } = this.props
        const { orderType } = placeQuickOrderCurrent
        if (orderType === "condition") {
            return
        }
        let _price = quote ? quote[typePrice] : 0
        this.props.updatePlaceQuickOrder({
            limitPrice: _price || '',
            quotePriceValid: true,
            stepPriceValid: true,
            orderType: "limit",
            subOrderType: fullSubOrderTypes.LO
        }, this.props.orderTypeUse)
        //Focus vào ô nhập giá
        let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, ""); clearTimeout(timer) }, 0);
    }

    onHandelFillQtty = (qtty = 0) => {
        const { quote, placeQuickOrderCurrent } = this.props
        const { orderType } = placeQuickOrderCurrent
        if (orderType === "condition") {
            this.props.updatePlaceQuickOrderConditon({
                quantity: qtty,
            }, this.props.orderTypeUse)
        } else {
            this.props.updatePlaceQuickOrder({
                quantity: qtty,
            }, this.props.orderTypeUse)
        }
    }

    renderTypeOrderMarket = () => {
        const { availableTrade, msgErrQtty, msgErrPrice } = this.state
        const { quote, currentSymbol, accountQuickOrderInfo, isNormalOrder, placeQuickOrderCurrent } = this.props
        const { action, orderType, subOrderTypes, subOrderType,
            limitPrice, quantity, stepPriceValid,
            quotePriceValid, quantityValid,
            editingOrder, validUntilOrder } = { ...this.props.placeQuickOrderCurrent }
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }
        const isEdittingOrder = editingOrder != null;

        return (
            <div className="list-market custom-form-group price-select-order-type txt---400-14-20">
                <input className='custom-form-control text-center' value={subOrderType || ''} readOnly tabIndex={-1} />
            </div>
        )

        // if (currentSymbol) {
        //     if (currentSymbol.exchange === "UPCOM") {
        //         return (
        //             <div className="number">
        //                 {action === orderActions.BUY &&
        //                     <div  >
        //                         {
        //                             quote.exchange === derivativeExchanges[0] ? CommonUtils.roundToTwo(quote.ceiling, 1) : CommonUtils.roundToTwo(quote.ceiling, 1000)
        //                         }
        //                     </div>}
        //                 {action === orderActions.SELL &&
        //                     <div >
        //                         {
        //                             quote.exchange === derivativeExchanges[0] ? CommonUtils.roundToTwo(quote.floor, 1) : CommonUtils.roundToTwo(quote.floor, 1000)
        //                         }
        //                     </div>}
        //             </div>
        //         )
        //     } else {
        //         return (
        //             <div className="list-market custom-form-group-select price-select-order-type txt---400-14-20">
        //                 <select
        //                     ref={this.selectTypeOrderMarketRef}
        //                     onChange={(event) => this.onChangePriceOrderType(event)}
        //                     className="focus-item custom-pointer custom-form-select text-center"
        //                     disabled={subOrderTypes.length <= 1}
        //                 >
        //                     {subOrderTypes && subOrderTypes.map((item, key) => {
        //                         return (
        //                             <option key={key} value={item} selected={subOrderType == item ? true : false}>
        //                                 {item}
        //                             </option>
        //                         )
        //                     })}
        //                 </select>
        //             </div>
        //         )
        //     }
        // } else {
        //     return (
        //         <div className="list-market custom-form-group-select price-select-order-type txt---400-14-20">
        //             <select
        //                 ref={this.selectTypeOrderMarketRef}
        //                 onChange={(event) => this.onChangePriceOrderType(event)}
        //                 className="focus-item custom-pointer custom-form-select text-center"
        //                 disabled={subOrderTypes && subOrderTypes.length <= 1}
        //             >
        //                 {subOrderTypes && subOrderTypes.map((item, key) => {
        //                     return (
        //                         <option key={key} value={item} selected={subOrderType == item ? true : false}>
        //                             {item}
        //                         </option>
        //                     )
        //                 })}
        //             </select>
        //         </div>)
        // }
    }

    renderSubOrderTypeSelect = () => {
        const { availableTrade, msgErrQtty, msgErrPrice } = this.state
        const { quote, currentSymbol, accountQuickOrderInfo, isNormalOrder, placeQuickOrderCurrent, config } = this.props
        const { action, orderType,
            limitPrice, quantity, stepPriceValid,
            quotePriceValid, quantityValid, subOrderType,
            editingOrder, validUntilOrder } = { ...this.props.placeQuickOrderCurrent }
        const { curCustomer, accounts, currentAccountId } = { ...accountQuickOrderInfo }
        const isEdittingOrder = editingOrder != null;

        let subOrderTypes = this.getSubOrderType(config, currentSymbol.exchange);
        const shortSubOrder = subOrderTypes && subOrderTypes.length <= 4; // Nếu có nhỏ hơn 4 loại giá thì thu nhỏ lại sang bên phải
        if (CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER) { //Bỏ phần giá thị trường ở màn hình outright 
            subOrderTypes = []
        }
        if (subOrderTypes.length > 0) {
            subOrderTypes.unshift('LO');
            return (
                <div className="type-view present-order">
                    {/*shortSubOrder && (
                        <div className="content content-left item-center txt---400-14-20">

                        </div>
                    )*/}

                    <div className="content content-left item-center txt---400-14-20">

                    </div>

                    {/*<div className={"content item-center txt---400-14-20 " + (shortSubOrder ? 'content-right' : 'content-full')}>*/}
                    <div className={"content item-center txt---400-14-20 " + (this.isBroker() ? 'content-present-broker' : 'content-right')}>
                        {subOrderTypes.map((item, index) => {
                            return <div tabIndex={-1}
                                className={"focus-item select-present txt---400-12-20 " + ((subOrderType === item) || (subOrderType === '' && item === 'LO') ? 'active' : '')}
                                onClick={() => { this.onChangePriceSubOrderType(item, isEdittingOrder) }}
                                // onFocus={() => this.onFocusQttyWithPercent(item.value, isEdittingOrder, "qtty-percent" + index)}
                                id={"qtty-percent" + index}
                            >
                                {item}
                            </div>
                        })}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                </div>
            )
        }
    }

    isQuickOrder = () => {
        const { orderTypeUse } = this.props
        return orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER
    }

    onToDateChanged = (dates) => {
        // let _toDate = moment(dates[0]).format(config.app.SERVER_DATE_FORMAT)
        let _toDate = dates[0]
        //console.log("onToDateChanged", dates[0])
        this.props.updatePlaceQuickOrder({
            toDate: _toDate,
        }, this.props.orderTypeUse)
    };

    renderMinMaxPrice = () => {
        const { availableTrade, msgErrQtty, msgErrPrice } = this.state
        const { quote, currentSymbol, accountQuickOrderInfo, isNormalOrder, placeQuickOrderCurrent, instrument, } = this.props
        const { action, orderType, subOrderType, subOrderTypes,
            limitPrice, quantity, stepPriceValid,
            quotePriceValid, quantityValid,
            editingOrder, validUntilOrder, toDate } = { ...placeQuickOrderCurrent }

        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }

        let floorPrice = 0;
        let ceilPrice = 9999999999999;

        if (validUntilOrder === TYPE_DAY.DURING_DAY) {
            if (quote != null) {
                if (typeAccount === TYPE_ACCOUNT.FDS) {
                    floorPrice = CommonUtils.divide(quote.floor, 1)
                    ceilPrice = CommonUtils.divide(quote.ceiling, 1)
                } else {
                    floorPrice = CommonUtils.divide(quote.floor, 1000)
                    ceilPrice = CommonUtils.divide(quote.ceiling, 1000)
                }
            }
        } else if (validUntilOrder === TYPE_DAY.MUCH_DAY) {

        }
        //console.log("renderMinMaxPrice", floorPrice, ceilPrice)
        return {
            minPrice: floorPrice,
            maxPrice: ceilPrice
        }
    }

    isDisablePlaceDraftOrder = () => {
        const { quote, currentSymbol, accountQuickOrderInfo, isNormalOrder, placeQuickOrderCurrent, instrument, } = this.props
        const { action, orderType, subOrderType, subOrderTypes,
            limitPrice, quantity, stepPriceValid,
            quotePriceValid, quantityValid,
            editingOrder, validUntilOrder, toDate } = { ...this.props.placeQuickOrderCurrent }
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        const isEdittingOrder = editingOrder != null;

        if (validUntilOrder === TYPE_DAY.MUCH_DAY) {
            return true
        }
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            return true
        }
        if (isEdittingOrder) {
            return true
        }
    };

    callbackDocument = event => {
        const { editingOrder } = { ...this.props.placeQuickOrderCurrent }
        const isEdittingOrder = editingOrder != null;
        if (event && event.code === "Enter") {
            this.onOrderActionSelected(orderActions.BUY, isEdittingOrder)
            this.priceOrderRef.current && this.priceOrderRef.current.focus();
            // this.focusSearchSymbol()
        }
    }

    onFocusBuy = () => {
        document.getElementById('btn-buy-focus').addEventListener("keydown", this.callbackDocument);
    }

    onBlurBuy = () => {
        document.getElementById('btn-buy-focus').removeEventListener("keydown", this.callbackDocument);
    }

    callbackDocument2 = event => {
        const { editingOrder } = { ...this.props.placeQuickOrderCurrent }
        const isEdittingOrder = editingOrder != null;
        if (event && event.code === "Enter") {
            this.onOrderActionSelected(orderActions.SELL, isEdittingOrder)
            this.priceOrderRef.current && this.priceOrderRef.current.focus();
            // this.focusSearchSymbol()
        }
    }

    onFocusSell = () => {
        document.getElementById('btn-sell-focus').addEventListener("keydown", this.callbackDocument2);
    }

    onBlurSell = () => {
        document.getElementById('btn-buy-focus').removeEventListener("keydown", this.callbackDocument2);
    }

    onHandleKeyDownPriceOrder = (event) => {
        const keyCode = event.which || event.keyCode;
        let isDisablePlaceDraftOrder = this.isDisablePlaceDraftOrder()
        const { loadStatusQuickOrderModalTrade } = this.props;
        // console.log('laojahackgame======> CHECK ENTER 1', loadStatusQuickOrderModalTrade, this.state.activePlaceOrderStatus)
        if (loadStatusQuickOrderModalTrade === LoadStatus.LOADING) return;
        if (this.state.activePlaceOrderStatus === LoadStatus.LOADING) return;
        if (keyCode === KeyCodeUtils.ENTER) {
            this.placeOrder()
        }

        if (keyCode === KeyCodeUtils.TAB && isDisablePlaceDraftOrder) {
            if (this.isBroker()) {
                emitter.emit("onTabSymbolTrade_Broker", true)
            } else {
                emitter.emit("onTabSymbolTrade_Customer", true)
            }
            event.preventDefault();
        }
    }

    onHandleKeyDownDraftOrder = (event) => {
        const keyCode = event.which || event.keyCode;
        const { loadStatusQuickOrderModalTrade } = this.props;
        // console.log('laojahackgame======> CHECK ENTER 2', loadStatusQuickOrderModalTrade, this.state.activePlaceOrderStatus)
        if (loadStatusQuickOrderModalTrade === LoadStatus.LOADING) return;
        if (keyCode === KeyCodeUtils.ENTER) {
            this.placeDraftOrder()
        }
        if (keyCode === KeyCodeUtils.TAB) {
            if (this.isBroker()) {
                emitter.emit("onTabSymbolTrade_Broker", true)
            } else {
                emitter.emit("onTabSymbolTrade_Customer", true)
            }
            event.preventDefault();
        }
    }

    // onFocusOrderType = (value, isEdittingOrder, id) => {
    //     const { currentSymbol } = this.props
    //     const self = this
    //     document.getElementById(id).addEventListener("keydown", event => {
    //         if (event && event.code === "Enter") {
    //             this.onChangeActiveTypeOrder(value, isEdittingOrder)
    //             if (value === 'condition') {
    //                 self.focusSelectTypeOrderCondition()
    //             } else if (value === "limit") {
    //                 self.focusPriceInput();
    //             }
    //             else if (value === "market") {
    //                 if (currentSymbol && currentSymbol.exchange === "UPCOM") {
    //                     self.focusQuantityInput()
    //                 } else {
    //                     self.focusSelectTypeOrderMarket()
    //                 }
    //             }
    //         }
    //     });
    // }

    // onFocusQttyWithPercent = (value, isEdittingOrder, id) => {
    //     const self = this
    //     document.getElementById(id).addEventListener("keydown", event => {
    //         if (event && event.code === "Enter") {
    //             this.setQttyWithPercent(value, isEdittingOrder)
    //             self.focusRadioTypeDay();
    //         }
    //     });
    // }

    onFocusTypeDay = (value, id) => {
        const self = this
        document.getElementById(id).addEventListener("keydown", event => {
            if (event && event.code === "Enter") {
                if (value === TYPE_DAY.MUCH_DAY) {
                    this.onChangeValidUntilOrder(value)
                    self.focusDatePicker();
                } else if (value === TYPE_DAY.DURING_DAY) {
                    this.onChangeValidUntilOrder(value)
                    self.focusBtnPlaceOrder();
                }
            }
        });
    }

    setcurrentTabIndex = (index) => {
        this._setState({ currentTabIndex: index });
    }

    onPriceKeyDown = (item) => {
        if (item.target.selectionStart === 0 && item.keyCode === 37) {
            $('[TabIndex*="2"]') && $('[TabIndex*="2"]').focus();
        }
    }

    onFocusQuantity = (e) => {
        // const { updatePlaceQuickOrder } = this.props;
        // const { quantity } = this.props.placeQuickOrderCurrent;
        // // if (e.target.readOnly) return;

        // if (!quantity || quantity === 0 || quantity === "0") {
        //     updatePlaceQuickOrder({
        //         quantity: null,
        //     }, this.props.orderTypeUse)
        // }
    }


    renderPriceValid = () => {
        const { placeQuickOrderCurrent } = this.props;
        const { orderType: currentOrderType, quotePriceValid, stepPriceValid, limitPrice, isValidMarketPrice, validUntilOrder } = { ...placeQuickOrderCurrent };
        // const { isValidMarketPrice } = this.state;
        if (validUntilOrder === TYPE_DAY.MUCH_DAY) return (<Fragment></Fragment>);

        if (currentOrderType === 'limit' && !quotePriceValid && !!limitPrice) {
            return <label className="text-red" style={{ marginLeft: '5px' }} > <FormattedMessage id="trade.place-order.invalid-price-message" /></label>
        } else if (currentOrderType === 'limit' && !stepPriceValid && quotePriceValid && !!limitPrice) {
            return <label className="text-red" style={{ marginLeft: '5px' }} > <FormattedMessage id="common.invalid-step-price" /></label>
        } else if (currentOrderType === 'limit' && isValidMarketPrice === false) {
            return <label className="text-red" style={{ marginLeft: '5px' }} > <FormattedMessage id="common.invalid-market-price" /></label>
        } else return <Fragment></Fragment>;
    };

    renderQuantityValid = () => {
        const { placeQuickOrderCurrent } = this.props;
        const { quantityValid, quantity, validUntilOrder } = placeQuickOrderCurrent;

        if (!quantityValid && quantity) {
            return <label className="text-red" style={{ marginLeft: '5px' }}><FormattedMessage id="trade.place-order.invalid-quantity-message" /></label>
        } else return <Fragment></Fragment>
    };

    renderSplitQuantityValid = () => {
        const { placeQuickOrderCurrent } = this.props;
        const { splitQuantityValid, splitQuantity, quantity } = placeQuickOrderCurrent;

        if (splitQuantity && (Number(splitQuantity) % 100) !== 0 || (splitQuantity && quantity && (Number(splitQuantity) > Number(quantity))) || (splitQuantity && !splitQuantityValid)) {
            return <label className="text-red" style={{ marginLeft: '5px' }}><FormattedMessage id="FE004022" /></label>
        } else return <Fragment></Fragment>
    };


    handleEditCancelButton = () => {
        const { orderTypeUse, updatePlaceQuickOrder, clearDataPlaceQuickOrder } = this.props;

        clearDataPlaceQuickOrder(orderTypeUse);
        if (orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER) {
            // Nếu là quickorder thì mở tab sổ lệnh với filter là các lệnh chờ
            emitter.emit("onChangeTabActiveQuickOrder", 2);
            let timer = setTimeout(() => {
                emitter.emit("onChangeStatusQuickOrderBook", 2);
                clearTimeout(timer)
            }, 500);
        } else if (orderTypeUse === ORDER_TYPE_USE.NORMAL_ORDER) {
            // Nếu là đặt lệnh thường thì scroll xuống và active tab lệnh hoạt động
            let tabItem = {
                "id": "orderActive",
                "nameId": "trade.sections.titles.orderActive"
            }
            emitter.emit("SCROLL_TO_BOTTOM", tabItem);
        }

        updatePlaceQuickOrder({
            action: orderActions.DEFAULT,
        }, orderTypeUse)
    }

    // Focus vào ô giá nếu đang để giá trị 0 ====> Clear để nhập giá mới
    onFocusPrice = (e) => {
        this._setState({ priceFocus: true });
        // const { updatePlaceQuickOrder } = this.props;
        // const { limitPrice, action } = this.props.placeQuickOrderCurrent;
        // // if (action === orderActions.DEFAULT) return;

        // if (!limitPrice || limitPrice === 0 || limitPrice === "0") {
        //     updatePlaceQuickOrder({
        //         limitPrice: null,
        //     }, this.props.orderTypeUse)
        // }
    }

    onPriceBlur = (e) => {
        this._setState({ priceFocus: false });
    }

    handlerKeyDownQuantity = (e) => {
        const keyCode = e.which || e.keyCode;
        const { loadStatusQuickOrderModalTrade } = this.props;
        // console.log('laojahackgame======> CHECK ENTER 3', loadStatusQuickOrderModalTrade, this.state.activePlaceOrderStatus)
        if (loadStatusQuickOrderModalTrade === LoadStatus.LOADING) return;
        if (this.state.activePlaceOrderStatus === LoadStatus.LOADING) return;
        if (keyCode === KeyCodeUtils.ENTER) {
            this.btnPlaceOrderRef.current && this.btnPlaceOrderRef.current.click();
        }
    }

    handleBlurSymbolSearch = () => {
        this.debounceLoadAvailableTradeData();
    }


    onChangeOrderTypeTPRLOR = (e) => {
        let value = e.target.value
        if (value) {
            let obj = {
                typeOrderSelectdTPRLOR: value,
            }
            if (value == LIST_TYPE_DEFAULT_TPRL_OR.AGREEMENT) {
                obj.partyIdTPRLOR = null
                obj.custodycdPartnerTPRLOR = null
            }
            this.props.updatePlaceQuickOrder(obj, this.props.orderTypeUse)
        }
    }

    onChangePaymentTypeTPRLOR = (e) => {
        let value = e.target.value
        if (value) {
            this.props.updatePlaceQuickOrder({
                typePaymentTPRLOR: value,
            }, this.props.orderTypeUse)
        }
    }


    onChangeCustodycdPartnerTPRLOR = (e) => {
        let value = e.target.value;
        if (value && value.length > 9) {
            this.props.updatePlaceQuickOrder({
                partyIdTPRLOR: value.substring(0, 3)
            }, this.props.orderTypeUse)
        }
        this.props.updatePlaceQuickOrder({
            custodycdPartnerTPRLOR: value,
        }, this.props.orderTypeUse)

    }

    onChangePartyIdTPRLOR = (e) => {
        let value = e.target.value;
        this.props.updatePlaceQuickOrder({
            partyIdTPRLOR: value,
        }, this.props.orderTypeUse)

    }

    render() {
        // Exclude some props from otherProps
        const { availableTrade, msgErrQtty, msgErrPrice } = this.state
        const { quote, currentSymbol, accountQuickOrderInfo, isNormalOrder, placeQuickOrderCurrent, instrument, clientConfig, config } = this.props
        const { action, orderType, subOrderType, subOrderTypes,
            limitPrice, quantity, splitQuantity, splitQuantityValid, isCheckSplitOrder, stepPriceValid,
            quotePriceValid, quantityValid,
            editingOrder, validUntilOrder, toDate, isValidMarketPrice, typeOrderSelectdTPRLOR, custodycdPartnerTPRLOR, partyIdTPRLOR, typePaymentTPRLOR } = { ...this.props.placeQuickOrderCurrent }
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountQuickOrderInfo }
        const isEdittingOrder = editingOrder != null;
        let editingAction = false
        let instrumentQtty = {
            EX: quote && quote.exchange,
            ST: quote && quote.StockType
        }
        const isDefaultState = action === orderActions.DEFAULT;
        let permission = this.checkPermission();
        const orderInputValid = this.isOrderInputValid();
        const orderActionsEnable = currentAccountId && currentSymbol && quote && orderInputValid;
        // let isDisablePlaceOrder = ((permission === false) ? true : !orderActionsEnable)
        let isDisablePlaceDraftOrder = this.isDisablePlaceDraftOrder()
        let totalPrice = this.genTotalPrice()
        let stepInputQuantity = currentSymbol && quote ? (currentSymbol.exchange == EXCHANGE.HOSE ? 100 : (quote.exchange === derivativeExchanges[0] ? 1 : 100)) : 1
        let { minPrice, maxPrice } = this.renderMinMaxPrice()
        const isBroker = this.isBroker();
        // Thêm eventTab và mũi tên trái để shifttab cho các element có tabIndex !=-1
        this.addFocusEvent();
        const subOrderTypesSelects = currentSymbol ? this.getSubOrderType(config, currentSymbol.exchange) : [];
        // //console.log('laojahackgame=======> RENDER');
        return (
            // <CustomScrollbars disableHorizontalScroll={true}>
            <RetryableContent className="" status={this.state.loadStatus} disableRetry={true}>
                <div className={"place-order-quick-order container-radio-order " + (this.isQuickOrder() ? "" : "place-normal-order")} ref={this.parentRef}>
                    {/* <CustomScrollbars
                        className="place-order-quick-order-scroll-container"> */}
                    <div className="block-place-order-quick-order first-block">


                        <SearchSymbolQuickOrder
                            isEdittingOrder={isEdittingOrder}
                            orderTypeUse={this.props.orderTypeUse}
                            placeQuickOrderCurrent={placeQuickOrderCurrent}
                            currentSymbol={currentSymbol}
                            updatePlaceQuickOrder={this.props.updatePlaceQuickOrder}
                            accountQuickOrderInfo={accountQuickOrderInfo}
                            symbolSearchRef={this.symbolSearchRef}
                            usePortfolioList={!isEdittingOrder && !isDefaultState && action && action === orderActions.SELL} // Có sử dụng danh sách mã trong danh mục không
                            handleBlur={this.handleBlurSymbolSearch}
                        />

                        <BlockPlacePrice
                            orderTypeUse={this.props.orderTypeUse}
                            symbolId={currentSymbol && currentSymbol.id}
                            accountQuickOrderInfo={accountQuickOrderInfo}
                            quote={quote}
                            onHandelFillPrice={this.onHandelFillPrice}
                            onHandelFillQtty={this.onHandelFillQtty}
                            availableTrade={availableTrade}
                            typeAccount={typeAccount}
                            action={action}
                            isBroker={isBroker}
                        />

                        <div className="choose-buy-sell">
                            <button className={this.getTabClasses(orderActions.BUY, action, isEdittingOrder, editingAction)}
                                onClick={() => { this.onOrderActionSelected(orderActions.BUY, isEdittingOrder) }}
                                id="btn-buy-focus"
                                onFocus={this.onFocusBuy}
                                onBlur={this.onBlurBuy}
                                tabIndex={0}
                                style={{ textTransform: 'uppercase' }}
                            >
                                <span className="txt---500-14-20">
                                    <FormattedMessage id="quick-order.place-order.buy" />
                                </span>
                            </button>

                            <button className={this.getTabClasses(orderActions.SELL, action, isEdittingOrder, editingAction)}
                                onClick={() => { this.onOrderActionSelected(orderActions.SELL, isEdittingOrder) }}
                                id="btn-sell-focus"
                                onFocus={this.onFocusSell}
                                onBlur={this.onBlurSell}
                                tabIndex={0}
                                style={{ textTransform: 'uppercase' }}
                            >
                                <span className="txt---500-14-20">
                                    <FormattedMessage id="quick-order.place-order.sell" />
                                </span>
                            </button>

                            {CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER
                                ? <></>
                                : <button
                                    className={'edit-status-btn focus-item ' + (isEdittingOrder || isDefaultState ? 'editting' : '')}
                                    onClick={() => {
                                        if (this.props.editOnly) return;
                                        this.handleEditCancelButton();
                                    }}
                                    tabIndex={-1}
                                // id="btn-sell-focus"
                                // onFocus={this.onFocusSell}
                                >
                                    <span className="txt---400-12-18">
                                        <FormattedMessage id="trade.place-order.edit_cancel" />
                                    </span>
                                </button>
                            }
                        </div>

                        <BlockCapacityContainer
                            symbolId={currentSymbol && currentSymbol.id}
                            accountQuickOrderInfo={accountQuickOrderInfo}
                            quote={quote}
                            onHandelFillPrice={this.onHandelFillPrice}
                            onHandelFillQtty={this.onHandelFillQtty}
                            availableTrade={availableTrade}
                            typeAccount={typeAccount}
                            action={action}
                            isBroker={isBroker}
                        />
                    </div>

                    <div className="block-place-order-quick-order">

                        {/*<div className="type-view type-order">
                            <div className="content content-left text-left txt---500-14-20">
                                <FormattedMessage id="quick-order.place-order.type-order" />&nbsp;
                                {clientConfig.configData && clientConfig.configData.order_guide_link ? (<a tabIndex={-1} href={clientConfig.configData.order_guide_link} target='_blank'>
                                    <IconInfoActive style={{ cursor: "pointer" }} />
                                </a>) : (<IconInfoActive style={{ cursor: "pointer" }} />)}
                            </div>
                            <div className="content content-right">
                                {listOrderType.map((item, index) => {
                                    return <div tabIndex="0" className={"select-type txt---400-14-20 " + (item.value === orderType ? "active " + (action === orderActions.BUY ? 'bg-buy' : 'bg-sell ') : "")}
                                        onFocus={() => this.onFocusOrderType(item.value, isEdittingOrder, "order-type" + index)}
                                        id={"order-type" + index}
                                        onClick={() => { this.onChangeActiveTypeOrder(item.value, isEdittingOrder) }}>
                                        <FormattedMessage id={item.title} />
                                    </div>
                                })}
                            </div>
                        </div>*/}

                        {orderType === "condition"
                            ?
                            <ConditionOrder
                                orderTypeUse={this.props.orderTypeUse}
                                availableTrade={this.state.availableTrade}
                                selectTypeOrderConditionRef={this.selectTypeOrderConditionRef}
                            />
                            :
                            <div>
                                {CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER &&
                                    <div className="type-view select-option-tprl-or" style={{ marginBottom: "10px" }}>
                                        <div className="content content-left text-left txt---500-14-20 txt-near-active">
                                            <FormattedMessage id="quick-order.place-order.type-order" />
                                        </div>
                                        <div className={"content content-right item-center txt---400-14-20 "}>
                                            <div className="custom-form-group-select">
                                                <select tabindex={0} ref={this.TPRLOR} className={"txt---500-14-20 custom-form-select focus-item"} value={typeOrderSelectdTPRLOR ? typeOrderSelectdTPRLOR : ''} onChange={this.onChangeOrderTypeTPRLOR}>
                                                    {_.map(listOrderTypeTPRLOR, (item, index) => {
                                                        return (<option key={index} value={item.value} selected={typeOrderSelectdTPRLOR && typeOrderSelectdTPRLOR == item.value ? true : false}>
                                                            {LanguageUtils.getMessageByKey(item.title, this.props.language)}
                                                        </option>);
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="type-view price-order">
                                    <div className="content content-left text-left txt---500-14-20 txt-near-active">
                                        <FormattedMessage id="quick-order.place-order.price" />
                                    </div>

                                    <div className={"content item-center txt---400-14-20 " + (this.isBroker() && CommonUtils.getOrderTypeUseLayout() != ORDER_TYPE_USE.TPRL_NM_ORDER && CommonUtils.getOrderTypeUseLayout() != ORDER_TYPE_USE.TPRL_OR_ORDER ? 'content-broker-price' : 'content-right')}>
                                        <div className="number price-input-suggest">
                                            <PriceInput
                                                name="limit-price"
                                                ref={this.priceOrderRef}
                                                subOrderTypes={subOrderTypesSelects}
                                                quote={this.props.quote}
                                                step={10}
                                                min={null}
                                                max={999999999999}
                                                value={limitPrice}
                                                // orderType={currentOrderType}
                                                // subOrderType={selectedSubOrderType}
                                                orderType={orderType}
                                                subOrderType={subOrderType}
                                                valid={quotePriceValid && isValidMarketPrice}
                                                validStep={stepPriceValid}
                                                onChange={this.onPriceChanged}
                                                onKeyDown={this.onInputKeyDown}
                                                onBlur={this.onPriceBlur}
                                                allowZero={false}
                                                allowDecimal={true}
                                                allowNegative={false}
                                                // isEdittingOrder={isEdittingOrder}
                                                readOnly={isDefaultState}
                                                onFocus={this.onFocusPrice}
                                                decimalScale={3}
                                                disabled={isDefaultState}
                                                tabIndex={0}
                                                id='quick-order-input' // check focus
                                            />
                                        </div>
                                        {/*orderType === "limit" &&
                                                <PriceInputOrder
                                                    name="limit-price"
                                                    ref={this.priceOrderRef}
                                                    subOrderTypes={""} // Hay gây lỗi ===> Kệ , khách hàng là bố
                                                    quote={this.props.quote ? this.props.quote : ""}
                                                    step={10}
                                                    min={minPrice}
                                                    max={maxPrice}
                                                    value={limitPrice}
                                                    orderType={orderType}
                                                    subOrderType={subOrderType}
                                                    valid={quotePriceValid}
                                                    validStep={stepPriceValid}
                                                    onChange={this.onPriceChanged}
                                                    onKeyDown={this.onPriceKeyDown}
                                                    onBlur={this.onBlurPrice}
                                                    allowZero={false}
                                                    allowDecimal={true}
                                                    allowNegative={false}
                                                    onFocus={this.onFocusPrice}
                                                    decimalScale={2}
                                                    onReLoadValue={true}
                                                    tabIndex={0}
                                                />



                                            </div>
                                        */}
                                        {/*orderType === "market" && this.renderTypeOrderMarket()*/}
                                    </div>
                                </div>

                                <div className="type-view error">
                                    <div className="content content-left text-left txt---500-14-20">

                                    </div>

                                    <div className="content content-right item-center txt---400-14-20">
                                        {this.renderPriceValid()}
                                    </div>
                                </div>
                                {currentSymbol && msgErrPrice &&
                                    <div className="type-view" style={{ height: "28px" }}>
                                        <div className="content content-left text-left">
                                        </div>
                                        <div className="content content-right item-center txt---400-14-20">
                                            <span className="text-red" style={{ marginLeft: '5px' }} > <FormattedMessage id={msgErrPrice} /></span>
                                        </div>
                                    </div>
                                }


                                {currentSymbol && this.renderSubOrderTypeSelect()}

                                <div className="type-view price-order">
                                    <div className="content content-left text-left txt---500-14-20 txt-near-active">
                                        <FormattedMessage id="quick-order.place-order.qtty" />
                                    </div>
                                    <div className={"content item-center txt---400-14-20 " + (this.isBroker() && CommonUtils.getOrderTypeUseLayout() != ORDER_TYPE_USE.TPRL_NM_ORDER && CommonUtils.getOrderTypeUseLayout() != ORDER_TYPE_USE.TPRL_OR_ORDER ? 'content-broker-qtty' : 'content-right')}>
                                        <div className="number qtty-input-suggest">
                                            <QuantityInputOrder
                                                name="quantity"
                                                step={stepInputQuantity}
                                                min={null}
                                                max={999999999999}
                                                instrument={instrumentQtty}
                                                value={quantity}
                                                valid={quantityValid}
                                                onChange={this.onQuantityChanged}
                                                // onKeyDown={this.onInputKeyDown}
                                                allowZero={false}
                                                allowDecimal={false}
                                                allowNegative={false}
                                                orderType={orderType}
                                                ref={this.quantityRef}
                                                // onFocus={this.onFocusQtty}
                                                onBlur={this.onBlurQtty}
                                                readOnly={(isEdittingOrder && (currentSymbol && currentSymbol.exchange == EXCHANGE.HOSE)) || isDefaultState}
                                                disabled={(isEdittingOrder && (currentSymbol && currentSymbol.exchange == EXCHANGE.HOSE)) || isDefaultState}
                                                isNotOddLot={validUntilOrder === TYPE_DAY.MUCH_DAY}
                                                onFocus={this.onFocusQuantity}
                                                onKeyDown={this.handlerKeyDownQuantity}
                                                tabIndex={0}
                                                quote={this.props.quote}
                                            />
                                        </div>
                                    </div>
                                    {this.isBroker() && !this.props.editOnly && !isDefaultState &&
                                        CommonUtils.getOrderTypeUseLayout() != ORDER_TYPE_USE.TPRL_OR_ORDER &&
                                        CommonUtils.getOrderTypeUseLayout() != ORDER_TYPE_USE.TPRL_NM_ORDER &&
                                        <div className="content item-center txt---400-14-20 content-broker-split-order">
                                            <TextTooltip
                                                placement='top'
                                                tooltipText={<FormattedMessage id="trade.place-order.split-order-check" />}
                                                targetID={`split-order-check`}
                                            ></TextTooltip>
                                            <input
                                                id='split-order-check'
                                                type='checkbox' className='input-checkbox'
                                                checked={isCheckSplitOrder}
                                                onChange={() => this.onCheckSplitOrder(!isCheckSplitOrder)}
                                                tabIndex={-1}
                                            />
                                            {/*<label className='label txt---400-14-20'>
                                                <FormattedMessage id="trade.place-order.split-order-check" />
                                            </label>*/}
                                        </div>
                                    }

                                    {(this.isBroker() && (this.props.editOnly || isDefaultState)) &&
                                        CommonUtils.getOrderTypeUseLayout() != ORDER_TYPE_USE.TPRL_OR_ORDER &&
                                        CommonUtils.getOrderTypeUseLayout() != ORDER_TYPE_USE.TPRL_NM_ORDER &&
                                        <div className="content item-center txt---400-14-20 content-broker-split-order disable">
                                            <input type='checkbox' className='input-checkbox'
                                                checked={false}
                                                onChange={() => this.onCheckSplitOrder(!isCheckSplitOrder)}
                                                tabIndex={-1}
                                                disabled
                                            />
                                            {/*<label className='label txt---400-14-20'>
                                                <FormattedMessage id="trade.place-order.split-order-check" />
                                            </label>*/}
                                        </div>
                                    }
                                </div>

                                <div className="type-view error">
                                    <div className="content content-left text-left txt---500-14-20">

                                    </div>

                                    <div className="content content-right item-center txt---400-14-20">
                                        {this.renderQuantityValid()}
                                    </div>
                                </div>

                                {isCheckSplitOrder &&
                                    (
                                        <Fragment >
                                            <div className="type-view price-order">
                                                <div className="content content-left text-left txt---500-14-20 txt-near-active">
                                                    <FormattedMessage id="trade.place-order.split-qtty" />
                                                </div>
                                                <div className={"content item-center txt---400-14-20 content-broker-qtty"}>
                                                    <div className="number qtty-input-suggest">
                                                        <QuantityInputOrder
                                                            name="split-quantity"
                                                            step={stepInputQuantity}
                                                            min={null}
                                                            max={999999999999}
                                                            instrument={instrumentQtty}
                                                            value={splitQuantity}
                                                            valid={splitQuantityValid}
                                                            onChange={this.onSplitQuantityChanged}
                                                            // onKeyDown={this.onInputKeyDown}
                                                            allowZero={false}
                                                            allowDecimal={false}
                                                            allowNegative={false}
                                                            orderType={orderType}
                                                            ref={this.splitQuantityRef}
                                                            // onFocus={this.onFocusQtty}
                                                            onBlur={this.onBlurQtty}
                                                            readOnly={isEdittingOrder && (currentSymbol && currentSymbol.exchange == EXCHANGE.HOSE)}
                                                            isNotOddLot={validUntilOrder === TYPE_DAY.MUCH_DAY}
                                                            // onFocus={this.onFocusQuantity}
                                                            tabIndex={-1}
                                                            quote={this.props.quote}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="type-view error">
                                                <div className="content content-left text-left txt---500-14-20">

                                                </div>

                                                <div className="content content-right item-center txt---400-14-20">
                                                    {this.renderSplitQuantityValid()}
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                                {currentSymbol && msgErrQtty &&
                                    <div className="type-view" style={{ height: "28px" }}>
                                        <div className="content content-left text-left">

                                        </div>
                                        <div className="content content-right item-center txt---400-14-20">
                                            <label className="text-red" style={{ marginLeft: '5px' }} > <FormattedMessage id={msgErrQtty} /></label>
                                        </div>
                                    </div>
                                }
                                {CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER &&
                                    <div className="type-view input-tprl-or" style={{ marginBottom: "10px" }}>
                                        <div className="content content-left text-left txt---500-14-20 txt-near-active">
                                            {/* Số tài khoản đối ứng */}
                                            <FormattedMessage id="quick-order.place-order.custodycd-partner" />
                                        </div>
                                        <div className={"content content-right item-center txt---400-14-20 "}>
                                            <div className="custom-form-group">
                                                <input
                                                    ref={this.inputSymbol}
                                                    type="text"
                                                    id='custodycdPartnerTPRLOR-selector'
                                                    name="custodycdPartnerTPRLOR"
                                                    maxLength="10"
                                                    className="custom-form-control txt---500-14-20"
                                                    value={custodycdPartnerTPRLOR ? custodycdPartnerTPRLOR : ""}
                                                    onChange={this.onChangeCustodycdPartnerTPRLOR}
                                                    // onKeyDown={this.handleKeyDown}
                                                    // onBlur={this.onBlurCustodycdPartnerTPRLOR}
                                                    disabled={typeOrderSelectdTPRLOR == LIST_TYPE_DEFAULT_TPRL_OR.AGREEMENT}
                                                    autoComplete="off"
                                                    tabIndex={0}
                                                    placeholder={LanguageUtils.getMessageByKey("quick-order.place-order.input", this.props.language)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER &&
                                    <div className="type-view input-tprl-or" style={{ marginBottom: "10px" }}>
                                        <div className="content content-left text-left txt---500-14-20 txt-near-active">
                                            {/* Mã thành viên */}
                                            <FormattedMessage id="quick-order.place-order.party-id" />
                                        </div>
                                        <div className={"content content-right item-center txt---400-14-20 "}>
                                            <div className="custom-form-group">
                                                <input
                                                    ref={this.inputSymbol}
                                                    type="text"
                                                    id='partyIdTPRLOR-selector'
                                                    name="partyIdTPRLOR"
                                                    // maxLength="10"
                                                    className="custom-form-control txt---500-14-20"
                                                    value={partyIdTPRLOR ? partyIdTPRLOR : ""}
                                                    onChange={this.onChangePartyIdTPRLOR}
                                                    // onKeyDown={this.handleKeyDown}
                                                    onBlur={this.handleBlur}
                                                    // disabled={typeOrderSelectdTPRLOR == LIST_TYPE_DEFAULT_TPRL_OR.AGREEMENT}
                                                    autoComplete="off"
                                                    tabIndex={0}
                                                    placeholder={LanguageUtils.getMessageByKey("quick-order.place-order.input", this.props.language)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER &&
                                    <div className="type-view select-option-tprl-or" style={{ marginBottom: "10px" }}>
                                        <div className="content content-left text-left txt---500-14-20 txt-near-active">
                                            {/* Hình thức TT */}
                                            <FormattedMessage id="quick-order.place-order.type-payment" />
                                        </div>
                                        <div className={"content content-right item-center txt---400-14-20 "}>
                                            <div className="custom-form-group-select">
                                                <select tabindex={0} ref={this.TPRLOR} className={"txt---500-14-20 custom-form-select focus-item"} value={typePaymentTPRLOR ? typePaymentTPRLOR : ''} onChange={this.onChangePaymentTypeTPRLOR}>
                                                    {_.map(listPaymentTPRLOR, (item, index) => {
                                                        return (<option key={index} value={item.id} selected={typePaymentTPRLOR && typePaymentTPRLOR == item.value ? true : false}>
                                                            {LanguageUtils.getMessageByKey(item.title, this.props.language)}
                                                        </option>);
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_NM_ORDER || CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER ? <>
                                </> :
                                    <div className="type-view valid-until-order">
                                        <div className="content content-left text-left txt---500-14-20 txt-near-active">
                                            <FormattedMessage id="quick-order.place-order.effect" />
                                        </div>
                                        <div className="content content-right item-center txt---400-14-20">
                                            <div className={"contaier-input " + (action === orderActions.BUY ? 'contaier-input-buy' : 'contaier-input-sell')}>
                                                <div className="radio-left">
                                                    <label className='container-radio txt-near-active' htmlFor="during-day">
                                                        <span className="txt---400-14-20"> <FormattedMessage id="quick-order.place-order.in-day" /></span>
                                                        <input
                                                            ref={this.radioTypeDayRef}
                                                            id="during-day"
                                                            type="radio"
                                                            name="during-day"
                                                            value={TYPE_DAY.DURING_DAY}
                                                            checked={validUntilOrder === TYPE_DAY.DURING_DAY ? true : false}
                                                            onFocus={() => this.onFocusTypeDay(TYPE_DAY.DURING_DAY, "during-day")}
                                                            onChange={() => this.onChangeValidUntilOrder(TYPE_DAY.DURING_DAY)}
                                                            tabIndex={-1}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className={"radio-right " + (this.isBroker() ? 'disable' : '')}>
                                                    <label className='container-radio txt-near-active' htmlFor="much-day">
                                                        <span className="txt---400-14-20"><FormattedMessage id="quick-order.place-order.much-day" /></span>
                                                        {this.isBroker() ?
                                                            <Fragment>
                                                                <input
                                                                    id="much-day"
                                                                    type="radio"
                                                                    name="much-day"
                                                                    value={TYPE_DAY.MUCH_DAY}
                                                                    checked={validUntilOrder === TYPE_DAY.MUCH_DAY ? true : false}
                                                                    onFocus={() => this.onFocusTypeDay(TYPE_DAY.MUCH_DAY, "much-day")}
                                                                    onChange={() => this.onChangeValidUntilOrder(TYPE_DAY.MUCH_DAY)}
                                                                    disabled
                                                                />
                                                                <span className="checkmark"></span>
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                <input
                                                                    id="much-day"
                                                                    type="radio"
                                                                    name="much-day"
                                                                    value={TYPE_DAY.MUCH_DAY}
                                                                    checked={validUntilOrder === TYPE_DAY.MUCH_DAY ? true : false}
                                                                    onFocus={() => this.onFocusTypeDay(TYPE_DAY.MUCH_DAY, "much-day")}
                                                                    onChange={() => this.onChangeValidUntilOrder(TYPE_DAY.MUCH_DAY)}
                                                                    tabIndex={-1}
                                                                />
                                                                <span className="checkmark"></span>
                                                            </Fragment>

                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {validUntilOrder === TYPE_DAY.MUCH_DAY &&
                                    <div className="type-view select-date">
                                        <div className="content content-left text-left ">
                                        </div>
                                        <div className="content content-right item-center txt---400-14-20 txt-near-active">
                                            <div className='txt---500-14-20'><FormattedMessage id="common.to-date" /></div>
                                            <div className="custom-form-group-n">
                                                <DatePicker
                                                    className="focus-item custom-form-control text-left txt---400-14-20 txt-near-active"
                                                    value={toDate}
                                                    onChange={this.onToDateChanged}
                                                    tabIndex={0}
                                                    ref={this.datePickerRef}
                                                />
                                                {/*  */}
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="type-view ">
                                    <div className={"action-container "}>
                                        <button
                                            id="btn-place-quick-order"
                                            ref={this.btnPlaceOrderRef}
                                            className={"focus-item txt---700-14-20 btn-action item-center " + (CommonUtils.getClassbyAction(action))}
                                            onKeyDown={this.onHandleKeyDownPriceOrder}
                                            onClick={this.placeOrder}
                                            tabIndex={0}
                                            disabled={isDefaultState}
                                        >
                                            {isEdittingOrder ? <span><FormattedMessage id="quick-order.place-order.edit" />&nbsp;</span> : ""}
                                            {
                                                action === orderActions.BUY && <FormattedMessage id="quick-order.place-order.buy" />
                                            }
                                            {
                                                action === orderActions.SELL && <FormattedMessage id="quick-order.place-order.sell" />
                                            }
                                            {/**VIX: khi ấn nút hủy/sửa không xác định trạng thái ====> Hiển thị chữ sửa và disable */}
                                            {
                                                isDefaultState && <FormattedMessage id="quick-order.place-order.edit" />
                                            }
                                            {
                                                !action && <FormattedMessage id="common.transaction" />
                                            }
                                            {totalPrice !== 0 &&
                                                <span >
                                                    : <FormattedPrice value={Number(totalPrice)} shortForm={false} isNotThousand={typeAccount === TYPE_ACCOUNT.FDS ? true : false} />
                                                </span>
                                            }
                                        </button>
                                        {/**VIX : Hiện bỏ lệnh nháp trong parse này */}
                                        {/*<button id="icon-cart-dart-quick-order-1" className={"focus-item txt---700-14-20 btn-action item-center btn-draf-order"} disabled={isDisablePlaceDraftOrder} onClick={this.placeDraftOrder} onKeyDown={this.onHandleKeyDownDraftOrder}>
                                            <img src={IconCartDark} alt="icon-edit" />
                                        </button>*/}
                                    </div>
                                </div>

                                {/*isEdittingOrder && <div className="type-view ">
                                    <div className={"action-container "}>
                                        <button className="txt---700-14-20 btn-cancel-input btn-action" onClick={this.onReset}>
                                            <FormattedMessage id="quick-order.place-order.cancel" />
                                        </button>
                                    </div>
                                </div>*/}
                            </div>
                        }
                    </div>
                    {/*{this.isQuickOrder() &&
                        <>
                            <div className="line-divide"></div>
                            <div className="block-place-order-quick-order block-mixed-order-book">
                                <MixedOrderbook
                                    mode='dual'
                                    currentSymbol={currentSymbol}
                                />
                            </div>
                        </>
                    }*/}

                    {/* </CustomScrollbars> */}
                </div >
            </RetryableContent >
            // </CustomScrollbars>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    const { orderTypeUse } = ownProps
    let _placeQuickOrderCurrent = null
    let currentSymbol = null
    let quote = null
    let _accountQuickOrderInfo = null
    let _currentLayoutPageActive = null

    if (orderTypeUse === ORDER_TYPE_USE.QUICK_ORDER) {
        _currentLayoutPageActive = "C&B"
        let _placeQuickOrder = state.orderNew.placeQuickOrder

        currentSymbol = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive] && _placeQuickOrder[_currentLayoutPageActive]["symbolCurrent"] ? _placeQuickOrder[_currentLayoutPageActive]["symbolCurrent"] : null
        quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]]
        _placeQuickOrderCurrent = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive]
        _accountQuickOrderInfo = _placeQuickOrderCurrent && _placeQuickOrderCurrent["accountQuickOrderInfo"]

    } else if (orderTypeUse === ORDER_TYPE_USE.NORMAL_ORDER) {
        _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        let _placeNormalOrder = state.orderNew.placeNormalOrder
        currentSymbol = _currentLayoutPageActive && _placeNormalOrder[_currentLayoutPageActive] && _placeNormalOrder[_currentLayoutPageActive]["symbolCurrent"] ? _placeNormalOrder[_currentLayoutPageActive]["symbolCurrent"] : null
        quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]]

        let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
        _accountQuickOrderInfo = _layoutPage && _layoutPage["accountInfo"]
        _placeQuickOrderCurrent = _currentLayoutPageActive && _placeNormalOrder[_currentLayoutPageActive]
        // //console.log('laojahackgame======> CURRENT SYMBOLS PLACE QUICK ORDER', { currentSymbol, orderTypeUse, _currentLayoutPageActive, _placeNormalOrder });
    }
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;
    const instrument = currentSymbol ? state.symbol.instruments[currentSymbol.id] || {} : {};
    const clientConfig = state.app.config ? state.app.config : {};
    // console.log('laojahackgame======> CHECK ENTER 456', state.layout.listIsOpenModal["C&B"]["QuickOrder"].isOpenModalTradeQuickOrder)

    return {
        config: state.user.userInfo.config,
        permissionInfo: permissionInfo,
        userInfo: userInfo,
        currentSymbol: currentSymbol,
        instrument: instrument,
        placeQuickOrderCurrent: _placeQuickOrderCurrent,
        accountQuickOrderInfo: _accountQuickOrderInfo,

        quote,
        language: state.app.language,
        role: state.user.userInfo.role,
        showReconfirmOrderConfig: state.app.showReconfirmOrderConfig,

        socketSEAction: state.socketRealtime.socketSEAction,
        socketPPAction: state.socketRealtime.socketPPAction,
        socketDOAction: state.socketRealtime.socketDOAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        currentDate: state.app.currentDate,
        defaultTheme: state.user.userInfo.defaultTheme,
        clientConfig: clientConfig,
        focusOnRender: state.orderNew.focusOnRender,
        loadStatusQuickOrderModalTrade: state.layout.listIsOpenModal["C&B"]["QuickOrder"].loadStatusQuickOrderModalTrade || '',
        isOpenModalTradeQuickOrder: state.layout.listIsOpenModal["C&B"]["QuickOrder"].isOpenModalTradeQuickOrder
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        fetchSymbolOrderbook: (symbolId) => dispatch(actions.fetchSymbolOrderbook(symbolId)),
        updatePlaceQuickOrderConditon: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrderConditon(data, typeOrder)),
        saveTradeAuthenticated: () => dispatch(actions.saveTradeAuthenticated()),
        setTradeAuthSuccess: () => dispatch(actions.setTradeAuthSuccess()),
        updatedSymbolLayoutPage: (symbolId, from, id) => dispatch(actions.updatedSymbolLayoutPage(symbolId, from, id)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setFocusOnRenderOrder: (focusKey) => dispatch(actions.setFocusOnRenderOrder(focusKey)),
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PlaceOrderQuickOrder));
